<template>
  <v-row>
    <v-col class="pr-4">
      <v-text-field
        v-model="sync_value"
        :label="label"
        :prefix="prefix"
        :suffix="suffix"
        :readonly="readonly"
        :disabled="disabled"
        :rules="rules"
        :ref="re"
        :clearable="clearable"
        :filled="filled"
        :type="type"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :precision="precision"
        v-currency="{
          currency: currency,
          locale: locale,
          precision: precision,
        }"
        :dense="dense"
        :hint="hint"
        :reverse="reverse"
        v-bind="$attrs"
      >
        <v-tooltip
          slot="append-outer"
          left
          max-width="400"
          v-if="tooltip != ''"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              {{ $i.mdiInformation }}
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
        <template v-for="(index, name) in $slots" v-slot:[name]>
          <slot :name="name"></slot>
        </template>

        <slot></slot>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { CurrencyDirective, parse } from "vue-currency-input"
export default {
  directives: {
    currency: CurrencyDirective,
  },
  computed: {
    sync_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
    numberValue() {
      return parse(this.value, {
        suffix: this.suffix,
        precision: this.precision,
        locale: this.locale,
        currency: this.currency,
      })
    },
  },
  props: {
    label: String,
    value: {},
    reverse: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    suffix: { type: String, default: "" },
    prefix: { type: String, default: "" },
    prependIcon: { type: String, default: "" },
    appendIcon: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    type: { type: String, default: "" },
    re: String,
    rules: {},
    clearable: {},
    filled: { type: Boolean, default: false },
    currency: { default: "EUR" },
    locale: { type: String, default: "de" },
    precision: { type: Number, default: 0 },
    dense: { type: Boolean, default: false },
    hint: { type: String, default: "" },
  },
  data() {
    return {}
  },
}
</script>
