<template>
  <v-row>
    <v-col class="pr-4">
      <v-menu
        v-model="inputMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :label="label"
            v-model="formatted_value"
            :readonly="readonly"
            v-on="on"
            :prefix="prefix"
            :suffix="suffix"
            :clearable="clearable"
            :prepend-icon="prependIcon"
            :disabled="disabled"
            :single-line="singleLine"
            :hide-details="hideDetails"
          >
            <v-tooltip slot="append-outer" left max-width="400" v-if="tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  {{ $i.mdiInformation }}
                </v-icon>
              </template>
              <span>{{ tooltip }}</span>
            </v-tooltip>
          </v-text-field>
        </template>
        <v-date-picker
          locale="de-de"
          v-model="sync_value"
          no-title
          @input="inputMenu = false"
          :first-day-of-week="1"
          :show-week="showWeek"
          :show-adjacent-months="showAdjacentMonths"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    sync_value: {
      get() {
        return this.inner_value?.split(" ")[0]
      },
      set(val) {
        this.inner_value = val
        this.$emit("update:value", val)
      },
    },
    formatted_value: {
      set: function (val) {
        // console.log(this.parseGermanDate(val))
        let germanDate = this.parseGermanDate(val)
        if (germanDate !== null) this.sync_value = germanDate
      },
      get: function () {
        return this.formatDate(this.inner_value)
      },
    },
  },
  props: {
    label: String,
    value: {},
    readonly: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    suffix: { type: String, default: "" },
    prefix: { type: String, default: "" },
    prependIcon: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    clearable: { type: Boolean, default: true },
    singleLine: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    showWeek: { type: Boolean, default: false },
    showAdjacentMonths: { type: Boolean, default: true },
  },
  data() {
    return {
      inputMenu: false,
      inner_value: null,
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      date = date.split(" ")[0]
      const [year, month, day] = date.split("-")
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split(".")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    parseGermanDate(input) {
      //const dateRegex = /^(\d{2})\.(\d{2})\.(\d{2}(\d{2})?)$/
      const dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/
      const match = input.match(dateRegex)

      if (!match) {
        // console.error(
        //   'Invalid date format. Please use the format "dd.mm.yyyy".'
        // )
        return null
      }

      // const [_, day, month, year] = match.map(Number)

      // const dateObject = new Date(year, month - 1, day)

      // if (
      //   dateObject.getDate() !== day ||
      //   dateObject.getMonth() !== month - 1 ||
      //   dateObject.getFullYear() !== year
      // ) {
      //   console.error("Invalid date values.")
      //   return null
      // }
      let [day, month, year] = input.split(".")
      // if (year < 100) year = (2000 + parseInt(year)).toString()

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}` // 00:00:00`
    },
  },
  created() {
    this.inner_value = this.value
  },
  watch: {
    value(nVal) {
      this.inner_value = nVal
    },
  },
}
</script>
