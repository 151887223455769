<template>
  <div>
    <login v-if="!$store.state.logged_in" />
    <v-item-group>
      <v-container fluid>
        <v-row>
          <v-col v-if="$store.state.logged_in">
            <v-card
              class="mx-auto"
              max-width="400"
              v-show="companies.length == 0"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                <v-avatar color="primary" size="70"> ?</v-avatar>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5">
                    Keine Gesellschaft verknüpft
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Informieren Sie den Ansprechpartner in ihrer Gesellschaft,
                    um eine Freischaltung für eine Gesellschaft zu erhalten.
                  </v-list-item-subtitle>
                  <v-btn color="secondary" to="register_company"
                    >Neue Gesellschaft registrieren</v-btn
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-row>
              <v-col
                v-for="company in companies"
                v-bind:key="company.public_id"
              >
                <v-card class="mx-auto" max-width="400">
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-avatar color="primary" size="70">{{
                      company.initials
                    }}</v-avatar>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-list-item three-line>
                    <v-list-item-avatar>
                      <v-icon>{{ $i.mdiOfficeBuildingMarker }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{ company.name_informal }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="company.name_informal != company.name"
                        >{{ company.name }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>
                        {{ company.address }}, {{ company.postcode }},
                        {{ company.city }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in company.locations"
                      :key="i"
                      class="elevation-1"
                      three-line
                      link
                      :to="`/${item.public_id}`"
                    >
                      <v-list-item-avatar>
                        <v-icon>{{ $i.mdiStoreMarker }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content color="secondary">
                        <v-list-item-title>
                          {{ item.address }}, {{ item.postcode }},
                          {{ item.city }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ item.public_id }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ item.landline }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-list dense>
                            <!-- <v-list-item-group color="primary">
                          <v-list-item
                            v-for="item in item.billing_modules"
                            :key="item"
                          >
                            <v-list-item-content>
                              <v-list-item-title v-text="item">
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group> -->
                          </v-list>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!company.locations.length" three-line>
                      <v-list-item-avatar color="primary">
                        <v-icon>{{ $i.mdiClose }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content color="error">
                        <v-list-item-title>
                          Kein Markt aktiviert</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          Bitte wenden Sie sich an den Administrator
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          oder
                          <router-link to="/register_location"
                            >registrieren Sie einen neuen Markt.</router-link
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="this.$route.params.location_id">
          <v-col
            v-for="(item, i) in $store.state.links"
            :key="i"
            v-if="!item.dev_state"
            class="col-md-3 col-sm-6 justify"
            cols="12"
          >
            <!-- v-if="
              !item.dev_state &&
              !(
                item.billed == false &&
                !$store.state.user_obj.features.includes('link_preview')
              )
            " -->
            <v-item>
              <router-link
                :to="
                  item.general_link
                    ? `${item.link}`
                    : `/${$store.state.location_id}${item.link}`
                "
              >
                <v-card
                  color="secondary"
                  class="d-flex justify-center"
                  height="60"
                >
                  <v-list-item two-line>
                    <v-list-item-title class="text-h6 mb-1">
                      {{ item.text }}
                    </v-list-item-title>
                    <v-badge
                      :content="
                        item.module == 'notes'
                          ? $store.state.notes_count
                          : item.module == 'orders'
                          ? $store.state.orders_overdue
                          : null
                      "
                      :value="item.counts > 0"
                      color="success"
                      overlap
                    >
                      <v-icon large>{{ $i[item.icon] }}</v-icon>
                    </v-badge>
                  </v-list-item>
                </v-card>
              </router-link>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import Login from "@/components/Login.vue"

export default {
  name: "HomeView",
  components: { Login },
  data: () => ({}),
  created() {
    //this.initialLoad();
    //this.$store.dispatch("apiGetCompany", this.$store.state.location_id);
  },
  activated() {
    if (this.$route.params.location_id) {
      // this.$store.dispatch("setLocation", {
      //   location_id: this.$route.params.location_id,
      // });
      //this.$store.dispatch("apiGetCompany", this.$store.state.location_id);
    }
  },
  methods: {},
  computed: {
    companies() {
      return this.$store.state.user_obj?.companies
    },
    notes_count() {
      return this.$store.state.notes_count
    },
    orders_count() {
      return this.$store.state.orders_overdue
    },
  },
}
</script>
