import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import AddShipment from '@/components/AddShipment.vue'
import Login from '@/components/Login.vue'
import Logout from '@/components/Logout.vue'
import ShowShipment from '@/components/ShowShipment.vue'

import Orders from '@/views/Orders.vue'
import OrdersSelected from '@/views/OrdersSelected.vue'
import Margin from '@/views/Margin.vue'
import InvoiceValuation from '@/views/invoicevaluation/InvoiceValuation.vue'
import InvoiceValuationMulti from '@/views/invoicevaluation/InvoiceValuationMulti.vue'
import Promotions from '../views/promotions/Promotions.vue'
import ShowPromotion from '@/components/ShowPromotion.vue'
import AddPromotionsArray from '@/components/promotions/AddPromotionsArray.vue'

import Analytics from '../views/Analytics.vue'
import Tasks from '../views/Tasks.vue'
import Test from '../views/Test.vue'
import Test2 from '../views/Test2.vue'
import Contacts from '../views/Contacts.vue'
import ShowContact from '../views/ShowContact.vue'
import RouterFrame from '../RouterFrame.vue'
import Register from '@/views/register/Register.vue'
import RegisterCompany from '@/views/register/RegisterCompany.vue'
import RegisterLocation from '@/views/register/RegisterLocation.vue'
import WineAdvisor from '@/views/WineAdvisor.vue'
import Admin from '@/views/Admin.vue'
import Plans from '@/views/plans/Plans.vue'
import Receipts from '@/views/receipts/Receipts.vue'
import ShowReceipt from '@/views/receipts/ShowReceipt.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,//import(/* webpackChunkName: "static" */'@/components/Login.vue'),
    meta: {
      title: "Login",
    },
  },
  {
    path: '/reset_password/:user_id/:token',
    name: 'ResetPassword',
    component: ResetPassword, //import(/* webpackChunkName: "static" */'@/views/ResetPassword.vue'),
    meta: {
      title: "Passwort zurücksetzen",
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register, //import(/* webpackChunkName: "static" */'@/views/register/Register.vue'),
    meta: {
      title: "Benutzer registrieren",
    },
  },
  {
    path: '/register_company',
    name: 'RegisterCompany',
    component: RegisterCompany, //import(/* webpackChunkName: "static" */'@/views/register/RegisterCompany.vue'),
    meta: {
      title: "Gesellschaft registrieren",
    },
  },
  {
    path: '/register_location',
    name: 'RegisterLocation',
    component: RegisterLocation, //import(/* webpackChunkName: "static" */'@/views/register/RegisterLocation.vue'),
    meta: {
      title: "Standort registrieren",
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      title: "Einstellungen",
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout, //import(/* webpackChunkName: "static" */'@/components/Logout.vue'),
    meta: {
      title: "Logout",
    },
  },
  {
    path: '/imprint',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "static" */'@/views/Imprint.vue'),
    meta: {
      title: "Impressum",
    },
  },
  {
    path: '/privacy',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "static" */'@/views/Privacy.vue'),
    meta: {
      title: "Datenschutzerklärung",
    },
  },
  {
        path: '/plans',
        name: 'Plans',
    component: Plans,
        meta: {
      title: "Lizenzen",
    },
      },
  {
    path: '/:location_id',
    component: RouterFrame,
    meta: {
      title: "lehweb",
    },
    children: [
      {
        path: '',
        name: 'Location_ID',
        component: Home,
        meta: {
          title: "Home",
        },
      },
      {
        path: 'shipments',
        name: 'Shipments',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Shipments.vue'),
        meta: {
          title: "Wareneingänge",
        },
      },
      {
        path: 'shipments/add',
        name: 'AddShipment',
        component: AddShipment,
        meta: {
          title: "Lieferung hinzufügen",
        },
      },
      {
        path: 'shipments/:supplier_id/:id',
        name: 'ShowShipment',
        component: ShowShipment,
        meta: {
          title: "Lieferung anzeigen",
        },
      },
      {
        path: 'suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "views" */ '../views/Suppliers.vue'),
        meta: {
          title: "Lieferanten",
        },
      },
      {
        path: 'margin',
        name: 'Margin',
        component: Margin,
        meta: {
          title: "Spannenrechner",
        },
      },
      {
        path: 'invoicevaluation',
        name: 'InvoiceValuation',
        component: InvoiceValuation,
        meta: {
          title: "Rechnungsbewertung",
        },
      },
      {
        path: 'invoicevaluationonline',
        name: 'InvoiceValuationOnline',
        component: InvoiceValuationMulti,
        meta: {
          title: "Rechnungsbewertung",
        },
      },
      {
        path: 'receipts',
        name: 'Receipts',
        component: Receipts,
        meta: {
          title: "Belege",
          module_name:"receipts",
        },
      },
      {
        path: 'receipts/:receipt_id',
        name: 'ShowReceipt',
        component: ShowReceipt,
        meta: {
          title: "Beleg bearbeiten",
          module_name:"receipts",
        },
      },
      {
        path: 'suppliers/add',
        name: 'AddSupplier',
        component: () => import(/* webpackChunkName: "views" */ '@/components/suppliers/AddSupplier.vue'),
        meta: {
          title: "Lieferanten hinzufügen",
        },
      },
      {
        path: 'suppliers/:id',
        name: 'ShowSupplier',
        component: () => import(/* webpackChunkName: "views" */ '@/components/suppliers/ShowSupplier.vue'),
        meta: {
          title: "Lieferant",
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders, //() => import(/* webpackChunkName: "views" */ '@/views/Orders.vue'),
        meta: {
          title: "Bestellungen",
          module_name:"orders",
        },
      },
      {
        path: 'orders/:supplier_id',
        name: 'OrdersSelected',
        component: OrdersSelected, //() => import(/* webpackChunkName: "views" */ '@/views/Orders.vue'),
        meta: {
          title: "Bestellungen",
          module_name:"orders",
        },
      },
      {
        path: 'promotions',
        name: 'Promotions',
        component: Promotions, //() => import(/* webpackChunkName: "views" */ '../views/Promotions.vue'),
        meta: {
          title: "Aktionen",
          module_name:"promotions",
        },
      },
      {
        path: 'promotions/add_array',
        name: 'AddPromotionsArray',
        component: AddPromotionsArray, //() => import(/* webpackChunkName: "views" */ '@/components/ShowPromotion.vue'),
        meta: {
          title: "Aktion",
          module_name:"promotions",
        },
      },
      {
        path: 'promotions/:id/:duplication_id?',
        name: 'ShowPromotion',
        component: ShowPromotion, //() => import(/* webpackChunkName: "views" */ '@/components/ShowPromotion.vue'),
        meta: {
          title: "Aktion",
          module_name:"promotions",
        },
      },
      {
        path: 'articles/:supplier_id',
        name: 'Articles',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Articles.vue'),
        meta: {
          title: "Artikel",
        },
      },
      {
        path: 'articles',
        name: 'AllArticles',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Articles.vue'),
        meta: {
          title: "Artikel",
        },
      },
      {
        path: 'audits',
        name: 'Audits',
        component: () => import(/* webpackChunkName: "audits" */ '@/views/Audits.vue'),
        meta: {
          title: "Audits",
        },
      },
      {
        path: 'audits/:audit_id',
        name: 'ShowAudit',
        component: () => import(/* webpackChunkName: "audits" */ '@/components/ShowAudit.vue'),
        meta: {
          title: "Audit bearbeiten",
        },
      },
      {
        path: 'revenue',
        name: 'Revenue',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Revenue.vue'),
        meta: {
          title: "Umsatz",
          module_name:"revenue",
        },
      },
      {
        path: 'notes/:public_id?',
        name: 'Notes',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Notes.vue'),
        meta: {
          title: "Notizen",
          module_name:"notes",
        },
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: Tasks
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts,
        meta: {
          title: "Kontakte",
          module_name:"contacts",
        },
      },
      {
        path: 'contacts/:public_id',
        name: 'ShowContact',
        component: ShowContact,
        meta: {
          title: "Kontakt bearbeiten",
          module_name:"contacts",
        },
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: Analytics
      },
      {
        path: 'wineadvisor',
        name: 'WineAdvisor',
        component: WineAdvisor,
        meta: {
          title: "Weinberater",
        },
      },
      {
        path: 'test',
        name: 'Test',
        component: Test
      },
      {
        path: 'test2',
        name: 'Test2',
        component: Test2
      },
      {
        path: ':catchall',
        name: 'Catchall',
        component: Login, //import(/* webpackChunkName: "static" */'@/components/Login.vue'),
        meta: {
          title: "eweb",
        },
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  if (!router.app.$store.state.user_obj || router.app.$store.state.user_obj === null) {
    router.app.$store.dispatch("apiGetUser")
  }
  //Check for set location_id in route and set location to store
  // if (to.name == "Location_ID") {
    
  //   if ((!("location_id" in from.params)) || ("location_id" in to.params && "location_id" in from.params && to.params.location_id != from.params.location_id)) {
  //     router.app.$store.dispatch("setLocation", to.params.location_id);
  //     router.app.$store.dispatch("setLinks");
  //   }
  // }
  // if navigated to location subroute set links
  // if (to.name != "Location_ID" && router.app.$store.state.location_id === null && "location_id" in to.params) {
  //   router.app.$store.dispatch("setLocation", to.params.location_id);
  //   // router.app.$store.dispatch("setLinks");
  // }

    // console.log(to.params.location_id, router.app.$store.state.allowed_locations)
  // check for location_id in allowed_locations
  // if ("location_id" in to.params && !(to.params.location_id in router.app.$store.state.allowed_locations)) {
  //   alert("Location ID not permitted")
  //   next(false)
  // }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

router.afterEach((to, from) => {
   //Check for set location_id in route and set location to store
  if (to.name == "Location_ID") {
    
    if ((!("location_id" in from.params)) || ("location_id" in to.params && "location_id" in from.params && to.params.location_id != from.params.location_id)) {
      router.app.$store.dispatch("setLocation", to.params.location_id);
      router.app.$store.dispatch("setLinks");
    }
  }
  // if navigated to location subroute set links
  if (to.name != "Location_ID" && router.app.$store.state.location_id === null && "location_id" in to.params) {
    router.app.$store.dispatch("setLocation", to.params.location_id);
    // router.app.$store.dispatch("setLinks");
  }
})

export default router
