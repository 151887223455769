<template>
  <div class="test">
    <v-card class="mx-auto elevation-6">
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiCheckCircle }}</v-icon>
        <v-toolbar-title>QS</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              v-model="search"
              :prepend-inner-icon="$i.mdiMagnify"
              label="Suchen"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card
        v-for="n in unique_entries('store_area', audit.questions)"
        :key="n"
      >
        <v-card-title>
          {{ n }}
        </v-card-title>
        <v-list-item
          three-line
          v-for="m in unique_entries(
            'department',
            filter_array(audit.questions, 'store_area', n)
          )"
          :key="m"
        >
          <v-list-item-content>
            <v-list-item-title>{{ m }}</v-list-item-title>
            <v-list-item-subtitle
              class="mb-6"
              v-for="x in unique_entries(
                'area',
                filter_array(
                  filter_array(audit.questions, 'store_area', n),
                  'department',
                  m
                )
              )"
              :key="x"
            >
              {{ x }}

              <v-data-table
                :headers="qs_table"
                :items="
                  filter_array(
                    filter_array(
                      filter_array(audit.questions, 'store_area', n),
                      'department',
                      m
                    ),
                    'area',
                    x
                  )
                "
                :items-per-page="-1"
                hide-default-footer
                class="elevation-1"
                :search="search"
                :sort-by="['year', 'week']"
                :sort-asc="['true']"
                item-key="public_id"
                dense
                single-expand
                :show-expand="false"
                :item-class="row_classes"
              >
                <template v-slot:item.result="{ item }">
                  <v-switch
                    class="v-input--reverse"
                    v-model="item.result"
                    :color="checkboxColor(item.result)"
                    :label="
                      item.result == undefined
                        ? 'keine Angabe'
                        : item.result == false
                        ? 'n. i. O.'
                        : 'i. O.'
                    "
                    dense
                    :disabled="disabled"
                  >
                  </v-switch>
                </template>
                <template v-slot:item.note="{ item }">
                  <v-textarea
                    v-model="item.note"
                    rows="1"
                    hide-details
                    dense
                    filled
                    placeholder="Notiz"
                    auto-grow
                    :disabled="disabled"
                  ></v-textarea>
                </template>
                <template v-slot:expanded-item="{ headers, item, isMobile }">
                  <td :colspan="headers.length" v-if="!isMobile">
                    {{ item.hint }}
                  </td>
                </template>
              </v-data-table>
              <v-divider></v-divider>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-data-table
        v-if="false"
        :headers="qs_table"
        :items="audit.questions"
        :items-per-page="-1"
        :footer-props="{ 'items-per-page-options': [10, 50, 100, -1] }"
        class="elevation-1"
        :search="search"
        :sort-by="['year', 'week']"
        :sort-asc="['true']"
        item-key="public_id"
        mobile-breakpoint="0"
        dense
      >
        <template v-slot:item.result="{ item }">
          <v-label>Off</v-label>
          <v-switch
            v-model="item.result"
            :color="checkboxColor(item.result)"
            :label="
              item.result == undefined
                ? 'keine Angabe'
                : item.result == false
                ? 'n. i. O.'
                : 'i. O.'
            "
            dense
            :disabled="disabled"
          >
          </v-switch>
        </template>
        <template v-slot:item.note="{ item }">
          <v-textarea
            v-model="item.note"
            rows="2"
            :disabled="disabled"
          ></v-textarea>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn
          color="secondary"
          class="mx-2"
          @click="editAudit"
          v-if="disabled"
          fab
          bottom
          right
          fixed
        >
          <v-icon dark> {{ $i.mdiPencil }} </v-icon>
        </v-btn>
        <v-btn
          color="success"
          class="mx-2"
          @click="saveAudit"
          v-if="!disabled"
          fab
          bottom
          right
          fixed
        >
          <v-icon dark> {{ $i.mdiContentSave }} </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style>
/* each row block css in mobile*/
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin-bottom: 10px;
  border: 1px solid #111;
  display: block;
}

/* each cell border in mobile */
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  border-bottom: 1px solid #111;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  display: block;
}
.v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-row
  .v-data-table__mobile-row__header {
  width: 100%;
}
.v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}
.v-data-table > .v-data-table__expanded .v-data-table__expanded__content {
  margin-top: -10px;
}
</style>

<script>
export default {
  name: "TestView",
  components: {},
  data: () => ({
    search: "",
    expanded: [],
    audit: {
      audit_date: null,
      audit_type: null,
      audit_type_id: null,
      location: null,
      public_id: null,
      questions: [],
      results: [],
      user: null,
    },
    disabled: false,
  }),
  computed: {
    qs_table() {
      return [
        { text: "Bezeichnung", value: "subject", sortable: false },
        { text: "Status", value: "result", sortable: false, width: "200px" },
        { text: "Notiz", value: "note", sortable: false, width: "200px" },
        //{ value: "data-table-expand" },
      ];
    },
  },
  methods: {
    checkboxColor(value) {
      return value == undefined
        ? "blue lighten-2"
        : value == false
        ? "error lighten-1"
        : "success lighten-1";
    },
    unique_entries(key, arr) {
      var areas = arr.map((x) => x[key]);
      return [...new Set(areas)];
    },
    filter_array(arr, filter_column, filter_value) {
      return arr.filter((obj) => {
        return obj[filter_column] === filter_value;
      });
    },
    row_classes(item) {
      return this.checkboxColor(item.result);
    },
    saveAudit() {
      this.disabled = !this.disabled;
      //this.apiPutAuditQuestions(this.$route.params.id);
    },
    editAudit() {
      this.disabled = !this.disabled;
    },
    apiGetAuditQuestions(location_id, audit_type_id) {
      //this.$store.commit('startLoading')
      //console.log(filteredData);
      this.$api
        .get("/audits/" + location_id + "/questions/" + audit_type_id)
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.inputForm = response.data;
        })
        .catch()
        .finally();
    },
    apiGetAudit(location_id) {
      this.$api
        .get("/audits/" + location_id + "/2a944a97-4631-4cfd-93d2-10cd751d683a")
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.audit = response.data;
        })
        .catch()
        .finally();
    },
    preventNav(event) {
      if (!this.disabled) return;
      event.preventDefault();
      event.returnValue = "";
    },
  },
  mounted: function () {
    this.apiGetAudit(
      this.$route.params.location_id,
      "d603c8e9-be3c-4230-abc5-5306b4da2b2f"
    );
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.disabled) {
      if (
        !window.confirm(
          "Bearbeitung noch nicht abgeschlossen. Möchten Sie die Seite wirklich verlassen?"
        )
      ) {
        return;
      }
    }
    next();
  },
  watch: {},
};
</script>
