<template>
  <div>
    <v-card
      @drop.prevent="addDropFile"
      @dragover.prevent
      @dragenter="dragenter"
      @dragleave="dragleave"
      color="#BBB"
      :class="'align-center justify-center text-center d-flex flex-column'"
      min-height="400"
    >
      <div class="dropzone d-flex" ref="dropzone">
        <h4>Belegart</h4>
        <v-chip-group
          v-model="receipt_type"
          active-class="primary--text"
          column
        >
          <v-chip
            v-for="receipt_type in receipt_types"
            :key="receipt_type"
            :value="receipt_type"
            class="mx-1"
          >
            {{ receipt_type }}
          </v-chip>
        </v-chip-group>
        <v-card-title></v-card-title>
        <v-icon x-large class="mb2" center>{{
          $i.mdiCloudUploadOutline
        }}</v-icon>
        Belege hier ablegen.
        <v-card-text>
          <v-row v-show="show_file_input">
            <v-col>
              <v-file-input
                v-model="files_upload"
                color="secondary"
                placeholder="Belege hochladen (pdf, jpg, png)"
                multiple
                :prepend-icon="$i.mdiPaperclip"
                full-width
                accept="image/*,.pdf"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 2" color="secondary" dark label small>
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files_upload.length - 2 }} Dateien
                  </span>
                </template>
              </v-file-input>
              <v-btn
                color="success"
                v-on:click="apiAddFiles"
                center
                class="mx-2"
                >Upload<v-icon right>{{ $i.mdiFileDownload }}</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row v-show="!show_file_input">
            <v-col>
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import receipt_types_lib from "@/assets/receipt_types.json"

export default {
  name: "AddReceipts",
  components: {},
  props: {
    config: { default: null },
  },
  data: () => ({
    show_file_input: true,
    files_upload: null,
    upload_response: null,
    download_file: null,
    receipt_type: null,
    receipt_types: receipt_types_lib,
  }),
  computed: {},
  methods: {
    resetUpload() {
      this.show_file_input = true
      this.files_upload = null
      this.upload_response = null
      this.download_file = null
      this.receipt_type = this.receipt_types[0]
    },
    // dragenter(e) {
    dragenter() {
      this.$refs.dropzone.style.backgroundColor = "#DDD"
      this.hoverCounter++
    },
    // dragleave(e) {
    dragleave() {
      this.hoverCounter--
    },
    addDropFile(e) {
      this.files_upload = e.dataTransfer.files
      this.apiAddFiles()
    },
    apiAddFiles() {
      if (!this.files_upload || this.files_upload.length == 0) {
        return
      }
      this.show_file_input = false
      //this.$store.commit('startLoading')
      //@articles.route("/<location_id>/supplier/<supplier_id>/", methods=["POST"])
      let fd = new FormData()
      if (!("length" in this.files_upload))
        this.files_upload = [this.files_upload]
      for (let i = 0; i < this.files_upload.length; i++) {
        fd.append("pdf[" + i + "]", this.files_upload[i])
      }
      fd.append("receipt_type", this.receipt_type || null)

      //   fd.append("pdf[]", this.files_upload);
      this.$api
        .post("receipts/" + this.$store.state.location_id + "/upload", fd)
        .then(() => {
          return this.$emit("closed"), this.resetUpload()
        })
        .catch()
        .then()
    },
  },
  watch: {
    hoverCounter(val) {
      if (val === 0) {
        this.$refs.dropzone.style.backgroundColor = null
      }
    },
  },
  mounted() {},
  created() {
    this.resetUpload()
  },
}
</script>

<style scoped>
.dropzone {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px dashed hsla(0, 0%, 100%, 0.7);
  border-radius: 20px;
  overflow: hidden;
  min-width: 80%;
  transition: background-color 0.2s;
}
.no-padding {
  padding: 0;
}
</style>