<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" xs="12">
        <v-row class="mt-5">
          <v-col
            v-for="(attachment, index) in documentAttachment"
            :key="`attachment-${index}`"
            cols="12"
            md="4"
            xs="12"
          >
            <v-card outlined :tile="tile" class="mx-auto" max-width="344">
              <template
                v-if="
                  attachment.file.name.split('.').pop().toLowerCase() ===
                    'jpg' ||
                  attachment.file.name.split('.').pop().toLowerCase() ===
                    'jpeg' ||
                  attachment.file.name.split('.').pop().toLowerCase() ===
                    'png' ||
                  attachment.file.name.split('.').pop().toLowerCase() ===
                    'tif' ||
                  attachment.file.name.split('.').pop().toLowerCase() === 'bmp'
                "
              >
                <v-img
                  :src="
                    'data:' +
                    attachment.file.format +
                    ',' +
                    attachment.file.base64
                  "
                  height="200px"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="deletePermission"
                    fab
                    small
                    absolute
                    right
                    class="mx-2"
                    color="light-grey"
                    @click="openDeleteDialog(index, '')"
                  >
                    <v-icon color="white">{{ $i.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </v-img>
              </template>
              <template v-else>
                <v-list-item-avatar
                  style="margin-top: 0"
                  tile
                  width="100%"
                  height="192"
                  color="blue-grey lighten-5"
                >
                  <template>
                    <v-icon
                      v-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                        'pdf'
                      "
                      size="120"
                      file-word-outline
                      color="red darken-1"
                      >{{ $i.mdiFilePdfBox }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'doc' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'docx' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'odt'
                      "
                      size="120"
                      file-word-outline
                      color="blue darken-1"
                      >{{ $i.mdiFileWordOutline }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'xls' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'xlsx'
                      "
                      size="120"
                      file-word-outline
                      color="teal darken-1"
                      >{{ $i.mdiFileExcelOutline }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'pptx' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'pptm' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'ppt'
                      "
                      size="120"
                      file-word-outline
                      color="orange darken-3"
                      >{{ $i.mdiFilePowerpointOutline }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'mp4' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'mov' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'flv' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'wmv' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'avi'
                      "
                      size="120"
                      file-word-outline
                      color="red lighten-1"
                      >{{ $i.mdiFileVideoOutline }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                        'dwg'
                      "
                      size="120"
                      file-word-outline
                      color="indigo lighten-2"
                      >{{ $i.mdiFileCad }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'zip' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          'rar' ||
                        attachment.file.name.split('.').pop().toLowerCase() ===
                          '7-zip'
                      "
                      size="120"
                      file-word-outline
                      color="lime lighten-1"
                      >{{ $i.mdiFolderZipOutline }}</v-icon
                    >
                    <v-icon
                      v-else-if="
                        attachment.file.name.split('.').pop().toLowerCase() ===
                        'txt'
                      "
                      size="120"
                      file-word-outline
                      color="light-green darken-3"
                      >{{ $i.mdiScriptTextOutline }}</v-icon
                    >
                    <v-icon
                      v-else
                      x-large
                      file-word-outline
                      color="indigo lighten-1"
                      >{{ $i.mdiFileQuestionOutline }}</v-icon
                    >
                  </template>
                </v-list-item-avatar>
              </template>
              <v-expand-transition>
                <template
                  v-if="
                    attachment.file.tags.length > 0 ||
                    attachment.file.description !== ''
                  "
                >
                  <div v-show="attachment.file.showDetailState">
                    <v-divider></v-divider>
                    <v-chip-group
                      v-if="attachment.file.tags.length > 0"
                      multiple
                      active-class="primary--text"
                      style="padding: 8px"
                    >
                      <v-chip
                        v-for="(tag, index) in attachment.file.tags"
                        :key="`attachment-${index}`"
                        style="margin: 5px"
                      >
                        {{ tag }}
                      </v-chip>
                    </v-chip-group>
                    <v-card-text
                      v-if="attachment.file.description != null"
                      style="
                        text-align: justify;
                        height: 200px;
                        overflow-y: auto;
                      "
                    >
                      {{ attachment.file.description }}
                    </v-card-text>
                  </div>
                </template>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--Insert New Document Dialog Start-->
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-card-text class="BYekan">
            <template>
              <v-file-input
                multiple
                v-model="tempAttachment"
                :prepend-icon="$i.mdiCamera"
                label="Beleg hochladen"
                placeholder="Beleg hochladen"
              >
                <v-tooltip slot="append-outer" left max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      {{ $i.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>Tooltip Text</span>
                </v-tooltip>
                <slot></slot>
              </v-file-input>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="BYekan"
              :disabled="tempAttachment == null || btnLoader"
              :loading="btnLoader"
              :color="btnColor"
              @click="uploadFieldChange"
              >{{ selectedLang[lang].add }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!--Insert New Document Dialog End-------------------------------------------------------------------->

    <!--File Uploader SnackBar Alert Start --------------------------------------------------------------->
    <v-snackbar
      class="BYekan"
      v-model="fileUploaderSnackBarAlert"
      right
      bottom
      :color="fileUploaderSnackBarAlertColor"
      :timeout="3000"
    >
      {{ fileUploaderSnackText }}
      <v-btn color="white" text @click="fileUploaderSnackBarAlert = false">
        <v-icon>{{ $i.mdiClose }}</v-icon>
      </v-btn>
    </v-snackbar>
    <!--File Uploader SnackBar Alert Start -->
    <!--DELETE Dialog End----------------------------------------------------------START---->
    <v-row justify="center">
      <v-dialog v-model="deleteDocumentDialog" persistent width="30%">
        <v-card>
          <v-card-title>
            <v-icon color="red"></v-icon>
          </v-card-title>
          <v-card-text class="BYekan">
            {{ selectedLang[lang].deleteDialog.message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="BYekan"
              color="green darken-1"
              text
              @click="deleteDocumentDialog = false"
              >{{ selectedLang[lang].deleteDialog.cancel }}</v-btn
            >
            <v-btn
              class="BYekan"
              color="primary"
              :disabled="btnLoader"
              :loading="btnLoader"
              @click="deleteDocument"
              >{{ selectedLang[lang].delete }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--DELETE Dialog End----------------------------------------------------------------END---->
  </v-container>
</template>

<script>
/**
 * This is an example of uploading files. with this component you can see, insert, delete files.
 * @version 1.0
 * @author [ali jahanpak](https://github.com/alijahanpak)
 * @since Version 1.0
 */
export default {
  props: {
    /**
     * Array contain files
     */
    documentAttachment: [Array],
    /**
     * Send api url for 'add' and 'delete'.
     * f.e: /building/add or /building/delete
     */
    maxFileSize: {
      type: Number,
      default: 5120,
    },
    /**
     * choose File Uploader Type
     */
    fileUploaderType: {
      type: String,
      default: "thumbnail",
    },
    /**
     * Maximum file Upload
     */
    maxFileCount: {
      type: Number,
      default: 0,
    },
    /**
     * choose File Uploader Card Type
     */
    badgeCounter: {
      type: Boolean,
      default: true,
    },
    /**
     * change Button Color
     */
    btnColor: {
      type: String,
      default: "info",
    },
    /**
     * Enable / Disable image compressor
     */
    imageCompressor: Boolean,
    /**
     * Image compress level (0 to 1)
     */
    imageCompressLevel: {
      type: Number,
      default: 0.5,
    },
    /**
     * Accept uploaded files
     */
    fileAccept: String,
    /**
     * show / hidden thumb for images in table and simple file uploader
     */
    thumb: {
      type: Boolean,
      default: true,
    },
    /**
     * show / hidden thumb column in table file uploader
     */
    tableThumbColumn: Boolean,
    /**
     * enable / disable table fixed header
     */
    tableFixedHeader: {
      type: Boolean,
      default: true,
    },
    /**
     * set table height
     */
    tableHeight: {
      type: Number,
      default: 400,
    },
    /**
     * Enable RTL support languages
     */
    rtlSupport: {
      type: Boolean,
      default: false,
    },
    /**
     * Change file uploader languages
     */
    lang: {
      type: String,
      default: "en",
    },
    /**
     * Change file Name before upload
     */
    changeFileName: {
      type: Boolean,
      default: false,
    },
    /**
     * Add file Description before upload
     */
    addFileDescription: {
      type: Boolean,
      default: false,
    },
    /**
     * Add file tags before upload
     */
    addFileTag: {
      type: Boolean,
      default: false,
    },
    /**
     * Array of file tags
     */
    tags: {
      type: Array,
    },
    /**
     * Change count of columns
     */
    cols: {
      type: Number,
      default: 4,
    },
    /**
     * Define Custom language for uploader
     */
    customLang: {
      type: Object,
      default: null,
    },
    /**
     * Set Insert/Add new file Permission
     */
    insertPermission: {
      type: Boolean,
      default: true,
    },
    /**
     * Set delete Permission
     */
    deletePermission: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    insertDocumentDialog: false,
    deleteDocumentDialog: false,
    tempAttachment: [],
    tempAttachmentChanged: [],
    fileUploaderSnackBarAlert: false,
    fileUploaderSnackText: "",
    fileUploaderSnackBarAlertColor: "green",
    readerFile: null,
    registryDocFile: [],
    documentAttachmentAPI: [],
    btnLoader: false,
    showDetail: false,
    showDetailState: [],
    selectedIndex: "",
    selectedId: "",
    returnedRecord: {},
    //Card Theme
    selectedLang: {},
    beforeInsertAttachments: {},
    attachmentIndex: "",
    languages: {
      en: {
        insertFile: "Insert File",
        insertNewFile: "Insert New File",
        add: "Add",
        delete: "Delete",
        edit: "Edit",
        deleteDialog: {
          message: "Are you sure you want to delete the file?",
          cancel: "cancel",
        },
        table: {
          thumb: "Thumb",
          name: "Name",
          size: "Size",
          tags: "tags",
          action: {
            action: "Action",
            deleteTooltip: "Delete",
          },
        },
        size: {
          kb: "KB",
          mb: "MB",
        },
        maxFileSizeAlert: "Max file Size is",
        maxFileCountAlert: "Max file Count is",
        fileName: "File Name",
        fileDescription: "File Description",
        fileTags: "File Tags",
      },
    },
  }),
  watch: {
    tempAttachment: function (newValue) {
      if (this.tempAttachment.length > 0) this.getAttachmentDetails(newValue);
      else this.tempAttachmentChanged = [];
    },
    badgeCounter: function () {
      this.$emit("update:badgeCounter", this.badgeCounter);
    },
    maxFileCount: function () {
      this.$emit("update:maxFileCount", this.maxFileCount);
    },
    maxFileSize: function () {
      this.$emit("update:maxFileSize", Number(this.maxFileSize));
    },
    imageCompressor: function () {
      this.$emit("update:imageCompressor", this.imageCompressor);
    },
    imageCompressLevel: function () {
      this.$emit("update:imageCompressLevel", this.imageCompressLevel);
    },
    fileAccept: function () {
      this.$emit("update:fileAccept", this.fileAccept);
    },
    thumb: function () {
      this.$emit("update:thumb", this.thumb);
    },
    changeFileName: function () {
      this.$emit("update:changeFileName", this.changeFileName);
    },
    addFileDescription: function () {
      this.$emit("update:addFileDescription", this.addFileDescription);
    },
    addFileTag: function () {
      this.$emit("update:addFileTag", this.addFileTag);
    },
    tableThumbColumn: function () {
      this.$emit("update:tableThumbColumn", this.tableThumbColumn);
    },
    tableFixedHeader: function () {
      this.$emit("update:tableFixedHeader", this.tableFixedHeader);
    },
    tableHeight: function () {
      this.$emit("update:tableHeight", this.tableHeight);
    },
    lang: function () {
      this.$emit("update:lang", this.lang);
    },
  },
  computed: {},
  mounted() {},

  created() {
    if (this.rtlSupport) this.changeRTL();
    this.setLang();
  },
  destroyed() {
    this.registryDocFile = [];
  },
  methods: {
    setLang() {
      if (this.customLang !== null) this.selectedLang = this.customLang;
      else this.selectedLang = this.languages;
    },
    changeRTL() {
      this.$vuetify.rtl = true;
    },

    openInputDocumentModal() {
      this.btnLoader = false;
      this.tempAttachment = [];
      this.insertDocumentDialog = true;
    },

    handleUpload(fileAttachment) {
      let reader = new FileReader();
      return new Promise(function (resolve) {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(fileAttachment);
      });
    },

    /**
     * asynchronous method to insert selected file(s)
     *
     * @public
     * @returns {Array} selected file(s)
     */
    async uploadFieldChange() {
      //this.btnLoader = true;
      for (let [index, item] of this.tempAttachment.entries()) {
        if (this.documentAttachment.length < this.maxFileCount) {
          if ((item.size / 1000).toFixed(1) > this.maxFileSize) {
            this.fileUploaderSnackBarAlertColor = "red";
            this.fileUploaderSnackText = `${
              this.selectedLang[this.lang].maxFileSizeAlert
            } ${Math.round(this.maxFileSize / 1024)} ${
              this.selectedLang[this.lang].size.mb
            }`;
            this.fileUploaderSnackBarAlert = true;
          } else {
            let tempFile = {};
            let file = {};
            try {
              this.readerFile = await this.handleUpload(item);
            } catch (e) {
              console.log(e);
            }
            let fullFileType = this.readerFile.split(";");
            let fileType = fullFileType[0].split(":");
            let status = false;
            let imgFile = "";
            let sizeInKb = 0;
            if (this.imageCompressor) {
              if (
                fileType[1] === "image/png" ||
                fileType[1] === "image/jpg" ||
                fileType[1] === "image/jpeg" ||
                fileType[1] === "application/octet-stream" ||
                fileType[1] === "image/webp"
              ) {
                status = true;
                imgFile = await this.compressImage(
                  this.readerFile,
                  fileType[1]
                );
              }
            }
            //tempFile.subject= this.tempAttachmentChanged[index].name + '.' + this.tempAttachmentChanged[index].format;
            let strTemp = this.readerFile.split(",");
            if (status) {
              let imgTemp = imgFile.split(",");
              tempFile.base64 = imgTemp[1];
              sizeInKb = new Buffer(imgFile, "base64").length;
              tempFile.size = String(sizeInKb);
            } else {
              tempFile.base64 = strTemp[1];
              tempFile.size = String(item.size);
            }
            tempFile.name =
              this.tempAttachmentChanged[index].name +
              "." +
              this.tempAttachmentChanged[index].format;
            if (this.tempAttachmentChanged[index].tags === undefined)
              tempFile.tags = [];
            else tempFile.tags = this.tempAttachmentChanged[index].tags;
            if (this.tempAttachmentChanged[index].description === undefined)
              tempFile.description = "";
            else
              tempFile.description =
                this.tempAttachmentChanged[index].description;
            tempFile.showDetailState = false;

            tempFile.format = strTemp[0].replace("data:", "");
            file.file = tempFile;
            this.registryDocFile.push(file);
            this.$emit("update:documentAttachment", this.registryDocFile);
            //console.log(JSON.stringify(this.registryDocFile));
          }
        } else {
          this.fileUploaderSnackBarAlertColor = "red";
          this.fileUploaderSnackText = `${
            this.selectedLang[this.lang].maxFileCountAlert
          } ${this.maxFileCount}`;
          this.fileUploaderSnackBarAlert = true;
        }
      }
      this.documentAttachmentAPI = [];
      this.tempAttachmentChanged = [];
      this.insertDocumentDialog = false;
    },

    compressImage(base64) {
      const canvas = document.createElement("canvas");
      const img = document.createElement("img");

      return new Promise((resolve, reject) => {
        let imageCompressLevelTemp = this.imageCompressLevel;
        img.onload = function () {
          let width = img.width;
          let height = img.height;
          /*const maxHeight = 1250
            const maxWidth = 1250

            if (width > height) {
              if (width > maxWidth) {
                height = Math.round((height *= maxWidth / width))
                width = maxWidth
              }
            } else {
              if (height > maxHeight) {
                width = Math.round((width *= maxHeight / height))
                height = maxHeight
              }
            }*/
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg", imageCompressLevelTemp));
        };
        img.onerror = function (err) {
          reject(err);
        };
        img.src = base64;
      });
    },

    openDeleteDialog(index, deleteId) {
      //this.btnLoader = false;
      this.selectedIndex = index;
      this.selectedId = deleteId;
      //this.deleteDocumentDialog = true;
      this.deleteDocument();
    },

    deleteDocument() {
      this.registryDocFile.splice(this.selectedIndex, 1);
      // this.documentAttachment = this.registryDocFile;
      this.deleteDocumentDialog = false;
    },

    async getAttachmentDetails(selectedAttachment) {
      for (let item of selectedAttachment) {
        try {
          this.readerFile = await this.handleUpload(item);
        } catch (e) {
          console.log(e);
        }
        let obj = {};
        let name = "";
        name = item.name.split(".");
        obj.format = item.name.substr(item.name.lastIndexOf(".") + 1);
        obj.name = name[0];
        obj.base64 = this.readerFile;
        this.tempAttachmentChanged.push(obj);
      }
    },

    getBinaryFile(attachment) {
      let fileUrl = "/file/" + attachment.file.url;
      window.open(process.env.apiBaseUrl + fileUrl);
    },

    getShowDetailState(index) {
      this.showDetailState[index] = !this.showDetailState[index];
      console.log("showDetailState" + JSON.stringify(this.showDetailState));
    },

    destroyFileUploader() {
      this.documentAttachmentAPI = [];
      // this.documentAttachment = [];
      this.registryDocFile = [];
      this.tempAttachment = [];
    },
  },
};
</script>

<style scoped lang="scss">
.one-line {
  line-height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 93%;
}
</style>
