<template>
  <v-slider
    v-model="sync_value"
    :max="max"
    :min="min"
    :step="step"
    :label="label"
    @mouseup="$emit('update:value', sync_value)"
    :readonly="readonly"
    :disabled="disabled"
    append="test"
    prepend="prefix"
    :rules="[rules.too_high]"
  >
    <template v-slot:prepend>
      <span v-if="prefix != ''" class="iir-label md-4">{{ prefix }}</span>
    </template>
    <template v-slot:append>
      <template v-if="showtextfield">
        <v-text-field
          v-model="sync_value"
          class="mt-0 pt-0"
          style="width: 60px"
          single-line
          :suffix="textSuffix"
          @keyup="$emit('update:value', sync_value)"
          :readonly="readonly"
          :disabled="disabled"
          :step="step"
          :type="type"
          :rules="[rules.required, rules.too_high_nc]"
        >
          <v-tooltip
            slot="append-outer"
            left
            max-width="400"
            v-if="tooltip != ''"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                {{ $i.mdiInformation }}
              </v-icon>
            </template>
            <span>{{ tooltip }}</span>
          </v-tooltip>
        </v-text-field>
      </template>
      <span v-if="suffix != ''" class="pr-2">{{ suffix }}</span>
    </template>
  </v-slider>
</template>


<script>
export default {
  props: {
    label: String,
    value: { type: Number, default: 0.0 },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    alert_value: { type: Number, default: 7 },
    suffix: { type: String, default: "" },
    textSuffix: { type: String, default: "" },
    prefix: { type: String, default: "" },
    step: { type: Number, default: 0.01 },
    showtextfield: { type: Boolean, default: true },
    type: { type: String, default: "text" },
    tooltip: { type: String, default: "" },
  },
  computed: {
    sync_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        too_high: (v) =>
          v <= this.alert_value || "Wareneingangstemperatur zu hoch",
        too_high_nc: (v) => v <= this.alert_value || "",
      },
    };
  },
};
</script>

<style scoped>
.iir-label {
  min-width: 150px;
}
</style>