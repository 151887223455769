<template>
  <div>
    <v-list
      class="overflow-y-auto full-width"
      max-height="300"
      min-width="400"
      two-line
      dense
    >
      <v-subheader>Neu</v-subheader>
      <v-list-item
        v-for="notification in notifications.filter((a) => !a.archived)"
        :key="notification.notification_id"
        to="notes"
        :to="`/${notification.location_id}/${notification.module}/${notification.module_id}/`"
        @click="archived_notification(notification)"
      >
        <v-list-item-icon>
          <v-icon :color="notification.archived ? 'grey' : 'success'">
            {{
              $i[
                notification.icon
                  ? notification.icon
                  : modules_to_icons[notification.module]
              ]
            }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            modules_to_names[notification.module]
          }}</v-list-item-title>
          <v-list-item-subtitle
            :class="!notification.archived ? ' font-weight-bold' : ''"
            v-text="notification.message"
          ></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            {{ Math.abs($gf.days_until_date(notification.valid_from)) }}
            Tage</v-list-item-action-text
          >

          <!-- <v-icon color="grey lighten-1"> mdi-star-outline </v-icon> -->
        </v-list-item-action>
      </v-list-item>
      <v-subheader>Archiviert</v-subheader>
      <v-list-item
        v-for="notification in notifications.filter((a) => a.archived)"
        :key="notification.notification_id"
        to="notes"
        :to="`/${notification.location_id}/${notification.module}/${notification.module_id}/`"
        @click="archived_notification(notification)"
      >
        <v-list-item-icon>
          <v-icon :color="notification.archived ? 'grey' : 'success'">
            {{
              $i[
                notification.icon
                  ? notification.icon
                  : modules_to_icons[notification.module]
              ]
            }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-light">{{
            modules_to_names[notification.module]
          }}</v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-light"
            v-text="notification.message"
          ></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text class="font-weight-light">
            {{ Math.abs($gf.days_until_date(notification.valid_from)) }}
            Tage</v-list-item-action-text
          >

          <!-- <v-icon color="grey lighten-1"> mdi-star-outline </v-icon> -->
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  computed: {
    department_list() {
      return this.$store.state.departments_list
    },
    departments_from_usr_obj() {
      if (this.$store.state.user_obj) {
        let obj = this.$store.state.user_obj
        let c = obj.companies.find((companies) =>
          companies.locations.some(
            (item) => item.public_id == this.$store.state.location_id
          )
        )
        return c.locations.find(
          (x) => x.public_id == this.$store.state.location_id
        ).departments
      } else {
        return []
      }
    },
    modules_to_names() {
      const newArray = this.$store.state.linklist.reduce(
        (result, { module, text }) => {
          result[module] = text
          return result
        },
        {}
      )
      return newArray
    },
    modules_to_icons() {
      const newArray = this.$store.state.linklist.reduce(
        (result, { module, icon }) => {
          result[module] = icon
          return result
        },
        {}
      )
      return newArray
    },
  },
  props: {
    notifications: {},
  },
  data() {
    return {
      inner_value: null,
    }
  },
  methods: {
    archived_notification(item) {
      this.$emit("close")
      this.apiPostNotification(item, { archived: true })
    },
    apiPostNotification(item, obj) {
      console.log(item)
      this.$api
        .post(
          "notifications/" + item["location_id"] + "/" + item["public_id"],
          obj,
          {
            withCredentials: true,
          }
        )
        .then((item.archived = true))
        .catch()
    },
  },
  created() {},
  watch: {},
}
</script>
