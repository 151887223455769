<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiAccountPlus }}</v-icon>
            <v-toolbar-title>Standort registrieren</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="apiRegisterLocation" ref="registerForm">
            <v-card-text>
              <v-autocomplete
                v-model="formData.company_id"
                :items="companies"
                :prepend-icon="$i.mdiOfficeBuildingMarker"
                item-text="name"
                item-value="public_id"
                label="Gesellschaft"
                ref="company"
                :rules="[rules.required]"
              >
                <template v-slot:no-data>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item>
                    <v-list-item-avatar color="grey lighten-6">
                      <v-icon> {{ $i.mdiClose }} </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        Keine Gesellschaft gefunden
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Es wurde keine passende Gesellschaft gefunden
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  <template>
                    <v-list-item-content>{{ item.name }}</v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-text-field
                label="Marktnummer"
                name="location_id"
                ref="publicidTextField"
                :prepend-icon="$i.mdiStoreMarker"
                v-model="formData.location_id"
                counter="8"
                :rules="[rules.required, rules.location_id]"
              ></v-text-field>
              <v-text-field
                label="Anschrift"
                name="address"
                ref="addressTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.address"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Postleitzahl"
                name="postcode"
                ref="postcodeTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.postcode"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Stadt"
                name="city"
                ref="cityNameTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.city"
                :rules="[rules.required]"
              ></v-text-field>

              <vue-tel-input-vuetify
                default-country="DE"
                :only-countries="[
                  'DE',
                  'GB',
                  'DK',
                  'BE',
                  'FR',
                  'IT',
                  'CH',
                  'AT',
                ]"
                :preferred-countries="['DE', 'AT']"
                disabledFetchingCountry
                v-model="formData.landline"
                :input-options="{ showDialCode: true }"
                mode="international"
                select-label="Land"
                label="Telefonnummer"
              >
              </vue-tel-input-vuetify>
              <vue-tel-input-vuetify
                default-country="DE"
                :only-countries="[
                  'DE',
                  'GB',
                  'DK',
                  'BE',
                  'FR',
                  'IT',
                  'CH',
                  'AT',
                ]"
                :preferred-countries="['DE', 'AT']"
                disabledFetchingCountry
                v-model="formData.fax"
                :input-options="{ showDialCode: true }"
                mode="international"
                select-label="Land"
                label="Faxnummer"
              >
              </vue-tel-input-vuetify>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit">Standort Registrieren</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegisterLocation",
  props: {},
  data: () => ({
    formData: {
      location_id: "",
      company_id: null,
      address: "",
      postcode: "",
      city: "",
      landline: null,
      fax: null,
      email: null,
      receipt_email: null,
    },
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
      location_id: (v) => v.length == 8 || "Marktnummer im 8-stelligen Format",
    },
  }),
  methods: {
    setUser() {
      this.$store.dispatch("apiGetUser")
      this.$router.push({
        name: "Home",
      })
    },
    apiRegisterLocation() {
      this.loading = true
      let statusCode = 0
      this.$api
        .post("register_location", this.formData)
        .then((response) => (statusCode = response.data.status))
        .catch(
          (error) => (
            (statusCode = error.response.data.status),
            error.response.data.error == "duplicate"
              ? this.$root.toast.error("Gesellschaft registriert")
              : null
          )
        )
        .finally(() => {
          this.setUser()
        })
      return statusCode
    },
  },
  computed: {
    companies() {
      if (this.$store.state.user_obj)
        return this.$store.state.user_obj.companies || []
      else return []
    },
  },
}
</script>