<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline">Passwort zurücksetzen</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Bitte geben Sie nachfolgend Ihre E-Mail-Adresse ein, damit wir Ihnen
            einen Link zum Zurücksetzen des Passworts zusenden können.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Email"
              name="Email"
              ref="emailTextField"
              :prepend-icon="$i.mdiEmail"
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" @click="hide('save')">
          Passwort zurücksetzen
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
// import iirDateInput from "@/components/iirDateInput.vue";
// import iirInput from "@/components/iirInput.vue";
export default {
  name: "resetPassword",
  components: {},
  computed: {},
  props: {},
  methods: {
    apiResetPassword() {
      this.$api
        .post("request_password_reset", { email: this.email })
        .then((response) => console.log(response.data))
        .catch()
        .then(() => this.$emit("saved"))
    },
    hide: function (arg) {
      if (this.email == "" && arg == "save") {
        alert("Keine Eingabe gemacht")
      } else if (arg == "save") {
        this.apiResetPassword()
        this.email = null
        this.$emit("closed")
      } else {
        this.email = null
        this.$emit("closed")
      }
    },
  },
  data() {
    return {
      email: null,
    }
  },
  created() {
    this.email = null
  },
}
</script>

