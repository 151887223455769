<template>
  <v-row>
    <v-sparkline
      :value="calc_sales"
      :smooth="radius || false"
      :padding="padding"
      :line-width="width"
      :stroke-linecap="lineCap"
      :fill="fill"
      :type="type"
      :auto-line-width="autoLineWidth"
      :color="color"
      auto-draw
    >
    </v-sparkline>
  </v-row>
</template>

<script>
import { weekRange, getWeekNumber } from "@/assets/isoweek.js";

export default {
  name: "iirArticleRevenueSpark",
  components: {},
  props: {
    revenue: { default: null },
  },
  data: () => ({
    article: [],
    width: 4,
    radius: 10,
    padding: 12,
    height: 15,
    lineCap: "round",
    fill: false,
    type: "trend",
    autoLineWidth: false,
    color: "success",
  }),
  computed: {
    calc_revenue_table() {
      //const zeroPad = (num, places) => String(num).padStart(places, "0");
      if (this.revenue) {
        let rev = this.revenue;
        //console.log(this.current_week);
        if (!JSON.stringify(rev).includes(this.current_week())) {
          rev.push({
            yearweek: this.current_week(),
            menge: Number(0).toFixed(4),
            umsatz: Number(0).toFixed(2),
          });
        }
        if (!JSON.stringify(rev).includes(this.current_week(9))) {
          rev.push({
            yearweek: this.current_week(9),
            menge: Number(0).toFixed(4),
            umsatz: Number(0).toFixed(2),
          });
        }
        rev.sort((a, b) =>
          a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
        );
        const rev2 = weekRange(
          rev[0].yearweek,
          rev[rev.length - 1].yearweek
        ).map((value) => ({
          yearweek: value,
          menge: Number(0),
          umsatz: Number(0),
        }));
        rev = rev.concat(rev2);
        const mengen = Array.from(
          rev.reduce(
            (m, { yearweek, menge }) =>
              m.set(
                yearweek,
                Number(Number(m.get(yearweek) || 0) + Number(menge)).toFixed(2)
              ),
            new Map()
          ),
          ([yearweek, menge]) => ({ yearweek, menge })
        );
        const umsaetze = Array.from(
          rev.reduce(
            (m, { yearweek, umsatz }) =>
              m.set(
                yearweek,
                Number(Number(m.get(yearweek) || 0) + Number(umsatz)).toFixed(2)
              ),
            new Map()
          ),
          ([yearweek, umsatz]) => ({ yearweek, umsatz })
        );
        let res = umsaetze.map((item, i) => Object.assign({}, item, mengen[i]));
        //console.log(arr3);
        res.sort((a, b) =>
          a.yearweek < b.yearweek ? 1 : b.yearweek < a.yearweek ? -1 : 0
        );
        return res;
      } else {
        return [];
      }
    },
    calc_sales() {
      let r = this.revenue
        ? this.calc_revenue_table
            .slice(1, 8)
            .map((item) => parseFloat(item.menge))
        : [0];
      //r = r.map((d) => d.menge);
      //console.log(r);
      return r.reverse();
    },
  },
  methods: {
    zeroPad(num, places) {
      return String(num).padStart(places, "0");
    },
    current_week(minus = 0) {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      let wk = getWeekNumber(new Date());
      return wk.year + "-" + zeroPad(wk.week - minus, 2);
    },
  },
  watch: {},
  mounted: function () {},
};
</script>
