<template>
  <v-card v-if="this.note !== null">
    <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
      <v-icon
        v-if="note.archived && !$vuetify.breakpoint.xsOnly"
        color="grey darken-2"
        class="pr-1"
        >{{ $i.mdiArchive }}</v-icon
      >
      <v-icon
        v-if="!note.archived && !$vuetify.breakpoint.xsOnly"
        color="green darken-2"
        class="pr-1"
        >{{ $i.mdiAlertCircle }}</v-icon
      >
      {{
        note.created != "neu"
          ? new Date(replaceDate(note.created)).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "neu"
      }}
      -
      {{
        note.created != "neu"
          ? new Date(replaceDate(note.created)).toLocaleString("de-DE", {
              hour: "2-digit",
              minute: "2-digit",
            })
          : ""
      }}
    </v-card-title>
    <v-card-subtitle>
      <v-icon>{{ $i.mdiAccount }}</v-icon
      ><profile-avatar :user="note.user_obj"></profile-avatar>
      <template v-if="note.valid_from && disabled"
        ><v-icon>{{ $i.mdiCalendarClock }}</v-icon
        >gültig ab {{ $gf.germanDate(note.valid_from) }}</template
      >
      <iir-date-input
        label="Gültig ab"
        :value.sync="note.valid_from"
        prepend-icon=""
        showWeek
        v-if="!disabled"
      >
      </iir-date-input>
    </v-card-subtitle>
    <v-card-text color="green">
      <v-textarea
        label="Notiz"
        counter="255"
        rows="3"
        :rules="[rules.length(255)]"
        v-model="note.note"
        :prepend-icon="null"
        :suffix="null"
        :disabled="note.archived || disabled"
      >
      </v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-btn :color="disabled ? `primary` : `success`" @click="editNote()">
        {{ disabled ? "bearbeiten" : "speichern" }}
        <v-icon right v-if="disabled">{{ $i.mdiPencil }}</v-icon>
        <v-icon right v-if="!disabled">{{ $i.mdiContentSave }}</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        @click="
          apiUpdateArchiveStatus(note.public_id)
          note.archived = !note.archived
        "
      >
        {{ note.archived ? "reaktivieren" : "archivieren" }}
        <v-icon right v-if="note.archived">{{ $i.mdiAutorenew }}</v-icon>
        <v-icon right v-if="!note.archived">{{ $i.mdiArchive }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :to="`/${$route.params.location_id}/suppliers/${note.supplier.supplier_id}/`"
        color="secondary"
      >
        zum Lieferanten <v-icon right>{{ $i.mdiTruckFast }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProfileAvatar from "@/components/ProfileAvatar.vue"
import iirDateInput from "@/components/iirDateInput.vue"
export default {
  components: {
    ProfileAvatar,
    iirDateInput,
  },
  computed: {
    sync_value: {
      get() {
        return this.note
      },
      set(val) {
        this.$emit("update:note", val)
      },
    },
  },
  props: {
    label: String,
    value: {},
    suffix: { type: String, default: "" },
    prefix: { type: String, default: "" },
    prependIcon: { type: String, default: "" },
    appendIcon: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    type: { type: String, default: "" },
    re: String,
    clearable: {},
    filled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    hint: { type: String, default: "" },
  },
  data() {
    return {
      disabled: true,
      note: null,
      rules: {
        length: (len) => (v) =>
          (v || "").length <= len || `Zu lang, maximal ${len} Zeichen.`,
      },
    }
  },
  mounted: function () {
    this.note = { ...this.value }
  },
  methods: {
    editNote() {
      if (!this.disabled) {
        this.apiUpdateNote(this.note)
      }
      this.disabled = !this.disabled
    },
    replaceDate(str) {
      return str.replace(" ", "T")
    },
    apiUpdateArchiveStatus(note_id) {
      //this.$store.commit('startLoading')
      this.$api
        .post(
          "notes/" +
            this.$route.params.location_id +
            "/archive_status/" +
            note_id
        )
        .then((response) => console.log(response.data))
        .catch()
        .finally(() => this.$emit("edited"))
      // .finally(() => (this.$store.commit('endLoading')));
    },
    apiUpdateNote(note) {
      //this.$store.commit('startLoading')
      if (
        this.value.note != note.note ||
        this.value.valid_from != note.valid_from
      ) {
        this.$api
          .post(
            "notes/" + this.$route.params.location_id + "/" + note.public_id,
            note
          )
          .then((response) => console.log(response.data))
          .catch()
          .finally(() => this.$emit("edited"))
      }
    },
  },
}
</script>
