<template>
  <div>
    <v-simple-checkbox
      v-model="item.finalized"
      :ripple="false"
      disabled
      dense
    ></v-simple-checkbox>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTableFinalized",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
  created() {},
}
</script>