<template>
  <v-data-table
    :headers="notes_table"
    :items="notes"
    :items-per-page="-1"
    :hide-default-footer="true"
    class="elevation-1"
    :sort-by="['created']"
    :sort-desc="[true]"
    :search="search"
    :custom-filter="customSearch"
    item-key="public_id"
    show-group-by
    mobile-breakpoint="0"
    :single-expand="true"
    :expanded.sync="itemExpanded"
    @click:row="handleClick"
    fixed-header
    dense
  >
    <template v-slot:item.archived="{ item }">
      <v-icon v-if="item.archived" color="grey darken-2" class="pr-1">{{
        $i.mdiArchive
      }}</v-icon>
      <v-icon v-if="!item.archived" color="green darken-2" class="pr-1">{{
        $i.mdiAlertCirlce
      }}</v-icon>
      {{ item.valid_from ? "ab " + $gf.germanDate(item.valid_from) : "" }}
    </template>
    <template v-slot:item.note="{ item }">
      <!-- {{ item.note.replace(/(.{30})..+/, "$1 …") }} -->
      {{ item.note.slice(0, 50) }}...
    </template>
    <template v-slot:item.created="{ item }">
      {{
        item.created
          ? new Date(replaceDate(item.created)).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : ""
      }}
    </template>
    <template v-slot:item.user_obj="{ item }">
      <profile-avatar :user="item.user_obj"></profile-avatar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <iir-notes-details
          :value="item"
          v-on:edited="apiGetNotes()"
        ></iir-notes-details>
        <v-card v-if="false">
          <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
            <v-icon
              v-if="item.archived && !$vuetify.breakpoint.xsOnly"
              color="grey darken-2"
              class="pr-1"
              >{{ $i.mdiArchive }}</v-icon
            >
            <v-icon
              v-if="!item.archived && !$vuetify.breakpoint.xsOnly"
              color="green darken-2"
              class="pr-1"
              >{{ $i.mdiAlertCircle }}</v-icon
            >
            {{
              item.created != "neu"
                ? new Date(replaceDate(item.created)).toLocaleString("de-DE", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "neu"
            }}
            -
            {{
              item.created != "neu"
                ? new Date(replaceDate(item.created)).toLocaleString("de-DE", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : ""
            }}
          </v-card-title>
          <v-card-subtitle>
            <v-icon>{{ $i.mdiAccount }}</v-icon> {{ item.user }}
            <profile-avatar :user="item.user_obj"></profile-avatar>
          </v-card-subtitle>
          <v-card-text color="green">
            <v-textarea
              label="Notiz"
              counter="255"
              rows="3"
              :rules="[rules.length(255)]"
              :value="item.note"
              :prepend-icon="null"
              :suffix="null"
              :disabled="item.archived"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              @click="
                apiUpdateArchiveStatus(item.public_id)
                item.archived = !item.archived
              "
            >
              {{ item.archived ? "reaktivieren" : "archivieren" }}</v-btn
            >
            <v-btn
              :to="`/${$route.params.location_id}/suppliers/${item.supplier.supplier_id}/`"
              color="secondary"
            >
              zum Lieferanten
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import iirNotesDetails from "@/components/notes/iirNotesDetails.vue"
import ProfileAvatar from "@/components/ProfileAvatar.vue"

export default {
  name: "iirNotesTable",
  components: {
    iirNotesDetails,
    ProfileAvatar,
  },
  computed: {
    sync_value: {
      get() {
        return this.notes
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
  },
  props: {
    notes: null,
    notes_table: null,
    search: null,
    filter_active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      itemExpanded: [],
    }
  },
  methods: {
    replaceDate(str) {
      return str.replace(" ", "T")
    },
    customSearch(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase())
      })
    },
    // customSearch(value, search, item) {
    //   const flatten = (obj, prefix = "", res = {}) =>
    //     Object.entries(obj).reduce((r, [key, val]) => {
    //       const k = `${prefix}${key}`;
    //       if (typeof val === "object") {
    //         flatten(val, `${k}.`, r);
    //       } else {
    //         res[k] = val;
    //       }
    //       return r;
    //     }, res);

    //   return Object.values(flatten(item)).some(
    //     (v) => v && v.toString().toLowerCase().includes(search)
    //   );
    // },
    apiGetNotes() {
      this.$store.dispatch("apiGetNotes", this.$store.state.location_id)
    },
    handleClick(item) {
      this.itemExpanded = item === this.itemExpanded[0] ? [] : [item]
    },
    apiUpdateArchiveStatus(note_id) {
      //this.$store.commit('startLoading')
      this.$api
        .post(
          "notes/" +
            this.$route.params.location_id +
            "/archive_status/" +
            note_id
        )
        .then((response) => console.log(response.data))
        .catch()
      // .finally(() => (this.$store.commit('endLoading')));
    },
    expand_link() {
      if ("public_id" in this.$route.params && this.notes) {
        let exp = this.notes.filter(
          (a) => a.public_id == this.$route.params.public_id
        )
        if (exp.length) {
          this.itemExpanded = exp
          this.$nextTick(() =>
            this.$vuetify.goTo(".v-data-table__expanded__row")
          )
        }
      }
    },
  },
  watch: {
    notes() {
      this.expand_link()
    },
  },
  created() {
    while (!this.notes) {
      setTimeout(this.expand_link(), 1000)
    }

    // this.notes_data = this.notes
  },
}
</script>
