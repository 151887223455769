<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline">Lieferanten hinzufügen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name"
                required
                v-model="supplier.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="hide('save')"> Save </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  computed: {
    sync_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
  props: {
    value: {},
  },
  methods: {
    apiAddSupplier() {
      this.loading = true;
      this.$api
        .post("suppliers/" + this.$route.params.location_id, this.supplier)
        .then(
          (response) => (this.supplier = response.data),
          this.$store.dispatch("apiGetSuppliers", {
            location_id: this.$store.state.location_id,
            active: false,
          })
        )
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    hide: function (arg) {
      if (this.supplier.name == "" && arg == "save") {
        alert("Keine Eingabe gemacht");
      } else if (arg == "save") {
        this.apiAddSupplier();
        this.$emit("closed", this.supplier.name, this.supplier.name);
        this.sync_value = false;
        this.$router.push({
          name: "Suppliers",
          params: { location_id: this.$store.state.location_id },
        });
      } else {
        this.sync_value = false;
      }
    },
  },
  data() {
    return {
      supplier: { name: "" },
      loading: false,
    };
  },
};
</script>

