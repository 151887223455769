<template>
  <div class="wineadvisor">
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiGlassWine }}</v-icon>
        <v-toolbar-title>Weinberater</v-toolbar-title>
      </v-toolbar>
      <v-card-subtitle> </v-card-subtitle>
      <v-card-text v-if="wines">
        <v-row>
          <v-col cols="12" md="8"
            ><v-text-field
              v-model="search"
              :prepend-inner-icon="$i.mdiMagnify"
              label="Suchen"
              clearable
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              v-model="show_images"
              label="Artikelbilder anzeigen"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.countries"
              :items="countries_list"
              label="Land"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-map-marker-radius"
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.regions"
              :items="regions_list"
              label="Region"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-map-marker-radius-outline"
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.winemakers"
              :items="winemakers_list"
              label="Winzer"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-tools"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.colors"
              :items="colors_list"
              label="Farbe"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-invert-colors"
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.sweetness"
              :items="sweetness_list"
              label="Restsüße"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-water-percent"
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.grapes"
              :items="grapes_list"
              label="Traube"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-fruit-grapes"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" xl="4">
            <v-switch
              v-model="winefilter.organic"
              inset
              :prepend-icon="$i.mdiLeafCircle"
              label="Bio"
              dense
              color="success"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-switch
              v-model="winefilter.non_alcoholic"
              inset
              :prepend-icon="$i.mdiGlassCocktailOff"
              label="Alkoholfrei"
              dense
              color="success"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              v-model="winefilter.contents"
              :items="contents_list"
              label="Größe"
              multiple
              dense
              clearable
              chips
              deletable-chips
              prepend-icon="mdi-bottle-wine"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-layout child-flex>
          <v-data-table
            :headers="articles_table"
            :items="wines_filtered"
            :items-per-page="supplier ? -1 : 50"
            :hide-default-footer="supplier ? true : false"
            :footer-props="{
              'items-per-page-options': [20, 50, 100, -1],
              'items-per-page-text': 'Einträge pro Seite',
              'items-per-page-all-text': 'Alle',
              'page-text': '{0}-{1} von {2}',
              'show-current-page': true,
            }"
            class="elevation-1"
            :search="search"
            :custom-filter="customSearch"
            item-key="nan"
            show-group-by
            group-by="wgr_4"
            mobile-breakpoint="0"
            :single-expand="true"
            :expanded.sync="itemExpanded"
            @click:row="handleClick"
            fixed-header
          >
            <template v-slot:group.header="{ items, isOpen, toggle }">
              <td
                :colspan="`${articles_table.length - 2}`"
                class="font-weight-bold"
                @click="toggle"
              >
                <v-icon>{{ $i.mdiPackage }}</v-icon>
                {{ items[0].wgr_4 }}
              </td>
              <td class="text-right">
                <v-icon @click="toggle"
                  >{{ isOpen ? $i.mdiMinus : $i.mdiPlus }}
                </v-icon>
              </td>
            </template>
            <template v-slot:item.brand="{ item }">
              <v-row>{{ item.brand }}</v-row>
              <v-row>{{ item.name }}</v-row>
              <v-row>{{ item.winemaker }}</v-row>
              <v-row>{{ item.title }}</v-row>
            </template>
            <template v-slot:item.grape="{ item }">
              <v-chip
                small
                v-for="dep in item.grape"
                :key="dep"
                color="secondary"
              >
                {{ dep }}
              </v-chip>
            </template>
            <template v-slot:item.labels="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="item.organic == 1" v-bind="attrs" v-on="on">{{
                    $i.mdiLeafCircle
                  }}</v-icon>
                </template>
                <span>Bio</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.non_alcoholic == 1"
                    v-bind="attrs"
                    v-on="on"
                    >{{ $i.mdiGlassCocktailOff }}</v-icon
                  >
                </template>
                <span>Alkoholfrei</span>
              </v-tooltip>
            </template>
            <template v-slot:item.w-3="{ item }">
              {{ filter_revenue(item.revenue, current_week(3)) }}
            </template>
            <template v-slot:item.w-2="{ item }">
              {{ filter_revenue(item.revenue, current_week(2)) }}
            </template>
            <template v-slot:item.w-1="{ item }">
              {{ filter_revenue(item.revenue, current_week(1)) }}
            </template>
            <template v-slot:item.revenue="{ item }">
              <iir-article-revenue-spark
                :revenue="item.revenue"
              ></iir-article-revenue-spark>
            </template>
            <template v-slot:item.img="{ item }">
              <v-card
                min-width="120"
                min-height="120"
                color="#FFFFFF"
                :class="'ma-0 pa-0'"
              >
                <v-img
                  :src="img_url(item.nan)"
                  contain
                  max-width="120"
                  max-height="120"
                  eager
                >
                  <template v-slot:placeholder>
                    <v-row class="ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <iir-article-info :nan="item.nan"></iir-article-info>
              </td>
            </template>
          </v-data-table>
        </v-layout>
      </v-card-text>
      <v-btn
        class="mx-2"
        fab
        dark
        color="success"
        fixed
        right
        bottom
        append
        @click.stop="resetFilters"
      >
        <v-icon dark> {{ $i.mdiAutorenew }} </v-icon>
      </v-btn>
      <!-- <v-dialog v-model="dialog" max-width="500px">
        <add-articles
          @closed="closeOverlay('closed')"
          @saved="closeOverlay('saved')"
        >
        </add-articles>
      </v-dialog> -->
    </v-card>
  </div>
</template>

<script>
import iirArticleInfo from "@/components/iirArticleInfo.vue"
import iirArticleRevenueSpark from "@/components/iirArticleRevenueSpark.vue"
import { getWeekNumber } from "@/assets/isoweek.js"
import wineData from "../assets/wineadvisor.json"

export default {
  name: "WineAdvisor",
  components: {
    iirArticleInfo,
    iirArticleRevenueSpark,
  },
  data: () => ({
    search: "",
    wines: [],
    revenue_rows: 5,
    itemExpanded: [],

    revenue: [],
    supplier: "",
    articles: [],
    model: "",
    dialog: false,
    show_images: true,
    winefilter: {
      winemakers: [],
      countries: [],
      regions: [],
      colors: [],
      grapes: [],
      sweetness: [],
      contents: [],
      organic: null,
      non_alcoholic: null,
    },
  }),
  computed: {
    articles_table() {
      let headers = [
        { text: "Warengruppe", value: "wgr_4", sortable: true },

        {
          text: "Artikel",
          value: "brand",
          sortable: true,
          groupable: false,
          //   width: "100%",
        },
        {
          text: "Land",
          value: "country",
          sortable: true,
          groupable: false,
        },
        {
          text: "Region",
          value: "region",
          sortable: true,
          groupable: false,
        },
        {
          text: "Farbe",
          value: "color",
          sortable: true,
          groupable: false,
        },
        {
          text: "Traube",
          value: "grape",
          sortable: true,
          groupable: false,
        },
        {
          text: "Restsüße",
          value: "sweetness",
          sortable: true,
          groupable: false,
          //   width: "150px",
        },
        {
          text: "Labels",
          value: "labels",
          align: "start",
          sortable: false,
          groupable: false,
          width: 50,
        },
        { text: "Inhalt", value: "content", sortable: true, groupable: false },
        {
          text: "NAN",
          value: "nan",
          align: "start",
          sortable: true,
          groupable: false,
          //   width: 50,
        },
      ]
      if (this.show_images) {
        headers = [
          {
            text: "Artikelbild",
            value: "img",
            groupable: false,
            width: 150,
          },
          ...headers,
        ]
      }
      return headers
    },
    grapes_list() {
      return this.summarize_array_by_key(this.wines_filtered, "grape")
    },
    countries_list() {
      return this.summarize_array_by_key(this.wines_filtered, "country")
    },
    regions_list() {
      return this.summarize_array_by_key(this.wines_filtered, "region")
    },
    winemakers_list() {
      return this.summarize_array_by_key(this.wines_filtered, "winemaker")
    },
    colors_list() {
      return this.summarize_array_by_key(this.wines_filtered, "color")
    },
    sweetness_list() {
      return this.summarize_array_by_key(this.wines_filtered, "sweetness")
    },
    contents_list() {
      return this.summarize_array_by_key(this.wines_filtered, "content")
    },
    wines_filtered() {
      const filters = this.winefilter

      return this.wines
        .map((e) => ({ ...e, grape: this.stringToArray(e.grape, ",") }))
        .filter(
          (x) =>
            (!filters.grapes.length ||
              this.findCommonElement(x.grape, filters.grapes)) &&
            (!filters.countries.length ||
              this.findCommonElement([x.country], filters.countries)) &&
            (!filters.regions.length ||
              this.findCommonElement([x.region], filters.regions)) &&
            (!filters.winemakers.length ||
              this.findCommonElement([x.winemaker], filters.winemakers)) &&
            (!filters.colors.length ||
              this.findCommonElement([x.color], filters.colors)) &&
            (!filters.sweetness.length ||
              this.findCommonElement([x.sweetness], filters.sweetness)) &&
            (!filters.contents.length ||
              this.findCommonElement([x.content], filters.contents)) &&
            (!filters.organic || x.organic === 1) &&
            (!filters.non_alcoholic || x.non_alcoholic === 1)
        )
    },
  },
  methods: {
    apiGetWines() {
      this.$api
        .get("/articles/" + this.$route.params.location_id + "/wines")
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.wines = response.data
        })
        .catch()
        .finally()
    },
    stringToArray(str, delimiter) {
      str = String(str)
      if (str.length) {
        return str.split(delimiter).map((item) => item.trim())
      } else {
        return []
      }
    },
    resetFilters() {
      this.winefilter = {
        winemakers: [],
        countries: [],
        regions: [],
        colors: [],
        grapes: [],
        sweetness: [],
        contents: [],
        organic: null,
        non_alcoholic: null,
      }
    },
    summarize_array_by_key(array, key) {
      // let u = [...new Set(array.map((item) => item[key]))].flat()
      // return [...new Set(u)].sort(function (a, b) {
      //   if (a.toLowerCase() < b.toLowerCase()) return -1
      //   if (a.toLowerCase() > b.toLowerCase()) return 1
      //   return 0
      // })
      // Check for null values in array or key
      if (array === null || key === null) {
        return []
      }

      let uniqueValues = [
        ...new Set(
          array.map((item) => (item[key] !== null ? item[key] : undefined))
        ),
      ].flat()
      let sortedValues = [...new Set(uniqueValues)].sort(function (a, b) {
        if (a !== null && b !== null) {
          if (a.toLowerCase() < b.toLowerCase()) return -1
          if (a.toLowerCase() > b.toLowerCase()) return 1
        }
        return 0
      })

      return sortedValues
    },
    customSearch(value, search, item) {
      const getObjectValues = (obj) =>
        obj && typeof obj === "object"
          ? Object.values(obj).map(getObjectValues).flat()
          : [obj]
      // console.log(getObjectValues(item));
      return getObjectValues(item).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      )
    },
    findCommonElement(array1, array2) {
      for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {
          if (array1[i] === array2[j]) {
            return true
          }
        }
      }

      return false
    },
    img_url(nan, size = 400) {
      const zeroPad = (num, places) => String(num).padStart(places, "0")
      nan = zeroPad(nan, 7)
      return (
        "https://img.rewe-bg.de/eweb/" +
        nan[0] +
        "/" +
        nan[0] +
        nan[1] +
        "/" +
        nan +
        "/" +
        nan +
        "-" +
        size +
        ".jpg"
      )
      //return "https://img.rewe-bg.de/eweb/${nan}-400.jpg"
    },
    handleClick(item) {
      this.itemExpanded = item === this.itemExpanded[0] ? [] : [item]
    },
    zeroPad(num, places) {
      return String(num).padStart(places, "0")
    },
    closeOverlay() {
      // this.overlay = false;
      this.dialog = false
    },
    current_week(minus = 0) {
      //const zeroPad = (num, places) => String(num).padStart(places, "0");
      let wk = getWeekNumber(new Date())
      return wk.year + "-" + this.zeroPad(wk.week - minus, 2)
    },
    filter_revenue(val, week) {
      if (val) {
        let rev = val.filter((x) => x.yearweek == week)
        if (rev.length) {
          return Number(rev[0].menge).toFixed(2)
        } else {
          return Number(0).toFixed(2)
        }
      } else {
        return Number(0).toFixed(2)
      }
    },
  },
  watch: {},
  mounted: function () {
    this.apiGetWines()
  },
}
</script>
