<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-toolbar-title class="print-only">
      <v-icon class="print-only" color="black">{{ $i.mdiSemanticWeb }}</v-icon>
      <span class="font-weight-light text-subtitle-2">
        {{ this.$store.state.location_id }} @
        {{ $gf.germanDate(new Date()) }}</span
      >
    </v-toolbar-title>
  </v-row>
</template>

<script>
export default {
  name: "TitleToolbarPrint",
  components: {},
  data: () => ({}),
}
</script>
