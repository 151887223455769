<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          {{ item.created ? $gf.germanDate(item.created) : "" }}
        </span>
      </template>
      <v-card>
        <v-card-title>{{ source }}</v-card-title>

        <v-card-subtitle>
          {{ item.created ? $gf.utcToLocal(item.created) : "" }}
        </v-card-subtitle>
        <v-card-text>
          <p v-if="!user_obj">{{ item.source }}: {{ item.source_email }}</p>

          <p v-if="user_obj">User:<profile-avatar :user="user_obj" /></p>
        </v-card-text>
      </v-card>
    </v-tooltip>
  </div>
</template>

<script>
import ProfileAvatar from "@/components/ProfileAvatar.vue"
export default {
  name: "ReceiptDataTableCreated",
  components: { ProfileAvatar },
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {
    user_obj() {
      return this.item.user_obj?.user_id ? this.item.user_obj : null
    },
    source() {
      if (this.item && this.item.source)
        return (
          this.item.source.charAt(0).toUpperCase() + this.item.source.slice(1)
        )
      else return null
    },
  },
  methods: {},
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>