<template>
  <v-card>
    {{ promotions_formatted }}
    <v-slide-group show-arrows>
      <v-slide-item
        v-for="items in promotions_formatted"
        :key="items.key"
        v-slot="{ active, toggle }"
      >
        <v-card class="ma-4" width="100">
          <v-card-title>{{ item.year }}-{{ item.week }}</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.product"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-card>
</template>

<script>
// import iirInput from "@/components/iirInput.vue"
// import departmentData from "@/assets/departments.json"
// import iirChipSelect from "@/components/iirChipSelect.vue"
// import ProfileAvatar from "@/components/ProfileAvatar.vue"
import { weekRange, getWeekNumber } from "@/assets/isoweek.js"

export default {
  name: "PromotionsWeekly",
  components: {},
  data: () => ({
    loaded: false,
  }),
  computed: {
    promotions_comp() {
      var arr = []
      var arr_residue = []
      var years = []
      for (const value of Object.values(this.promotions)) {
        if (years.indexOf(value.year) === -1) {
          years.push(value.year)
        }
        arr.push({
          yearweek: value.year + "-" + this.$gf.zeroPad(value.week, 2),
          year: value.year,
          week: value.week,
          size: value.size_in_quarter,
          category: value.category,
          product: value.product,
        })
        const res3 = weekRange(
          arr[0].yearweek,
          arr[arr.length - 1].yearweek
        ).map((value) => ({
          yearweek: value,
          size: 0,
          category: null,
          product: null,
        }))
        arr = arr.concat(res3)
        // arr_residue.push({
        //   yearweek: value.year + "-" + this.$gf.zeroPad(value.week, 2),
        //   year: value.year,
        //   week: value.week,
        //   size: 0,
        // })
      }
      arr.sort((a, b) =>
        a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
      )
      return arr
    },
    promotions_formatted() {
      const groupByKey2 = (list, key, { omitKey = false }) =>
        list.reduce(
          (hash, { [key]: value, ...rest }) => ({
            ...hash,
            [value]: (hash[value] || []).concat(
              omitKey ? { ...rest } : { [key]: value, ...rest }
            ),
          }),
          {}
        )
      const groupByKey = (items, key) =>
        items.reduce(
          (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
          }),
          {}
        )
      return groupByKey(this.promotions_comp, "yearweek")
    },
  },
  props: {
    promotions: {},
  },
  methods: {},
  mounted() {},
  watch: {},
}
</script>

