<template>
  <router-view />
</template>

<script>
export default {
  name: "RouterFrame",
  mounted: function () {
    // if (!this.$store.state.logged_in && this.$route.name != "Login") {
    //   this.$router.push({
    //     name: "Login",
    //   });
    // }
    // this.$store.commit("SET_LINKS");
  },
}
</script>
