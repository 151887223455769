<template>
  <v-card>
    <v-form @submit.prevent="save">
      <v-card-title>Lizenz</v-card-title>
      <admin-tab-license
        v-if="is_siteadmin"
        :location_id="location.public_id"
      ></admin-tab-license>
      <!-- <v-card-text>
        <iir-chip-select
          :value.sync="formData.billing_modules"
          :prepend-icon="$i.mdiToyBrick"
          :clearable="false"
          label="Freigeschaltete Module"
          :disabled="true"
          multiple
        >
        </iir-chip-select>
        <iir-chip-select
          :value.sync="formData.billing_plan"
          :prepend-icon="$i.mdiToyBrick"
          :clearable="false"
          label="Laufender Plan"
          :disabled="true"
          multiple
        >
        </iir-chip-select>
      </v-card-text> -->
      <v-card-title> Standortdaten </v-card-title>
      <v-card-text>
        <v-row dense
          ><v-col cols="">
            <v-text-field
              label="Anschrift"
              name="address"
              ref="lasttNameTextField"
              :prepend-icon="$i.mdiAccountOutline"
              v-model="formData.address"
              :rules="[rules.required]"
            ></v-text-field> </v-col
        ></v-row>
        <v-row dense
          ><v-col cols="6">
            <v-text-field
              label="Postleitzahl"
              name="postcode"
              ref="lasttNameTextField"
              :prepend-icon="$i.mdiAccountOutline"
              v-model="formData.postcode"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Stadt"
              name="city"
              ref="lasttNameTextField"
              :prepend-icon="$i.mdiAccountOutline"
              v-model="formData.city"
              :rules="[rules.required]"
            ></v-text-field> </v-col
        ></v-row>
        <v-row dense
          ><v-col cols="6">
            <vue-tel-input-vuetify
              default-country="DE"
              :only-countries="['DE', 'GB', 'DK', 'BE', 'FR', 'IT', 'CH', 'AT']"
              :preferred-countries="['DE', 'AT']"
              disabledFetchingCountry
              v-model="formData.landline"
              :input-options="{ showDialCode: showDialCode }"
              mode="international"
              select-label="Land"
              label="Telefon"
              clearable
              hint="inkl. Vorwahl (z. B. 040)"
            >
            </vue-tel-input-vuetify>
          </v-col>
          <v-col cols="6">
            <vue-tel-input-vuetify
              default-country="DE"
              :only-countries="['DE', 'GB', 'DK', 'BE', 'FR', 'IT', 'CH', 'AT']"
              :preferred-countries="['DE', 'AT']"
              disabledFetchingCountry
              v-model="formData.fax"
              :input-options="{ showDialCode: showDialCode }"
              mode="international"
              select-label="Land"
              label="Fax"
              clearable
              hint="inkl. Vorwahl (z. B. 040)"
            >
            </vue-tel-input-vuetify> </v-col
        ></v-row>
        <v-row dense
          ><v-col cols="6">
            <v-text-field
              label="Email"
              v-model="formData.email"
              :prepend-icon="$i.mdiAt"
              clearable
              :rules="[rules.email_validation]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Belegpostfach"
              v-model="formData.receipt_import_email"
              :prepend-icon="$i.mdiAt"
              clearable
              :rules="[rules.email_validation]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Scan2Mail-Marktadresse"
              v-model="formData.scan2mail_import_email"
              :prepend-icon="$i.mdiAt"
              clearable
              :rules="[rules.email_validation]"
            >
            </v-text-field> </v-col
        ></v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" type="submit">Speichern</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import iirChipSelect from "@/components/iirChipSelect.vue"
import AdminTabLicense from "./AdminTabLicense.vue"
export default {
  components: {
    iirChipSelect,
    AdminTabLicense,
  },
  computed: {
    sync_value: {
      get() {
        return this.formData
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
    is_siteadmin() {
      return this.$store.state.user_obj.is_siteadmin || false
    },
  },
  data: () => ({
    showDialCode: false,
    formData: {},
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
      email_validation: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return value != ""
          ? pattern.test(value) || "Ungültige E-Mail-Adresse."
          : true
      },
    },
    show_password: false,
  }),

  props: {
    location: {},
  },
  methods: {
    apiSaveLocation() {
      // this.loading = true;
      this.$api
        .put("admin/location/" + this.formData.public_id, this.formData)
        .then(() => {})
        .catch((error) => console.log(error))
      // .finally(() => (this.loading = false));
    },
    save: function () {
      this.apiSaveLocation()
    },
  },
  mounted() {
    this.formData = this.location
    this.$nextTick(() => {
      this.showDialCode = true
    })
  },
}
</script>

