
<template>
  <v-row justify="center">
    <v-dialog v-model="value" max-width="600px">
      <v-card color="amber darken-2">
        <v-card-title>
          <span class="text-h5">{{ billing_type }}</span>
        </v-card-title>
        <v-card-text v-if="receipt">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model="supplier_name"
                  readonly
                  @focus="$event.target.select()"
                  :prepend-icon="$i.mdiClipboardText"
                  @click:prepend="clipboard(supplier_name)"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="IBAN"
                  v-model="iban"
                  readonly
                  @focus="$event.target.select()"
                  :prepend-icon="$i.mdiClipboardText"
                  @click:prepend="clipboard(iban)"
                  dense
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Summe"
                  v-model="value_gross"
                  @focus="$event.target.select()"
                  :prepend-icon="$i.mdiClipboardText"
                  @click:prepend="clipboard(value_gross)"
                  dense
                >
                  <template v-slot:append>
                    <v-chip
                      small
                      v-show="
                        !receipt.paid &&
                        receipt.supplier.payment_term &&
                        receipt_date
                      "
                    >
                      <v-icon left small>
                        {{ $i.mdiCalendar }}
                      </v-icon>
                      {{
                        $gf.germanDate(
                          $gf.addDaysToDate(
                            receipt_date,
                            receipt.supplier.payment_term
                          )
                        )
                      }}
                    </v-chip>
                  </template>
                </v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6" md="6">
                <iir-currency-input
                  label="Summe"
                  :value.sync="value_gross"
                  :currency="{ prefix: '', suffix: '' }"
                  :precision="2"
                  dense
                  @focus="$event.target.select()"
                >
                  <template v-slot:prepend>
                    <v-icon @click="clipboard(value_gross)">{{
                      $i.mdiClipboardText
                    }}</v-icon>
                  </template>
                  <template v-slot:append>
                    <v-chip
                      small
                      v-show="
                        !receipt.paid &&
                        receipt.supplier.payment_term &&
                        receipt_date
                      "
                    >
                      <v-icon left small>
                        {{ $i.mdiCalendar }}
                      </v-icon>
                      {{
                        $gf.germanDate(
                          $gf.addDaysToDate(
                            receipt_date,
                            receipt.supplier.payment_term
                          )
                        )
                      }}
                    </v-chip>
                  </template>
                </iir-currency-input>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
                v-show="receipt.supplier.payment_discount"
              >
                <v-text-field
                  label="Skonto"
                  v-model="value_discounted"
                  readonly
                  @focus="$event.target.select()"
                  :prepend-icon="$i.mdiClipboardText"
                  @click:prepend="clipboard(value_discounted)"
                  dense
                >
                  <template v-slot:append>
                    <v-chip
                      small
                      v-show="
                        !receipt.paid &&
                        receipt.supplier.payment_discount &&
                        receipt_date
                      "
                    >
                      <v-icon left small>
                        {{ $i.mdiCalendar }}
                      </v-icon>
                      {{ receipt.supplier.payment_discount }}%:
                      {{
                        $gf.germanDate(
                          $gf.addDaysToDate(
                            receipt_date,
                            receipt.supplier.payment_discount_term
                          )
                        )
                      }}
                    </v-chip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Verwendungszweck"
                  v-model="reason_for_payment"
                  readonly
                  @focus="$event.target.select()"
                  :prepend-icon="$i.mdiClipboardText"
                  @click:prepend="clipboard(reason_for_payment)"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-show="receipt.supplier.payment_discount"
              >
                <v-text-field
                  label="Verwendungszweck Skonto"
                  v-model="reason_for_payment_discounted"
                  readonly
                  @focus="$event.target.select()"
                  :prepend-icon="$i.mdiClipboardText"
                  @click:prepend="clipboard(reason_for_payment_discounted)"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="!receipt?.paid"
            color="success"
            @click="pay_and_close()"
            >Bezahlt</v-btn
          >
          <v-btn color="error" @click="value = false"> Schließen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue"
export default {
  name: "ReceiptPaymentData",
  components: {
    iirCurrencyInput,
  },
  props: {
    receipt: { default: null },
    extended_content: { default: [] },
    dialog: { default: false },
  },
  data: () => ({
    show_dialog: false,
    value_gross: null,
  }),
  computed: {
    value: {
      get() {
        return this.dialog
      },
      set(newValue) {
        this.$emit("update:dialog", newValue)
        this.$emit("update:paid", true)
      },
    },
    billing_type() {
      return this.receipt?.supplier?.billing_type || "Unbekannte Zahlungsart"
    },
    receipt_date() {
      return this.receipt?.receipt_date || "Unbekannte Zahlungsart"
    },
    supplier_name() {
      return (
        this.receipt?.supplier?.name_long || this.receipt?.supplier?.name || ""
      )
    },
    receipt_number() {
      return this.receipt?.receipt_number || null
    },
    reason_for_payment() {
      return this.receipt_number
        ? `Re-Nr. ${this.receipt_number} ${this.value_gross}`
        : null
    },
    reason_for_payment_discounted() {
      return this.receipt_number
        ? `Re-Nr. ${this.receipt_number} ${this.value_gross} abzgl. Skonto`
        : null
    },
    iban() {
      // if (this.extended_content) {
      //   return (
      //     this.extended_content?.find((x) => x["key"] === "iban")["value"] ||
      //     null
      //   )
      // } else {
      //   return null
      // }
      if (Array.isArray(this.extended_content)) {
        const gross_value =
          this.extended_content?.find((x) => x.key === "iban") || null
        return gross_value ? gross_value.value : null
      } else {
        return null
      }
    },
    value_gross_parsed_old() {
      if (Array.isArray(this.extended_content)) {
        const gross_value =
          this.extended_content?.find((x) => x.key === "value_gross") || null
        return gross_value
          ? this.$gf.parseLocalizedFloat(gross_value.value)
          : null
      } else {
        return null
      }
    },
    value_gross_old() {
      return this.$gf.parseDec(this.value_gross_parsed, 2)
    },
    value_discounted() {
      let val_gross = this.value_gross
      val_gross = this.$gf.parseLocalizedFloat(val_gross)

      return this.$gf.parseDec(
        (val_gross * (100 - this.receipt.supplier.payment_discount)) / 100,
        2
      )
    },
  },
  methods: {
    async clipboard(val) {
      try {
        await navigator.clipboard.writeText(val)
        this.$root.toast.success(`"${val}" kopiert!`)
      } catch ($e) {
        this.$root.toast.error("Kopieren nicht möglich!")
      }
    },
    apiPutReceipt(obj) {
      this.$api
        .put(
          "receipts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then((response) => console.log(response.data))
        .catch()
    },
    pay_and_close() {
      this.apiPutReceipt({ paid: true })
      this.$emit("paid", true)
      this.value = false
    },
    set_gross() {
      let value_gross_parsed = () => {
        if (Array.isArray(this.extended_content)) {
          const gross_value =
            this.extended_content?.find((x) => x.key === "value_gross") || null
          return gross_value
            ? this.$gf.parseLocalizedFloat(gross_value.value)
            : null
        } else {
          return null
        }
      }
      this.value_gross = this.$gf.parseDec(value_gross_parsed(), 2)
    },
  },
  watch: {
    dialog(newValue) {
      this.set_gross()
      if (newValue) {
        document.body.classList.add("always-scrollable")
      } else {
        document.body.classList.remove("always-scrollable")
      }
    },
  },
  mounted() {
    this.set_gross()
  },
  created() {},
}
</script>