<template>
  <div>
    <v-layout v-if="item.receipt_type == 'Rechnung'">
      <v-simple-checkbox
        v-model="item.paid"
        :ripple="false"
        @input="checked_box(item, 'paid')"
      ></v-simple-checkbox>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="item.supplier.billing_type" v-bind="attrs" v-on="on">{{
            billing_type
          }}</v-icon></template
        >
        <span> {{ item.supplier.billing_type }}</span>
      </v-tooltip>
      <v-chip
        small
        v-if="
          !item.paid &&
          item.supplier.payment_discount &&
          item.receipt_date &&
          $gf.payment_days(
            item.receipt_date,
            item.supplier.payment_discount_term
          ) > 0
        "
      >
        {{
          item.receipt_date
            ? $gf.payment_days(
                item.receipt_date,
                item.supplier.payment_discount_term
              ) + "T"
            : null
        }}: {{ item.supplier.payment_discount }}%
      </v-chip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            small
            v-if="!item.paid && item.supplier.payment_term && item.receipt_date"
            v-bind="attrs"
            v-on="on"
          >
            {{
              item.receipt_date
                ? $gf.payment_days(
                    item.receipt_date,
                    item.supplier.payment_term
                  ) + "T"
                : null
            }}
          </v-chip>
        </template>
        <span
          >Zahlung bis
          {{
            $gf.germanDate(
              $gf.addDaysToDate(item.receipt_date, item.supplier.payment_term)
            )
          }}</span
        >
      </v-tooltip>
    </v-layout>
    <v-layout v-else>
      <v-chip small>keine Rechnung</v-chip>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTablePaid",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {
    billing_type() {
      let str = this.item.supplier.billing_type
      if (typeof str !== "string" || str.length === 0) {
        return str
      } else {
        if (str.charAt(0) == "Ü")
          return this.$i.mdiContactlessPaymentCircleOutline
        else if (str.charAt(0) == "L")
          return this.$i.mdiContactlessPaymentCircle
      }
      return str.charAt(0).toUpperCase()
    },
  },
  methods: {
    checked_box(item, box) {
      this.apiPutReceipt(item["public_id"], { [box]: item[box] })
    },
    apiPutReceipt(receipt_id, obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then()
        .catch()
    },
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>