<template>
  <v-data-table
    :headers="orders_table"
    :items="sync_value"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :footer-props="footerProps"
    :dense="dense"
    fixed-header
    :items-per-page="10"
    class="elevation-1"
    :search="search"
    item-key="public_id"
    mobile-breakpoint="0"
  >
    <template slot="no-data">
      Lieferanten auswählen um Bestellunge anzuzeigen.
    </template>
    <template v-slot:item.order_date="{ item }">
      {{
        item.order_date
          ? new Date(replaceDate(item.order_date)).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : ""
      }}
    </template>
    <!-- <template v-slot:item.delivery_date="{ item }">
      {{
        item.delivery_date
          ? new Date(replaceDate(item.delivery_date)).toLocaleDateString(
              "de-DE",
              {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }
            )
          : ""
      }}
    </template> -->
    <template v-slot:item.blank_order="{ item }">
      <v-checkbox
        v-model="item.blank_order"
        disabled
        hide-details
        :dense="dense"
        class="shrink mr-1 mt-1"
        :label="item.blank_order ? item.holding_period + ' Tage' : ''"
      ></v-checkbox>
    </template>
    <template v-slot:item.user_obj="{ item }">
      <profile-avatar :user="item.user_obj"></profile-avatar>
    </template>
  </v-data-table>
</template>

<script>
import ProfileAvatar from "@/components/ProfileAvatar.vue";

export default {
  name: "ShowShipments",
  components: {
    ProfileAvatar,
  },
  props: {
    filled: { type: Boolean, default: false },
    items: {},
    sortBy: {
      default: function () {
        return ["order_date"];
      },
    },
    sortDesc: {
      default: function () {
        return ["true"];
      },
    },
    footerProps: {},
    dense: { type: Boolean, default: false },
    showSearch: { type: Boolean, default: false },
  },
  computed: {
    sync_value: {
      get() {
        return this.items;
      },
      set(val) {
        this.$emit("update:items", val);
      },
    },
    orders_table() {
      return [
        {
          text: "Lieferant",
          value: "supplier.name",
          align: "start",
          sortable: true,
        },
        { text: "Bestelldatum", value: "order_date", sortable: true },
        //{ text: "erw. Lieferdatum", value: "delivery_date", sortable: true },
        { text: "Leerbestellung", value: "blank_order", sortable: true },
        { text: "User", value: "user_obj", sortable: true },
      ];
    },
  },
  data: () => ({
    search: "",
    expanded: [],
  }),
  methods: {
    replaceDate(str) {
      return str.replace(" ", "T");
    },
  },
};
</script>
