<template>
  <v-card class="mx-auto">
    <v-card-title> {{ article.marke }} - {{ article.name }} </v-card-title>
    <v-card-subtitle>{{ article.menge }} - {{ article.nan }}</v-card-subtitle>
    <v-img :src="img" contain max-height="300">
      <template v-slot:placeholder>
        <v-row class="ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-text class="text-center">
      <vue-barcode
        :value="article.nan"
        :height="25"
        :width="2.5"
        font-size="15"
        font-options="bold"
        :margin-right="0"
        :margin="2"
        :display-value="false"
        :flat="true"
      ></vue-barcode>
    </v-card-text>
    <v-card-text>
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in wgr_table"
            :key="i"
            class="elevation-1"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-row align="center">
        <v-col> </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="show_revenue = !show_revenue" color="secondary">
        {{ show_revenue ? "verkleinern" : "Umsatz" }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="show_revenue"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-card-text class="pa-0">
          <v-data-table
            :headers="revenue_table"
            :items="article.revenue"
            :sort-by="['yearweek']"
            :sort-desc="[true]"
            mobile-breakpoint="0"
            hide-default-footer
            class="elevation-1"
            :items-per-page="-1"
            dense
            :height="
              article.revenue && article.revenue.length > revenue_rows
                ? 48 * revenue_rows - 1
                : undefined
            "
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import VueBarcode from "@/components/iirBarcode.js";

export default {
  name: "iirArticleInfo",
  components: {
    VueBarcode,
  },
  props: {
    nan: { type: String, default: null },
  },
  data: () => ({
    img: "https://img.rewe-bg.de/7223891/27491349_digital-image.png",
    article: [],
    revenue_rows: 5,
    show_revenue: false,
  }),
  computed: {
    revenue_table() {
      return [
        { text: "KW", value: "yearweek", sortable: true },
        { text: "Umsatz", value: "umsatz", sortable: false },
        { text: "Menge", value: "menge", sortable: false },
      ];
    },
    wgr_table() {
      return [
        { icon: "mdi-currency-eur", value: this.calc_revenue + " / W" },
        { icon: "mdi-diameter-variant", value: this.calc_sales + " / W" },
        { icon: "mdi-numeric-1-box", value: this.article.fokussortiment },
        { icon: "mdi-numeric-2-box", value: this.article.fokusgruppe },
        { icon: "mdi-numeric-3-box", value: this.article.wgr_4 },
      ];
    },
    calc_revenue() {
      return this.article && this.article.revenue
        ? (
            this.article.revenue
              .slice(1, 4)
              .map((item) => parseFloat(item.umsatz))
              .reduce((prev, curr) => prev + curr, 0) / 4
          ).toFixed(2)
        : 0;
    },
    calc_sales() {
      return this.article && this.article.revenue
        ? (
            this.article.revenue
              .slice(1, 4)
              .map((item) => parseFloat(item.menge))
              .reduce((prev, curr) => prev + curr, 0) / 4
          ).toFixed(2)
        : 0;
    },
  },
  methods: {
    apiGetArticle(location_id, nan) {
      //this.$store.commit('startLoading')
      //console.log(filteredData);
      this.$api
        .get("/articles/" + location_id + "/" + nan)
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.article = response.data;
        })
        .catch()
        .finally();
    },
    getRevenue(nan) {
      let rev = this.revenue.filter((x) => x.nan == nan);
      if (rev.length) {
        return rev[0].revenue;
      } else {
        return [];
      }
    },
  },
  watch: {},
  mounted: function () {
    this.apiGetArticle(this.$route.params.location_id, this.nan);
  },
};
</script>
