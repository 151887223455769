<template>
  <div class="orders">
    <v-card class="mx-auto elevation-6" v-if="initialized">
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiBeakerPlus }}</v-icon>
        <v-toolbar-title>Bestellungen</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <iir-search-input v-model="search"></iir-search-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="suppliers_table"
        :items="suppliers_filtered"
        class="elevation-1"
        :search="search"
        :sort-by="['name']"
        :sort-asc="['true']"
        item-key="public_id"
        :single-select="true"
        fixed-header
        :items-per-page="50"
        hide-default-footer
        mobile-breakpoint="0"
        dense
        group-by="next_order"
      >
        <template v-slot:group.header="{ items, isOpen, toggle }">
          <td
            :colspan="`${suppliers_table.length - 2}`"
            class="font-weight-bold"
            @click="toggle"
          >
            <v-icon>{{ $i.mdiCalendarToday }}</v-icon>
            {{
              items[0].next_order
                ? new Date(replaceDate(items[0].next_order)).toLocaleDateString(
                    "de-DE",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }
                  )
                : ""
            }}
          </td>
          <td class="text-right">
            <v-icon @click="toggle"
              >{{ isOpen ? $i.mdiMinus : $i.mdiPlus }}
            </v-icon>
          </td></template
        ><template v-slot:item.last_order="{ item }">
          {{
            item.last_order
              ? new Date(replaceDate(item.last_order)).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : ""
          }}
        </template>
        <template v-slot:item.next_order="{ item }">
          {{
            item.next_order
              ? new Date(replaceDate(item.next_order)).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : ""
          }}
        </template>
        <template v-slot:item.order_overdue="{ item }">
          <v-icon v-if="item.order_overdue" color="red darken-2">{{
            $i.mdiAlertCircle
          }}</v-icon>
          <v-icon v-if="!item.order_overdue" color="green darken-2">{{
            $i.mdiCheckCircle
          }}</v-icon>
        </template>
        <!-- <template v-slot:item.order_overdue="{ item }">
          <v-icon v-if="item.order_overdue" color="red darken-2">{{
            $i.mdiAlertCircle
          }}</v-icon>
          <v-icon v-if="!item.order_overdue" color="green darken-2">{{
            $i.mdiCheckCircle
          }}</v-icon>
        </template> -->
        <template v-slot:item.departments="{ item }">
          <v-chip
            small
            v-for="dep in item.departments"
            :key="dep"
            color="secondary"
          >
            {{ dep }}
          </v-chip>
        </template>
        <template v-slot:item.categories="{ item }">
          <v-chip
            small
            v-for="cat in item.categories"
            :key="cat"
            color="secondary"
          >
            {{ cat }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="initialized">
      <v-card-title>
        Bestellungen
        <v-spacer></v-spacer>
        <v-btn
          :to="`/${$route.params.location_id}/suppliers/${$route.params.supplier_id}/`"
          color="secondary"
        >
          zum Lieferanten <v-icon right>{{ $i.mdiTruckFast }}</v-icon>
        </v-btn>
        <v-btn
          :to="`/${$route.params.location_id}/articles/${$route.params.supplier_id}/`"
          color="secondary"
        >
          Artikel<v-icon right>{{ $i.mdiCubeOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="suppliers_filtered[0]">
        Mindestbestellwert:{{
          suppliers_filtered[0].minimum_order_value
            ? suppliers_filtered[0].minimum_order_value
            : "keine Angabe"
        }}
      </v-card-text>
      <show-orders :items="orders" dense></show-orders>
      <!-- <v-data-table
              :headers="orders_table"
              :items="orders"
              class="elevation-1"
              :sort-by="['order_date']"
              :sort-desc="['true']"
              item-key="public_id"
              fixed-header
              :items-per-page="-1"
              hide-default-footer
            >
              <template slot="no-data">
                Lieferanten auswählen um Bestellunge anzuzeigen.
              </template>
            </v-data-table> -->
    </v-card>
    <v-card v-if="false">
      <v-calendar
        ref="calendar"
        v-model="calendar.value"
        :events="calendar_events"
        :event-overlap-mode="calendar.mode"
        :event-overlap-threshold="5"
        :weekdays="calendar.weekdays"
        :type="calendar.type"
      ></v-calendar>
    </v-card>

    <v-btn
      class="mx-2"
      fab
      dark
      color="secondary"
      fixed
      right
      bottom
      append
      @click.stop="dialog = !dialog"
    >
      <v-icon dark> {{ $i.mdiPlus }} </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <add-order
        :selected-supplier="suppliers_filtered[0]"
        @closed="closeOverlay('closed')"
        @saved="closeOverlay('saved')"
      >
      </add-order>
    </v-dialog>
  </div>
</template>

<script>
import AddOrder from "@/components/AddOrder.vue";
import ShowOrders from "@/components/ShowOrders.vue";
import IirSearchInput from "@/components/iirSearchInput.vue";

export default {
  name: "OrdersView",
  components: {
    AddOrder,
    ShowOrders,
    IirSearchInput,
  },
  data: () => ({
    initialized: false,
    orders: [],
    search: "",
    filter_overdue: true,
    showAddSupplierDialogue: false,
    selected_supplier: [],
    overlay: false,
    dialog: false,
    supplier_rows: 12,
    calendar: {
      weekdays: [1, 2, 3, 4, 5, 6],
      value: "",
      type: "month",
      mode: "stack",
      events: [],
    },
  }),
  computed: {
    suppliers_table() {
      return [
        { text: "Lieferant", value: "name", align: "start", sortable: true },
        { text: "ID", value: "supplier_number", sortable: true },
        // {
        //   text: "Tage seit letzter Bestellung",
        //   value: "days_since_last_order",
        //   sortable: true,
        // },
        // { text: "Bestellrhythmus", value: "ordering_rhythm", sortable: true },
        { text: "Besteller", value: "ordering_person", sortable: true },
        { text: "Rhythmus", value: "ordering_rhythm", sortable: true },
        { text: "Letzte Bestellung", value: "last_order", sortable: true },
        {
          text: "Nächste Bestellung",
          value: "next_order",
          sortable: true,
          groupable: true,
        },
        {
          text: "Überfällig",
          value: "order_overdue",
          sortable: true,
        },
        { text: "Abteilung", value: "departments" },
        { text: "Sortimente", value: "categories" },
      ];
    },
    suppliers_filtered() {
      return this.suppliers.filter(
        (supplier) => supplier.public_id == this.$route.params.supplier_id
      );
    },
    orders_table() {
      return [
        {
          text: "Lieferant",
          value: "supplier.name",
          align: "start",
          sortable: true,
        },
        { text: "Bestelldatum", value: "order_date", sortable: true },
        // { text: "erw. Lieferdatum", value: "delivery_date", sortable: true },
      ];
    },
    suppliers() {
      return this.$store.state.orders_suppliers || [];
    },
    calendar_events() {
      if (this.$store.state.orders_suppliers) {
        return (
          this.$store.state.orders_suppliers.map((item) => ({
            name: item.name,
            start: item.next_order.substring(0, 10),
            timed: false,
          })) || []
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    refresh: function (done) {
      this.$store.dispatch(
        "apiOrdersLoadSuppliers",
        this.$route.params.location_id
      );
      done();
    },
    replaceDate(str) {
      return str.replace(" ", "T");
    },
    handleClick(row) {
      this.toggleSelection(row);
      this.loadOrders();
      //this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected"));
    },
    loadOrders() {
      this.orders = [];
      this.apiGetOrders(
        this.$route.params.location_id,
        this.$route.params.supplier_id
      );
    },
    toggleSelection(key) {
      if (this.selected_supplier.includes(key)) {
        this.selected_supplier = this.selected_supplier.filter(
          (selectedkey) => selectedkey !== key
        );
        this.supplier_rows = 12;
      } else {
        this.selected_supplier.pop();
        this.selected_supplier.push(key);
        this.supplier_rows = null;
      }
    },
    clearSelection() {
      this.selected_supplier.pop();
      this.supplier_rows = null;
    },
    apiGetOrders(location_id, supplier_id) {
      this.$api
        .get("/orders/" + location_id + "/" + supplier_id)
        .then((response) => (this.orders = response.data))
        .catch();
    },
    closeOverlay(arg) {
      this.overlay = false;
      this.dialog = false;
      if (arg == "saved") {
        this.clearSelection();
        this.$router.push({
          name: "Orders",
          params: { location_id: this.$route.params.location_id },
        });
      }
    },
  },
  mounted: function () {},
  created() {
    // if (this.suppliers && !this.suppliers.length) {
    this.$store.dispatch(
      "apiOrdersLoadSuppliers",
      this.$route.params.location_id
    );
    this.loadOrders();
    this.initialized = true;
    // }
  },
};
</script>
