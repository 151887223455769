<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="mx-auto elevation-6">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiTableLargePlus }}</v-icon>
            <v-toolbar-title>Rechnungsbewertung</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="numrows++">
              <v-icon dark> {{ $i.mdiPlus }} </v-icon>
            </v-btn>
            <v-btn icon @click="numrows > 0 ? numrows-- : null">
              <v-icon dark> {{ $i.mdiMinus }} </v-icon>
            </v-btn>
          </v-toolbar>
          <v-form @submit.prevent="clearForm" ref="marginFOrm">
            <v-card-actions> </v-card-actions>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="6">
                      <v-row class="text-center">
                        <v-col
                          ><p class="text-center">
                            {{ vat_rates.reduced }}%
                          </p></v-col
                        >
                      </v-row>
                      <v-row v-for="n in numrows" v-bind:key="n" class="mx-1">
                        <iir-currency-input
                          dense
                          :precision="2"
                          :value.sync="eingaben_reduced[n]"
                          reverse
                          :ref="'reduced-' + n"
                          re="ref"
                          :tabindex="n"
                        >
                        </iir-currency-input
                      ></v-row>
                      <v-row class="mx-1">
                        Summe netto:
                        <iir-currency-input
                          dense
                          disabled
                          :precision="2"
                          re="ref_reduced"
                          ref="ref_reduced"
                          reverse
                        >
                        </iir-currency-input
                      ></v-row>
                      <v-row class="mx-1">
                        <v-slider
                          v-model="margin_reduced"
                          step="1"
                          ticks
                          max="60"
                          :label="'Spanne: ' + margin_reduced + '%'"
                          tabindex="-1"
                        ></v-slider>
                      </v-row>
                      <v-row class="mx-1">
                        VK brutto ({{ vat_rates.reduced }}%):
                        <iir-currency-input
                          dense
                          disabled
                          :precision="2"
                          re="ref_vk_reduced"
                          ref="ref_vk_reduced"
                          reverse
                        >
                        </iir-currency-input
                      ></v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col>
                          <p class="text-center">{{ vat_rates.full }}%</p>
                        </v-col>
                      </v-row>
                      <v-row v-for="m in numrows" v-bind:key="m" class="mx-1">
                        <iir-currency-input
                          dense
                          :precision="2"
                          :value.sync="eingaben_full[m]"
                          reverse
                          :ref="`full-${m}`"
                          re="ref"
                          :tabindex="m + numrows"
                        >
                        </iir-currency-input
                      ></v-row>
                      <v-row class="mx-1">
                        Summe netto:
                        <iir-currency-input
                          dense
                          disabled
                          :precision="2"
                          re="ref_full"
                          ref="ref_full"
                          reverse
                        >
                        </iir-currency-input
                      ></v-row>
                      <v-row class="mx-1">
                        <v-slider
                          v-model="margin_full"
                          step="1"
                          ticks
                          max="60"
                          :label="'Spanne: ' + margin_full + '%'"
                          tabindex="-1"
                        ></v-slider>
                      </v-row>
                      <v-row class="mx-1">
                        VK brutto ({{ vat_rates.full }}%):
                        <iir-currency-input
                          dense
                          disabled
                          :precision="2"
                          re="ref_vk_full"
                          ref="ref_vk_full"
                          reverse
                        >
                        </iir-currency-input
                      ></v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mx-1" v-if="file_upload">
                    <v-chip-group
                      v-model="market_selected"
                      active-class="secondary--text"
                      column
                    >
                      <v-chip
                        v-for="market in markets"
                        :key="market.id"
                        :value="market.id"
                      >
                        <v-avatar color="primary" left>{{
                          market.id
                        }}</v-avatar>
                        {{ market.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                  <v-row class="mx-1" v-if="file_upload">
                    <v-chip-group
                      v-model="department_selected"
                      active-class="secondary--text"
                      column
                    >
                      <v-chip
                        v-for="department in departments"
                        :key="department.id"
                        :value="department.id"
                      >
                        <v-avatar color="primary" left>{{
                          department.id
                        }}</v-avatar>
                        {{ department.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                  <v-row class="mx-1">
                    <v-btn
                      color="primary"
                      v-on:click="addSticker()"
                      v-if="file_upload"
                      >hinzufügen<v-icon right>{{ $i.mdiPlus }}</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-on:click="clearForm()"
                      >Löschen<v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
                    >
                  </v-row>
                  <v-row
                    class="mx-1 d-flex"
                    v-if="file_upload && stickers.length"
                  >
                    <v-card class="elevation-0" width="100%">
                      <v-list flat>
                        <v-subheader>Bewertungen</v-subheader>
                        <v-list-item-group>
                          <v-list-item
                            v-for="(item, i) in stickers"
                            :key="i + item.market + item.department"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-chip>
                                  <v-avatar color="primary" left>
                                    {{ item.market }}
                                  </v-avatar>
                                  {{
                                    markets.find((a) => a.id == item.market)
                                      .name
                                  }}
                                </v-chip>
                                <v-chip>
                                  <v-avatar color="primary" left>
                                    {{ item.department }}
                                  </v-avatar>
                                  {{
                                    departments.find(
                                      (a) => a.id == item.department
                                    ).name
                                  }}
                                </v-chip>
                                <v-chip>
                                  <v-avatar color="primary" left>
                                    {{ vat_rates.reduced }}%
                                  </v-avatar>
                                  {{ item.vk_reduced }}
                                </v-chip>
                                <v-chip>
                                  <v-avatar color="primary" left>
                                    {{ vat_rates.full }}%
                                  </v-avatar>
                                  {{ item.vk_full }}
                                </v-chip>
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon>
                                <v-icon @click="remove_sticker(item)">{{
                                  $i.mdiClosecircle
                                }}</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          v-on:click="generatePdf()"
                          v-if="file_upload"
                          >Download<v-icon right>{{
                            $i.mdiContentSave
                          }}</v-icon></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="8">
                  <v-flex text-xs-center>
                    <v-card
                      @drop.prevent="addDropFile"
                      @dragover.prevent
                      @dragenter="dragenter"
                      @dragleave="dragleave"
                      color="#BBB"
                      :class="'align-center justify-center text-center d-flex flex-column'"
                      width="800"
                      height="400"
                      v-if="!upload_response"
                    >
                      <div class="dropzone d-flex" ref="dropzone">
                        <v-icon x-large class="mb2" center>{{
                          $i.mdiCloudUploadOutline
                        }}</v-icon>
                      </div>
                    </v-card>
                    <v-card width="800" v-if="upload_response">
                      <v-row>
                        <v-col>
                          <v-carousel
                            v-model="active_page"
                            hide-delimiter-background
                            hide-delimiters
                            show-arrows-on-hover
                            height="1131"
                          >
                            <v-carousel-item
                              v-for="page in upload_response.pages"
                              :key="page.file"
                            >
                              <div
                                v-draggable
                                style="
                                  z-index: 20001 !important;
                                  background: #bbb;
                                "
                                v-for="sticker in stickers_active"
                                :key="sticker.market + sticker.department"
                                :value="sticker.department"
                                :ref="sticker.market + sticker.department"
                                @click="sticker_move(sticker)"
                                :style="`left:${sticker.left}px;top:${sticker.top}px`"
                                transition="scale-transition"
                                origin="center center"
                              >
                                <svg-bewertung
                                  :config="sticker"
                                  :ref="`sticker-${sticker.market}-${sticker.department}`"
                                >
                                </svg-bewertung>
                              </div>
                              <vue-photo-zoom-pro
                                :high-url="`${$api_url}/files/invoices/${upload_response.id}/${page.file}?${nonce}`"
                                type="circle"
                              >
                                <v-img
                                  :src="`${$api_url}/files/invoices/${upload_response.id}/${page.file}?${nonce}`"
                                  style="width: 800px"
                                />
                              </vue-photo-zoom-pro>
                            </v-carousel-item>
                          </v-carousel>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-flex>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue"
import svgBewertung from "@/components/invoicevaluation/svgBewertung.vue"
import { parse, setValue } from "vue-currency-input"
import VuePhotoZoomPro from "vue-photo-zoom-pro"
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css"

export default {
  name: "InvoiceValuation",
  components: {
    iirCurrencyInput,
    VuePhotoZoomPro,
    svgBewertung,
  },
  props: {
    source: String,
  },
  data: () => ({
    vat_rates: { reduced: 7, full: 19 },
    eingaben_reduced: { 0: 0 },
    eingaben_full: { 0: 0 },
    margin_reduced: 25,
    margin_full: 25,
    numrows: 1,
    file_upload: null,
    upload_response: null,
    download_file: null,
    active_page: null,
    departments: [
      { id: 10, name: "Kolo" },
      { id: 11, name: "Obst & Gemüse" },
      { id: 30, name: "Service" },
    ],
    markets: [
      { id: 1, name: "Hauptmarkt" },
      { id: 2, name: "Getränkemarkt" },
    ],
    department_selected: 10,
    market_selected: 1,
    stickers: [],
    files: [],
    hoverCounter: 0,
    nonce: 0,
  }),
  computed: {
    full_sum_c: function () {
      return this.summarizeObject(this.eingaben_full)
    },
    reduced_sum_c: function () {
      return this.summarizeObject(this.eingaben_reduced)
    },
    stickers_active: function () {
      return this.stickers.filter((a) => a.page == this.active_page)
    },
  },
  created() {
    this.nonce = parseInt(new Date().getTime() / 10000)
  },
  methods: {
    dragenter() {
      this.$refs.dropzone.style.backgroundColor = "#DDD"
      this.hoverCounter++
    },
    dragleave() {
      this.hoverCounter--
    },
    sticker_move(event) {
      const targetIndex = this.stickers.findIndex(
        (a) => a.department == event.department && a.market == event.market
      )
      this.stickers[targetIndex].left =
        this.$refs[event.market + event.department][0].offsetLeft
      this.stickers[targetIndex].top =
        this.$refs[event.market + event.department][0].offsetTop
    },
    generatePdf() {
      let q = []
      q = this.stickers.map((x) => ({
        ...x,
        svg: this.$refs[`sticker-${x.market}-${x.department}`][0].$el.innerHTML,
      }))
      // /invoices/<uuid>/download/
      this.$api
        .post("files/invoices/" + this.upload_response.id + "/generate", q)
        .then((response) => (this.download_file = response.data))
        .catch()
        .then(() => this.downloadPdf())
    },
    downloadPdf() {
      this.$api
        .get(
          "files/invoices/" +
            this.download_file.id +
            "/" +
            this.download_file.filename,
          {
            responseType: "blob",
            params: {},
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", this.download_file.filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch()
        .then()
    },
    addSticker() {
      // console.log(this.$refs.ref_vk_reduced.$refs.ref_vk_reduced);
      if (this.full_sum_c == 0 && this.reduced_sum_c == 0) {
        return false
      }
      if (
        this.stickers.some(
          (sticker) =>
            sticker.department === this.department_selected &&
            sticker.market === this.market_selected
        )
      ) {
        alert("Abteilung bereits bewertet.")
      } else {
        this.stickers.push({
          department: this.department_selected,
          market: this.market_selected,
          vk_reduced: this.calculateGross(
            this.reduced_sum_c,
            this.vat_rates.reduced,
            this.margin_reduced
          ),
          vk_full: this.calculateGross(
            this.full_sum_c,
            this.vat_rates.full,
            this.margin_full
          ),
          page: this.active_page,
          left: 250,
          top: 400,
        })
        this.clearForm()
      }
    },
    remove_sticker(sticker) {
      this.stickers = this.stickers.filter(
        (a) => a.department == sticker.department && a.market == sticker.market
      )
    },
    clearForm() {
      this.eingaben_reduced = { 0: 0 }
      this.eingaben_full = { 0: 0 }
      //console.log(this.$refs);
      //setValue(this.$refs["reduced1"][0].$refs["ref"], "");
      for (let i = 1; i <= this.numrows; i++) {
        setValue(this.$refs["reduced-" + i][0].$refs["ref"], null)
        setValue(this.$refs["full-" + i][0].$refs["ref"], null)
        //console.log(i);
      }
      this.department_selected = 10
      this.market_selected = 1
    },
    summarizeObject(val) {
      return Object.values(val).reduce((a, b) => a + this.parseNumber(b))
    },
    parseNumber(val) {
      return parse(val, {
        suffix: "",
        precision: "",
        locale: "DE",
        currency: "EUR",
      })
    },
    calculateGross(net, vat, margin) {
      return ((net / (1 - margin / 100)) * (1 + vat / 100)).toFixed(2)
    },
    setSums() {
      setValue(this.$refs.ref_full.$refs.ref_full, this.full_sum_c)
      setValue(
        this.$refs.ref_vk_full.$refs.ref_vk_full,
        this.calculateGross(
          this.full_sum_c,
          this.vat_rates.full,
          this.margin_full
        )
      )
      setValue(this.$refs.ref_reduced.$refs.ref_reduced, this.reduced_sum_c)
      setValue(
        this.$refs.ref_vk_reduced.$refs.ref_vk_reduced,
        this.calculateGross(
          this.reduced_sum_c,
          this.vat_rates.reduced,
          this.margin_reduced
        )
      )
    },
    addDropFile(e) {
      this.file_upload = e.dataTransfer.files[0]
      //console.log(this.image_upload);
      this.apiAddFile()
    },
    apiAddFile() {
      //this.$store.commit('startLoading')
      //@articles.route("/<location_id>/supplier/<supplier_id>/", methods=["POST"])
      let fd = new FormData()
      fd.append("pdf", this.file_upload)

      this.$api
        .post("files/invoices/upload", fd)
        .then(
          (response) => (
            (this.upload_response = response.data),
            (this.active_page = response.data.page_count - 1)
          )
        )
        .catch()
    },
  },
  watch: {
    hoverCounter(val) {
      if (val === 0) {
        this.$refs.dropzone.style.backgroundColor = null
      }
    },
    full_sum_c: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
    reduced_sum_c: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
    margin_full: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
    margin_reduced: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
/*.v-carousel {
  cursor: url("~@/assets/bewertung.png") 0 0, crosshair;
}*/
.dropzone {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px dashed hsla(0, 0%, 100%, 0.7);
  border-radius: 20px;
  overflow: hidden;
  transition: background-color 0.2s;
}
</style>
