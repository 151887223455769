<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline">Benutzer einladen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout wrap>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="E-Mail"
                  name="email"
                  ref="emailTextField"
                  :prepend-icon="$i.mdiEmail"
                  v-model="formData.email"
                  :rules="[rules.email_validation]"
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-autocomplete
                  v-model="formData.company_id"
                  :items="companies"
                  :prepend-icon="$i.mdiTruckFast"
                  item-text="name"
                  item-value="public_id"
                  label="Gesellschaft"
                  ref="company"
                  dense
                >
                  <template v-slot:no-data>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item>
                      <v-list-item-avatar color="grey lighten-6">
                        <v-icon> {{ $i.mdiClose }} </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Keine Gesellschaft gefunden
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Es wurde keine passende Gesellschaft gefunden
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>{{ item.name }}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-autocomplete
                  v-model="formData.locations"
                  :items="locations"
                  dense
                  :prepend-icon="$i.mdiStoreMarker"
                  item-text="public_id"
                  item-value="public_id"
                  label="Marktnummer"
                  multiple
                  chips
                  v-show="locations"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.public_id"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.address"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="hide('close')">
          schließen
          <v-icon right>{{ $i.mdiCloseCircleOutline }}</v-icon>
        </v-btn>

        <v-btn color="success" @click="hide('save')">
          speichern
          <v-icon right>{{ $i.mdiContentSave }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
// import iirDateInput from "@/components/iirDateInput.vue";
export default {
  name: "addInvite",
  components: {
    // iirDateInput,
  },
  computed: {
    companies() {
      return this.$store.state.user_obj.companies || []
    },
    locations() {
      if (this.formData.company_id) {
        return this.companies.find(
          (x) => x.public_id == this.formData.company_id
        )["locations"]
      } else {
        return null
      }
    },
    sync_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
  },
  props: {},
  methods: {
    apiAddInvite() {
      //this.$store.commit('startLoading')
      this.$api
        .post("invite", this.formData)
        .then((response) => console.log(response.data))
        .catch()
        .then(() => (this.$emit("closed"), this.clear()))
    },
    hide: function (arg) {
      if (this.formData.email == "" && arg == "save") {
        alert("Keine Eingabe gemacht")
      } else if (arg == "save") {
        this.apiAddInvite()
        //this.$emit("saved");
        this.sync_value = false
      } else {
        this.sync_value = false
        this.clear()
        this.$emit("closed")
      }
    },
    clear() {
      this.formData = {
        email: null,
        company_id: null,
        locations: null,
      }
    },
  },
  data() {
    return {
      formData: {
        email: null,
        company_id: null,
        locations: null,
      },
      rules: {
        length: (len) => (v) =>
          (v || "").length <= len || `Zu lang, maximal ${len} Zeichen.`,
        required: (value) => !!value || "Pflichtfeld.",
        min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
        email_validation: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return value != ""
            ? pattern.test(value) || "Ungültige E-Mail-Adresse."
            : true
        },
      },
    }
  },
  created() {
    this.clear()
    // if (this.$store.state.suppliers == null) {
    //   this.$store.dispatch("apiGetSuppliers", {
    //     location_id: this.$route.params.location_id,
    //     active: true,
    //   });
    // }
  },
}
</script>

