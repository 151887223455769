var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.company_authorization)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.users_table,"items":_vm.company_authorization,"items-per-page":50,"footer-props":{
    'items-per-page-options': [20, 50, 100, -1],
    'items-per-page-text': 'Einträge pro Seite',
    'items-per-page-all-text': 'Alle',
    'page-text': '{0}-{1} von {2}',
    'show-current-page': true,
  },"sort-by":['last_name'],"sort-asc":['true'],"item-key":"user_id","single-expand":true,"expanded":_vm.itemExpanded,"dense":"","item-class":_vm.getRowStyle},on:{"update:expanded":function($event){_vm.itemExpanded=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.locations",fn:function({ item }){return _vm._l((item.locations),function(location){return _c('v-chip',{key:location.location_id,class:_vm.getRowStyle(item),attrs:{"value":location.location_id,"small":""}},[_vm._v(" "+_vm._s(location.location_id)+" ")])})}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.locations)+" ")])]}}],null,false,851215579)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }