<template>
  <div>
    <v-layout>
      <v-simple-checkbox
        v-model="item.recognized"
        :ripple="false"
        disabled
        dense
      ></v-simple-checkbox>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-show="!item.recognized"
            color="primary"
            v-bind="attrs"
            v-on="on"
            rounded
            @click.stop="apiEnqueueFile(item)"
            ><v-icon> {{ $i.mdiTextRecognition }} </v-icon></v-btn
          >
        </template>
        <span>Erneut verarbeiten</span>
      </v-tooltip></v-layout
    >
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTableRecognized",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {},
  methods: {
    apiEnqueueFile(item) {
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            item.public_id +
            "/enqueue"
        )
        .then(() => {
          //this.$store.dispatch("apiGetReceipts", this.$store.state.location_id)
          this.$store.dispatch(
            "apiGetReceiptsFQ",
            this.$store.state.location_id
          )
        })
        .catch()
    },
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>