 <template>
  <v-text-field
    v-model="content"
    :prepend-inner-icon="$i.mdiMagnify"
    label="Suchen"
    single-line
    hide-details
    clearable
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
  },
  watch: {
    content(currentValue) {
      this.$emit("input", currentValue);
    },
  },
};
</script>
