<template>
  <v-card>
    <v-form @submit.prevent="save">
      <v-card-text>
        <v-text-field
          label="E-Mail"
          name="email"
          ref="emailTextField"
          :prepend-icon="$i.mdiEmail"
          v-model="formData.email"
          :disabled="true"
        ></v-text-field>
        <v-text-field
          label="Vorname"
          name="first_name"
          ref="firstNameTextField"
          :prepend-icon="$i.mdiAccount"
          v-model="formData.first_name"
          :rules="[required]"
        ></v-text-field>
        <v-text-field
          label="Nachname"
          name="last_name"
          ref="lasttNameTextField"
          :prepend-icon="$i.mdiAccountOutline"
          v-model="formData.last_name"
          :rules="[required]"
        ></v-text-field>
      </v-card-text>
      <v-card-title>Passwort ändern</v-card-title>
      <v-card-text>
        <v-row
          ><v-col cols="4">
            <v-text-field
              label="altes Passwort"
              name="password"
              :prepend-icon="$i.mdiShieldKey"
              :type="show_password ? 'text' : 'password'"
              :append-icon="show_password ? $i.mdiEye : $i.mdiEyeOff"
              @click:append="show_password = !show_password"
              v-model="formData.old_password"
              hint="Mindestens 8 Zeichen"
              :rules="[min]"
              counter
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-show="formData.old_password"
              label="neues Passwort"
              name="password"
              :prepend-icon="$i.mdiShieldKey"
              :type="show_password_new ? 'text' : 'password'"
              :append-icon="show_password_new ? $i.mdiEye : $i.mdiEyeOff"
              @click:append="show_password_new = !show_password_new"
              v-model="formData.new_password"
              hint="Mindestens 8 Zeichen"
              :rules="[min]"
              counter
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-if="formData.new_password"
              label="neues Passwort bestätigen"
              name="password"
              :prepend-icon="$i.mdiShieldKey"
              :type="show_password_new_match ? 'text' : 'password'"
              :append-icon="show_password_new_match ? $i.mdiEye : $i.mdiEyeOff"
              @click:append="show_password_new_match = !show_password_new_match"
              v-model="new_password_match"
              hint="Mindestens 8 Zeichen"
              :rules="[min, password_match]"
              counter
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" :disabled="!passwords_matching()"
          >Speichern</v-btn
        >
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-title>Aktive Sitzungen</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(item, i) in this.active_sessions" :key="i">
              <v-list-item-icon>
                <!-- <v-icon>{{ $i["mdiNumeric" + (i + 1) + "Box"] }}</v-icon> -->
                <v-icon
                  :color="item.is_active_session ? `success` : ``"
                  v-show="item.is_mobile"
                  >{{ $i.mdiCellphone }}</v-icon
                >
                <v-icon
                  :color="item.is_active_session ? `success` : ``"
                  v-show="item.is_tablet"
                  >{{ $i.mdiTablet }}</v-icon
                >
                <v-icon
                  :color="item.is_active_session ? `success` : ``"
                  v-show="item.is_pc"
                  >{{ $i.mdiLaptop }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.user_agent }}<v-spacer></v-spacer>Login:
                  {{ $gf.utcToLocal(item.created) }}
                  {{
                    item.valid_until
                      ? "Gültig bis " + $gf.utcToLocal(item.valid_until)
                      : ""
                  }}
                  {{
                    item.last_access
                      ? "Letzter Zugriff: " + $gf.utcToLocal(item.last_access)
                      : ""
                  }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="apiDisableSession(item.session_id)">
                  <v-icon color="grey lighten-1">{{ $i.mdiDelete }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
export default {
  computed: {
    sync_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
  },
  data: () => ({
    formData: {
      email: "",
      first_name: "",
      last_name: "",
      old_password: "",
      new_password: "",
    },
    new_password_match: "",
    show_password: false,
    show_password_new: false,
    show_password_new_match: false,
    active_sessions: [],
  }),
  props: {
    value: {},
  },
  mounted() {
    if (this.$store.state.user_obj) {
      this.formData.email = this.$store.state.user_obj.email
      this.formData.first_name = this.$store.state.user_obj.first_name
      this.formData.last_name = this.$store.state.user_obj.last_name
    }
    this.apiGetSessions()
  },
  methods: {
    required: (value) => !!value || "Pflichtfeld.",
    min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    password_match: function () {
      if (this.passwords_matching()) {
        return true
      } else {
        return "Passwörter stimmen nicht überein"
      }
    },
    passwords_matching: function () {
      return this.new_password_match === this.formData.new_password || false
    },
    apiSaveUser() {
      this.loading = true
      this.$api
        .put("admin/user", this.formData, { withCredentials: true })
        .then()
        .catch((error) => console.log(error))
        .finally(
          () => ((this.loading = false), this.$store.dispatch("apiGetUser"))
        )
    },
    apiDisableSession(session_id) {
      this.$api
        .put(
          "admin/sessions/" + session_id + "/disable",
          {},
          {
            withCredentials: true,
          }
        )
        .then(this.apiGetSessions())
        .catch((error) => console.log(error))
    },
    save: function () {
      this.apiSaveUser()
    },
    apiGetSessions() {
      this.$api
        .get("admin/sessions/" + this.$store.state.user_obj.user_id)
        .then((response) => (this.active_sessions = response.data))
        .catch((error) => console.log(error))
    },
  },
}
</script>

