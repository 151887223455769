<template>
  <div>
    <v-layout>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-edit-dialog
            :return-value.sync="item.receipt_type"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
            ref="editDialog"
          >
            <v-chip small v-bind="attrs" round v-on="on" :color="color">
              {{ receipt_type || "X" }}
            </v-chip>
            <template v-slot:input>
              <v-select
                v-model="item.receipt_type"
                :prepend-icon="$i.mdiBullseyeArrow"
                :clearable="true"
                label="Belegart"
                :items="receipt_types_lib"
                @change="save"
                ref="s"
              >
              </v-select>
            </template>
          </v-edit-dialog>
        </template>
        <span>{{ item.receipt_type || "unbekannt" }}</span>
      </v-tooltip>
    </v-layout>
  </div>
</template>

<script>
import receipt_types_lib from "@/assets/receipt_types.json"

export default {
  name: "ReceiptDataTableReceiptType",
  components: {},
  props: {
    item: {},
  },
  data: () => ({
    receipt_types_lib: receipt_types_lib,
  }),
  computed: {
    receipt_type() {
      let str = this.item.receipt_type
      if (typeof str !== "string" || str.length === 0) {
        return str
      } else return str.charAt(0).toUpperCase()
    },
    color() {
      return (
        this.getRandomColorFromPalette(
          receipt_types_lib.indexOf(this.item.receipt_type)
        ) + " darken-2"
      )
    },
  },
  methods: {
    set_receipt_type(item, box) {
      this.apiPutReceipt(item["public_id"], { [box]: item[box] })
      this.$store.dispatch("apiGetReceiptsFQ", this.$store.state.location_id)
    },
    apiPutReceipt(receipt_id, obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then()
        .catch()
    },
    getRandomColorFromPalette(number) {
      const colors = [
        "blue",
        "green",
        "red",
        "yellow",
        "purple",
        "pink",
        "orange",
        "indigo",
        "teal",
        "cyan",
        "deep-purple",
        "deep-orange",
        "lime",
        "light-blue",
        "amber",
        "deep-orange",
      ]

      const randomIndex = number % colors.length
      return colors[randomIndex]
    },
    save() {
      this.set_receipt_type(this.item, "receipt_type")
      this.$refs.editDialog.isActive = false
    },
    cancel() {},
    open() {},
    close() {},
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>