<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline" v-if="promotion.public_id">Aktion</span>
        <span class="headline" v-else>Aktion hinzufügen</span>
        <v-spacer></v-spacer>
        <profile-avatar
          :user="promotion.user_obj"
          v-if="'user_obj' in promotion"
        ></profile-avatar>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              color="secondary"
              size="32"
              v-show="'created' in promotion"
            >
              <v-icon color="white" dark small v-bind="attrs" v-on="on">
                {{ $i.mdiCalendar }}
              </v-icon>
            </v-avatar>
          </template>
          <span>{{ promotion.created }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :label="inputModel.year.label"
              :hint="inputModel.year.hint"
              :value.sync="promotion.year"
              :prepend-icon="$i.mdiCounter"
              :disabled="disabled"
              clearable
            >
              <template v-slot:append-outer>
                <v-chip-group
                  active-class="primary--text"
                  v-model="promotion.year"
                  :disabled="disabled"
                >
                  <v-chip
                    v-for="year in years_selection"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </v-chip>
                </v-chip-group>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="6" sm="6" v-if="false">
            <iir-input
              :label="inputModel.year.label"
              :hint="inputModel.year.hint"
              :value.sync="promotion.year"
              :prepend-icon="$i.mdiCounter"
              :disabled="disabled"
            >
            </iir-input>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <iir-input
              :label="inputModel.week.label"
              :hint="week_range_hint"
              :value.sync="promotion.week"
              :prepend-icon="$i.mdiCalendar"
              :disabled="disabled"
              :rules="[rules.isoWeekRule]"
              clearable
              ref="week"
            >
            </iir-input>
          </v-col>
        </v-row>
        <v-row v-if="false" no-gutters>
          <v-col cols="12" md="12">
            <iir-input
              :label="inputModel.name.label"
              :hint="inputModel.name.hint"
              :value.sync="promotion.name"
              :prepend-icon="$i.mdiTagText"
              :disabled="disabled"
              :clearable="true"
            >
            </iir-input>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" sm="6">
            <iir-chip-select
              :value.sync="promotion.category"
              :prepend-icon="$i.mdiBullseyeArrow"
              :clearable="true"
              :label="inputModel.category.label"
              :items="inputModel.departments_combo"
              :disabled="disabled"
              dense
            >
            </iir-chip-select>
          </v-col>
          <!-- <v-col cols="12" md="6" sm="6">
            <iir-input
              :label="inputModel.product.label"
              :hint="inputModel.product.hint"
              :value.sync="promotion.product"
              :prepend-icon="$i.mdiPackageVariant"
              :disabled="disabled"
              :clearable="true"
            >
            </iir-input>
          </v-col> -->
          <v-col cols="12" md="6" sm="6">
            <v-combobox
              :items="all_promotions"
              :label="inputModel.product.label"
              item-text="product"
              item-value="product"
              :prepend-icon="$i.mdiPackageVariant"
              :disabled="disabled"
              :clearable="true"
              :hint="inputModel.product.hint"
              @change="changeProduct"
              :menu-props="{ closeOnClick: false }"
              :value="promotion.product"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.product"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.category"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
            <!-- <iir-input
              :label="inputModel.name.label"
              :hint="inputModel.name.hint"
              :value.sync="promotion.name"
              :prepend-icon="$i.mdiTagText"
              :disabled="disabled"
              :clearable="true"
            >
            </iir-input> -->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="3" sm="6">
            <v-text-field
              :label="inputModel.size_in_quarter.label"
              :hint="inputModel.size_in_quarter.hint"
              v-model="promotion.size_in_quarter"
              :prepend-icon="$i.mdiResize"
              :disabled="disabled"
              clearable
            >
              <template v-slot:append-outer>
                <v-chip-group
                  active-class="primary--text"
                  v-model="promotion.size_in_quarter"
                  :disabled="disabled"
                >
                  <v-chip
                    v-for="size in sizes_selection"
                    :key="size"
                    :value="size"
                  >
                    {{ size }}
                  </v-chip>
                </v-chip-group>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-combobox
              v-model="promotion.source"
              :prepend-icon="$i.mdiNote"
              :clearable="true"
              :label="inputModel.source.label"
              :items="inputModel.source_combo"
              :disabled="disabled"
            >
              <template v-slot:append-outer>
                <v-chip-group
                  active-class="primary--text"
                  v-model="promotion.source"
                  :disabled="disabled"
                >
                  <v-chip
                    v-for="size in inputModel.source_combo.slice(0, -2)"
                    :key="size"
                    :value="size"
                  >
                    {{ size }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:selection="{ attrs, item, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  small
                  color="secondary"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" md="4" sm="8">
            <iir-input
              :label="inputModel.bonus.label"
              :hint="inputModel.bonus.hint"
              :value.sync="promotion.bonus"
              :prepend-icon="$i.mdiCashPlus"
              :disabled="disabled"
              :clearable="true"
            >
            </iir-input>
          </v-col>
          <v-col cols="12" md="2" sm="4">
            <v-checkbox v-model="promotion.bonus_paid" :disabled="disabled">
              <template v-slot:label>
                <div>
                  {{ inputModel.bonus_paid.label }}
                </div></template
              >
              <template v-slot:hint>
                <div>
                  {{ inputModel.bonus_paid.hint }}
                </div></template
              >
            </v-checkbox>
          </v-col>

          <!--<v-col cols="12" md="3" sm="6" v-if="false">
            <iir-input
              :label="inputModel.duration_weeks.label"
              :hint="inputModel.duration_weeks.hint"
              :value.sync="promotion.duration_weeks"
              :prepend-icon="$i.mdiCalendarClock"
              :disabled="disabled"
            >
            </iir-input>
          </v-col>-->
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="promotion.supplier_id"
              :items="suppliers"
              :prepend-icon="$i.mdiTruckFast"
              item-text="name"
              item-value="public_id"
              label="Lieferant"
              ref="suppliers"
              :disabled="disabled"
            >
              <template v-slot:no-data>
                <v-divider class="mb-2"></v-divider>
                <v-list-item>
                  <v-list-item-avatar color="grey lighten-6">
                    <v-icon> {{ $i.mdiClose }} </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Kein Lieferant gefunden
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Es wurde kein passender Lieferant gefunden
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.name }} ({{ item.supplier_number }})
              </template>
              <template v-slot:item="{ item }">
                <template>
                  <v-list-item-content
                    >{{ item.name }} ({{
                      item.supplier_number
                    }})</v-list-item-content
                  >
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              :label="inputModel.note.label"
              :hint="inputModel.note.hint"
              v-model="promotion.note"
              :prepend-icon="$i.mdiNote"
              :disabled="disabled"
              rows="3"
              :rules="[rules.length(255)]"
              counter="255"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="mx-2"
          @click="editPromotion"
          v-if="disabled"
          fab
          bottom
          right
          fixed
        >
          <v-icon dark> {{ $i.mdiPencil }} </v-icon>
        </v-btn>
        <v-btn
          color="success"
          class="mx-2"
          @click="hide('save')"
          v-if="!disabled"
          fab
          bottom
          right
          fixed
        >
          <v-icon dark> {{ $i.mdiContentSave }} </v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import iirInput from "@/components/iirInput.vue"
import departmentData from "@/assets/departments.json"
import iirChipSelect from "@/components/iirChipSelect.vue"
import ProfileAvatar from "@/components/ProfileAvatar.vue"

export default {
  name: "AddPromotion",
  components: {
    iirInput,
    iirChipSelect,
    ProfileAvatar,
  },
  data: () => ({
    initialized: false,
    supplier_activated: false,
    disabled: true,
    //supplier: supplierData,
    promotion: {
      public_id: "",
      year: "",
      week: "",
      name: "",
      category: "",
      product: "",
      note: "",
      supplier_id: "",
      supplier: "",
      size_in_quarter: "",
      bonus: "",
      bonus_paid: false,
      source: "",
      user: "",
    },
    filter_order_active: true,
    inputModel: {
      year: {
        label: "Jahr",
        hint: "",
        tooltip: "Tooltip Belegdatum",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      week: {
        tooltip: "Tooltip Belegdatum",
        label: "Kalenderwoche",
        hint: "",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      name: {
        tooltip: "Tooltip Supplier",
        label: "Bezeichnung",
        hint: "",
      },
      departments_combo: departmentData,
      source_combo: ["HZ", "DG", "JG", "ZOW", "Sonstige"],
      category: {
        tooltip: "Tooltip Supplier",
        label: "Sortiment",
        hint: "",
      },
      product: {
        tooltip: "Tooltip Supplier",
        label: "Bezeichnung",
        hint: "Milka Kleintafeln 21-fach 85-100g",
      },
      note: {
        tooltip: "Tooltip Supplier",
        label: "Notiz",
        hint: "",
      },
      duration_weeks: {
        tooltip: "Tooltip Belegdatum",
        label: "Standdauer (Wochen)",
        hint: "",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      size_in_quarter: {
        tooltip: "Tooltip Belegdatum",
        label: "Größe in ¼",
        hint: "Größe in ¼ Chep, 1 EUR = 4x",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      bonus: {
        tooltip: "Tooltip Supplier",
        label: "Vereinbarung / NR / WKZ",
        hint: "10%, 1VE, ...",
      },
      bonus_paid: {
        tooltip: "Tooltip Supplier",
        label: "WKZ erhalten",
        hint: "Vereinbarung erfüllt/ NR erhalten/ WKZ abgerechnet",
      },
      source: {
        tooltip: "Tooltip Supplier",
        label: "Quelle",
        hint: "HZ, DG, ZOW, ...",
      },
    },
    rules: {
      length: (len) => (v) =>
        (v || "").length <= len || `Zu lang, maximal ${len} Zeichen.`,
      isoWeekRule: (value) => {
        // Convert input value to number
        const number = parseInt(value, 10)
        // Check if it's an integer and within the range 1 to 53
        if (Number.isInteger(number) && number >= 0 && number <= 53) {
          return true
        }
        return "Bitte Kalenderwoche angeben (1-53)."
      },
    },
  }),
  computed: {
    week_range_hint() {
      const getISOWeekDates = (year, week) => {
        // Convert to integers
        let parsedYear = parseInt(year, 10)
        let parsedWeek = parseInt(week, 10)
        // Check if year and week are valid
        if (
          isNaN(parsedYear) ||
          parsedYear < 1 ||
          isNaN(parsedWeek) ||
          parsedWeek < 1 ||
          parsedWeek > 53
        ) {
          return null
        }

        let start = new Date(Date.UTC(parsedYear, 0, 4))
        start.setDate(
          start.getDate() + (parsedWeek - 1) * 7 - (start.getDay() || 7)
        )
        let end = new Date(start)
        end.setDate(end.getDate() + 6)
        let promotions_in_week =
          this.$store.state.promotions.filter(
            (p) =>
              p.year == this.promotion.year && p.week == this.promotion.week
          ).length + "x¼: " || null

        return (
          promotions_in_week +
          this.$gf.germanDate(start.toISOString().substring(0, 10), "2-digit") +
          "-" +
          this.$gf.germanDate(end.toISOString().substring(0, 10), "2-digit")
        )
      }
      return getISOWeekDates(this.promotion.year, this.promotion.week)
    },
    years_selection() {
      return [2024, 2025, 2026]
    },
    sizes_selection() {
      return [1, 2, 4]
    },
    suppliers() {
      return this.$store.state.suppliers || []
    },
    all_promotions() {
      const nameCounter = new Map()
      const newArray = this.$store.state.promotions.map(
        ({ category, product }) => {
          let counter = 1
          if (nameCounter.has(product)) {
            counter = nameCounter.get(product) + 1
          }
          nameCounter.set(product, counter)
          return { category, product, counter }
        }
      )

      // Sort the newArray by counter in descending order and then alphabetically by product
      newArray.sort((a, b) => {
        if (b.counter !== a.counter) {
          return b.counter - a.counter // Sort by counter in descending order
        } else {
          return a.product.localeCompare(b.product) // If counters are equal, sort alphabetically by product in ascending order
        }
      })

      return newArray
    },
  },
  props: {},
  methods: {
    changeProduct(e) {
      if (e === null) {
        this.promotion.product = null
      } else {
        if (typeof e !== "object") {
          this.promotion.product = e
        } else {
          this.promotion.product = e.product
          this.promotion.category = e.category
        }
      }
    },
    set_year(year) {
      this.promotion.year = year
    },
    set_size(size) {
      this.promotion.size_in_quarter = size
    },
    editPromotion() {
      this.disabled = !this.disabled
    },
    apiAddPromotion() {
      this.loading = true
      this.promotion.user = this.$store.state.user
      this.$api
        .post("promotions/" + this.$route.params.location_id, this.promotion, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.$router.back()
          } else {
            this.$root.toast.error("Nicht gespeichert!")
          }
        })
        .catch((error) => console.log(error))
        .finally(
          (this.loading = false),
          this.$store.dispatch(
            "apiGetPromotions",
            this.$route.params.location_id
          )
        )
    },
    apiGetPromotion(public_id, duplicate = false) {
      this.loading = true
      //this.$store.commit('startLoading')
      this.$api
        .get("promotions/" + this.$route.params.location_id + "/" + public_id, {
          withCredentials: true,
        })
        .then((response) => {
          this.promotion = response.data[0]
          this.promotion.product = response.data[0].product
          this.changeProduct(this.promotion)
          // console.log(response.data[0])
          if (duplicate) {
            this.promotion.week = null
            this.promotion.bonus_paid = null
            delete this.promotion.created
            this.promotion.public_id = null
            this.promotion.user_id = null
            delete this.promotion.user_obj
          }
        })
        .catch()
        .finally(() => (this.loading = false))
    },
    hide: function (arg) {
      if (this.promotion.product == "" && arg == "save") {
        this.$root.toast.error("Bitte mindestens eine Bezeichnung eingeben")
      } else if (arg == "save") {
        this.apiAddPromotion()
        this.$emit("closed", this.promotion.product, this.promotion.product)
        this.sync_value = false
        this.disabled = true
      } else {
        this.sync_value = false
      }
    },
  },
  mounted: function () {
    if (this.$route.params.id == "add") {
      this.disabled = false
      this.promotion.year = new Date().getFullYear()
    } else if (this.$route.params.id == "duplicate") {
      this.apiGetPromotion(this.$route.params.duplication_id, true)
      this.disabled = false
    } else if (!this.promotion.length) {
      this.apiGetPromotion(this.$route.params.id)
    }
    //alert(this.$store.state.user);
  },
}
</script>

