<template>
  <div class="orders">
    <v-card class="mx-auto elevation-6" v-if="initialized">
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiBeakerPlus }}</v-icon>
        <v-toolbar-title>Bestellungen</v-toolbar-title>
        <title-toolbar-print />
      </v-toolbar>
      <v-card-text>
        <v-row class="d-print-none">
          <v-col cols="12" md="6">
            <iir-search-input v-model="search_filter"></iir-search-input>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox
              v-model="filter_overdue"
              label="Nur überfällige Lieferanten"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- <v-chip-group
              v-model="departments_filter"
              active-class="primary--text"
              column
              multiple
            > -->
            <h4>Offene Bestellungen:</h4>
            <v-chip
              v-for="department in departments_list"
              :key="department"
              :value="department"
              class="mx-1"
            >
              {{ department }}
              <v-avatar
                color="error"
                v-if="departments_count[department]"
                right
              >
                {{ departments_count[department] }}</v-avatar
              >
            </v-chip>
            <!-- </v-chip-group> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title> Zeitkritische Bestellungen </v-card-title>
      <v-data-table
        :headers="suppliers_table_criticle"
        :items="suppliers_criticle_filtered"
        class="elevation-1"
        :search="search_filter"
        :sort-by="['name']"
        :sort-asc="['true']"
        item-key="id"
        @click:row="handleClick"
        @item-selected="handleClick"
        :single-select="true"
        v-model="selected_supplier"
        fixed-header
        :items-per-page="-1"
        hide-default-footer
        mobile-breakpoint="0"
        dense
        group-by="next_order"
        :height="
          supplier_rows &&
          suppliers.length > supplier_rows &&
          selected_supplier[0]
            ? 48 * supplier_rows - 1
            : undefined
        "
        :item-class="data_table_row_class"
      >
        <template v-slot:group.header="{ items, isOpen, toggle }">
          <td
            :colspan="`${suppliers_table_criticle.length - 1}`"
            class="font-weight-bold"
            @click="toggle"
          >
            <v-icon>{{ $i.mdiCalendarToday }}</v-icon>
            {{
              items[0].next_order
                ? new Date(replaceDate(items[0].next_order)).toLocaleDateString(
                    "de-DE",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }
                  )
                : ""
            }}
          </td>
          <td class="text-right">
            <v-icon @click="toggle"
              >{{ isOpen ? $i.mdiMinus : $i.mdiPlus }}
            </v-icon>
          </td></template
        ><template v-slot:item.last_order="{ item }">
          {{
            item.last_order
              ? new Date(replaceDate(item.last_order)).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : ""
          }}
        </template>
        <template v-slot:item.next_order="{ item }">
          {{
            item.next_order
              ? new Date(replaceDate(item.next_order)).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : ""
          }}
        </template>
        <template v-slot:item.order_overdue="{ item }">
          <v-icon v-if="item.order_overdue" color="red darken-2">{{
            $i.mdiAlertCircle
          }}</v-icon>
          <v-icon v-if="!item.order_overdue" color="green darken-2">{{
            $i.mdiCheckCircle
          }}</v-icon>
        </template>
        <template v-slot:item.max_hour="{ item }">
          bis {{ item.max_hour }} Uhr
          {{
            remaining_minutes(item.max_hour) < 120
              ? `(${remaining_minutes(item.max_hour)} Minuten)`
              : ""
          }}
        </template>
        <!-- <template v-slot:item.order_overdue="{ item }">
          <v-icon v-if="item.order_overdue" color="red darken-2">{{
            $i.mdiAlertCircle
          }}</v-icon>
          <v-icon v-if="!item.order_overdue" color="green darken-2">{{
            $i.mdiCheckCircle
          }}</v-icon>
        </template> -->
        <template v-slot:item.departments="{ item }">
          <v-chip
            small
            v-for="dep in item.departments"
            :key="dep"
            color="secondary"
          >
            {{ dep }}
          </v-chip>
        </template>
        <template v-slot:item.categories="{ item }">
          <v-chip
            small
            v-for="cat in item.categories"
            :key="cat"
            color="secondary"
          >
            {{ cat }}
          </v-chip>
        </template>
      </v-data-table>

      <v-card-title> Alle Bestellungen </v-card-title>
      <v-data-table
        :headers="suppliers_table"
        :items="suppliers_filtered"
        class="elevation-1"
        :search="search_filter"
        :sort-by="['name']"
        :sort-asc="['true']"
        item-key="id"
        @click:row="handleClick"
        @item-selected="handleClick"
        :single-select="true"
        v-model="selected_supplier"
        fixed-header
        :items-per-page="50"
        hide-default-footer
        mobile-breakpoint="0"
        dense
        group-by="next_order"
        :height="
          supplier_rows &&
          suppliers.length > supplier_rows &&
          selected_supplier[0]
            ? 48 * supplier_rows - 1
            : undefined
        "
      >
        <template v-slot:group.header="{ items, isOpen, toggle }">
          <td
            :colspan="`${suppliers_table.length - 2}`"
            class="font-weight-bold"
            @click="toggle"
          >
            <v-icon>{{ $i.mdiCalendarToday }}</v-icon>
            {{
              items[0].next_order
                ? new Date(replaceDate(items[0].next_order)).toLocaleDateString(
                    "de-DE",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }
                  )
                : ""
            }}
          </td>
          <td class="text-right">
            <v-icon @click="toggle"
              >{{ isOpen ? $i.mdiMinus : $i.mdiPlus }}
            </v-icon>
          </td></template
        ><template v-slot:item.last_order="{ item }">
          {{
            item.last_order
              ? new Date(replaceDate(item.last_order)).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : ""
          }}
        </template>
        <template v-slot:item.next_order="{ item }">
          {{
            item.next_order
              ? new Date(replaceDate(item.next_order)).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : ""
          }}
        </template>
        <template v-slot:item.order_overdue="{ item }">
          <v-icon v-if="item.order_overdue" color="red darken-2">{{
            $i.mdiAlertCircle
          }}</v-icon>
          <v-icon v-if="!item.order_overdue" color="green darken-2">{{
            $i.mdiCheckCircle
          }}</v-icon>
        </template>
        <!-- <template v-slot:item.order_overdue="{ item }">
          <v-icon v-if="item.order_overdue" color="red darken-2">{{
            $i.mdiAlertCircle
          }}</v-icon>
          <v-icon v-if="!item.order_overdue" color="green darken-2">{{
            $i.mdiCheckCircle
          }}</v-icon>
        </template> -->
        <template v-slot:item.departments="{ item }">
          <v-chip
            small
            v-for="dep in item.departments"
            :key="dep"
            color="secondary"
          >
            {{ dep }}
          </v-chip>
        </template>
        <template v-slot:item.categories="{ item }">
          <v-chip
            small
            v-for="cat in item.categories"
            :key="cat"
            color="secondary"
          >
            {{ cat }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="initialized && selected_supplier[0]">
      <v-card-title>
        Bestellungen
        <v-spacer></v-spacer>
        <v-btn
          v-if="selected_supplier[0]"
          :to="`/${$route.params.location_id}/suppliers/${selected_supplier[0].public_id}/`"
          color="secondary"
        >
          zum Lieferanten <v-icon right>{{ $i.mdiTruckFast }}</v-icon>
        </v-btn>
        <v-btn
          v-if="selected_supplier[0]"
          :to="`/${$route.params.location_id}/articles/${selected_supplier[0].public_id}/`"
          color="secondary"
        >
          Artikel<v-icon right>{{ $i.mdiCubeOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        Mindestbestellwert:
        {{
          selected_supplier[0].minimum_order_value
            ? selected_supplier[0].minimum_order_value
            : "keine Angabe"
        }}
      </v-card-text>
      <show-orders :items="orders" dense></show-orders>
    </v-card>
    <v-card v-if="false">
      <v-calendar
        ref="calendar"
        v-model="calendar.value"
        :events="calendar_events"
        :event-overlap-mode="calendar.mode"
        :event-overlap-threshold="5"
        :weekdays="calendar.weekdays"
        :type="calendar.type"
      ></v-calendar>
    </v-card>

    <v-btn
      class="mx-2 d-print-none"
      fab
      dark
      color="secondary"
      fixed
      right
      bottom
      append
      @click.stop="dialog = !dialog"
    >
      <v-icon dark> {{ $i.mdiPlus }} </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <add-order
        :selected-supplier="selected_supplier[0]"
        @closed="closeOverlay('closed')"
        @saved="closeOverlay('saved')"
      >
      </add-order>
    </v-dialog>
  </div>
</template>

<script>
import AddOrder from "@/components/AddOrder.vue"
import ShowOrders from "@/components/ShowOrders.vue"
import iirSearchInput from "@/components/iirSearchInput.vue"
import TitleToolbarPrint from "@/components/TitleToolbarPrint.vue"

export default {
  name: "OrdersView",
  components: {
    AddOrder,
    ShowOrders,
    iirSearchInput,
    TitleToolbarPrint,
  },
  data: () => ({
    initialized: false,
    orders: [],
    filter_overdue: true,
    showAddSupplierDialogue: false,
    selected_supplier: [],
    overlay: false,
    dialog: false,
    supplier_rows: 12,
    calendar: {
      weekdays: [1, 2, 3, 4, 5, 6],
      value: "",
      type: "month",
      mode: "stack",
      events: [],
    },
    currentTime: new Date(),
  }),
  computed: {
    departments_filter: {
      get() {
        return this.$store.state.user_departments_filter
      },
      set(value) {
        // this.$store.dispatch("setOrdersDepartmentsFilter", value);
        this.$store.commit("ORDERS_FILTER_DEPARTMENTS", value)
      },
    },
    search_filter: {
      get() {
        return this.$store.state.orders_search_filter
      },
      set(value) {
        // this.$store.dispatch("setOrdersDepartmentsFilter", value);
        this.$store.commit("ORDERS_FILTER_SEARCH", value)
      },
    },
    suppliers_table() {
      return [
        { text: "Lieferant", value: "name", align: "start", sortable: true },
        { text: "ID", value: "supplier_number", sortable: true },
        { text: "Besteller", value: "ordering_person", sortable: true },
        { text: "Rhythmus", value: "ordering_rhythm", sortable: true },
        { text: "Letzte Bestellung", value: "last_order", sortable: true },
        {
          text: "Nächste Bestellung",
          value: "next_order",
          sortable: true,
          groupable: true,
        },
        {
          text: "Überfällig",
          value: "order_overdue",
          sortable: true,
          filter: (value) => {
            if (!this.filter_overdue) return true
            else return value
          },
        },
        {
          text: "Abteilung",
          value: "departments",
          filter: (value) => {
            if (this.departments_filter.length)
              return this.findCommonElement(value, this.departments_filter)
            else return value
          },
        },
        { text: "Sortimente", value: "categories" },
      ]
    },
    suppliers_table_criticle() {
      return [
        { text: "Uhrzeit", value: "max_hour", align: "start", sortable: true },
        { text: "Lieferant", value: "name", align: "start", sortable: true },
        { text: "ID", value: "supplier_number", sortable: true },
        { text: "Letzte Bestellung", value: "last_order", sortable: true },
        {
          text: "Abteilung",
          value: "departments",
          filter: (value) => {
            if (this.departments_filter.length)
              return this.findCommonElement(value, this.departments_filter)
            else return value
          },
        },
        { text: "Sortimente", value: "categories" },
      ]
    },
    suppliers_filtered() {
      if (this.selected_supplier.length) {
        // filtered supplier-list
        //console.log(this.selected_supplier[0].public_id);
        return this.suppliers.filter(
          (supplier) =>
            supplier.public_id == this.selected_supplier[0].public_id
        )
      } else {
        return this.suppliers
      }
    },
    suppliers_criticle_filtered() {
      return this.suppliers.filter(
        (supplier) => "max_hour" in supplier && supplier.order_overdue == true
      )
    },
    orders_table() {
      return [
        {
          text: "Lieferant",
          value: "supplier.name",
          align: "start",
          sortable: true,
        },
        { text: "Bestelldatum", value: "order_date", sortable: true },
        // { text: "erw. Lieferdatum", value: "delivery_date", sortable: true },
      ]
    },
    suppliers() {
      return this.$store.state.orders_suppliers || []
    },
    calendar_events() {
      if (this.$store.state.orders_suppliers) {
        return (
          this.$store.state.orders_suppliers.map((item) => ({
            name: item.name,
            start: item.next_order.substring(0, 10),
            timed: false,
          })) || []
        )
      } else {
        return []
      }
    },
    departments_list() {
      let u = [
        ...new Set(
          this.suppliers
            .filter((item) => (this.filter_overdue ? item.order_overdue : true))
            .map((item) => item.departments)
        ),
      ].flat()
      return [...new Set(u)].sort()
    },
    departments_count() {
      let u = [
        ...new Set(
          this.suppliers
            .filter((item) => (this.filter_overdue ? item.order_overdue : true))
            .map((item) => item.departments)
        ),
      ].flat()
      return u.reduce((a, b) => (a[b] = a[b] + 1 || 1) && a, {})
    },
  },
  methods: {
    remaining_minutes(max_hour) {
      const d = this.currentTime
      return (max_hour - d.getHours() - 1) * 60 + 60 - d.getMinutes()
    },
    remaining_seconds(max_hour) {
      const d = this.currentTime
      return (
        ((max_hour - d.getHours() - 1) * 60 + 60 - d.getMinutes()) * 60 +
        60 -
        d.getSeconds()
      )
    },
    data_table_row_class(item) {
      let remaining = this.remaining_minutes(item.max_hour)

      let classes = {
        30: "red darken-1",
        60: "deep-orange darken-1",
        90: "orange darken-2",
        120: "amber",
        180: "green darken-1",
      }
      for (const [key, value] of Object.entries(classes)) {
        if (remaining < key) {
          return value
        }
      }
    },
    refresh: function (done) {
      this.$store.dispatch(
        "apiOrdersLoadSuppliers",
        this.$route.params.location_id
      )
      done()
    },
    replaceDate(str) {
      return str.replace(" ", "T")
    },
    findCommonElement(array1, array2) {
      for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {
          if (array1[i] === array2[j]) {
            return true
          }
        }
      }

      return false
    },
    handleClick(row) {
      this.$router.push({
        name: "OrdersSelected",
        params: { supplier_id: row.public_id },
      })
      //this.toggleSelection(row);
      //this.loadOrders();
      //this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected"));
    },
    loadOrders() {
      this.orders = []
      if (this.selected_supplier.length) {
        this.apiGetOrders(
          this.$route.params.location_id,
          this.selected_supplier[0].public_id
        )
      }
    },
    toggleSelection(key) {
      if (this.selected_supplier.includes(key)) {
        this.selected_supplier = this.selected_supplier.filter(
          (selectedkey) => selectedkey !== key
        )
        this.supplier_rows = 12
      } else {
        this.selected_supplier.pop()
        this.selected_supplier.push(key)
        this.supplier_rows = null
      }
    },
    clearSelection() {
      this.selected_supplier.pop()
      this.supplier_rows = null
    },
    apiGetOrders(location_id, supplier_id) {
      this.$api
        .get("/orders/" + location_id + "/" + supplier_id)
        .then((response) => (this.orders = response.data))
        .catch()
    },
    closeOverlay(arg) {
      this.overlay = false
      this.dialog = false
      if (arg == "saved") {
        this.clearSelection()
        this.$store.dispatch(
          "apiOrdersLoadSuppliers",
          this.$route.params.location_id
        )
        this.loadOrders()
      }
    },
  },
  mounted: function () {
    // this.currentTime = new Date();
    // console.log(this.currentTime);
    window.setInterval(() => {
      this.currentTime = new Date()
    }, 1000)
  },
  created() {
    // if (this.suppliers && !this.suppliers.length) {
    this.$store.dispatch(
      "apiOrdersLoadSuppliers",
      this.$route.params.location_id
    )
    this.initialized = true
    // }
  },
  // watch: {
  //   async currentTime(newValue, oldValue) {
  //     // console.log(newValue, oldValue);
  //   },
  // },
}
</script>
