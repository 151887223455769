var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item.receipt_type == 'Rechnung')?_c('v-layout',[_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"input":function($event){return _vm.checked_box(_vm.item, 'paid')}},model:{value:(_vm.item.paid),callback:function ($$v) {_vm.$set(_vm.item, "paid", $$v)},expression:"item.paid"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.item.supplier.billing_type)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.billing_type))]):_vm._e()]}}],null,false,2937694156)},[_c('span',[_vm._v(" "+_vm._s(_vm.item.supplier.billing_type))])]),(
        !_vm.item.paid &&
        _vm.item.supplier.payment_discount &&
        _vm.item.receipt_date &&
        _vm.$gf.payment_days(
          _vm.item.receipt_date,
          _vm.item.supplier.payment_discount_term
        ) > 0
      )?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.item.receipt_date ? _vm.$gf.payment_days( _vm.item.receipt_date, _vm.item.supplier.payment_discount_term ) + "T" : null)+": "+_vm._s(_vm.item.supplier.payment_discount)+"% ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!_vm.item.paid && _vm.item.supplier.payment_term && _vm.item.receipt_date)?_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.receipt_date ? _vm.$gf.payment_days( _vm.item.receipt_date, _vm.item.supplier.payment_term ) + "T" : null)+" ")]):_vm._e()]}}],null,false,774406579)},[_c('span',[_vm._v("Zahlung bis "+_vm._s(_vm.$gf.germanDate( _vm.$gf.addDaysToDate(_vm.item.receipt_date, _vm.item.supplier.payment_term) )))])])],1):_c('v-layout',[_c('v-chip',{attrs:{"small":""}},[_vm._v("keine Rechnung")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }