<template>
  <v-row class="ma-0">
    <v-col cols="12" md="12">
      <iir-search-input v-model="search" class="mx-4"></iir-search-input>
    </v-col>
    <v-col cols="12" md="12" class="ma-0 pa-0">
      <v-data-table
        :items="itemsComp"
        :headers="receipts_table"
        :sort-by="['receipt_date']"
        :sort-desc="['true']"
        :footer-props="{
          'items-per-page-text': 'Einträge pro Seite',
          'items-per-page-all-text': 'Alle',
          'page-text': '{0}-{1} von {2}',
          'show-current-page': true,
        }"
        item-key="full_path"
        dense
        :hide-default-footer="itemsComp.length <= 15"
        :search="search"
        @click:row="handleClick"
        style="cursor: pointer"
        :single-expand="true"
        :items-per-page="15"
        :expanded.sync="expanded"
        @item-expanded="handleClick_expanded"
        show-expand
        class="elevation-1"
      >
        <template v-slot:item.size="{ item }">
          {{ item.size ? humanFileSize(item.size, true) : "" }}
        </template>
        <template v-slot:item.receipt_date="{ item }">
          {{
            item.receipt_date
              ? new Date(item.receipt_date).toLocaleDateString("de-DE", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : ""
          }}
        </template>
        <template v-slot:item.receipt_supplier="{ item }">
          <div style="white-space: nowrap">{{ item.receipt_supplier }}</div>
        </template>
        <template v-slot:item.icon="{ item }">
          <v-icon label="TEST">
            {{
              getExpandedPath == item.full_path
                ? $i.mdiFolderOpen
                : getIcon(item.type)
            }}
          </v-icon>
        </template>
        <!-- <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" v-if="childrenLoaded">
            <receipt-file-table
              :items="tempItem"
              subdir=""
              v-if="childrenLoaded"
            >
            </receipt-file-table>
          </td>
        </template> -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <iframe
              :src="pdfsrc"
              style="width: 100%; height: 80vh; border: none"
            >
              Oops! an error has occurred.
            </iframe>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        hide-overlay
        width="100vh"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-container fill-height class="d-flex">
          <v-layout column fill-height>
            <v-flex grow>
              <v-row>
                <v-col cols="6">
                  <v-card
                    :class="'ma-0 pa-0 justify-center'"
                    @click="dialog = false"
                    height="90vh"
                  >
                    <v-card-title>Lieferschein</v-card-title>
                    <iframe
                      :src="pdfsrc"
                      style="
                        width: 100%;
                        height: 100%;
                        flex-grow: 1;
                        border: none;
                      "
                    >
                      Oops! an error has occurred.
                    </iframe>
                    <v-btn
                      class="mt-10"
                      fab
                      dark
                      small
                      top
                      right
                      absolute
                      color="primary"
                      @click="dialog = false"
                    >
                      <v-icon dark small> {{ $i.mdiClose }} </v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card
                    :class="'ma-0 pa-0 justify-center'"
                    @click="dialog = false"
                    height="90vh"
                  >
                    <v-card-title>Rechnung</v-card-title>
                    <iframe
                      :src="receiptsrc"
                      style="
                        width: 100%;
                        height: 100%;
                        flex-grow: 1;
                        border: none;
                      "
                    >
                      Oops! an error has occurred.
                    </iframe>
                    <v-btn
                      class="mt-10"
                      fab
                      dark
                      small
                      top
                      right
                      absolute
                      color="primary"
                      @click="dialog = false"
                    >
                      <v-icon dark small> {{ $i.mdiClose }} </v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
    </v-col>
  </v-row>
</template>
  
  <script>
import filetypes from "@/assets/files.json"
import ReceiptFileTable from "@/components/receipts/ReceiptFileTable.vue"
import iirSearchInput from "@/components/iirSearchInput.vue"

export default {
  name: "ReceiptFileTable",
  components: {
    ReceiptFileTable,
    iirSearchInput,
  },
  props: {
    items: null,
    itemText: { type: String, default: "" },
    activatable: { type: Boolean, default: true },
    itemKey: { type: String, default: "" },
    openOnClick: { type: Boolean, default: true },
    subdir: null,
    supplier: { type: String, default: "" },
    receipt_id: { default: null },
  },
  computed: {
    getExpandedPath() {
      if (this.expanded.length) {
        return this.expanded[0].full_path
      } else {
        return ""
      }
    },
    itemsComp() {
      if (this.subdir != "") {
        return this.filesFolder(this.items, this.subdir)
      } else {
        return this.filesFolder(this.items, "files")
      }
    },
    receipts_table() {
      let r = [
        {
          text: "",
          value: "icon",
          align: "start",
          sortable: false,
        },
      ]
      if ("id" in this.$route.params) {
        r.push({
          text: "Dateiname",
          value: "name",
          align: "start",
          sortable: true,
        })
      }
      if (this.subdir != "files" && this.subdir != "") {
        r.push({
          text: "Belegdatum",
          value: "receipt_date",
          sortable: true,
        })
        r.push({
          text: "Belegnummer",
          value: "receipt_number",
          sortable: true,
        })
        r.push({
          text: "Belegtyp",
          value: "receipt_type",
          sortable: true,
        })
        r.push({
          text: "Lieferant",
          value: "receipt_supplier",
          sortable: true,
        })
      }
      r.push({
        text: "Dateigröße",
        value: "size",
        sortable: true,
      })
      return r
    },
    supplier_id() {
      let supplier_id = this.supplier
      if ("id" in this.$route.params) supplier_id = this.$route.params.id
      return supplier_id
    },
  },
  data() {
    return {
      open: [],
      active: [],
      files: filetypes,
      expanded: [],
      tempItem: null,
      search: "",
      childrenLoaded: false,
      dialog: false,
      pdfsrc: null,
      receiptsrc: null,
    }
  },
  methods: {
    handleClick_expanded(row) {
      this.pdfsrc = "about:blank"
      this.receiptsrc = "about:blank"
      this.apiGetFile(row.item)
      this.apiGetReceipt()
    },
    handleClick(row, event) {
      this.pdfsrc = "about:blank"
      this.receiptsrc = "about:blank"
      this.apiGetFile(row)
      this.apiGetReceipt()
      if (!("id" in this.$route.params)) {
        //this.dialog = true
        if (event.isExpanded) {
          const indexExpanded = this.expanded.findIndex((i) => i === row)
          this.expanded.splice(indexExpanded, 1)
        } else {
          this.expanded.push(row)
        }
      }
    },
    getIcon(filetype) {
      //   console.log(this.files);
      const f = this.files.filter((item) => item.type == filetype)[0]
      if (!f) {
        return this.files.filter((item) => item.type == "default")[0].icon
      }
      return f.icon
    },
    getChildren(item) {
      //console.log(item);
      this.childrenLoaded = false

      this.$api
        .get(
          "files/" + this.$route.params.location_id + "/" + this.supplier_id, //this.$route.params.id,
          {
            params: { path: item.path },
          }
        )
        .then((response) => {
          this.tempItem = response.data
        })
        .catch()
        .then((this.childrenLoaded = true))
    },
    apiGetFile(obj) {
      if (obj && obj.type && !obj.type.includes("directory")) {
        this.$api
          .get(
            "files/" + this.$route.params.location_id + "/" + this.supplier_id,
            {
              responseType: "blob",
              params: { file: obj.full_path },
            }
          )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            if (!("id" in this.$route.params)) {
              this.pdfsrc = url
            } else {
              const link = document.createElement("a")
              link.href = url
              // const contentDisposition =
              //   response.headers["content-disposition"];
              link.setAttribute("download", obj.name)
              document.body.appendChild(link)
              link.click()
            }
          })
          .catch()
          .then()
      } else {
        // console.log(obj);

        if (JSON.stringify(this.expanded) === JSON.stringify([obj])) {
          this.expanded = []
        } else {
          this.getChildren(obj)
          this.expanded = [obj]
        }
      }
    },
    apiGetReceipt() {
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.receipt_id +
            "/" +
            this.receipt_id +
            ".pdf",
          {
            responseType: "blob",
            // params: { meta: meta },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          this.receiptsrc = url
        })
        .catch()
        .then()
    },
    filesFolder(arr, subdir) {
      //   console.log(arr);
      //   console.log(subdir);
      if (arr === null) return []
      const filteredKeys = [subdir]
      const fil = filteredKeys.reduce(
        (obj, key) => ({ ...obj, [key]: arr[key] }),
        {}
      )
      const f = fil[subdir]
      f.forEach((item) => {
        if (item.type == "httpd/unix-directory") {
          item.children = []
        }
      })
      return f
    },
    log(s) {
      console.log(s)
      //alert(s);
    },
    humanFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + " B"
      }

      const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      )

      return bytes.toFixed(dp) + " " + units[u]
    },
  },
  watch: {},
}
</script>
  