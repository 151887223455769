<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiAccountKey }}</v-icon>
            <v-toolbar-title>Kennwort zurücksetzen</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="apiChangePassword">
            <v-card-text>
              {{ $route.params }}
              <v-row>
                <v-col>
                  <v-text-field
                    label="neues Passwort"
                    name="password"
                    :prepend-icon="$i.mdiShieldKey"
                    :type="show_password_new ? 'text' : 'password'"
                    :append-icon="show_password_new ? $i.mdiEye : $i.mdiEyeOff"
                    @click:append="show_password_new = !show_password_new"
                    v-model="formData.new_password"
                    hint="Mindestens 8 Zeichen"
                    :rules="[min]"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-if="formData.new_password"
                    label="neues Passwort bestätigen"
                    name="password"
                    :prepend-icon="$i.mdiShieldKey"
                    :type="show_password_new_match ? 'text' : 'password'"
                    :append-icon="
                      show_password_new_match ? $i.mdiEye : $i.mdiEyeOff
                    "
                    @click:append="
                      show_password_new_match = !show_password_new_match
                    "
                    v-model="new_password_match"
                    hint="Mindestens 8 Zeichen"
                    :rules="[min, password_match]"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn
                color="accent"
                v-show="formData.new_password && passwords_matching()"
                @click="apiChangePassword"
                >Passwort speichern</v-btn
              ><v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ResetPassword",
  components: {},
  props: {},
  data: () => ({
    formData: {
      email: "",
      first_name: "",
      last_name: "",
      old_password: "",
      new_password: "",
    },
    new_password_match: "",
    show_password: false,
    show_password_new: false,
    show_password_new_match: false,
  }),
  methods: {
    required: (value) => !!value || "Pflichtfeld.",
    min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    password_match: function () {
      if (this.passwords_matching()) {
        return true
      } else {
        return "Passwörter stimmen nicht überein"
      }
    },
    passwords_matching: function () {
      return this.new_password_match === this.formData.new_password || false
    },
    apiChangePassword() {
      if (this.$store.state.logged_in) {
        this.$root.toast.error(
          "Passwortänderung nicht möglich. Sie sind bereits eingeloggt. Wir leiten Sie um."
        )
        this.$router.push({
          name: "Home",
        })
        return null
      }
      if (this.passwords_matching()) {
        let payload = {
          user_id: this.$route.params.user_id,
          token: this.$route.params.token,
          new_password: this.formData.new_password,
        }
        this.$api
          .post("password_reset", payload, { withCredentials: true })
          .then(
            (response) => this.$root.toast.success("Änderung erfolgreich"),
            this.$router.push({
              name: "Home",
            })
          )
          .catch((error) =>
            this.$root.toast.error("Fehler bei der Änderung des Passworts.")
          )
          .finally(() => (this.loaded = true))
        return statusCode
      }
    },
  },
  created() {
    if (this.$store.state.logged_in) {
      this.$root.toast.error(
        "Passwortänderung nicht möglich. Sie sind aktuell eingeloggt."
      )
      this.$router.push({
        name: "Home",
      })
    }
    //this.timer_check_login = setInterval(this.check_login, 100);
  },
}
</script>

<style></style>
