<template>
  <v-form ref="addShipment">
    <v-card class="mx-auto" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">
            Wareneingang hinzufügen
          </v-list-item-title>
          <v-list-item-subtitle
            >Bitte füllen Sie die nachfolgenden Felder vollständig aus, um den
            Wareneingang zu buchen.</v-list-item-subtitle
          >
          Lieferant - Abfrage über API von bestehenden Lieferanten Akzeptiert
          Lieferscheinupload
        </v-list-item-content>
      </v-list-item>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <!-- Input: Lieferant -->
            <v-autocomplete
              v-model="inputForm.supplier"
              :items="suppliers"
              :prepend-icon="$i.mdiTruckFast"
              item-text="name"
              item-value="id"
              label="Lieferant"
              ref="suppliers"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <!-- Input: Datetime -->
            <v-datetime-picker
              label="Lieferzeit"
              v-model="inputForm.delivery_date"
              date-format="dd.MM.yyyy"
              :time-picker-props="{ ampmInTitle: false, format: '24hr' }"
              :text-field-props="{ prependIcon: $i.mdiCalendar }"
            >
              <template slot="dateIcon">
                <v-icon>{{ $i.mdiCalendar }}</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>{{ $i.mdiClock }}</v-icon>
              </template>

              <slot></slot>
            </v-datetime-picker>
          </v-col>
          <v-col cols="12" md="4">
            <!-- Input: Belegnummer -->
            <iir-input
              label="Belegnummer"
              :value.sync="inputForm.receipt_number"
              :tooltip="inputModel.receipt_number.tooltip"
              :prepend-icon="$i.mdiCounter"
              v-if="inputForm.supplier != 0"
            >
            </iir-input>
          </v-col>
          <v-col cols="12" md="4">
            <!-- Input: Belegdatum -->
            <iir-date-input
              label="Belegdatum"
              :value.sync="inputForm.receipt_date"
              :tooltip="inputModel.receipt_date.tooltip"
              :prepend-icon="$i.mdiCalendar"
              v-if="inputForm.supplier != 0"
            >
            </iir-date-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="12" sm="12">
            Warengruppe
            <!-- Input: Warengruppe -->
            <v-card>
              <v-list>
                <v-list-item v-for="(item, i) in categories" :key="i" dense>
                  <v-list-item-action>
                    <v-switch
                      v-model="inputForm.category.val"
                      color="primary"
                      label=""
                      :value="item.id"
                      hide-details
                    >
                    </v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="inputForm.category.val.includes(i)"
                    >
                      <iir-slider
                        prefix="Temperatur"
                        :value.sync="inputForm.category.temp[i]"
                        textSuffix="°"
                        tooltip=""
                        :step="0.1"
                        :min="item.slider_min"
                        :max="item.slider_max"
                        :alert_value="item.max_temp"
                        v-if="item.max_temp != ''"
                      ></iir-slider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="inputForm.category.val.includes(i)">
                    <v-checkbox
                      v-model="inputForm.category.accepted[i]"
                      color="primary"
                      :label="
                        inputForm.category.accepted[i] == false
                          ? 'Annahme verweigern'
                          : 'Ware annehmen'
                      "
                      :disabled="
                        item.max_temp < inputForm.category.temp[i]
                          ? true
                          : false
                      "
                      indeterminate
                      :off-icon="$i.mdiAlertOctagon"
                    >
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="12" sm="12">
            Belege hochladen

            <!-- Input: Lieferschein-Foto -->
            <v-file-input
              multiple
              :prepend-icon="$i.mdiCamera"
              label="Beleg hochladen"
              placeholder="Beleg hochladen"
              v-model="inputForm.receipt_photos"
            >
              <v-tooltip slot="append-outer" left max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    {{ $i.mdiInformation }}
                  </v-icon>
                </template>
                <span>{{ inputModel.receipt_photos.tooltip }}</span>
              </v-tooltip>
              <slot></slot>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="12" sm="12">
            Lieferschein fotografieren 2
            <!-- Input: Lieferschein-Foto -->
            <iir-image-uploader
              :documentAttachment.sync="inputForm.receipt_photos"
              :fileUploaderType="'thumbnail'"
              :maxFileSize="10240"
              :imageCompressor="false"
              :maxFileCount="50"
              :badgeCounter="false"
              :thumb="true"
              :changeFileName="true"
              :addFileDescription="false"
              :addFileTag="false"
            >
            </iir-image-uploader>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="ma-2 white--text"
          :disabled="!inputForm.category.val.length"
          @click="submitForm"
        >
          Wareneingang verbuchen
          <v-icon right dark> {{ $i.mdiCheckboxMarked }} </v-icon>
        </v-btn>
        <v-btn color="warning" class="ma-2 white--text" @click="resetForm">
          Zurücksetzen
          <v-icon right dark> {{ $i.mdiSyncCircle }} </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="showAddSupplierDialogue" max-width="700">
        <add-supplier
          :value.sync="showAddSupplierDialogue"
          @closed="addSupplier"
        >
        </add-supplier>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import iirInput from "@/components/iirInput.vue";
import iirDateInput from "@/components/iirDateInput.vue";
import iirSlider from "@/components/iirSlider.vue";
import AddSupplier from "@/components/suppliers/AddSupplier.vue";
import axios from "axios";
import iirImageUploader from "@/components/iirImageUploader.vue";

export default {
  name: "AddShipment",
  components: {
    iirInput,
    iirDateInput,
    iirSlider,
    AddSupplier,
    iirImageUploader,
  },
  data: () => ({
    inputModel: {
      supplier: { tooltip: "Tooltip Supplier" },
      delivery_date: { tooltip: "Tooltip Time" },
      receipt_number: { tooltip: "Tooltip Belegnummer" },
      receipt_date: { tooltip: "Tooltip Belegdatum" },
      category: { tooltip: "" },
      receipt_photos: {
        tooltip: "Eine oder mehrere Seiten hochladen",
      },
      temperature_acceptable: false,
    },
    inputForm: {
      supplier: "",
      delivery_date: new Date(),
      receipt_number: "",
      receipt_date: "",
      category: { val: [], temp: [], accepted: [] },
      receipt_photos: [],
      temperature_acceptable: false,
    },
    categories: [
      {
        id: 0,
        name: "Trockensortiment",
        max_temp: "",
        icon: "",
        tooltip: "",
        slider_min: "",
        slider_max: "",
      },
      {
        id: 1,
        name: "Frische",
        max_temp: 7,
        icon: "",
        tooltip: "",
        slider_min: -2,
        slider_max: 10,
      },
      {
        id: 2,
        name: "Fleisch / Innereien / Hackfleisch vorverp.",
        max_temp: 2,
        icon: "",
        tooltip: "",
        slider_min: -2,
        slider_max: 6,
      },
      {
        id: 3,
        name: "Frischfisch",
        max_temp: 2,
        icon: "",
        tooltip: "",
        slider_min: -2,
        slider_max: 6,
      },
      {
        id: 4,
        name: "Tiefkühlkost",
        max_temp: -18,
        icon: "",
        tooltip: "",
        slider_min: -28,
        slider_max: 0,
      },
    ],
    showAddSupplierDialogue: false,
    loading: false,
    initialized: false,
  }),
  computed: {
    suppliers() {
      return this.$store.state.suppliers || [];
    },
  },
  watch: {
    "inputForm.category": {
      handler() {
        var acceptable = true;
        //console.log(this.inputForm.category.val.length)
        this.inputForm.category.val.forEach((value) => {
          //console.log(value)
          //console.log(this.inputForm.category.temp[value])
          var current_temp = this.inputForm.category.temp[value];
          var max_temp = this.categories.filter((a) => a.id == value)[0]
            .max_temp;
          if (max_temp < current_temp) {
            console.log("Temperatur zu hoch");
            acceptable = false;
          }
        });
        this.inputForm.temperature_acceptable = acceptable;
        //console.log(this.inputForm.category.temp)
        //alert(val)
      },
      deep: true,
    },
  },
  methods: {
    openDialogue: function (dialogue) {
      if (dialogue == "addSupplier") {
        this.showAddSupplierDialogue = true;
      }
    },
    addSupplier: function (_name, _id) {
      this.suppliers.push({ name: _name, id: _id });
      this.$refs.suppliers.isMenuActive = true;
    },
    resetForm() {
      this.$refs.addShipment.reset();
    },
    submitForm() {
      axios
        .post("https://en30jz4ip3et.x.pipedream.net", this.inputForm)
        .then((res) => {
          //Perform Success Action
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  mounted: function () {},
};
</script>
