<template>
  <v-card>
    <v-form @submit.prevent="save">
      <v-card-text>
        <v-text-field
          label="Firma"
          name="first_name"
          ref="firstNameTextField"
          :prepend-icon="$i.mdiAccount"
          v-model="formData.name"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Geschäftsbezeichnung"
          name="name_informal"
          ref="lasttNameTextField"
          :prepend-icon="$i.mdiAccountOutline"
          v-model="formData.name_informal"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Anschrift"
          name="address"
          ref="lasttNameTextField"
          :prepend-icon="$i.mdiAccountOutline"
          v-model="formData.address"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Postleitzahl"
          name="postcode"
          ref="lasttNameTextField"
          :prepend-icon="$i.mdiAccountOutline"
          v-model="formData.postcode"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Stadt"
          name="city"
          ref="lasttNameTextField"
          :prepend-icon="$i.mdiAccountOutline"
          v-model="formData.city"
          :rules="[rules.required]"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit">Speichern</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  computed: {
    sync_value: {
      get() {
        return this.formData;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
  data: () => ({
    formData: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
    },
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    },
    show_password: false,
  }),
  props: {
    company: {},
  },
  methods: {
    apiSaveCompany() {
      // this.loading = true;
      this.$api
        .put("admin/company/" + this.formData.public_id, this.formData)
        .then(() => {})
        .catch((error) => console.log(error));
      // .finally(() => (this.loading = false));
    },
    save: function () {
      this.apiSaveCompany();
    },
  },
  mounted: function () {
    this.formData = this.company;
  },
};
</script>

