<template>
  <div class="contacts">
    <v-form @submit.prevent="hide">
      <v-card class="mx-auto elevation-6">
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title>
            <v-icon class="mr-1">{{ $i.mdiAccountMultiple }}</v-icon>
            Kontakt
            {{
              this.$route.params.public_id == "add"
                ? "hinzufügen"
                : "bearbeiten"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="secondary" size="32" class="d-print-none">
                <v-icon
                  color="white"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                  v-if="'created' in contact"
                >
                  {{ $i.mdiCalendar }}
                </v-icon>
              </v-avatar>
            </template>
            <span>Erstellt: {{ contact.created }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="secondary" size="32" class="d-print-none">
                <v-icon
                  color="white"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                  v-if="'updated' in contact"
                >
                  {{ $i.mdiUpdate }}
                </v-icon>
              </v-avatar>
            </template>
            <span>Bearbeitet: {{ contact.updated }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6" lg="4" md="4" sm="6">
              <iir-input
                :label="inputModel.name.label"
                :hint="inputModel.name.hint"
                :value.sync="contact.name"
                :prepend-icon="$i.mdiAccount"
                :readonly="disabled"
                :clearable="!disabled"
              >
              </iir-input>
            </v-col>
            <v-col cols="6" lg="4" md="4" sm="6">
              <iir-input
                :label="inputModel.first_name.label"
                :hint="inputModel.first_name.hint"
                :value.sync="contact.first_name"
                :prepend-icon="$i.mdiAccountOutline"
                :readonly="disabled"
                :clearable="!disabled"
              >
              </iir-input>
            </v-col>
            <v-col cols="6" lg="4" md="4">
              <iir-input
                :label="inputModel.type.label"
                :hint="inputModel.type.hint"
                :value.sync="contact.type"
                :prepend-icon="$i.mdiAccountTie"
                :readonly="disabled"
                :clearable="!disabled"
              >
              </iir-input>
            </v-col>
            <v-col cols="6" lg="4" md="4">
              <iir-input
                :label="inputModel.company.label"
                :hint="inputModel.company.hint"
                :value.sync="contact.company"
                :prepend-icon="$i.mdiTagText"
                :readonly="disabled"
                :clearable="!disabled"
              >
              </iir-input>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-text-field
                :label="inputModel.tel.label"
                :hint="inputModel.tel.hint"
                v-model="contact.tel"
                :prepend-icon="$i.mdiCellphone"
                :readonly="disabled"
                :append-outer-icon="contact.tel ? $i.mdiPhone : ''"
                @click:append-outer="callPhone(contact.tel)"
                v-show="disabled"
              >
              </v-text-field>
              <vue-tel-input-vuetify
                default-country="DE"
                :only-countries="[
                  'DE',
                  'GB',
                  'DK',
                  'BE',
                  'FR',
                  'IT',
                  'CH',
                  'AT',
                ]"
                :preferred-countries="['DE', 'AT']"
                disabledFetchingCountry
                v-model="contact.tel"
                mode="international"
                select-label="Land"
                :readonly="disabled"
                :label="inputModel.tel.label"
                :hint="inputModel.tel.hint"
                clearable
                v-show="!disabled"
              >
              </vue-tel-input-vuetify>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-text-field
                :label="inputModel.landline.label"
                :hint="inputModel.landline.hint"
                v-model="contact.landline"
                :prepend-icon="$i.mdiAccount"
                :readonly="disabled"
                :append-outer-icon="contact.landline ? $i.mdiPhone : ''"
                @click:append-outer="callPhone(contact.landline)"
                v-show="disabled"
              >
              </v-text-field>
              <vue-tel-input-vuetify
                default-country="DE"
                :only-countries="[
                  'DE',
                  'GB',
                  'DK',
                  'BE',
                  'FR',
                  'IT',
                  'CH',
                  'AT',
                ]"
                :preferred-countries="['DE', 'AT']"
                disabledFetchingCountry
                v-model="contact.landline"
                mode="international"
                select-label="Land"
                :readonly="disabled"
                :label="inputModel.landline.label"
                :hint="inputModel.landline.hint"
                clearable
                v-show="!disabled"
              >
              </vue-tel-input-vuetify>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :label="inputModel.email.label"
                :hint="inputModel.email.hint"
                v-model="contact.email"
                :prepend-icon="$i.mdiAt"
                :readonly="disabled"
                :append-outer-icon="contact.email ? $i.mdiEmail : ''"
                @click:append-outer="mailto(contact.email)"
                :clearable="!disabled"
                :rules="[rules.email_validation]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <iir-input
                :label="inputModel.fax.label"
                :hint="inputModel.fax.hint"
                :value.sync="contact.fax"
                :prepend-icon="$i.mdiFax"
                :readonly="disabled"
                v-show="disabled"
              >
              </iir-input>
              <vue-tel-input-vuetify
                default-country="DE"
                :only-countries="[
                  'DE',
                  'GB',
                  'DK',
                  'BE',
                  'FR',
                  'IT',
                  'CH',
                  'AT',
                ]"
                :preferred-countries="['DE', 'AT']"
                disabledFetchingCountry
                v-model="contact.fax"
                mode="international"
                select-label="Land"
                :readonly="disabled"
                :label="inputModel.fax.label"
                :hint="inputModel.fax.hint"
                clearable
                v-show="!disabled"
              >
              </vue-tel-input-vuetify>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-textarea
                :label="inputModel.note.label"
                :prepend-icon="$i.mdiNotebook"
                counter="255"
                rows="3"
                :rules="[rules.length(255)]"
                v-model="contact.note"
                :suffix="null"
                :readonly="disabled"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          <v-icon> {{ $i.mdiNotebook }} </v-icon>
          Notizen
        </v-card-title>
        <v-card-subtitle>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="search"
                :prepend-inner-icon="$i.mdiMagnify"
                label="Suchen"
                clearable
              ></v-text-field> </v-col
            ><v-col cols="12" md="6">
              <v-checkbox
                v-model="filter_active"
                label="Nur offene Notizen"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <iir-notes-table
            :notes="notes"
            :notes_table="notes_table"
            :search="search"
            :filter_active="filter_active"
          ></iir-notes-table>
          <div class="text-center d-print-none">
            <v-btn
              color="accent"
              class="ma-2 justify-center"
              @click="dialog_note = !dialog_note"
              v-if="disabled"
            >
              <v-icon dark> {{ $i.mdiNotebookPlus }} </v-icon>
              Notiz hinzufügen
            </v-btn>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          <v-icon> {{ $i.mdiTruckFast }} </v-icon>
          Lieferanten
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in this.contact_suppliers"
                :key="i"
                :to="
                  disabled
                    ? `/${$route.params.location_id}/suppliers/${item.supplier_id}/`
                    : ``
                "
              >
                <v-list-item-icon>
                  <v-icon>{{ $i["mdiNumeric" + (i + 1) + "Box"] }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="!disabled" class="d-print-none">
                  <v-btn icon @click="removeSupplier(item.supplier_id)">
                    <v-icon color="grey lighten-1">{{ $i.mdiDelete }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div class="text-center d-print-none">
            <v-btn
              color="accent"
              class="ma-2 justify-center"
              @click="dialog_supplier = !dialog_supplier"
              v-if="disabled"
            >
              <v-icon dark> {{ $i.mdiTruckPlus }} </v-icon>
              Lieferanten hinzufügen
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="text-center d-print-none">
            <v-btn
              color="error"
              class="ma-2 justify-center"
              @click="apiDeleteContact"
              v-if="disabled"
            >
              <v-icon dark> {{ $i.mdiDeleteForever }} </v-icon>
              Kontakt löschen
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="mx-2 d-print-none"
            @click="editContact"
            v-if="disabled"
            fab
            bottom
            right
            fixed
          >
            <v-icon dark> {{ $i.mdiPencil }} </v-icon>
          </v-btn>
          <v-btn
            color="success"
            class="mx-2 d-print-none"
            @click="hide('save')"
            v-if="!disabled"
            fab
            bottom
            right
            fixed
          >
            <v-icon dark> {{ $i.mdiContentSave }} </v-icon>
          </v-btn>
        </v-card-actions>

        <v-dialog v-model="dialog_supplier" max-width="500px">
          <select-supplier
            @saved="closeOverlaySupplier($event)"
            @closed="closeOverlaySupplier($event)"
          >
          </select-supplier>
        </v-dialog>
        <v-dialog v-model="dialog_note" max-width="500px">
          <add-note
            @closed="closeOverlayNote('closed')"
            @saved="closeOverlayNote('saved')"
          >
          </add-note>
        </v-dialog>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import iirInput from "@/components/iirInput.vue"
import SelectSupplier from "@/components/SelectSupplier.vue"
import iirNotesTable from "@/components/notes/iirNotesTable.vue"
import addNote from "@/components/notes/AddNote.vue"

export default {
  name: "ShowContact",
  components: {
    iirInput,
    addNote,
    SelectSupplier,
    iirNotesTable,
  },
  data: () => ({
    initialized: false,
    disabled: true,
    dialog_supplier: false,
    dialog_note: false,
    filter_active: true,
    search: null,

    rules: {
      length: (len) => (v) =>
        (v || "").length <= len || `Zu lang, maximal ${len} Zeichen.`,
      email_validation: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return value != ""
          ? pattern.test(value) || "Ungültige E-Mail-Adresse."
          : true
      },
    },
    contact: {
      public_id: "",
      name: null,
      first_name: null,
      type: null,
      company: null,
      tel: null,
      landline: null,
      email: null,
      fax: null,
    },
    inputModel: {
      name: {
        label: "Name",
        hint: "",
        tooltip: "Tooltip Belegdatum",
      },
      first_name: {
        tooltip: "Tooltip Belegdatum",
        label: "Vorname",
        hint: "",
      },
      company: {
        tooltip: "Tooltip Supplier",
        label: "Firma",
        hint: "",
      },
      type: {
        tooltip: "Tooltip Supplier",
        label: "Position",
        hint: "",
      },
      tel: {
        tooltip: "Tooltip Supplier",
        label: "Telefon",
        hint: "inkl. Vorwahl (z. B. 040)",
      },
      landline: {
        tooltip: "Tooltip Supplier",
        label: "Festnetz",
        hint: "inkl. Vorwahl (z. B. 040)",
      },
      email: {
        tooltip: "Tooltip Supplier",
        label: "E-Mail",
        hint: "",
      },
      fax: {
        tooltip: "Tooltip Belegdatum",
        label: "Fax",
        hint: "inkl. Vorwahl (z. B. 040)",
      },
      note: {
        label: "Hinweise",
      },
    },
  }),
  computed: {
    contact_suppliers() {
      if (this.contact.suppliers) {
        return [...this.contact.suppliers].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      } else {
        return []
      }
    },
    notes() {
      if (this.$store.state.notes) {
        return (
          this.$store.state.notes.filter((d) =>
            this.supplier_ids.includes(d.supplier.supplier_id)
          ) || []
        )
      } else {
        return []
      }
    },
    supplier_ids() {
      let ids = this.contact_suppliers.map(({ supplier_id }) => supplier_id)
      return ids
    },
    notes_table() {
      return [
        {
          text: "Lieferant",
          value: "supplier.name",
          sortable: true,
          groupable: true,
        },
        {
          text: "Notiz",
          value: "note",
          groupable: false,
          sortable: false,
        },
        { text: "Datum", value: "created", sortable: true, groupable: false },
        {
          text: "Bediener",
          value: "user",
          align: "start",
          groupable: false,
        },
        {
          text: "Archiviert",
          value: "archived",
          sortable: true,
          groupable: false,
          filter: (value) => {
            if (!this.filter_active) return true
            else return !value
          },
        },
      ]
    },
  },
  props: {},
  methods: {
    callPhone(n) {
      window.location.href = "tel:" + n
    },
    mailto(n) {
      window.location.href = "mailto:" + n
    },
    closeOverlaySupplier(arg) {
      this.dialog_supplier = false
      // API TO SAVE RELATION
      if (arg) {
        this.apiAddContactSupplier(arg.supplier_id)
      }
    },
    closeOverlayNote(arg) {
      this.dialog_note = false
      if (arg == "saved") {
        this.$store.dispatch("apiGetNotes", this.$store.state.location_id)
      }
    },
    editContact() {
      this.disabled = !this.disabled
    },
    removeSupplier(public_id) {
      this.apiRemoveContactSupplier(public_id)
    },
    apiAddContact() {
      this.loading = true
      this.$api
        .post("contacts/" + this.$route.params.location_id, this.contact)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            //this.$root.toast.success("Gespeichert!");
            this.$router.push({
              name: "ShowContact",
              params: { public_id: response.data.Created },
            })
            //this.$router.back();
          } else {
            this.$root.toast.error("Nicht gespeichert!")
          }
        })
        .catch((error) => console.log(error))
        .finally((this.loading = false))
    },
    apiAddContactSupplier(supplier_id) {
      this.loading = true
      this.$api
        .post(
          "contacts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.public_id +
            "/" +
            supplier_id,
          this.contact
        )
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            //this.$root.toast.success("Gespeichert!");
            this.apiGetContact(this.$route.params.public_id)
            //this.$router.back();
          } else {
            this.$root.toast.error("Nicht gespeichert!")
          }
        })
        .catch((error) => console.log(error))
        .finally((this.loading = false))
    },
    apiRemoveContactSupplier(supplier_id) {
      this.loading = true
      this.$api
        .delete(
          "contacts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.public_id +
            "/" +
            supplier_id
        )
        .then((response) => {
          if (
            response.status == 200 ||
            response.status == 201 ||
            response.status == 204
          ) {
            //this.$root.toast.success("Gespeichert!");
            this.apiGetContact(this.$route.params.public_id)
            //this.$router.back();
          } else {
            this.$root.toast.error("Nicht gespeichert!")
          }
        })
        .catch((error) => console.log(error))
        .finally((this.loading = false))
    },
    apiSaveContact() {
      this.loading = true
      this.$api
        .put(
          "contacts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.public_id,
          this.contact
        )
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.$root.toast.success("Kontakt gespeichert.")
            //this.$root.toast.success("Gespeichert!");
            //console.log(response.data);
            //console.log("text");
            //this.$router.back();
            // this.$router.push({
            //   name: "ShowContact",
            //   params: { public_id: response.data.Created },
            // });
          } else {
            this.$root.toast.error("Nicht gespeichert!")
          }
        })
        .catch((error) => console.log(error))
        .finally((this.loading = false))
    },
    apiGetContact(public_id) {
      this.loading = true
      //this.$store.commit('startLoading')
      this.$api
        .get("contacts/" + this.$route.params.location_id + "/" + public_id)
        .then((response) => (this.contact = response.data[0]))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false))
    },
    apiDeleteContact() {
      this.loading = true
      //this.$store.commit('startLoading')
      this.$api
        .delete(
          "contacts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.public_id
        )
        .then((response) => (this.contact = response.data[0]))
        .catch((error) => console.log(error))
        .finally(() => ((this.loading = false), this.$router.back()))
    },
    preventNav(event) {
      if (this.disabled) return
      event.preventDefault()
      event.returnValue = ""
    },
    hide: function (arg) {
      if (arg == "save") {
        if (this.$route.params.public_id == "add") {
          this.apiAddContact()
        } else {
          this.apiSaveContact()
        }
        this.$emit("closed", this.contact, this.contact)
        this.sync_value = false
        this.disabled = true
      } else {
        this.sync_value = false
      }
    },
  },
  mounted: function () {
    if (this.$route.params.public_id == "add") {
      this.disabled = false
    } else if (!this.contact.length) {
      this.apiGetContact(this.$route.params.public_id)
    }
    //alert(this.$store.state.user);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav)
    })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.disabled) {
      if (
        !window.confirm(
          "Bearbeitung noch nicht abgeschlossen. Möchten Sie die Seite wirklich verlassen?"
        )
      ) {
        return
      }
    }
    next()
  },
}
</script>

