<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline">Lieferanten auswählen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout wrap>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-autocomplete
                  v-model="supplier.supplier_id"
                  :items="suppliers"
                  :prepend-icon="$i.mdiTruckFast"
                  item-text="name"
                  item-value="public_id"
                  label="Lieferant"
                  ref="suppliers"
                  clearable
                >
                  <template v-slot:no-data>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item>
                      <v-list-item-avatar color="grey lighten-6">
                        <v-icon> {{ $i.mdiClose }} </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Kein Lieferant gefunden
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Es wurde kein passender Lieferant gefunden
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="hide('close')">
          schließen
          <v-icon right>{{ $i.mdiCloseCircleOutline }}</v-icon>
        </v-btn>

        <v-btn color="success" @click="hide('save')">
          speichern
          <v-icon right>{{ $i.mdiContentSave }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "SelectSupplier",
  components: {},
  computed: {
    suppliers() {
      return this.$store.state.suppliers || []
    },
    sync_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
  },
  props: {},
  methods: {
    hide: function (arg) {
      if (this.supplier.supplier_id == "" && arg == "save") {
        alert("Keine Eingabe gemacht")
      } else if (arg == "save") {
        //this.apiAddNote();
        this.$emit("saved", this.supplier)
        this.sync_value = false
      } else {
        this.sync_value = false
        this.$emit("closed")
      }
    },
  },
  data() {
    return {
      supplier: {
        supplier_name: "",
        supplier_id: "",
      },
    }
  },
  created() {
    this.supplier.supplier_id = null

    if (this.$store.state.suppliers == null) {
      this.$store.dispatch("apiGetSuppliers", {
        location_id: this.$route.params.location_id,
        active: true,
      })
    }
  },
}
</script>

