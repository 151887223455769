<template>
  <v-tabs vertical v-model="tab" background-color="secondary lighten-1">
    <v-tab v-for="location in company.locations" :key="location.public_id">
      <v-icon left> {{ $i.mdiStoreMarker }} </v-icon>
      {{ location.public_id }}
    </v-tab>
    <v-tab key="add_location" to="register_location">
      <v-icon left> {{ $i.mdiPlus }} </v-icon> Markt hinzufügen
    </v-tab>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="location in company.locations"
        :key="location.public_id"
      >
        <v-card flat>
          <v-card-text>
            <admin-tab-company-locations-data :location="location" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="add_location">
        <v-card flat>
          <v-card-text>Markt hinzufügens</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import AdminTabCompanyLocationsData from "@/components/admin/AdminTabCompanyLocationsData.vue"

export default {
  components: { AdminTabCompanyLocationsData },
  data: () => ({
    tab: null,
    formData: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
    },
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    },
    show_password: false,
  }),
  props: {
    company: {},
  },
  methods: {
    apiSaveLocation() {
      // this.loading = true;
      // this.$api
      //   .post("suppliers/" + this.$route.params.location_id, this.supplier)
      //   .then((response) => (this.supplier = response.data))
      //   .catch((error) => console.log(error))
      //   .finally(() => (this.loading = false));
    },
    save: function () {
      this.apiSaveLocation()
    },
  },
}
</script>

