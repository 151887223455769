<template>
  <v-card class="mx-auto elevation-6" v-if="initialized">
    <v-toolbar color="primary" dark flat dense>
      <v-icon class="mr-1">{{ $i.mdiPackageVariant }}</v-icon>
      <v-toolbar-title
        >Wareneingang von {{ shipment.supplier.name }} vom
        {{ formatDate(shipment.delivery_date) }}</v-toolbar-title
      >
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="12">
          <!-- Input: Belegnummer -->
          <iir-input
            label="Lieferant"
            :value.sync="shipment.supplier.name"
            :prepend-icon="$i.mdiTruckFast"
            disabled
          >
          </iir-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <!-- Input: Datetime -->
          <iir-input
            label="Lieferzeit"
            v-model="shipment.delivery_date"
            :prepend-icon="$i.mdiClock"
            disabled
          >
          </iir-input>
        </v-col>
        <v-col cols="12" md="4">
          <v-datetime-picker
            label="Lieferzeit"
            v-model="shipment.delivery_date"
            :time-picker-props="{ ampmInTitle: false, format: '24hr' }"
            :text-field-props="{ prependIcon: $i.mdiCalendar }"
            date-format="dd.MM.yyyy"
            time-format="HH:mm:ss"
            disabled
          >
            <template slot="dateIcon">
              <v-icon>{{ $i.mdiCalendar }}</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>{{ $i.mdiClock }}</v-icon>
            </template>

            <slot></slot>
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" md="4">
          <!-- Input: Belegnummer -->
          <iir-input
            label="Belegnummer"
            :value.sync="shipment.receipt_number"
            :prepend-icon="$i.mdiCounter"
            disabled
          >
          </iir-input>
        </v-col>
        <v-col cols="12" md="4">
          <!-- Input: Belegdatum -->
          <iir-date-input
            label="Belegdatum"
            :value.sync="shipment.receipt_date"
            :prepend-icon="$i.mdiCalendar"
            :clearable="false"
            disabled
          >
          </iir-date-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="12" sm="12">
          <v-card>
            <v-data-table
              :headers="temperature_table"
              :items="shipment.categories"
              class="elevation-1"
              :sort-by="['delivery_date']"
              :sort-desc="['true']"
              :hide-default-footer="true"
              dense
            >
              <template v-slot:item.accepted="{ item }">
                <v-simple-checkbox
                  v-model="item.accepted"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.temp="{ item }">
                <span>{{ item.temp || "no" }} °C</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="12" sm="12"> Belege </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="secondary"
        :to="`/${$route.params.location_id}/suppliers/${shipment.supplier.supplier_id}/`"
      >
        zum Lieferanten <v-icon right>{{ $i.mdiTruckFast }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import iirInput from "@/components/iirInput.vue";
import iirDateInput from "@/components/iirDateInput.vue";

export default {
  name: "ShowShipment",
  components: {
    iirInput,
    iirDateInput,
  },
  data: () => ({
    loading: false,
    initialized: false,
    shipment: {},
    shipment_table: [
      { text: "Lieferant", value: "supplier", align: "start", sortable: true },
      { text: "Lieferzeit", value: "delivery_date", sortable: true },
      { text: "Belegnummer", value: "receipt_number", sortable: true },
      { text: "Belegdatum", value: "receipt_date", sortable: true },
      { text: "Beleg vorhanden", value: "receipt_photos", sortable: true },
    ],
    temperature_table: [
      { text: "Warengruppe", value: "name", align: "start", sortable: true },
      { text: "Temperatur", value: "temp", sortable: true },
      { text: "Ware angenommen", value: "accepted", sortable: true },
    ],
    inputModel: {
      supplier: { tooltip: "Tooltip Supplier" },
      delivery_date: { tooltip: "Tooltip Time" },
      receipt_number: { tooltip: "Tooltip Belegnummer" },
      receipt_date: { tooltip: "Tooltip Belegdatum" },
      category: { tooltip: "" },
      receipt_photos: {
        tooltip: "Eine oder mehrere Seiten hochladen",
      },
      temperature_acceptable: false,
    },
    categories: [
      {
        id: 0,
        name: "Trockensortiment",
        max_temp: "",
        icon: "",
        tooltip: "",
        slider_min: "",
        slider_max: "",
      },
      {
        id: 1,
        name: "Frische",
        max_temp: 7,
        icon: "",
        tooltip: "",
        slider_min: -2,
        slider_max: 10,
      },
      {
        id: 2,
        name: "Fleisch / Innereien / Hackfleisch vorverp.",
        max_temp: 2,
        icon: "",
        tooltip: "",
        slider_min: -2,
        slider_max: 6,
      },
      {
        id: 3,
        name: "Frischfisch",
        max_temp: 2,
        icon: "",
        tooltip: "",
        slider_min: -2,
        slider_max: 6,
      },
      {
        id: 4,
        name: "Tiefkühlkost",
        max_temp: -18,
        icon: "",
        tooltip: "",
        slider_min: -28,
        slider_max: 0,
      },
    ],
  }),
  methods: {
    apiGetShipment() {
      this.loading = true;
      this.$api
        .get(
          "shipments/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.supplier_id +
            "/" +
            this.$route.params.id
        )
        .then((response) => (this.shipment = response.data))
        .catch((error) => console.log(error))
        .finally(() => ((this.loading = false), (this.initialized = true)));
    },
    formatDate(date) {
      if (!date) return null;
      date = date.split(" ")[0];
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
  },
  mounted: function () {
    if (!this.shipment.length) {
      this.apiGetShipment();
    }
  },
};
</script>
