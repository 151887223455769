<template>
  <div>
    <v-layout>
      <v-simple-checkbox
        v-model="item.checked"
        :ripple="false"
        dense
        @input="checked_box(item, 'checked')"
      ></v-simple-checkbox>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-if="
              !item.checked &&
              item.recognized &&
              item.receipt_type == 'Rechnung' &&
              item.supplier.name !== null
            "
            color="success"
            class="d-print-none"
            v-bind="attrs"
            v-on="on"
            rounded
            @click.stop="
              open_delivery_notes(item.supplier.public_id, item.public_id)
            "
            ><v-icon> {{ $i.mdiPackageVariant }} </v-icon></v-btn
          >
        </template>
        <span>Lieferscheine öffnen</span>
      </v-tooltip>
    </v-layout>
    <v-dialog
      v-model="dialog_delivery_notes"
      fullscreen
      hide-overlay
      width="100vh"
      transition="dialog-bottom-transition"
    >
      <receipt-delivery-notes
        :supplier_id="delivery_notes_supplier_id"
        :receipt_id="delivery_notes_receipt_id"
        @closed="dialog_delivery_notes = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import ReceiptDeliveryNotes from "@/components/receipts/ReceiptDeliveryNotes.vue"
export default {
  name: "ReceiptDataTableChecked",
  components: {
    ReceiptDeliveryNotes,
  },
  props: {
    item: {},
  },
  data: () => ({
    dialog_delivery_notes: false,
    delivery_notes_supplier_id: null,
    delivery_notes_receipt_id: null,
  }),
  computed: {},
  methods: {
    checked_box(item, box) {
      this.apiPutReceipt(item["public_id"], { [box]: item[box] })
    },
    open_delivery_notes(supplier_id, receipt_id) {
      this.delivery_notes_supplier_id = supplier_id
      this.delivery_notes_receipt_id = receipt_id
      this.dialog_delivery_notes = true
    },
    apiPutReceipt(receipt_id, obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then()
        .catch()
    },
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>