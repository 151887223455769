<template>
  <div>
    <v-dialog v-model="show_dialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5">Modul nicht freigeschaltet</v-card-title>
        <v-card-text>
          Das Modul "{{ current_module.text }}" ist in der aktuellen Lizenz
          nicht freigeschaltet.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="$router.back()"> zurück </v-btn>
          <v-btn color="success" :to="'/plans'"> zu den Lizenzen </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    all_modules() {
      return this.$store.state.links?.filter(
        (x) => x.module == this.route_module && (x.billed || x.free_module)
      )
    },
    current_module() {
      return (
        this.$store.state.links?.filter(
          (x) => x.module == this.route_module
        )[0] || []
      )
    },
  },
  props: {
    module_name: {},
  },
  data() {
    return {
      show_dialog: false,
      route_module: null,
    }
  },
  methods: {
    changed() {
      //     console.log(
      //   this.$store.state.links?.filter(
      //     (x) => x.module == this.route_module && (x.billed || x.free_module)
      //   )
      // )
      if ("module_name" in this.$route.meta) {
        this.route_module = this.$route.meta.module_name
        let module_l = this.$store.state.links?.filter(
          (x) => x.module == this.route_module && (x.billed || x.free_module)
        )
        this.show_dialog =
          (module_l?.length ?? 0) === 0 && this.$store.state.location_id != "*"
      } else {
        this.show_dialog = false
      }
    },
  },
  mounted() {},
  watch: {
    "$route.meta": function (search) {
      this.changed()
    },
    // all_modules: function (newQuestion, oldQuestion) {
    //   this.show_dialog = this.all_modules.length == 0 ? true : false
    // },
  },
}
</script>
