<template>
  <v-snackbar
    :color="color"
    :timeout="timer"
    v-model="showSnackbar"
    bottom
    center
  >
    <v-icon left>{{ icon }}</v-icon
    >{{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="showSnackbar = false"> X </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiCheck } from "@mdi/js"
export default {
  name: "iirToast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "success",
      icon: mdiCheck,
      timer: 2000,
    }
  },
  methods: {
    show(data) {
      data = data || {}
      this.message = data.message || 'missing "message".'
      this.color = data.color || "info"
      this.timer = data.timer || 3000
      this.icon = data.icon || this.$i.mdiCheck
      this.showSnackbar = true
    },
    success(message) {
      this.message = message || 'missing "message".'
      this.color = "success"
      this.timer = 3000
      this.icon = this.$i.mdiCheck
      this.showSnackbar = true
    },
    error(message) {
      this.message = message || 'missing "message".'
      this.color = "error"
      this.timer = 3000
      this.icon = this.$i.mdiAlertCircle
      this.showSnackbar = true
    },
    alert(message) {
      this.message = message || 'missing "message".'
      this.color = "warning darken-2"
      this.timer = 3000
      this.icon = this.$i.mdiAlertOctagonOutline
      this.showSnackbar = true
    },
  },
}
</script>
