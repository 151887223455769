var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.access_log_table,"items":_vm.access_log.access_log,"items-per-page":10,"footer-props":{
            'items-per-page-options': [10, 50, 100, -1],
            'items-per-page-text': 'Einträge pro Seite',
            'items-per-page-all-text': 'Alle',
            'page-text': '{0}-{1} von {2}',
            'show-current-page': true,
            'show-first-last-page': true,
          },"sort-by":['created'],"sort-asc":[false],"dense":"","item-key":"id","single-expand":true,"expanded":_vm.itemExpanded,"options":_vm.options,"server-items-length":_vm.access_log.total_rows},on:{"update:expanded":function($event){_vm.itemExpanded=$event},"click:row":_vm.handleClick,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.request_args)+" ")])]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Speichern")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }