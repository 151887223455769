

export function weekRange(min, max) {
  let ret = []
  min = String(min)
  max = String(max)
  let min_year = parseInt(min.substr(0,4))
  let min_week = parseInt(min.substr(5,2))
  let max_year = parseInt(max.substr(0,4))
  let max_week = parseInt(max.substr(5,2))

  for (var i=min_year; i <= max_year; i++) {
    if(i == min_year && min_year < max_year) {
      ret = ret.concat(generateWeeks(i, min_week, lastIsoWeek(min_year)))
    } else if(i == min_year && min_year == max_year) {
      ret = ret.concat(generateWeeks(i, min_week, max_week))
    } else if (i> min_year && i < max_year) {
      ret = ret.concat(generateWeeks(i, 1, lastIsoWeek(i)))
    } else {
      ret = ret.concat(generateWeeks(i, 1, max_week))
    }
    //ret = [...new Set(ret)];
    
  }
  //ret.sort()
  return ret
}
export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return {"year": d.getUTCFullYear(),  "week": weekNo};
}


function lastIsoWeek(year) 
  {
    var lastofyear = new Date(year,11,31)
     var tdt = new Date(lastofyear.valueOf());
     var dayn = (lastofyear.getDay() + 6) % 7;
     tdt.setDate(tdt.getDate() - dayn + 3);
     var firstThursday = tdt.valueOf();
     tdt.setMonth(0, 1);
     if (tdt.getDay() !== 4) 
       {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
     return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        }

const zeroPad = (num, places) => String(num).padStart(places, "0");

function generateWeeks(year, min_week, max_week) {
  var arr = [];
  
  for(var i=min_week; i <= max_week; i++) {
    arr.push(String(year) + "-" + zeroPad(i, 2))
  }

  return arr;
}