<template>
  <div>
    <v-progress-linear
      :active="loading"
      :indeterminate="indeterminate"
      absolute
      bottom
      :color="color"
      :value="value"
    ></v-progress-linear>
  </div>
</template>   

<script>
export default {
  props: {
    color: { type: String, default: "accent" },
    indeterminate: { type: Boolean, default: false },
    value: { type: Number, default: 0 },
    active: { type: Boolean, default: false },
  },
  computed: {
    loading() {
      return this.active;
    },
  },
  data() {
    return {};
  },
};
</script>