<template>
  <div>
    <!-- <v-select
      v-model="sync_value"
      :items="department_list"
      label="Abteilungen"
      multiple
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index < 2" small>
          <span>{{ item }}</span>
        </v-chip>
        <span v-if="index === 2" class="grey--text caption"
          >(+{{ sync_value.length - 2 }})</span
        >
      </template>
    </v-select> -->
    <v-list class="overflow-y-auto full-width" max-height="300" min-width="400">
      <v-list-item-group v-model="sync_value" multiple>
        <v-list-item v-for="item in department_list" :key="item" :value="item">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list-item @click.prevent="clearFilter">
      <template>
        <v-list-item-icon>
          <v-icon>{{ $i.mdiClose }}</v-icon></v-list-item-icon
        >

        <v-list-item-content>
          <v-list-item-title>löschen</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
  </div>
</template>

<script>
export default {
  computed: {
    sync_value: {
      get() {
        // return this.inner_value;
        return this.$store.state.user_departments_filter
      },
      set(val) {
        this.inner_value = val
        this.$store.dispatch("apiSetDepartmentsFilter", {
          location_id: this.$store.state.location_id,
          departments: val,
        })
      },
    },
    department_list() {
      return this.$store.state.departments_list
    },
    departments_from_usr_obj() {
      if (this.$store.state.user_obj) {
        let obj = this.$store.state.user_obj
        let c = obj.companies.find((companies) =>
          companies.locations.some(
            (item) => item.public_id == this.$store.state.location_id
          )
        )
        return c.locations.find(
          (x) => x.public_id == this.$store.state.location_id
        ).departments
      } else {
        return []
      }
    },
  },
  props: {},
  data() {
    return {
      inner_value: null,
    }
  },
  methods: {
    clearFilter() {
      this.sync_value = []
    },
  },
  created() {},
  watch: {},
}
</script>
