<template>
  <div class="ShowReceipt">
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiReceiptTextCheck }}</v-icon>
        <v-toolbar-title>Beleg bearbeiten</v-toolbar-title>
      </v-toolbar>
      <div v-if="$vuetify.breakpoint.xsOnly">
        <v-card-title>
          <v-icon> {{ $i.mdiTableLargePlus }} </v-icon>
          Beleg
        </v-card-title>
        <v-card-text>
          <!-- <v-row>
            <v-col> -->
          <!-- <iframe
            :src="receiptsrc"
            style="
              width: 100%;
              height: 100%;
              flex-grow: 1;
              border: none;
              min-height: 800px;
            "
            :v-if="receiptsrc != 'about:blank'"
          >
            Oops! an error has occurred.
          </iframe> -->
          <div class="pdf-viewer" v-if="receiptsrc !== null">
            <vue-pdf-embed :source="receiptsrc" />
          </div>
          <!-- </v-col>
          </v-row> -->
        </v-card-text>
      </div>
      <v-card-title>
        <v-icon> {{ $i.mdiDatabase }} </v-icon>
        Belegdaten
      </v-card-title>
      <v-card-text>
        <v-row v-if="receipt">
          <v-col xs="12" sm="6" md="4" lg="2">
            <v-autocomplete
              v-model="location_id"
              :items="locations"
              dense
              :prepend-icon="$i.mdiStoreMarker"
              item-text="public_id"
              item-value="public_id"
              label="Marktnummer"
              chips
              v-show="locations"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.public_id"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.address"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col xs="12" sm="6" md="4" lg="2">
            <v-select
              v-model="receipt_type"
              :prepend-icon="$i.mdiBullseyeArrow"
              :clearable="true"
              label="Belegart"
              :items="receipt_types"
              chips
              dense
            >
            </v-select>
          </v-col>
          <v-col xs="12" sm="6" md="4" lg="4">
            <v-autocomplete
              v-model="supplier"
              :items="suppliers"
              :prepend-icon="$i.mdiTruckFast"
              :append-outer-icon="$i.mdiLinkVariant"
              @click:append-outer="navigateToSupplier"
              return-object
              item-text="name"
              item-value="supplier_id"
              label="Lieferant"
              clearable
              class="shrink"
              chips
              dense
            >
              <template v-slot:no-data>
                <v-divider class="mb-2"></v-divider>
                <v-list-item>
                  <v-list-item-avatar color="grey lighten-6">
                    <v-icon> {{ $i.mdiClose }} </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Kein Lieferant gefunden
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Es wurde kein passender Lieferant gefunden
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip v-if="item.name">{{ item.name }}</v-chip>
              </template>
              <template v-slot:item="{ item }">
                <template>
                  <v-list-item-content>{{ item.name }}</v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col xs="12" sm="6" md="4" lg="2">
            <iir-date-input
              :value.sync="receipt_date"
              :prepend-icon="$i.mdiCalendar"
              hide-details
              class="shrink"
              label="Belegdatum"
              :readonly="false"
            >
            </iir-date-input>
          </v-col>
          <v-col xs="12" sm="6" md="4" lg="2">
            <v-text-field
              label="Belegnummer"
              v-model="receipt_number"
              :prepend-icon="$i.mdiCounter"
              hide-details
              class="shrink"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="receipt">
          <v-col sm="6" md="4" lg="2">
            <v-checkbox dense v-model="checked" label="geprüft"></v-checkbox>
            <v-btn
              chip
              small
              color="success"
              @click.stop="dialog_delivery_notes = !dialog_delivery_notes"
              >Lieferscheine<v-icon> {{ $i.mdiPackageVariant }} </v-icon></v-btn
            >
          </v-col>
          <v-col
            sm="6"
            md="4"
            lg="2"
            v-show="receipt.receipt_type == 'Rechnung'"
          >
            <v-checkbox dense v-model="paid" label="bezahlt"></v-checkbox
            ><v-chip @click="show_payment = true">{{
              receipt.supplier.billing_type
                ? receipt.supplier.billing_type
                : "Zahlung"
            }}</v-chip>
            <v-chip
              small
              v-show="
                !receipt.paid &&
                receipt.supplier.payment_discount &&
                receipt.receipt_date
              "
            >
              {{
                receipt.receipt_date
                  ? $gf.payment_days(
                      receipt.receipt_date,
                      receipt.supplier.payment_discount_term
                    ) + " Tage"
                  : null
              }}: {{ receipt.supplier.payment_discount }}% bis
              {{
                $gf.germanDate(
                  $gf.addDaysToDate(
                    receipt.receipt_date,
                    receipt.supplier.payment_discount_term
                  )
                )
              }}
            </v-chip>
            <v-chip
              small
              v-show="
                !receipt.paid &&
                receipt.supplier.payment_term &&
                receipt.receipt_date
              "
            >
              {{
                receipt.receipt_date
                  ? $gf.payment_days(
                      receipt.receipt_date,
                      receipt.supplier.payment_term
                    ) + " Tage"
                  : null
              }}
              netto bis
              {{
                $gf.germanDate(
                  $gf.addDaysToDate(
                    receipt.receipt_date,
                    receipt.supplier.payment_term
                  )
                )
              }}
            </v-chip>
          </v-col>

          <v-col sm="6" md="4" lg="2">
            <v-checkbox dense v-model="downloaded" label="geladen"></v-checkbox>
            <v-btn
              chip
              small
              color="success"
              v-show="!receipt.downloaded && receipt.finalized"
              @click.stop="downloadFile()"
              >Download<v-icon> {{ $i.mdiDownload }} </v-icon></v-btn
            >
            <v-chip v-show="!receipt.finalized">nicht bewertet</v-chip>
          </v-col>
          <v-col sm="6" md="4" lg="2">
            <v-checkbox
              dense
              v-model="archived"
              label="archiviert"
            ></v-checkbox>
          </v-col>
          <v-col sm="6" md="4" lg="2" v-show="receipt.pdf.pages.length > 1">
            <v-btn chip small color="secondary" @click.stop="goToSplit"
              >Dokument aufsplitten<v-icon>
                {{ $i.mdiFormatPageSplit }}
              </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-show="changed_receipt">
        <v-spacer></v-spacer>
        <v-btn color="success" v-on:click="saveReceiptData()" class="mr-3"
          >speichern<v-icon right>{{ $i.mdiContentSave }}</v-icon></v-btn
        >
        <v-btn color="primary" v-on:click="resetReceiptData()"
          >zurücksetzen<v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-if="$vuetify.breakpoint.smAndUp">
      <v-card-title>
        <v-icon> {{ $i.mdiTableLargePlus }} </v-icon>
        Bewertung<v-spacer />
        <v-tooltip
          top
          v-if="is_siteadmin && receipt?.finalized && !changed_receipt"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="d-print-none"
              rounded
              v-bind="attrs"
              v-on="on"
              @click.stop="apiResetReceipt()"
              ><v-icon> {{ $i.mdiImport }} </v-icon></v-btn
            >
          </template>
          <span>Beleg zurücksetzen, Finalisierung öffnen</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <receipt-valuation
              :disabled="changed_receipt || (receipt && receipt.finalized)"
              v-if="receipt"
              :config="receipt"
              :extended_content.sync="extended_content"
              @finalized="receiptFinalized()"
            ></receipt-valuation>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="receipt && 'content' in receipt">
      <v-card-title>
        <v-icon> {{ $i.mdiScriptTextOutline }} </v-icon>
        Inhalt
      </v-card-title>
      <v-card-text v-if="extended_content">
        <v-card-subtitle>Extended Content</v-card-subtitle>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Key</th>
                <th class="text-left">Wert</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in extended_content">
                <td>{{ item.key }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- <v-row>
          <v-col>
            <v-textarea :value="JSON.stringify(extended_content)"></v-textarea>
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-card-text v-for="content in receipt.content">
        <v-card-subtitle>{{ content.type }}</v-card-subtitle>
        <v-row>
          <v-col>
            <v-textarea :value="content.content"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-if="receipt && show_split"
      class="splitter"
      :disabled="splitter_disabled"
    >
      <v-card-title>
        <v-icon> {{ $i.mdiScriptTextOutline }} </v-icon>
        Splitter
      </v-card-title>
      <v-card-text>
        <v-container class="pa-1">
          <v-item-group v-model="selected_pages" multiple>
            <v-row v-for="(split_row, i) in split_pages">
              <!-- <v-col
                max-width="200"
                cols="auto"
                align-self="start"
                height="100%"
              >
                <v-avatar color="orange" size="62">
                  <span class="white--text text-h5">{{ i }}</span>
                </v-avatar>
              </v-col> -->
              <v-col
                v-for="page in split_row"
                :key="page.file"
                cols="auto"
                align-self="start"
                :style="`background-color: ${rgbaColors[i]}`"
              >
                <v-item v-slot="{ active, toggle }">
                  <vue-photo-zoom-pro
                    :high-url="`${$api_url}/files/invoices/${receipt.public_id}/${page.file}?${nonce}`"
                    type="circle"
                  >
                    <v-img
                      :src="`${$api_url}/files/invoices/${receipt.public_id}/${page.file}?${nonce}`"
                      :style="width_string"
                      contain
                      @click="toggle"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                      <!-- <v-btn icon dark>
                      <v-icon color="primary">
                        {{ active ? "mdi-heart" : "mdi-heart-outline" }}
                      </v-icon>
                    </v-btn> -->
                    </v-img>
                  </vue-photo-zoom-pro>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-slider
          v-model="selected_width"
          step="20"
          ticks
          tick-size="3"
          min="100"
          max="300"
          :append-icon="$i.mdiPlus"
          :prepend-icon="$i.mdiMinus"
          @click:append="() => (selected_width += 20)"
          @click:prepend="() => (selected_width -= 20)"
        ></v-slider>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-actions>
        <v-btn color="success" v-on:click="apiSplitReceipt()"
          >Splitten<v-icon right>{{ $i.mdiContentSave }}</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialog_delivery_notes"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
      width="100vh"
    >
      <receipt-delivery-notes
        v-if="supplier && supplier.public_id"
        :supplier_id="supplier.public_id"
        :receipt_id="receipt.public_id"
        @closed="dialog_delivery_notes = false"
      />
    </v-dialog>
    <receipt-payment-data
      :receipt="receipt"
      :extended_content="extended_content"
      :dialog.sync="show_payment"
      @paid="receipt.paid = true"
    />
  </div>
</template>
  
  <script>
import ReceiptValuation from "@/components/receipts/ReceiptValuation.vue"
import iirDateInput from "@/components/iirDateInput.vue"
import ReceiptDeliveryNotes from "@/components/receipts/ReceiptDeliveryNotes.vue"
import receipt_types_lib from "@/assets/receipt_types.json"
import iirChipSelect from "@/components/iirChipSelect.vue"
import ReceiptPaymentData from "@/components/receipts/ReceiptPaymentData.vue"
import VuePhotoZoomPro from "vue-photo-zoom-pro"
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css"
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed"

export default {
  name: "ShowReceipt",
  components: {
    ReceiptValuation,
    iirDateInput,
    ReceiptDeliveryNotes,
    iirChipSelect,
    ReceiptPaymentData,
    VuePhotoZoomPro,
    VuePdfEmbed,
    //   AddReceipts,
  },
  data: () => ({
    receipt: null,
    suppliers: null,
    original_receipt: null,
    changed_receipt: false,
    dialog_delivery_notes: false,
    receipt_types: receipt_types_lib,
    extended_content: null,
    show_payment: false,
    receiptsrc: null,
    selected_pages: [],
    selected_width: 200,
    rgbaColors: [
      "rgba(255, 182, 193, 0.7)", // Light Pink with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 255, 153, 0.7)", // Light Yellow with 70% transparency
      "rgba(255, 182, 193, 0.7)", // Light Pink with 70% transparency
      "rgba(221, 160, 221, 0.7)", // Light Purple with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 218, 185, 0.7)", // Peach with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(255, 160, 122, 0.7)", // Light Salmon with 70% transparency
      "rgba(255, 105, 180, 0.7)", // Light Pinkish Red with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 255, 153, 0.7)", // Light Yellow with 70% transparency
      "rgba(255, 182, 193, 0.7)", // Light Pink with 70% transparency
      "rgba(221, 160, 221, 0.7)", // Light Purple with 70% transparency
      "rgba(173, 216, 230, 0.7)", // Light Blue with 70% transparency
      "rgba(255, 218, 185, 0.7)", // Peach with 70% transparency
      "rgba(144, 238, 144, 0.7)", // Light Green with 70% transparency
      "rgba(255, 160, 122, 0.7)", // Light Salmon with 70% transparency
    ],
    show_split: false,
    splitter_disabled: false,
    nonce: 0,
  }),
  computed: {
    is_siteadmin() {
      return this.$store.state.user_obj?.is_siteadmin ?? false
    },
    width_string() {
      return `width: ${this.selected_width}px; height: ${
        this.selected_width * 1.414
      }px`
    },
    split_pages() {
      const pageNumbers = this.selected_pages
      const pages = this.receipt.pdf.pages

      let resultArray = []
      let currentPage = -1

      // pageNumbers.forEach((pageNumber) => {
      //   const pageArray = this.receipt.pdf.pages.filter(
      //     (obj) => obj.page < pageNumber && obj.page >= currentPage
      //   )
      //   resultArray.push(pageArray)
      //   currentPage = pageNumber
      // })
      for (var i = 0; i <= pages.length; i++) {
        if (pageNumbers.includes(i) || i == pages.length - 1) {
          let localArr = pages
            .filter((obj) => obj.page <= i && obj.page > currentPage)
            .map(({ file, page }) => ({ file, page }))
          resultArray.push(localArr)

          currentPage = i
        }
      }
      return resultArray
    },
    companies() {
      return this.$store.state.user_obj.companies || []
    },
    locations() {
      let ret = []
      for (const company of this.companies) {
        const { public_id: companyId, name: companyName, locations } = company

        for (const location of locations) {
          const locationData = {
            ...location,
            company_id: companyId,
            company_name: companyName,
          }

          ret.push(locationData)
        }
      }
      // console.log(ret)
      return ret
    },
    archived: {
      get() {
        return this.receipt.archived || null
      },
      set(val) {
        this.receipt.archived = val
        this.apiPutReceipt({ archived: val })
      },
    },
    paid: {
      get() {
        return this.receipt.paid || null
      },
      set(val) {
        this.receipt.paid = val
        this.apiPutReceipt({ paid: val })
      },
    },
    checked: {
      get() {
        return this.receipt.checked || null
      },
      set(val) {
        this.receipt.checked = val
        this.apiPutReceipt({ checked: val })
      },
    },
    downloaded: {
      get() {
        return this.receipt.downloaded || null
      },
      set(val) {
        this.receipt.downloaded = val
        this.apiPutReceipt({ downloaded: val })
      },
    },
    receipt_number: {
      get() {
        return this.receipt.receipt_number || null
      },
      set(val) {
        this.receipt.receipt_number = val
        this.changed_receipt =
          val != this.original_receipt.receipt_number ? true : false
      },
    },
    location_id: {
      get() {
        return this.receipt.location_id || null
      },
      set(val) {
        this.receipt.location_id = val
        this.changed_receipt =
          val != this.original_receipt.location_id ? true : false
      },
    },
    receipt_type: {
      get() {
        return this.receipt.receipt_type || null
      },
      set(val) {
        this.receipt.receipt_type = val
        this.changed_receipt =
          val != this.original_receipt.receipt_type ? true : false
      },
    },
    receipt_date: {
      get() {
        return this.receipt.receipt_date || null
      },
      set(val) {
        this.receipt.receipt_date = val
        this.changed_receipt =
          val != String(this.original_receipt.receipt_date).slice(0, 10)
            ? true
            : false
      },
    },
    supplier: {
      get() {
        return this.receipt && "supplier" in this.receipt
          ? this.receipt.supplier
          : null
      },
      set(val) {
        if (val === null) {
          this.receipt.supplier = {
            billing_type: null,
            name: null,
            public_id: null,
          }
        } else {
          let s = {
            billing_type: val.billing_type,
            name: val.name,
            public_id: val.public_id,
          }
          this.receipt.supplier = s
          this.changed_receipt =
            val.name != this.original_receipt.supplier.name ? true : false
        }
      },
    },
  },
  methods: {
    goToSplit() {
      this.show_split = !this.show_split
      this.$nextTick(() => this.$vuetify.goTo(".splitter"))
    },
    apiSplitReceipt() {
      this.splitter_disabled = true
      this.$api
        .post(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.$route.params.receipt_id +
            "/split",
          this.split_pages
        )
        .then(() => {
          this.splitter_disabled = false
          this.$router.back()
        })
        .catch()
        .then()
    },
    navigateToSupplier() {
      let params = {
        location_id: this.$route.params.location_id,
        id: this.supplier.public_id,
      }
      this.$router.push({
        name: "ShowSupplier",
        params: params,
      })
    },
    receiptFinalized() {
      this.receipt.finalized = true
      if (!this.changed_receipt) this.$router.back()
    },
    resetReceiptData() {
      this.supplier = this.original_receipt.supplier
      this.receipt_number = this.original_receipt.receipt_number
      this.receipt_date = this.original_receipt.receipt_date
      this.location_id = this.original_receipt.location_id
      this.changed_receipt = false
    },
    downloadFile() {
      // let meta = {
      //   receipt_type: this.receipt.receipt_type,
      //   receipt_date: this.receipt.receipt_date,
      //   receipt_number: this.receipt.receipt_number,
      //   name: this.receipt.supplier.name,
      // };
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.receipt.public_id +
            "/" +
            this.receipt.public_id +
            "_finalized.pdf",
          {
            responseType: "blob",
            // params: { meta: meta },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          )
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            response.headers["x-filename"] //.split("filename=")[1]
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
          this.apiPutReceipt({ downloaded: true })
          this.receipt.downloaded = true
        })
        .catch()
        .then()
    },
    apiGetReceiptPDF() {
      this.receiptsrc = "about:blank"
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.$route.params.receipt_id +
            "/" +
            this.$route.params.receipt_id +
            ".pdf",
          {
            responseType: "blob",
            // params: { meta: meta },
          }
        )
        .then((response) => {
          this.receiptsrc = "about:blank"
          const url = window.URL.createObjectURL(new Blob([response.data]))
          this.receiptsrc = url
        })
        .catch()
        .then()
    },
    saveReceiptData() {
      let obj = {}
      if (
        this.supplier &&
        this.supplier.name != this.original_receipt.supplier.name
      )
        obj["supplier_id"] = this.supplier.public_id
      if (
        (this.receipt_date &&
          this.original_receipt.receipt_date &&
          this.receipt_date !=
            this.original_receipt.receipt_date.slice(0, 10)) ||
        (this.receipt_date && this.original_receipt.receipt_date === null)
      )
        obj["receipt_date"] = this.receipt_date
      if (
        this.receipt_number &&
        this.receipt_number != this.original_receipt.receipt_number
      )
        obj["receipt_number"] = this.receipt_number
      if (
        this.receipt_type &&
        this.receipt_type != this.original_receipt.receipt_type
      )
        obj["receipt_type"] = this.receipt_type
      if (
        this.location_id &&
        this.location_id != this.original_receipt.location_id
      )
        obj["location_id"] = this.location_id

      this.apiPutReceipt(obj)
      this.original_receipt = { ...this.receipt }
      this.changed_receipt = false
    },
    apiGetReceipt(location_id, receipt_id) {
      this.$api
        .get("/receipts/" + location_id + "/" + receipt_id)
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.receipt = response.data[0]
          this.original_receipt = { ...this.receipt }
        })
        .catch()
        .finally()
      this.$api
        .get("/receipts/" + location_id + "/" + receipt_id + "/extended")
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.extended_content = response.data //JSON.stringify(response.data)
        })
        .catch()
        .finally()
    },
    apiResetReceipt() {
      this.$api
        .post(
          "receipts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.receipt_id +
            "/reset",
          {},
          { withCredentials: true }
        )
        .then((response) => {
          this.receipt.finalized = false
          if (!this.changed_receipt) this.$router.back()
        })
        .catch()
    },
    apiPutReceipt(obj) {
      this.$api
        .put(
          "receipts/" +
            this.$route.params.location_id +
            "/" +
            this.$route.params.receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then((response) => console.log(response.data))
        .catch()
    },
  },
  watch: {},
  mounted: function () {
    this.apiGetReceipt(
      this.$route.params.location_id,
      this.$route.params.receipt_id
    )
  },
  created() {
    this.suppliers = this.$store.state.suppliers || []
    if (this.$vuetify.breakpoint.xsOnly) {
      this.apiGetReceiptPDF()
    }
    this.nonce = parseInt(new Date().getTime() / 10000)
  },
}
</script>
  

<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.pdf-viewer {
  padding: 0px;
}
</style>