import Vue from 'vue'
import Vuex from 'vuex'
import departmentData from "@/assets/departments.json";
import linkData from "@/assets/links.json";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loading: false,
    location_id: null,
    logged_in: false,
    company: null,
    user: null,
    suppliers: null,
    suppliers_active_only: null,
    orders: null,
    orders_suppliers: null,
    orders_overdue: null,
    orders_departments_filter: [],
    orders_search_filter: null,
    notes: null,
    notes_count: null,
    contacts: null,
    contacts_search_filter: null,
    links: null,
    promotions_search_filter: null,
    promotions_weeks_filter: [],
    promotions_wkz_filter: false,
    promotions_show_history: false,
    promotions_add_array: [],
    suppliers_search_filter: null,
    suppliers_optional_columns: [],
    suppliers_active_filter: true,
    departments_list: departmentData,
    user_obj: null,
    user_obj_refresh: null,
    user_departments_filter: [],
    receipt_types_filter: [],
    receipt_supplier_filter: [],
    receipt_payment_types_filter: [],
    receipt_paid_filter: [],
    receipts_hide_archived: true,
    receipts_options: null,
    suppliers_options: null,
    linklist: linkData,
    billing_modules: [],
    billing_plan: null,
    receipts: null,
    receipts_no_ocr_count: null,
    receipts_total_count: null,
    receipts_suppliers: null,
    receipts_filter_query: null,
    receipts_fq_loading: false,
    allowed_locations: [],
    promotions: [],
  },
  mutations: {
    startLoading: state => state.loading = true,
    endLoading: state => state.loading = false,
    setUser(state, id) { state.user = id },
    SET_LOCATION(state, id) {
      state.location_id = id
    },
    setCompany(state, company) { state.company = null, state.company = company },
    LOGOUT(state) {
      state.location_id = null
      state.company = null
      state.user = null
      state.links = null
      state.logged_in = false
      state.user_obj = false
      state.user_obj_refresh= null
      state.allowed_locations = []

    },
    CLEAR_STATE(state) {
      state.suppliers = null
      state.suppliers_active_only = null
      state.orders = null
      state.orders_suppliers = null
      state.orders_overdue = null
      state.orders_departments_filter = []
      state.orders_search_filter = null
      state.notes = null
      state.notes_count = null
      state.contacts = null
      state.contacts_search_filter = null
      state.promotions_search_filter = null
      state.suppliers_search_filter = null
      state.suppliers_optional_columns = []
      state.suppliers_active_filter = true
      state.promotions_weeks_filter = []
      state.promotions_wkz_filter = false
      state.promotions_show_history = false
      state.promotions_add_array = []
      state.receipts_hide_archived = true
      state.receipts = null
      state.receipts_no_ocr_count = null
      state.receipts_total_count = null
      state.receipts_suppliers = null
      state.receipts_filter_query = null,
      state.receipts_fq_loading = false
      state.receipt_supplier_filter = []
      state.receipt_types_filter = []
      state.receipt_payment_types_filter = []
      state.receipt_paid_filter = []
      state.receipts_options = null
      state.suppliers_options = null
      state.allowed_locations = []
      state.billing_modules= []
      state.billing_plan = null
      state.promotions= []
    },
    ORDERS_LOAD_SUPPLIERS(state, suppliers) {
      state.orders_suppliers = null
      state.orders_suppliers = suppliers;
      if (state.orders_suppliers) {
        state.orders_overdue = state.orders_suppliers.filter((order) => order.order_overdue == true)
          .length;
      }
    },
    ORDERS_FILTER_DEPARTMENTS(state, departments) {
      state.orders_departments_filter = departments
    },
    ORDERS_FILTER_SEARCH(state, search) {
      state.orders_search_filter = search
    },
    CONTACTS_FILTER_SEARCH(state, search) {
      state.contacts_search_filter = search
    },
    PROMOTIONS_FILTER_SEARCH(state, search) {
      state.promotions_search_filter = search
    },
    PROMOTIONS_FILTER_WKZ(state, search) {
      state.promotions_wkz_filter = search
    },
    PROMOTIONS_FILTER_WEEKS_SEARCH(state, search) {
      state.promotions_weeks_filter = search
    },
    PROMOTIONS_SHOW_HISTORY(state, search) {
      state.promotions_show_history = search
    },
    PROMOTIONS_ADD_ARRAY(state, add_array) {
      state.promotions_add_array = add_array
    },
    SUPPLIERS_FILTER_SEARCH(state, search) {
      state.suppliers_search_filter = search
    },
    SUPPLIERS_OPTIONAL_COLUMNS(state, search) {
      state.suppliers_optional_columns = search
    },
    SUPPLIERS_FILTER_ACTIVE(state, search) {
      state.suppliers_active_filter = search
    },
    LOAD_SUPPLIERS(state, { suppliers, active }) {
      state.suppliers = null
      state.suppliers_active_only = active;
      state.suppliers = suppliers;
    },
    LOAD_NOTES(state, notes) {
      state.notes = null
      state.notes = notes;
      if (state.notes) {
        state.notes_count = state.notes.filter((note) => note.archived == false)
          .length;
      }
    },
    LOAD_CONTACTS(state, contacts) {
      state.contacts = null
      state.contacts = contacts;
    },
    LOAD_RECEIPTS(state, receipts) {
      //if(JSON.stringify(state.receipts) !== JSON.stringify(receipts)) {
        state.receipts = null
      state.receipts = receipts;
        state.receipts_no_ocr_count = receipts.filter((o) => o.archived === false && o.recognized === false).length
      //}
    },
    LOAD_RECEIPTS_FQ(state, receipts) {
      state.receipts = state.receipts_no_ocr_count = state.receipts_total_count = state.receipts_suppliers = null;

      state.receipts = null
      state.receipts = receipts.receipts;
      state.receipts_no_ocr_count = receipts.receipts.filter((o) => o.archived === false && o.recognized === false).length
      state.receipts_total_count = receipts.total_receipts
      state.receipts_suppliers = null
      state.receipts_suppliers = receipts.suppliers
    },
    SET_RECEIPTS_FILTER_QUERY(state, filter_query) {
      state.receipts_filter_query = filter_query
    },
    SET_USER_NOTIFICATIONS(state, notifications) {
      if (notifications && state.user_obj) {
        state.user_obj.notifications = notifications
      }
    },
    SET_USER_OBJ(state, user) {
      // console.log(user)
      if(user) {
        state.user_obj = null
        state.user_obj_refresh = null //TODO
        state.user_obj = user;
        // console.log("user", user)
        // console.log("user_obj", state.user_obj)
        state.logged_in = true
        if (state.location_id && state.user_obj) {
          // Set Departments Filter
          let c = state.user_obj.companies.find((companies) =>
            companies.locations.some(
              (item) => item.public_id == state.location_id
            )
          );
          state.user_departments_filter = c.locations.find(
            (x) => x.public_id == state.location_id
          ).departments;
        }

        // set allowed locations
        if (state.user_obj) {
          let loc = []
          let data = state.user_obj
          if (data && data.companies && Array.isArray(data.companies)) {
            data.companies.forEach((company) => {
              if (company.locations && Array.isArray(company.locations)) {
                company.locations.forEach((location) => {
                  if (location.public_id) {
                    loc.push(location.public_id);
                  }
                });
              }
            });
          }
          state.allowed_locations = loc
        }
        // this.commit('SET_LINKS')
      }
    },
    SET_DEPARTMENTS_FILTER(state, departments) {
      state.user_departments_filter = departments
    },
    SET_RECEIPT_TYPES_FILTER(state, receipt_types) {
      state.receipt_types_filter = receipt_types
    },
    SET_RECEIPT_PAYMENT_TYPES_FILTER(state, receipt_payment_types) {
      state.receipt_payment_types_filter = receipt_payment_types
    },
    SET_RECEIPT_PAID_FILTER(state, receipt_paid) {
      state.receipt_paid_filter = receipt_paid
    },
    SET_RECEIPT_SUPPLIER_FILTER(state, receipt_supplier) {
      state.receipt_supplier_filter = receipt_supplier
    },
    SET_RECEIPT_HIDE_ARCHIVED(state, hide_archived) {
      state.receipts_hide_archived = hide_archived
    },
    SET_RECEIPT_OPTIONS(state, options) {
      state.receipts_options = options
    },
    SET_SUPPLIERS_OPTIONS(state, options) {
      state.suppliers_options = options
    },
    SET_LINKS(state) {
      if (state.location_id && state.user_obj) {
        let active_location = state.user_obj.companies.map(e => e.locations.filter(l => l.public_id == state.location_id)).flat() || null
        // state.links = l
        if (active_location && active_location.length > 0) {

         state.links = state.linklist.map(function (link) {
            // Check if the current location's billing modules includes this link's module
            const isBilled = active_location
              .find((m) => m.public_id == state.location_id)
              ?.billing_modules.map((e) => e.toLowerCase())
              .includes(link.module) || link.free_module;

            // Return a new object with all properties of the original link,
            // and add the 'billed' property if isBilled is true
            return {
              ...link,
              billed: isBilled ? true : false,
            };
          });

          state.billing_modules = active_location
          .find((m) => m.public_id == state.location_id)
          .billing_modules.map((e) => {
            return e.toLowerCase();
          })
          state.billing_plan = active_location
          .find((m) => m.public_id == state.location_id)
          .billing_plan
        }
        
      }

    },
    SET_LINKS_BACKUP(state) {
      // let active_module = function (module) {
      //   return state.company.locations
      //     .find((m) => m.public_id == state.location_id)
      //     .billing_modules.map((e) => {
      //       return e.toLowerCase();
      //     })
      //     .includes(module);
      // };
      if (state.location_id && state.user_obj) {
        let active_location = state.user_obj.companies.map(e => e.locations.filter(l => l.public_id == state.location_id)).flat() || null
        // state.links = l
        if (active_location && active_location.length > 0) {
          state.links = state.linklist.filter(function (link) {
          return ("free_module" in link) || active_location
            .find((m) => m.public_id == state.location_id)
            .billing_modules.map((e) => {
              return e.toLowerCase();
            })
            .includes(link.module)
        });
        state.billing_modules = active_location
          .find((m) => m.public_id == state.location_id)
          .billing_modules.map((e) => {
            return e.toLowerCase();
          })
          state.billing_plan = active_location
          .find((m) => m.public_id == state.location_id)
          .billing_plan
        }
        
      }
      // console.log(state.user_obj)
      // console.log(state.billing_modules)

    },
    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions
    },
  },
  actions: {
    apiGetUser({ commit }) {
      commit('startLoading')
      Vue.prototype.$api
        .post("/user", {}, { withCredentials: true })
        .then((response) => commit('SET_USER_OBJ', response.data.user))
        .catch(() => { })
        .then(() => commit('SET_LINKS'))
        .finally(
          () =>
            (commit('endLoading'))
        );
    },
    apiGetUserNotifications({ commit }, ) {
      commit('startLoading')
      Vue.prototype.$api
        .get("/notifications", {}, { withCredentials: true })
        .then((response) => commit('SET_USER_NOTIFICATIONS', response.data))
        .catch(() => { })
        .finally(
          () =>
            (commit('endLoading'))
        );
    },
    apiGetCompany({ commit }, location_id) {
      commit('startLoading')
      if (location_id !== null) {
        Vue.prototype.$api
          .get("/companies/" + location_id)
          .then((response) => commit('setCompany', response.data))
          .catch(() => { })
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    apiSetDepartmentsFilter({ commit }, par) {
      commit('startLoading')
      if (par.location_id !== null) {
        commit('SET_DEPARTMENTS_FILTER', par.departments)
        Vue.prototype.$api
          .post("/user/departments", par, { withCredentials: true })
          .then((response) => commit('SET_USER_OBJ', response.data.user))
          .catch(() => { })
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    apiOrdersLoadSuppliers({ commit, state }, location_id) {
      if (state.billing_modules.includes("orders")) {
        commit('startLoading')
        Vue.prototype.$api
          .get("/orders/" + location_id + "/suppliers")
          .then((response) => commit('ORDERS_LOAD_SUPPLIERS', response.data))
          .catch(() => { })
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    apiGetSuppliers({ commit }, { location_id }) {
      if (location_id) {
        commit('startLoading')
        Vue.prototype.$api
          .get("/suppliers/" + location_id)//+ "/active")
          .then((response) => commit('LOAD_SUPPLIERS', { suppliers: response.data, active: false }))
          .catch(() => { })
          .finally(
            () => (
              commit('endLoading')
            )
          );
      }

    },
    apiGetNotes({ commit, state }, location_id) {
      if (state.billing_modules.includes("notes")) {
        Vue.prototype.$api
          .get("/notes/" + location_id)
          .then((response) => commit('LOAD_NOTES', response.data))
          .catch(() => { })
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    apiGetContacts({ commit, state }, location_id) {
      if (state.billing_modules.includes("contacts")) {
        Vue.prototype.$api
          .get("/contacts/" + location_id)
          .then((response) => commit('LOAD_CONTACTS', response.data))
          .catch()
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    apiGetReceipts({ commit, state }, location_id) {
      if (state.billing_modules.includes("receipts")) {
        Vue.prototype.$api
          .get("/receipts/" + location_id)
          .then((response) => commit('LOAD_RECEIPTS', response.data))
          .catch()
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    apiGetReceiptsFQ({ commit, state }, location_id) {
      if (state.billing_modules.includes("receipts") || location_id == "*") {
        this.state.receipts_fq_loading = true
        Vue.prototype.$api
          .post("/receipts/" + location_id + "/fq", this.state.receipts_filter_query, { withCredentials:true })
          .then((response) => commit('LOAD_RECEIPTS_FQ', response.data))
          .catch()
          .finally(
            () =>
              (commit('endLoading'), this.state.receipts_fq_loading = false)
          );
      }
    },
    apiGetPromotions({ commit, state }, location_id) {
      if (state.billing_modules.includes("promotions")) {
        this.state.receipts_fq_loading = true
        Vue.prototype.$api
          .get("/promotions/" + location_id, { withCredentials:true })
          .then((response) => commit('SET_PROMOTIONS', response.data))
          .catch()
          .finally(
            () =>
              (commit('endLoading'))
          );
      }
    },
    setReceiptsFilterQuery({ commit }, filter_query) {
      commit('SET_RECEIPTS_FILTER_QUERY', filter_query)
    },
    setLinks({ commit, dispatch }) {
      commit('SET_LINKS')
    },
    logout({ commit }) {
      commit('LOGOUT')
      commit('CLEAR_STATE')
    },
    setLocation({ commit, state, dispatch }, location_id) {
      // console.log("STATE", state.location_id)
      // console.log("location_id", location_id)
      if(state.location_id != location_id){
        commit("SET_LOCATION", location_id)
        commit("CLEAR_STATE")
      }
      // commit('SET_LINKS')
      dispatch("setLinks")
      dispatch("apiGetSuppliers", {
        location_id: location_id,
        active: false,
      })
      dispatch("apiGetNotes", location_id)
      dispatch("apiOrdersLoadSuppliers", location_id)
      dispatch("apiGetContacts", location_id)
      dispatch("apiGetUserNotifications")
    },
    setOrdersDepartmentsFilter({ commit }, departments) {
      commit("ORDERS_FILTER_DEPARTMENTS", departments)
    }

  },
  modules: {
  }
})

export default store
