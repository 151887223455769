<template>
  <div>
    <v-card
      style="height: 142px; width: 100px"
      color="#FFFFFF"
      :class="'ma-0 pa-0'"
    >
      <v-img
        :src="`${$api_url}files/invoices/${item.public_id}/0.jpg`"
        style="width: 100px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTablePreview",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>