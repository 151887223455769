<template>
  <div class="promotions">
    <v-card class="mx-auto elevation-6" v-if="initialized">
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiSale }}</v-icon>
        <v-toolbar-title>Aktionen</v-toolbar-title>
        <v-spacer />
        <title-toolbar-print />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="d-print-none"
              rounded
              v-bind="attrs"
              v-on="on"
              to="promotions/add_array"
              ><v-icon> {{ $i.mdiPlaylistPlus }} </v-icon></v-btn
            >
          </template>
          <span>Massenanlage</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <promotions-chart
          :chart-data="chartdata_new"
          :options="chartoptions"
          @on-bar-click="barclick"
          :height="200"
          :styles="{ height: '200px' }"
        ></promotions-chart>
        <v-divider class="mx-4" vertical></v-divider>
        <v-row>
          <v-col cols="12" sm="6" xs="6">
            <iir-search-input v-model="search_filter"></iir-search-input>
          </v-col>
          <!-- <v-col cols="12" sm="6" xs="6">
            <iir-chip-select
              :value.sync="filter_weeks"
              :clearable="true"
              label="Wochenfilter"
              multiple
              :readonly="!filter_weeks.length"
              dense
            ></iir-chip-select>
          </v-col> -->
          <v-col cols="12" sm="6" xs="6">
            <v-select
              v-model="filter_weeks"
              :items="weekfilter_list"
              label="Wochenfilter"
              multiple
              clearable
            >
              <template v-slot:selection="{ item }">
                <v-chip small>
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title dense v-show="false">
        <v-icon> {{ $i.mdiChartTree }} </v-icon>
        Weekly
      </v-card-title>
      <v-card-text>
        <!-- <promotions-weekly :promotions="filtered_promotions" /> -->
      </v-card-text>
      <v-card-title dense>
        <v-icon> {{ $i.mdiChartTree }} </v-icon>
        Aktionen &amp; Aufbauten
        <v-spacer></v-spacer>
        <v-switch
          v-model="show_history"
          inset
          color="success"
          label="Vergangenheit einblenden"
        ></v-switch>
      </v-card-title>
      <v-data-table
        :headers="promotions_table"
        :items="filtered_promotions"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 100, -1],
          'items-per-page-text': 'Einträge pro Seite',
          'items-per-page-all-text': 'Alle',
          'page-text': '{0}-{1} von {2}',
          'show-current-page': true,
        }"
        class="elevation-1"
        :search="search_filter"
        :sort-by="['year', 'week']"
        :sort-asc="['true']"
        item-key="public_id"
        @click:row="handleClick"
        show-group-by
        mobile-breakpoint="0"
        dense
      >
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="isOpen">{{ $i.mdiPlus }}</v-icon>
              <v-icon v-else>{{ $i.mdiPlus }}</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold">{{ group }} - {{}}</span>
          </td>
        </template>
        <template v-slot:item.user="{ item }">
          <profile-avatar :user="item.user_obj"></profile-avatar
          >{{ item.user_obj ? "" : item.user }}
        </template>
        <template v-slot:item.duplicate="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="secondary"
                rounded
                v-bind="attrs"
                v-on="on"
                @click.stop="duplicatePromotion(item)"
                ><v-icon> {{ $i.mdiPlusBoxMultiple }} </v-icon></v-btn
              >
            </template>
            <span>Aktion duplizieren</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-title>
        <v-icon> {{ $i.mdiCashRegister }} </v-icon>
        WKZ <v-spacer></v-spacer
        ><v-switch
          v-model="filter_wkz"
          inset
          color="success"
          label="Abgeschlossene WKZ einblenden"
        ></v-switch>
      </v-card-title>
      <v-data-table
        :headers="promotions_table_wkz"
        :items="filtered_promotions_wkz"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 100, -1],
          'items-per-page-text': 'Einträge pro Seite',
          'items-per-page-all-text': 'Alle',
          'page-text': '{0}-{1} von {2}',
          'show-current-page': true,
        }"
        class="elevation-1"
        :search="search_filter"
        :sort-by="['year', 'week']"
        :sort-asc="['true']"
        item-key="public_id"
        @click:row="handleClick"
        show-group-by
        mobile-breakpoint="0"
        dense
      >
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="isOpen">{{ $i.mdiPlus }}</v-icon>
              <v-icon v-else>{{ $i.mdiPlus }}</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold">{{ group }} - {{}}</span>
          </td>
        </template>
        <template v-slot:item.user="{ item }">
          <profile-avatar :user="item.user_obj"></profile-avatar
          >{{ item.user_obj ? "" : item.user }}
        </template>
        <template v-slot:item.bonus="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="success"
                v-show="item.bonus && item.bonus_paid"
                v-bind="attrs"
                v-on="on"
              >
                {{ $i.mdiCheckCircle }}
              </v-icon>
            </template>
            <span>NR erhalten / WKZ abgerechnet</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="error"
                v-show="item.bonus && !item.bonus_paid"
                v-bind="attrs"
                v-on="on"
              >
                {{ $i.mdiAlertCircle }}
              </v-icon>
            </template>
            <span>NR offen / WKZ nicht abgerechnet</span>
          </v-tooltip>
          {{ item.bonus }}
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 d-print-none"
      fab
      dark
      color="secondary"
      fixed
      right
      bottom
      to="add"
      append
    >
      <v-icon dark> {{ $i.mdiPlus }} </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { weekRange, getWeekNumber } from "@/assets/isoweek.js"
import IirSearchInput from "@/components/iirSearchInput.vue"
import ProfileAvatar from "@/components/ProfileAvatar.vue"
import PromotionsWeekly from "@/components/promotions/PromotionsWeekly.vue"
import TitleToolbarPrint from "@/components/TitleToolbarPrint.vue"

export default {
  name: "PromotionsView",
  components: {
    IirSearchInput,
    ProfileAvatar,
    PromotionsWeekly,
    PromotionsChart: () => import("@/components/PromotionsChart.vue"),
    TitleToolbarPrint,
  },
  data: () => ({
    dialog: false,
    // promotions: {},
    show_all: false,
    show_all_wkz: false,
    chartdata: {},
    chartoptions: {
      responsive: true,
      onclick: null,
      maintainAspectRatio: false,
      scales: { xAxes: { stacked: true }, yAxes: { stacked: true } },
      plugins: {
        datalabels: {
          display: true,
          anchor: "start",
          color: "white",
          align: "top",
          formatter: Math.round,
          font: {
            weight: "bold",
            color: "white",
          },
        },
      },
      legend: {
        display: false,
      },
      animation: 0,
    },
  }),
  computed: {
    promotions() {
      return this.$store.state.promotions
    },
    initialized() {
      return this.$store.state.promotions.length
    },
    search_filter: {
      get() {
        return this.$store.state.promotions_search_filter
      },
      set(value) {
        this.$store.commit("PROMOTIONS_FILTER_SEARCH", value)
      },
    },
    filter_weeks: {
      get() {
        return this.$store.state.promotions_weeks_filter
      },
      set(value) {
        this.$store.commit("PROMOTIONS_FILTER_WEEKS_SEARCH", value)
      },
    },
    filter_wkz: {
      get() {
        return this.$store.state.promotions_wkz_filter
      },
      set(value) {
        this.$store.commit("PROMOTIONS_FILTER_WKZ", value)
      },
    },
    show_history: {
      get() {
        return this.$store.state.promotions_show_history
      },
      set(value) {
        this.$store.commit("PROMOTIONS_SHOW_HISTORY", value)
      },
    },
    filtered_promotions() {
      var filtered = this.promotions
      if (!this.show_history) {
        filtered = filtered.filter(
          (d) =>
            d.year > this.current_week.year ||
            (d.year == this.current_week.year &&
              d.week >= this.current_week.week)
        )
      }
      if (this.filter_weeks.length) {
        filtered = filtered.filter((d) =>
          this.filter_weeks.includes(d.year + "-" + this.$gf.zeroPad(d.week, 2))
        )
      }
      return filtered
    },
    filtered_promotions_wkz() {
      if (this.filter_wkz) {
        return this.promotions.filter((d) => d.bonus && d.bonus != "")
      }

      return this.promotions.filter(
        (d) => d.bonus && d.bonus != "" && !d.bonus_paid
      )
    },
    current_week() {
      return getWeekNumber(new Date())
    },
    promotions_table() {
      let headers = [
        { text: "Jahr", value: "year", align: "start", sortable: true },
        { text: "Woche", value: "week", sortable: true },
        //{ text: "Name", value: "name", sortable: true, groupable: false },
        { text: "Produkt", value: "product", sortable: true, groupable: false },
        { text: "Sortimente", value: "category", sortable: true },

        {
          text: "Größe",
          value: "size_in_quarter",
          sortable: true,
          groupable: false,
        },
        //{ text: "NR", value: "bonus", sortable: true, groupable: false },
        { text: "Quelle", value: "source", sortable: true, groupable: false },
        { text: "User", value: "user", sortable: true },
      ]

      if (this.$vuetify.breakpoint.xsOnly) {
        headers = [
          {
            text: "",
            value: "duplicate",
            groupable: false,
            sortable: false,
          },
          ...headers,
        ]
      } else {
        headers = [
          ...headers,
          {
            text: "",
            value: "duplicate",
            groupable: false,
            sortable: false,
          },
        ]
      }

      return headers
    },
    promotions_table_wkz() {
      return [
        { text: "Jahr", value: "year", align: "start", sortable: true },
        { text: "Woche", value: "week", sortable: true },
        //{ text: "Name", value: "name", sortable: true, groupable: false },
        { text: "Sortimente", value: "category", sortable: true },
        { text: "Produkt", value: "product", sortable: true, groupable: false },
        { text: "Quelle", value: "source", sortable: true, groupable: false },
        { text: "WKZ", value: "bonus", sortable: true },
      ]
    },
    promotions_chart_new() {
      var arr = []
      var arr_residue = []
      var years = []
      for (const value of Object.values(this.filtered_promotions)) {
        if (years.indexOf(value.year) === -1) {
          years.push(value.year)
        }
        arr.push({
          yearweek: value.year + "-" + this.$gf.zeroPad(value.week, 2),
          year: value.year,
          week: value.week,
          size: value.size_in_quarter,
        })
        arr_residue.push({
          yearweek: value.year + "-" + this.$gf.zeroPad(value.week, 2),
          year: value.year,
          week: value.week,
          size: 0,
        })
        // for (let i = 1; i < value.duration_weeks; i++) {
        //   arr.push({
        //     yearweek: value.year + "-" + zeroPad(value.week + i, 2),
        //     year: value.year,
        //     week: value.week + i,
        //     size: 0,
        //   });
        //   arr_residue.push({
        //     yearweek: value.year + "-" + zeroPad(value.week + i, 2),
        //     year: value.year,
        //     week: value.week + i,
        //     size: value.size_in_quarter,
        //   });
        // }
      }

      arr.sort((a, b) =>
        a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
      )
      const res3 = weekRange(arr[0].yearweek, arr[arr.length - 1].yearweek).map(
        (value) => ({ yearweek: value, size: 0 })
      )
      arr = arr.concat(res3)

      const res = Array.from(
        arr.reduce(
          (m, { yearweek, size }) =>
            m.set(yearweek, (m.get(yearweek) || 0) + size),
          new Map()
        ),
        ([yearweek, size]) => ({ yearweek, size })
      )

      res.sort((a, b) =>
        a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
      )

      // const res2 = Array.from(
      //   arr_residue.reduce(
      //     (m, { yearweek, size }) =>
      //       m.set(yearweek, (m.get(yearweek) || 0) + size),
      //     new Map()
      //   ),
      //   ([yearweek, size]) => ({ yearweek, size })
      // );
      // //console.log(res2);
      // //console.log(res[0], res[res.length - 1]);

      // //console.log(res3);
      // res2.sort((a, b) =>
      //   a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
      // );

      const total = res.map((val) => ({
        //, i) => ({
        yearweek: val.yearweek,
        size: parseInt(val.size), // + parseInt(res2[i].size),
      }))

      return {
        nnew: res,
        //old: res2,
        total: total,
      }
    },
    promotions_chart_labels_new() {
      //return Object.getOwnPropertyNames(this.promotions_chart)
      let chart = this.promotions_chart_new
      //let old = chart.old.map((item) => item.yearweek);
      let nnew = chart.nnew.map((item) => item.yearweek)
      //return [...new Set([...nnew, ...old])].sort();
      return nnew.sort()
    },
    promotions_chart_values_new() {
      return this.promotions_chart.map((item) => item.size)
    },
    chartdata_new() {
      let ret = {
        labels: this.promotions_chart_labels_new,
        datasets: [
          {
            label: "1/4-Cheps",
            backgroundColor: this.$vuetify.theme.themes.dark.accent,
            type: "bar",
            data: this.promotions_chart_new.nnew.map((item) => item.size),
            fill: false,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
        ],
      }

      // {
      //   ret.datasets.push({
      //     label: "",
      //     type: "line",
      //     color: "transparent",
      //     indexLabel: "Gesamt: {y}",
      //     toolTipContent: "{y}",
      //     data: this.promotions_chart_new.nnew.map((item) => item.size),
      //     datalabels: {
      //       display: true,
      //       color: "white",
      //       labels: {
      //         title: {
      //           font: {
      //             weight: "bold",
      //           },
      //         },
      //         value: {
      //           color: "green",
      //         },
      //       },
      //       anchor: "middle",
      //       align: "end",
      //       formatter: Math.round,
      //       font: {
      //         weight: "bold",
      //       },
      //     },
      //   });
      // }
      return ret
    },
    weekfilter_list() {
      var arr = []
      for (const value of Object.values(this.promotions)) {
        arr.push(value.year + "-" + this.$gf.zeroPad(value.week, 2))
      }

      arr.sort((a, b) => (a < b ? 1 : b < a ? -1 : 0))
      return arr
      // arr = arr.concat(arr);

      // const res = Array.from(
      //   arr.reduce(
      //     (m, { yearweek, size }) =>
      //       m.set(yearweek, (m.get(yearweek) || 0) + size),
      //     new Map()
      //   ),
      //   ([yearweek, size]) => ({ yearweek, size })
      // );

      // res.sort((a, b) =>
      //   a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
      // );

      // const total = res.map((val) => ({
      //   //, i) => ({
      //   yearweek: val.yearweek,
      //   size: parseInt(val.size), // + parseInt(res2[i].size),
      // }));

      // return {
      //   nnew: res,
      //   total: total,
      // };
    },
  },
  methods: {
    barclick(data) {
      let week = this.promotions_chart_new.nnew[data.index].yearweek
      if (this.filter_weeks.includes(week)) {
        this.filter_weeks = this.filter_weeks.filter((e) => e !== week)
      } else {
        this.filter_weeks.push(week)
      }
    },
    handleClick(row) {
      // set active row and deselect others
      //this.desserts.map((item, index) => {
      //  item.selected = item === row;

      //  this.$set(this.desserts, index, item);
      //});

      // or just do something with your current clicked row item data
      //alert(row.name);
      if (event.shiftKey || event.ctrlKey) {
        let route = this.$router.resolve({
          name: "ShowPromotion",
          params: { id: row.public_id },
        })
        window.open(route.href, "_blank")
        self.focus()
      } else {
        this.$router.push({
          name: "ShowPromotion",
          params: { id: row.public_id },
        })
      }
    },
    duplicatePromotion(item) {
      this.$router.push({
        name: "ShowPromotion",
        params: { id: "duplicate", duplication_id: item.public_id },
      })
    },
    apiGetPromotions(location_id) {
      //this.$store.commit('startLoading')
      this.$store.dispatch("apiGetPromotions", location_id)
      // this.$api
      //   .get("/promotions/" + location_id)
      //   .then((response) => (this.promotions = response.data))
      //   .catch()
      //   .finally(
      //     () =>
      //       /*(this.$store.commit('endLoading')), */ (this.initialized = true)
      //   )
    },
  },
  mounted: function () {
    if (!this.promotions.length) {
      this.apiGetPromotions(this.$route.params.location_id)

      // this.promotions = promotionData
      // this.initialized = true
    }
  },
}
</script>
