<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="grey lighten-1">
            {{ $i.mdiMagnifyScan }}
          </v-icon>
        </v-btn>
      </template>
      <v-card color="#FFFFFF" :class="'ma-0 pa-0'">
        <v-img
          :src="`${$api_url}files/invoices/${item.public_id}/0.jpg`"
          style="width: 80%px; max-width: 500px"
          contain
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTablePreviewIcon",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>