<template>
  <!-- <v-container class="fill-height" fluid>
    <v-row align="center" justify="center"> -->
  <!-- <v-col cols="12"> -->
  <div class="invoice_valuation">
    <v-card class="mx-auto elevation-6">
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiTableLargePlus }}</v-icon>
        <v-toolbar-title>Rechnungsbewertung</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="clear_valuation"
              v-bind="attrs"
              v-on="on"
              v-show="files_upload"
            >
              <v-icon dark> {{ $i.mdiAutorenew }} </v-icon>
            </v-btn>
          </template>
          <span>Bewertung neu starten</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-row v-if="!upload_response">
          <v-col sm="12" md="4" lg="4">
            <invoice-valuation-no-upload />
          </v-col>
          <v-col sm="12" md="8" lg="8">
            <v-flex text-xs-center>
              <v-card
                @drop.prevent="addDropFile"
                @dragover.prevent
                @dragenter="dragenter"
                @dragleave="dragleave"
                color="#BBB"
                :class="'align-center justify-center text-center d-flex flex-column'"
                min-height="400"
              >
                <div class="dropzone d-flex" ref="dropzone">
                  <v-icon x-large class="mb2" center>{{
                    $i.mdiCloudUploadOutline
                  }}</v-icon>
                  Rechnung hier ablegen.
                  <v-card-text>
                    <v-row v-show="show_file_input">
                      <v-col>
                        <v-file-input
                          v-model="files_upload"
                          color="secondary"
                          placeholder="Belege hochladen (pdf, jpg, png)"
                          multiple
                          :prepend-icon="$i.mdiPaperclip"
                          full-width
                          accept="image/*,.pdf"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="secondary"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files_upload.length - 2 }} Dateien
                            </span>
                          </template>
                        </v-file-input>
                        <v-btn
                          color="success"
                          v-on:click="apiAddFiles"
                          center
                          class="mx-2"
                          >Upload<v-icon right>{{
                            $i.mdiFileDownload
                          }}</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row v-show="!show_file_input">
                      <v-col>
                        <div class="text-center">
                          <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
              </v-card>
            </v-flex>
          </v-col>
        </v-row>
        <v-tabs vertical centered v-model="tab_selected" value="1">
          <v-tab-item
            v-for="r in upload_response"
            v-bind:key="r.id"
            transition="fade-transition"
          >
            <invoice-valuation-tab
              :config="r"
              @finalized="finalize_invoice"
              @reopened="reopen_invoice"
            ></invoice-valuation-tab>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
  <!-- </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
import InvoiceValuationTab from "@/components/invoicevaluation/InvoiceValuationTab.vue"
import InvoiceValuationNoUpload from "@/components/invoicevaluation/InvoiceValuationNoUpload.vue"

export default {
  name: "InvoiceValuationMulti",
  components: {
    InvoiceValuationTab,
    InvoiceValuationNoUpload,
  },
  props: {
    source: String,
  },
  data: () => ({
    vat_rates: { reduced: 7, full: 19 },
    eingaben_reduced: { 0: 0 },
    eingaben_full: { 0: 0 },
    margin_reduced: 25,
    margin_full: 25,
    files_upload: null,
    upload_response: null,
    download_file: null,
    department_selected: 10,
    market_selected: 1,
    stickers: [],
    files: [],
    hoverCounter: 0,
    tab_selected: null,
    show_file_input: true,
  }),
  computed: {
    all_invoices_finalized: {
      get: function () {
        return (
          this.upload_response !== null &&
          this.upload_response.filter((a) => a.finalized == false).length == 0
        )
      },
    },
  },
  methods: {
    clear_valuation() {
      this.upload_response = null
      this.files_upload = null
      this.download_file = null
      this.show_file_input = true
      // this.tab_selected = null;
      this.stickers.length = 0
      this.files.length = 0
    },
    dragenter() {
      this.$refs.dropzone.style.backgroundColor = "#DDD"
      this.hoverCounter++
    },
    dragleave() {
      this.hoverCounter--
    },
    finalize_invoice(e) {
      // console.log(e)
      const targetIndex = this.upload_response.findIndex((a) => a.id == e.id)
      this.upload_response[targetIndex].finalized = true
      this.upload_response[targetIndex].download_file = e.filename
      this.download_invoices()
      // if (this.all_invoices_finalized) {
      //   this.tab_selected = 0
      //   this.download_all_pdf_preview("all")
      // }
    },
    reopen_invoice(e) {
      const targetIndex = this.upload_response.findIndex((a) => a.id == e.id)
      this.upload_response[targetIndex].finalized = false
      this.upload_response[targetIndex].download_file = null
    },
    generatePdf() {
      let q = []
      q = this.stickers.map((x) => ({
        ...x,
        svg: this.$refs[`sticker-${x.market}-${x.department}`][0].$el.innerHTML,
      }))
      // /invoices/<uuid>/download/
      this.$api
        .post("files/invoices/" + this.upload_response.id + "/generate", q)
        .then((response) => (this.download_file = response.data))
        .catch()
        .then(() => this.downloadPdf())
    },
    download_invoices() {
      for (let i = 0; i < this.upload_response.length; i++) {
        this.download_pdf(this.upload_response[i])
      }
      this.clear_valuation()
    },
    download_pdf(file) {
      this.$api
        .get("files/invoices/" + file.id + "/" + file.download_file, {
          responseType: "blob",
          params: { meta: file.meta },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          )
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            file.original_filename
            //response.headers["x-filename"] //.split("filename=")[1]
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch()
        .then()
    },
    download_all_pdf(a, download = true) {
      let params = this.upload_response
      this.$api
        .get("files/invoices/" + a, {
          responseType: "blob",
          params: { params },
        })
        .then((response) => {
          if (download) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute(
              "download",
              a == "all" ? "invoices.pdf" : "invoices.zip"
            )
            document.body.appendChild(link)
            link.click()
          } else {
            // console.log(this.$refs["invoice_frame"]);
            this.$refs["invoice_frame"].contentDocument.write(
              response.data.text()
            )
          }
        })
        .catch()
        .then()
    },
    download_all_pdf_preview(a) {
      this.$refs["invoice_frame"].src = "about:blank"
      let params = this.upload_response
      this.$api
        .get("files/invoices/" + a, {
          responseType: "blob",
          params: { params },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          // console.log(this.$refs["invoice_frame"]);
          this.$refs["invoice_frame"].src = url
        })
        .catch()
        .then()
    },
    addDropFile(e) {
      if (e.dataTransfer.files.length > 1) {
        this.$root.toast.error(
          "In der kostenfreien Lizenz ist nur eine Rechnung pro Upload erlaubt."
        )
        return null
      }
      if (
        e.dataTransfer.files[0].type != "application/pdf" &&
        !e.dataTransfer.files[0].type.includes("image")
      ) {
        this.$root.toast.error("Nur pdf-Dateien können hochgeladen werden.")
        return null
      }
      this.files_upload = e.dataTransfer.files
      this.apiAddFiles()
    },
    apiAddFiles() {
      if (!this.files_upload || this.files_upload.length == 0) {
        return
      }
      this.show_file_input = false
      // console.log(this.files_upload)
      //this.$store.commit('startLoading')
      //@articles.route("/<location_id>/supplier/<supplier_id>/", methods=["POST"])
      let fd = new FormData()
      if (!("length" in this.files_upload))
        this.files_upload = [this.files_upload]
      for (let i = 0; i < this.files_upload.length; i++) {
        fd.append("pdf[" + i + "]", this.files_upload[i])
      }
      // console.log(fd)

      //   fd.append("pdf[]", this.files_upload);
      //.post("files/" + this.$store.state.location_id + "/invoices/upload", fd)
      this.$api
        .post(
          "files/" + this.$store.state.location_id + "/invoices/upload_multi",
          fd
        )
        .then(
          (response) =>
            (this.upload_response = response.data.map((a) => ({
              ...a,
              finalized: false,
              download_file: null,
            })))
        )
        .catch()
        .then(
          this.$nextTick(() => {
            this.tab_selected = 0
          })
        )
    },
  },
  watch: {
    hoverCounter(val) {
      if (val === 0) {
        this.$refs.dropzone.style.backgroundColor = null
      }
    },
  },
}
</script>

<style scoped>
/*.v-carousel {
  cursor: url("~@/assets/bewertung.png") 0 0, crosshair;
}*/
.dropzone {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px dashed hsla(0, 0%, 100%, 0.7);
  border-radius: 20px;
  overflow: hidden;
  min-width: 80%;
  transition: background-color 0.2s;
}
.no-padding {
  padding: 0;
}
</style>
