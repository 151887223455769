<template>
  <v-card>
    <v-card-text>
      <v-row
        ><v-col>
          <v-data-table
            :headers="access_log_table"
            :items="access_log.access_log"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [10, 50, 100, -1],
              'items-per-page-text': 'Einträge pro Seite',
              'items-per-page-all-text': 'Alle',
              'page-text': '{0}-{1} von {2}',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
            class="elevation-1"
            :sort-by="['created']"
            :sort-asc="[false]"
            dense
            item-key="id"
            :single-expand="true"
            :expanded.sync="itemExpanded"
            @click:row="handleClick"
            :options.sync="options"
            :server-items-length="access_log.total_rows"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                {{ item.request_args }}
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" type="submit">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    access_log_table() {
      return [
        {
          text: "Nachname",
          value: "user.last_name",
          sortable: true,
          groupable: true,
        },
        {
          text: "Vorname",
          value: "user.first_name",
          groupable: false,
          sortable: false,
        },
        {
          text: "Zeit",
          value: "created",
          align: "start",
          groupable: false,
          sortable: true,
        },
        {
          text: "Pfad",
          value: "path",
          align: "start",
          groupable: false,
        },
        {
          text: "Location",
          value: "request_args.location_id",
          align: "start",
          groupable: false,
        },
        {
          text: "Method",
          value: "request_method",
          align: "start",
          groupable: false,
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          groupable: false,
        },
        {
          text: "Dauer",
          value: "total_time",
          align: "start",
          groupable: false,
        },
      ]
    },
    filter_query() {
      const filter = {}

      return {
        options: this.options,
        filter: filter,
      }
    },
  },
  data: () => ({
    access_log: [],
    itemExpanded: [],
    options: null,
  }),
  props: {
    value: {},
  },
  mounted() {
    this.apiGetAccessLog()
  },
  methods: {
    handleClick(item) {
      this.itemExpanded = item === this.itemExpanded[0] ? [] : [item]
    },
    apiGetAccessLog() {
      this.$api
        .get("admin/access_log", {
          params: {
            filter_query: JSON.stringify(this.filter_query),
          },
        })
        .then((response) => (this.access_log = response.data))
        .catch((error) => console.log(error))
    },
  },
  watch: {
    filter_query(nVal, oVal) {
      this.apiGetAccessLog()
    },
  },
}
</script>

