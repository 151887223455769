<template>
  <!-- <v-row justify="center" v-if="user"> -->
  <v-menu bottom min-width="200px" rounded offset-y v-if="user_c">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-avatar :color="user_unknown ? 'grey' : 'secondary'" size="32">
          <span class="text-button">{{ initials() }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar :color="user_unknown ? 'grey' : 'secondary'">
            <span class="text-button">{{ initials() }}</span>
          </v-avatar>
          <h3>{{ user_c.last_name }}</h3>
          <p class="text-caption mt-1">
            {{ user_c.first_name }}
          </p>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
  <!-- </v-row> -->
</template>

<script>
export default {
  props: {
    user: {},
  },
  computed: {
    user_c() {
      if (this.user) {
        return this.user
      } else {
        return { first_name: "Unbekannt", last_name: "User" }
      }
    },
    user_unknown() {
      return this.user ? false : true
    },
  },
  data() {
    return {}
  },
  methods: {
    initials: function () {
      return this.user_c.first_name[0] + this.user_c.last_name[0]
    },
  },
  watch: {},
}
</script>
