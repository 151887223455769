var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test"},[_c('v-card',{staticClass:"mx-auto elevation-6"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$i.mdiCheckCircle))]),_c('v-toolbar-title',[_vm._v("QS")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.$i.mdiMagnify,"label":"Suchen","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._l((_vm.unique_entries('store_area', _vm.audit.questions)),function(n){return _c('v-card',{key:n},[_c('v-card-title',[_vm._v(" "+_vm._s(n)+" ")]),_vm._l((_vm.unique_entries(
          'department',
          _vm.filter_array(_vm.audit.questions, 'store_area', n)
        )),function(m){return _c('v-list-item',{key:m,attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(m))]),_vm._l((_vm.unique_entries(
              'area',
              _vm.filter_array(
                _vm.filter_array(_vm.audit.questions, 'store_area', n),
                'department',
                m
              )
            )),function(x){return _c('v-list-item-subtitle',{key:x,staticClass:"mb-6"},[_vm._v(" "+_vm._s(x)+" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.qs_table,"items":_vm.filter_array(
                  _vm.filter_array(
                    _vm.filter_array(_vm.audit.questions, 'store_area', n),
                    'department',
                    m
                  ),
                  'area',
                  x
                ),"items-per-page":-1,"hide-default-footer":"","search":_vm.search,"sort-by":['year', 'week'],"sort-asc":['true'],"item-key":"public_id","dense":"","single-expand":"","show-expand":false,"item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"item.result",fn:function({ item }){return [_c('v-switch',{staticClass:"v-input--reverse",attrs:{"color":_vm.checkboxColor(item.result),"label":item.result == undefined
                      ? 'keine Angabe'
                      : item.result == false
                      ? 'n. i. O.'
                      : 'i. O.',"dense":"","disabled":_vm.disabled},model:{value:(item.result),callback:function ($$v) {_vm.$set(item, "result", $$v)},expression:"item.result"}})]}},{key:"item.note",fn:function({ item }){return [_c('v-textarea',{attrs:{"rows":"1","hide-details":"","dense":"","filled":"","placeholder":"Notiz","auto-grow":"","disabled":_vm.disabled},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})]}},{key:"expanded-item",fn:function({ headers, item, isMobile }){return [(!isMobile)?_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.hint)+" ")]):_vm._e()]}}],null,true)}),_c('v-divider')],1)})],2)],1)})],2)}),(false)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.qs_table,"items":_vm.audit.questions,"items-per-page":-1,"footer-props":{ 'items-per-page-options': [10, 50, 100, -1] },"search":_vm.search,"sort-by":['year', 'week'],"sort-asc":['true'],"item-key":"public_id","mobile-breakpoint":"0","dense":""},scopedSlots:_vm._u([{key:"item.result",fn:function({ item }){return [_c('v-label',[_vm._v("Off")]),_c('v-switch',{attrs:{"color":_vm.checkboxColor(item.result),"label":item.result == undefined
              ? 'keine Angabe'
              : item.result == false
              ? 'n. i. O.'
              : 'i. O.',"dense":"","disabled":_vm.disabled},model:{value:(item.result),callback:function ($$v) {_vm.$set(item, "result", $$v)},expression:"item.result"}})]}},{key:"item.note",fn:function({ item }){return [_c('v-textarea',{attrs:{"rows":"2","disabled":_vm.disabled},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})]}}],null,false,4188797985)}):_vm._e(),_c('v-card-actions',[(_vm.disabled)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"secondary","fab":"","bottom":"","right":"","fixed":""},on:{"click":_vm.editAudit}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$i.mdiPencil)+" ")])],1):_vm._e(),(!_vm.disabled)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","fab":"","bottom":"","right":"","fixed":""},on:{"click":_vm.saveAudit}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$i.mdiContentSave)+" ")])],1):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }