<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat dense>
            <v-toolbar-title>Logout</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            Ausgeloggt. Wieder
            <router-link to="/login">einloggen?</router-link></v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LogoutView",
  props: {
    source: String,
  },
  data: () => ({
    marktnummer: "",
    bedienernummer: "",
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout")
      this.$api
        .post("logout", {})
        .then(() =>
          this.$router.push({
            name: "Login",
            //params: { location_id: this.$route.params.location_id, supplier_id: row.supplier.supplier_id, id: row.public_id },
            //params: { supplier_id: this.$route.params.id, id: row.public_id },
          })
        )
        .catch(() => {})
    },
  },
  mounted() {
    this.logout()
  },
}
</script>

<style></style>
