<template>
  <div>
    <v-card class="mx-auto" max-width="350" light v-for="license in contracts">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            Lizenz: {{ license.plan.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            >Gültigkeit: {{ $gf.germanDate(license.valid_from)
            }}{{
              license.valid_until
                ? " - " + $gf.germanDate(license.valid_until)
                : ""
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <!-- <v-card-text>
      <v-row align="center">
        <v-col class="text-h6">
          &euro; {{ license.price_net }} / {{ license.term_days }} Tage</v-col
        >
      </v-row>
    </v-card-text> -->
      <v-list two-line flat>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiTimetable }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Laufzeit</v-list-item-title>
            <v-list-item-subtitle
              >{{ license.term_days }} Tage</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiCurrencyEur }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Preis</v-list-item-title>
            <v-list-item-subtitle
              >{{ license.price_net }} Euro /
              {{ license.term_days }} Tage</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiCalendarRefresh }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Automatische Verlängerung</v-list-item-title>
            <v-list-item-subtitle
              >um {{ license.term_days }} Tage</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox
              :input-value="license.auto_renewal"
              color="primary"
              disabled
            ></v-checkbox>
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiTimerCancel }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Kündigungsfrist für autom. Verl.</v-list-item-title
            >
            <v-list-item-subtitle
              >{{ license.termination_period }} Tag(e)</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="license.is_terminated">
          <v-list-item-icon>
            <v-icon>{{ $i.mdiCalendarRemove }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vertrag gekündigt</v-list-item-title>
            <v-list-item-subtitle
              >gekündigt am: {{ $gf.germanDate(license.termination_date) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-slider
      v-model="time"
      :max="6"
      :tick-labels="labels"
      class="mx-4"
      ticks
    ></v-slider> -->
      <v-card-title>Module</v-card-title>
      <v-card-text>
        <v-list class="transparent">
          <v-chip v-for="item in license.plan.modules" :key="item">
            <!-- <v-list-item v-for="item in license.plan.modules" :key="item"> -->
            <v-list-item-title>{{ item }}</v-list-item-title>

            <!-- <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon> -->
            <!-- </v-list-item> -->
          </v-chip>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <!-- <v-card-actions>
      <v-btn text> Full Report </v-btn>
    </v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    license_backup: {
      public_id: "71b34b70-3456-11ec-9f17-42010a840002",
      plan_id: "9529c1f6-3456-11ec-9f17-42010a840002",
      plan: {
        name: "Full",
        modules: ["InvoiceValuationOnline", "Contacts", "Receipts", "Ordering"],
      },
      company_id: "7f07a334-3456-11ec-9f17-42010a840002",
      user_id: "81348f3c-3456-11ec-9f17-42010a840002",
      valid_from: "2023-08-01T00:00:00",
      valid_until: "2024-07-31T00:00:00",
      term_days: 365,
      price_net: 1000.0,
      auto_renewal: true,
      termination_period: 30,
      termination_date: "2023-07-20T00:00:00",
      is_terminated: true,
    },
    contracts: [],
  }),
  props: {
    value: {},
    location_id: {},
  },
  methods: {
    apiGetContracts() {
      this.loading = true
      this.$api
        .get("admin/contracts/" + this.location_id)
        .then((response) => (this.contracts = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    this.apiGetContracts()
  },
}
</script>

