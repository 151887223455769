<template>
  <div>
    <v-card class="mx-auto" max-width="350">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            {{ license.plan.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            >Gültigkeit: {{ $gf.germanDate(license.valid_from)
            }}{{
              license.valid_until
                ? " - " + $gf.germanDate(license.valid_until)
                : ""
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <!-- <v-card-text>
      <v-row align="center">
        <v-col class="text-h6">
          &euro; {{ license.price_net }} / {{ license.term_days }} Tage</v-col
        >
      </v-row>
    </v-card-text> -->
      <v-list two-line flat>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiTimetable }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Laufzeit</v-list-item-title>
            <v-list-item-subtitle
              >{{ license.term_days }} Tage</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiCurrencyEur }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Preis</v-list-item-title>
            <v-list-item-subtitle
              >{{ license.price_net }} Euro /
              {{ license.term_days }} Tage</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiCalendarRefresh }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Automatische Verlängerung</v-list-item-title>
            <v-list-item-subtitle
              >um {{ license.term_days }} Tage</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox
              :input-value="license.auto_renewal"
              color="primary"
              disabled
            ></v-checkbox>
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ $i.mdiTimerCancel }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Kündigungsfrist für autom. Verl.</v-list-item-title
            >
            <v-list-item-subtitle
              >{{ license.termination_period }} Tag(e)</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="license.is_terminated">
          <v-list-item-icon>
            <v-icon>{{ $i.mdiCalendarRemove }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vertrag gekündigt</v-list-item-title>
            <v-list-item-subtitle
              >gekündigt am: {{ $gf.germanDate(license.termination_date) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-slider
      v-model="time"
      :max="6"
      :tick-labels="labels"
      class="mx-4"
      ticks
    ></v-slider> -->
      <v-card-title>Module</v-card-title>
      <v-card-text>
        <v-list class="transparent">
          <v-chip v-for="item in license.plan.modules" :key="item">
            <!-- <v-list-item v-for="item in license.plan.modules" :key="item"> -->
            <v-list-item-title>{{ item }}</v-list-item-title>

            <!-- <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon> -->
            <!-- </v-list-item> -->
          </v-chip>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <!-- <v-card-actions>
      <v-btn text> Full Report </v-btn>
    </v-card-actions> -->
    </v-card>

    <v-divider></v-divider>
    <v-row>
      <v-col v-for="license in licenses">
        <v-card rounded="xl" outlined class="mx-auto" max-width="350">
          <div :class="license.color">
            <v-card-title>{{ license.name }}</v-card-title>
            <v-card-subtitle>{{ license.description }}</v-card-subtitle>
          </div>
          <!-- <v-card-text>
      <v-row align="center">
        <v-col class="text-h6">
          &euro; {{ license.price_net }} / {{ license.term_days }} Tage</v-col
        >
      </v-row>
    </v-card-text> -->
          <v-list flat dense>
            <v-list-item v-for="mod in all_modules">
              <v-list-item-icon>
                <v-icon>{{ $i[mod.icon] }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ mod.text }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  :color="license.color + ' lighten-2'"
                  v-if="license.modules.includes(mod.module)"
                  >{{ $i.mdiCheckboxMarked }}</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-text v-if="license.price_per_month">
            <!-- <v-scroll-x-transition leave-absolute> -->
            <div class="text-center" v-if="!yearly_payment">
              <span class="text-h4">{{ license.price_per_month }}€</span>
              <span class="text-subtitle-1">/ Monat</span>
            </div>
            <!-- </v-scroll-x-transition> -->
            <!-- <v-scroll-x-reverse-transition leave-absolute> -->
            <div class="text-center" v-if="yearly_payment">
              <span class="text-h4 text-bold"
                >{{ license.price_per_year }}€</span
              >
              <span class="text-subtitle-1">/ Jahr</span>
            </div>
            <!-- </v-scroll-x-reverse-transition> -->
            <div class="text-center">
              <v-switch v-model="yearly_payment" inset color="secondary">
                <template v-slot:label>
                  <div class="text-center">
                    <div class="font-weight-normal">
                      <strong>Jährliche Zahlung</strong>
                    </div>
                    <div class="rounded blue-grey pa-1">2 Monate gratis</div>
                  </div>
                </template>
              </v-switch>
              <v-btn block color="accent" to="plans/book">buchen</v-btn>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <!-- <v-scroll-x-transition leave-absolute> -->
            <div class="text-center" v-if="!yearly_payment">
              <span class="text-h4">{{ license.price_per_month }}€</span>
              <span class="text-subtitle-1">/ Monat</span>
            </div>
            <!-- </v-scroll-x-transition> -->
            <!-- <v-scroll-x-reverse-transition leave-absolute> -->
            <div class="text-center" v-if="yearly_payment">
              <span class="text-h4">{{ license.price_per_year }}€</span>
              <span class="text-subtitle-1">/ Jahr</span>
            </div>
            <!-- </v-scroll-x-reverse-transition> -->
            <div class="text-center">dauerhaft kostenlos</div>
            <v-btn block color="secondary" to="register"
              >Jetzt registrieren</v-btn
            >
          </v-card-text>
          <!-- <v-card-actions>
      <v-btn text> Full Report </v-btn>
    </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import linkData from "@/assets/billing_modules.json"
export default {
  components: {},
  data: () => ({
    license: {
      public_id: "71b34b70-3456-11ec-9f17-42010a840002",
      plan_id: "9529c1f6-3456-11ec-9f17-42010a840002",
      plan: {
        name: "Full",
        modules: ["InvoiceValuationOnline", "Contacts", "Receipts", "Ordering"],
      },
      company_id: "7f07a334-3456-11ec-9f17-42010a840002",
      user_id: "81348f3c-3456-11ec-9f17-42010a840002",
      valid_from: "2023-08-01T00:00:00",
      valid_until: "2024-07-31T00:00:00",
      term_days: 365,
      price_net: 1000.0,
      auto_renewal: true,
      termination_period: 30,
      termination_date: "2023-07-20T00:00:00",
      is_terminated: true,
    },
    contracts: [],
    yearly_payment: false,
    licenses: [
      {
        name: "Free",
        description: "",
        color: "blue-grey",
        price_per_month: 0,
        price_per_year: 0,
        free_months_per_year: 2,
        modules: ["invoicevaluation", "suppliers", "margin"],
      },
      {
        name: "Markt",
        description: "",
        color: "deep-purple",
        price_per_month: 49,
        price_per_year: 490,
        free_months_per_year: 2,
        modules: [
          "invoicevaluation",
          "suppliers",
          "margin",
          "promotions",
          "contacts",
          "orders",
          "notes",
        ],
      },
      {
        name: "Backoffice",
        description: "",
        color: "indigo darken-2",
        price_per_month: 59,
        price_per_year: 590,
        free_months_per_year: 2,
        modules: ["invoicevaluation", "suppliers", "margin", "receipts"],
      },
      {
        name: "Pro",
        description: "",
        color: "green darken",
        price_per_month: 99,
        price_per_year: 990,
        free_months_per_year: 2,
        modules: [
          "invoicevaluation",
          "suppliers",
          "margin",
          "promotions",
          "contacts",
          "orders",
          "notes",
          "receipts",
        ],
      },
    ],
  }),
  props: {
    value: {},
    location_id: {},
  },
  computed: {
    all_modules() {
      return linkData
    },
  },
  methods: {
    apiGetContracts() {
      this.loading = true
      this.$api
        .get("admin/contracts/" + this.location_id)
        .then((response) => (this.contracts = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    // this.apiGetContracts()
  },
}
</script>

