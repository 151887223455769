<template>
  <div>
    <v-layout>
      <span :class="suspicious_date ? 'red--text' : ''">{{
        item.receipt_date ? $gf.germanDate(item.receipt_date) : ""
      }}</span>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="suspicious_date"
            v-bind="attrs"
            v-on="on"
            color="primary"
            class="ml-1"
            >{{ $i.mdiAlertCircle }}</v-icon
          ></template
        >
        <span>Belegdatum auffällig, bitte prüfen.</span>
      </v-tooltip>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTableReceiptDate",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {
    suspicious_date() {
      return (
        (this.$gf.days_until_date(this.item.receipt_date, this.item.created) <
          -90 ||
          this.$gf.days_until_date(this.item.receipt_date, this.item.created) >
            3) &&
        this.item.receipt_date !== null &&
        !this.item.archived
      )
    },
  },
  methods: {},
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>