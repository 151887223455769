<template>
  <div>
    {{ calculated_sums }}
  </div>
</template>

<script>
export default {
  name: "ReceiptPaymentChart",
  components: {},
  props: {
    receipts: { default: [] },
  },
  data: () => ({}),
  computed: {
    calculated_sums() {
      let sums = {}

      this.receipts.forEach((receipt) => {
        // Skip if the necessary fields are null
        if (
          !receipt.receipt_date ||
          (receipt.supplier.payment_discount_term == null &&
            receipt.supplier.payment_term == null)
        ) {
          return
        }

        // Calculate the effective date
        let daysToAdd
        let discountApplicable = false

        if (receipt.supplier.payment_discount_term != null) {
          daysToAdd = receipt.supplier.payment_discount_term
          discountApplicable = true
        } else {
          daysToAdd =
            receipt.supplier.payment_term != null
              ? receipt.supplier.payment_term
              : 0
        }

        let effectiveDate = new Date(receipt.receipt_date)
        effectiveDate.setDate(effectiveDate.getDate() + daysToAdd)

        // Format date to YYYY-MM-DD
        let dateString = effectiveDate.toISOString().split("T")[0]

        // Calculate discounted value_gross if applicable
        let valueGross = receipt.value_gross
        if (discountApplicable && receipt.supplier.payment_discount != null) {
          let discount = receipt.supplier.payment_discount / 100
          valueGross *= 1 - discount
        }

        // Sum the value_gross for each date
        if (!sums[dateString]) {
          sums[dateString] = 0
        }
        sums[dateString] += valueGross
      })

      // Convert the sums object to an array of objects
      return Object.keys(sums)
        .map((date) => {
          return {
            date: new Date(date),
            gross_sum: this.$gf.parseDec(sums[date], 2),
          }
        })
        .sort((a, b) => a.date - b.date)
    },
  },
  methods: {},
  watch: {},
  mounted() {},
  created() {},
}
</script>
