var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',[_c('v-simple-checkbox',{attrs:{"ripple":false,"dense":""},on:{"input":function($event){return _vm.checked_box(_vm.item, 'archived')}},model:{value:(_vm.item.archived),callback:function ($$v) {_vm.$set(_vm.item, "archived", $$v)},expression:"item.archived"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
            ((_vm.item.finalized && _vm.item.receipt_type == 'Rechnung') ||
              _vm.item.receipt_type == 'Lieferschein' ||
              _vm.item.receipt_type == 'Zahlungsavis' ||
              _vm.item.receipt_type == 'WKPS' ||
              _vm.item.receipt_type == 'THM') &&
            !_vm.item.archived
          ),expression:"\n            ((item.finalized && item.receipt_type == 'Rechnung') ||\n              item.receipt_type == 'Lieferschein' ||\n              item.receipt_type == 'Zahlungsavis' ||\n              item.receipt_type == 'WKPS' ||\n              item.receipt_type == 'THM') &&\n            !item.archived\n          "}],attrs:{"small":"","color":"secondary","rounded":"","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.apiMoveToNextcloud(_vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$i.mdiCloudUpload)+" ")])],1)]}}])},[_c('span',[_vm._v("Ins Archiv verschieben")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }