<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <!--v-if="selectedSupplier">-->
      <v-card-title>
        <span class="headline">Bestellung hinzufügen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout wrap>
            <v-row dense>
              <!-- <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Lieferant"
                  v-model="selectedSupplier.name"
                  disabled
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" class="d-flex">
                <v-autocomplete
                  v-model="supplier.supplier_id"
                  :items="suppliers"
                  :prepend-icon="$i.mdiTruckFast"
                  item-text="name"
                  item-value="public_id"
                  label="Lieferant"
                  ref="suppliers"
                  :disabled="selectedSupplier && selectedSupplier !== {}"
                >
                  <template v-slot:no-data>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item>
                      <v-list-item-avatar color="grey lighten-6">
                        <v-icon> {{ $i.mdiClose }} </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Kein Lieferant gefunden
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Es wurde kein passender Lieferant gefunden
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }} ({{ item.supplier_number }})
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content
                        >{{ item.name }} ({{
                          item.supplier_number
                        }})</v-list-item-content
                      >
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <iir-date-input
                  label="Bestelldatum"
                  :value.sync="order.order_date"
                  prepend-icon=""
                  :readonly="false"
                >
                </iir-date-input>
              </v-col>
              <!--<v-col cols="12" sm="6" md="6" class="d-flex">
                <iir-date-input
                  label="erw. Lieferdatum"
                  :value.sync="order.delivery_date"
                  prepend-icon=""
                >
                </iir-date-input>
              </v-col>-->
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <v-checkbox
                  v-model="order.blank_order"
                  label="Leerbestellung"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <iir-input
                  label="Aufschub in Tagen"
                  :value.sync="order.holding_period"
                  :rules="[rules.min(1)]"
                >
                </iir-input>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <v-chip-group active-class="secondary--text" column>
                  <v-chip
                    v-for="blanks in blank_times"
                    :key="blanks"
                    @click="set_blank(blanks)"
                  >
                    <v-avatar color="primary" left>{{ blanks }}</v-avatar> Tage
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="hide('close')">
          schließen
          <v-icon right>{{ $i.mdiCloseCircleOutline }}</v-icon>
        </v-btn>

        <v-btn color="success" @click="hide('save')">
          speichern
          <v-icon right>{{ $i.mdiContentSave }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import iirDateInput from "@/components/iirDateInput.vue"
import iirInput from "@/components/iirInput.vue"
export default {
  name: "addOrder",
  components: {
    iirInput,
    iirDateInput,
  },
  computed: {
    sync_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
    suppliers() {
      return this.$store.state.suppliers || []
    },
  },
  props: {
    value: {},
    selectedSupplier: {},
  },
  methods: {
    apiAddOrder() {
      this.loading = true
      this.$api
        .post(
          "orders/" +
            this.$route.params.location_id +
            "/" +
            this.supplier.supplier_id,
          this.order
        )
        .then((response) => (this.order = response.data), this.hide())
        .catch((error) => console.log(error))
        .then(() =>
          this.$store.dispatch(
            "apiOrdersLoadSuppliers",
            this.$route.params.location_id
          )
        )
    },
    hide: function (arg) {
      if (
        (this.order.order_date == "" && arg == "save") ||
        (arg == "save" &&
          this.order.blank_order &&
          (!this.order.holding_period || this.order.holding_period == 0))
      ) {
        alert("Keine Eingabe gemacht")
      } else if (arg == "save") {
        this.apiAddOrder()
        this.$emit("saved")
        this.sync_value = false
      } else {
        this.sync_value = false
        this.$emit("closed")
      }
    },
    clear() {
      this.order = {
        // supplier_name: "",
        // supplier_id: "",
        order_date: new Date().toISOString().slice(0, 10),
        // delivery_date: "",
        blank_order: false,
        holding_period: "",
      }
    },
    set_blank(e) {
      this.order.holding_period = e == this.order.holding_period ? null : e
      this.order.blank_order = e == this.order.holding_period ? true : false
    },
  },
  data() {
    return {
      order: null,
      rules: {
        min: (len) => (v) => (v || 0) >= len || `Mindestens ${len} Tage.`,
      },
      blank_times: [7, 14, 21, 28],
      supplier: {
        supplier_name: "",
        supplier_id: "",
      },
    }
  },
  created() {
    this.clear()
    if (this.selectedSupplier) {
      this.supplier.supplier_name = this.selectedSupplier.name
      this.supplier.supplier_id = this.selectedSupplier.public_id
    }
  },

  // watch: {
  //   selectedSupplier: {
  //     handler() {
  //       if (
  //         this.selectedSupplier.name &&
  //         (this.order.supplier_name == "" ||
  //           this.order.supplier_name != this.selectedSupplier.name)
  //       ) {
  //         this.order.supplier_name = this.selectedSupplier.name;
  //         this.order.supplier_id = this.selectedSupplier.public_id;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  // mounted: function () {
  //   if (this.order.supplier_name == "") {
  //     this.order.supplier_name = this.selectedSupplier.name;
  //     this.order.supplier_id = this.selectedSupplier.public_id;
  //   }
  // },
}
</script>

