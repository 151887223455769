var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts"},[(_vm.initialized)?_c('v-card',{staticClass:"mx-auto elevation-6"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$i.mdiAccountMultiple))]),_c('v-toolbar-title',[_vm._v("Kontakte")]),_c('title-toolbar-print')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('iir-search-input',{model:{value:(_vm.search_filter),callback:function ($$v) {_vm.search_filter=$$v},expression:"search_filter"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.contacts_table,"items":_vm.contacts,"items-per-page":50,"footer-props":{
        'items-per-page-options': [20, 50, 100, -1],
        'items-per-page-text': 'Einträge pro Seite',
        'items-per-page-all-text': 'Alle',
        'page-text': '{0}-{1} von {2}',
        'show-current-page': true,
      },"search":_vm.search_filter,"custom-filter":_vm.customSearch,"sort-by":['company', 'name'],"sort-asc":['true'],"item-key":"public_id","dense":"","mobile-breakpoint":"0"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.departments",fn:function({ item }){return _vm._l((item.departments),function(dep){return _c('v-chip',{key:dep,attrs:{"small":"","color":"secondary"}},[_vm._v(" "+_vm._s(dep)+" ")])})}},{key:"item.suppliers",fn:function({ item }){return _vm._l((item.suppliers),function(supplier){return _c('v-chip',{key:supplier.supplier_id,attrs:{"small":"","color":"secondary"}},[_vm._v(" "+_vm._s(supplier.name)+" ")])})}},{key:"item.full_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.name)+" ")]}},{key:"item.company",fn:function({ item }){return [_vm._v(" "+_vm._s(item.company.replace(/(.{30})..+/, "$1 …"))+" ")]}},{key:"item.type_company",fn:function({ item }){return [_vm._v(" "+_vm._s(item.type)+" "+_vm._s(item.company ? "(" + item.company + ")" : "")+" ")]}},{key:"item.tel",fn:function({ item }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.tel))])]}},{key:"item.fax",fn:function({ item }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.fax))])]}},{key:"item.landline",fn:function({ item }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.landline))])]}}],null,false,305122170)})],1):_vm._e(),_c('v-btn',{staticClass:"mx-2 d-print-none",attrs:{"fab":"","dark":"","color":"secondary","fixed":"","right":"","bottom":"","to":"add","append":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$i.mdiPlus)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }