<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline">Massenanlage</span>
        <v-spacer />
        <v-btn icon @click="numrows++" color="success">
          <v-icon dark> {{ $i.mdiPlus }} </v-icon>
        </v-btn>
        <v-btn icon @click="numrows > 1 ? numrows-- : null" color="error">
          <v-icon dark> {{ $i.mdiMinus }} </v-icon>
        </v-btn>
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              rounded
              v-bind="attrs"
              v-on="on"
              @click="clearPromotions"
              ><v-icon> {{ $i.mdiSyncCircle }} </v-icon></v-btn
            >
          </template>
          <span>Liste leeren</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="promotions_table"
          :items="promotions"
          mobile-breakpoint="0"
          :items-per-page="-1"
          hide-default-footer
          :single-expand="true"
          :expanded.sync="itemExpanded"
          item-key="key"
          :item-class="getRowStyle"
        >
          <template v-slot:item.year="{ item }">
            <v-chip-group active-class="primary--text" v-model="item.year">
              <v-chip v-for="year in years_selection" :key="year" :value="year">
                {{ year }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:item.week="{ item }">
            <iir-input
              :value.sync="item.week"
              clearable
              ref="week"
              :rules="[rules.isoWeekRule]"
              :hint="week_range_hint(item.year, item.week)"
            >
            </iir-input>
          </template>
          <template v-slot:item.category="{ item }">
            <iir-chip-select
              :value.sync="item.category"
              :clearable="true"
              :items="inputModel.departments_combo"
              dense
            >
            </iir-chip-select>
          </template>
          <template v-slot:item.product="{ item }">
            <iir-input
              :label="inputModel.product.label"
              :value.sync="item.product"
              :clearable="true"
            >
            </iir-input>
            <!-- <v-combobox
              :items="all_promotions"
              :label="inputModel.product.label"
              item-text="product"
              item-value="product"
              :clearable="true"
              :hint="inputModel.product.hint"
              @change="changeProduct(item)"
              :menu-props="{ closeOnClick: false }"
              :value="item.product"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.product"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.category"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox> -->
          </template>
          <template v-slot:item.size_in_quarter="{ item }">
            <v-text-field
              :label="inputModel.size_in_quarter.label"
              v-model="item.size_in_quarter"
              clearable
            >
            </v-text-field>
          </template>
          <template v-slot:item.source="{ item }">
            <v-combobox
              v-model="item.source"
              :clearable="true"
              :label="inputModel.source.label"
              :items="inputModel.source_combo"
            >
              <template v-slot:selection="{ attrs, item, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  small
                  color="secondary"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </template>
          <template v-slot:item.bonus="{ item }">
            <v-btn @click="handleClick(item)" icon
              ><v-icon>{{ $i.mdiCurrencyEur }}</v-icon></v-btn
            >
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="6" md="6">
                  <iir-input
                    :label="inputModel.bonus.label"
                    :value.sync="item.bonus"
                    :clearable="true"
                  >
                  </iir-input>
                </v-col>
                <v-col cols="2" md="2">
                  <v-checkbox v-model="item.bonus_paid">
                    <template v-slot:label>
                      <div>
                        {{ inputModel.bonus_paid.label }}
                      </div></template
                    >
                  </v-checkbox>
                </v-col>
                <v-col cols="4" md="4">
                  <v-autocomplete
                    v-model="item.supplier_id"
                    :items="suppliers"
                    :prepend-icon="$i.mdiTruckFast"
                    item-text="name"
                    item-value="public_id"
                    label="Lieferant"
                    ref="suppliers"
                  >
                    <template v-slot:no-data>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item>
                        <v-list-item-avatar color="grey lighten-6">
                          <v-icon> {{ $i.mdiClose }} </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            Kein Lieferant gefunden
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Es wurde kein passender Lieferant gefunden
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.name }} ({{ item.supplier_number }})
                    </template>
                    <template v-slot:item="{ item }">
                      <template>
                        <v-list-item-content
                          >{{ item.name }} ({{
                            item.supplier_number
                          }})</v-list-item-content
                        >
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    :label="inputModel.note.label"
                    v-model="item.note"
                    :prepend-icon="$i.mdiNote"
                    rows="2"
                    :rules="[rules.length(255)]"
                    counter="255"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="mx-2"
          @click="apiPutPromotions"
          fab
          bottom
          right
          fixed
        >
          <v-icon dark> {{ $i.mdiContentSave }} </v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import iirInput from "@/components/iirInput.vue"
import departmentData from "@/assets/departments.json"
import iirChipSelect from "@/components/iirChipSelect.vue"
import ProfileAvatar from "@/components/ProfileAvatar.vue"

export default {
  name: "AddPromotion",
  components: {
    iirInput,
    iirChipSelect,
    ProfileAvatar,
  },
  data: () => ({
    initialized: false,
    numrows: 1,
    supplier_activated: false,
    itemExpanded: [],
    disabled: true,
    //promotions: [],
    //supplier: supplierData,
    promotion: {
      key: 0,
      public_id: null,
      year: "",
      week: "",
      name: "",
      category: "",
      product: "",
      note: "",
      supplier_id: "",
      supplier: "",
      size_in_quarter: "",
      bonus: "",
      bonus_paid: false,
      source: "",
      user: "",
    },
    filter_order_active: true,
    inputModel: {
      year: {
        label: "Jahr",
        hint: "",
        tooltip: "Tooltip Belegdatum",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      week: {
        tooltip: "Tooltip Belegdatum",
        label: "Kalenderwoche",
        hint: "",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      name: {
        tooltip: "Tooltip Supplier",
        label: "Bezeichnung",
        hint: "",
      },
      departments_combo: departmentData,
      source_combo: ["HZ", "DG", "JG", "ZOW", "Sonstige"],
      category: {
        tooltip: "Tooltip Supplier",
        label: "Sortiment",
        hint: "",
      },
      product: {
        tooltip: "Tooltip Supplier",
        label: "Bezeichnung",
        hint: "Milka Kleintafeln 21-fach 85-100g",
      },
      note: {
        tooltip: "Tooltip Supplier",
        label: "Notiz",
        hint: "",
      },
      duration_weeks: {
        tooltip: "Tooltip Belegdatum",
        label: "Standdauer (Wochen)",
        hint: "",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      size_in_quarter: {
        tooltip: "Tooltip Belegdatum",
        label: "Größe in 1/4",
        hint: "Größe in 1/4 Chep, 1 EUR = 4x",
        currency: { prefix: "", suffix: "" },
        precision: 0,
      },
      bonus: {
        tooltip: "Tooltip Supplier",
        label: "Vereinbarung / NR / WKZ",
        hint: "10%, 1VE, ...",
      },
      bonus_paid: {
        tooltip: "Tooltip Supplier",
        label: "WKZ erhalten",
        hint: "Vereinbarung erfüllt/ NR erhalten/ WKZ abgerechnet",
      },
      source: {
        tooltip: "Tooltip Supplier",
        label: "Quelle",
        hint: "HZ, DG, ZOW, ...",
      },
    },
    rules: {
      length: (len) => (v) =>
        (v || "").length <= len || `Zu lang, maximal ${len} Zeichen.`,
      isoWeekRule: (value) => {
        // Convert input value to number
        const number = parseInt(value, 10)
        // Check if it's an integer and within the range 1 to 53
        if (Number.isInteger(number) && number >= 0 && number <= 53) {
          return true
        }
        return "Bitte Kalenderwoche angeben (1-53)."
      },
    },
  }),
  computed: {
    all_promotions() {
      const nameCounter = new Map()
      const newArray = this.$store.state.promotions.map(
        ({ category, product }) => {
          let counter = 1
          if (nameCounter.has(product)) {
            counter = nameCounter.get(product) + 1
          }
          nameCounter.set(product, counter)
          return { category, product, counter }
        }
      )

      // Sort the newArray by counter in descending order and then alphabetically by product
      newArray.sort((a, b) => {
        if (b.counter !== a.counter) {
          return b.counter - a.counter // Sort by counter in descending order
        } else {
          return a.product.localeCompare(b.product) // If counters are equal, sort alphabetically by product in ascending order
        }
      })

      return newArray
    },
    promotions_comp() {
      return this.promotions
    },
    promotions: {
      // getter
      get: function () {
        return this.$store.state.promotions_add_array
      },
      // setter
      set: function (newValue) {
        this.$store.state.promotions_add_array = newValue
      },
    },
    promotions_table() {
      let headers = [
        { text: "Jahr", value: "year", align: "start", sortable: true },
        { text: "Woche", value: "week", sortable: true, width: "100px" },

        {
          text: "Sortimente",
          value: "category",
          sortable: true,
          width: "100px",
        },
        {
          text: "Produkt",
          value: "product",
          sortable: true,
          groupable: false,
          width: "200px",
        },
        {
          text: "Größe",
          value: "size_in_quarter",
          sortable: true,
          groupable: false,
        },
        { text: "Quelle", value: "source", sortable: true, groupable: false },
        { text: "WKZ", value: "bonus", sortable: true, groupable: false },
      ]
      return headers
    },
    years_selection() {
      const currentYear = new Date().getFullYear()
      return [currentYear, currentYear + 1]
    },
    sizes_selection() {
      return [1, 2, 4]
    },
    suppliers() {
      return this.$store.state.suppliers || []
    },
  },
  props: {},
  methods: {
    week_range_hint(year, week) {
      const getISOWeekDates = (year, week) => {
        // Convert to integers
        let parsedYear = parseInt(year, 10)
        let parsedWeek = parseInt(week, 10)
        // Check if year and week are valid
        if (
          isNaN(parsedYear) ||
          parsedYear < 1 ||
          isNaN(parsedWeek) ||
          parsedWeek < 1 ||
          parsedWeek > 53
        ) {
          return null
        }

        let start = new Date(Date.UTC(parsedYear, 0, 4))
        start.setDate(
          start.getDate() + (parsedWeek - 1) * 7 - (start.getDay() || 7)
        )
        let end = new Date(start)
        end.setDate(end.getDate() + 6)
        let promotions_in_week =
          this.$store.state.promotions.filter(
            (p) => p.year == year && p.week == week
          ).length + "x¼: " || null

        return (
          promotions_in_week +
          this.$gf.germanDate(start.toISOString().substring(0, 10)) +
          "-" +
          this.$gf.germanDate(end.toISOString().substring(0, 10))
        )
      }
      return getISOWeekDates(year, week)
    },
    changeProduct(item, e) {
      if (e === null) {
        item.product = null
      } else {
        if (typeof e !== "object") {
          item.product = e
        } else {
          item.product = e.product
          item.category = e.category
        }
      }
    },
    getRowStyle(item) {
      const { public_id } = item

      if (public_id !== null) {
        return "success darken-1"
      } else {
        return null
      }
    },
    handleClick(item) {
      this.itemExpanded = item === this.itemExpanded[0] ? [] : [item]
    },
    clearPromotions() {
      let temp = { ...this.promotion }
      temp.key = 0
      this.promotions = [temp]
    },
    apiAddPromotion() {
      this.loading = true
      this.promotion.user = this.$store.state.user
      this.$api
        .post("promotions/" + this.$route.params.location_id, this.promotion, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.$router.back()
          } else {
            this.$root.toast.error("Nicht gespeichert!")
          }
        })
        .catch((error) => console.log(error))
        .finally((this.loading = false))
    },
    apiGetPromotion(public_id, duplicate = false) {
      this.loading = true
      //this.$store.commit('startLoading')
      this.$api
        .get("promotions/" + this.$route.params.location_id + "/" + public_id, {
          withCredentials: true,
        })
        .then((response) => {
          this.promotion = response.data[0]
          if (duplicate) {
            this.promotion.week = null
            this.promotion.bonus_paid = null
            delete this.promotion.created
            this.promotion.public_id = null
            this.promotion.user_id = null
            delete this.promotion.user_obj
          }
        })
        .catch()
        .finally(() => (this.loading = false))
    },
    apiPutPromotions() {
      const promotions = this.promotions
      for (let i = 0; i < promotions.length; i++) {
        let promotion = promotions[i]
        let key = promotion.key
        promotion.user = this.$store.state.user
        if (promotion.public_id === null) {
          this.$api
            .post("promotions/" + this.$route.params.location_id, promotion, {
              withCredentials: true,
            })
            .then((response) => {
              if (response.status == 200 || response.status == 201) {
                promotions[i] = response.data
                promotions[i].key = key
                this.promotions = JSON.parse(JSON.stringify(promotions))
                // let temp = { ...this.promotion }
                // temp.key = key
                // promotions[i] = temp
                // console.log(i, this.promotions[i])
                // this.promotions.splice(i, 1)
              } else {
                this.$root.toast.error("Nicht gespeichert!")
              }
            })
            .catch((error) => console.log(error))
        }
      }
    },
    hide: function (arg) {
      if (this.promotion.product == "" && arg == "save") {
        this.$root.toast.error("Bitte mindestens eine Bezeichnung eingeben")
      } else if (arg == "save") {
        this.apiAddPromotion()
        this.$emit("closed", this.promotion.product, this.promotion.product)
        this.sync_value = false
        this.disabled = true
      } else {
        this.sync_value = false
      }
    },
  },
  mounted: function () {
    this.promotion.year = new Date().getFullYear()
    while (this.promotions.length < this.numrows) {
      let temp = { ...this.promotion }
      temp.key = this.promotions.length
      this.promotions.push(temp)
    }
    //alert(this.$store.state.user);
  },
  watch: {
    numrows(nVal, oVal) {
      if (nVal > oVal) {
        let temp = { ...this.promotion }
        temp.key = this.promotions.length
        this.promotions.push(temp)
      } else {
        this.promotions.pop()
      }
    },
  },
}
</script>

