<template>
  <!-- <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12"> -->
  <v-card class="mx-auto elevation-6">
    <v-form @submit.prevent="clearForm" ref="marginFOrm">
      <v-card-actions>
        <v-spacer />
        <v-btn icon @click="numrows++" color="success">
          <v-icon dark> {{ $i.mdiPlus }} </v-icon>
        </v-btn>
        <v-btn icon @click="numrows > 1 ? numrows-- : null" color="error">
          <v-icon dark> {{ $i.mdiMinus }} </v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
      <v-card-text>
        <v-row
          ><v-col
            ><p class="text-center">{{ vat_rates.reduced }}%</p></v-col
          >
          <v-col
            ><p class="text-center">{{ vat_rates.full }}%</p></v-col
          ></v-row
        >
        <v-row>
          <v-col class="mx-2">
            <v-row v-for="n in numrows" v-bind:key="n">
              <iir-currency-input
                dense
                :precision="2"
                :value.sync="eingaben_reduced[n]"
                reverse
                :ref="'reduced-' + n"
                re="ref"
                :tabindex="n"
              >
              </iir-currency-input
            ></v-row>
            <v-row>
              Summe netto:
              <iir-currency-input
                dense
                disabled
                :precision="2"
                re="ref_reduced"
                ref="ref_reduced"
                reverse
              >
              </iir-currency-input
            ></v-row>
            <v-row>
              <v-slider
                v-model="margin_reduced"
                step="1"
                ticks
                max="60"
                :label="'Spanne: ' + margin_reduced + '%'"
                tabindex="-1"
              ></v-slider>
            </v-row>
            <v-row>
              VK brutto ({{ vat_rates.reduced }}%):
              <iir-currency-input
                dense
                disabled
                :precision="2"
                re="ref_vk_reduced"
                ref="ref_vk_reduced"
                reverse
              >
              </iir-currency-input
            ></v-row>
          </v-col>
          <v-col class="mx-2" outlined>
            <v-row v-for="m in numrows" v-bind:key="m">
              <iir-currency-input
                dense
                :precision="2"
                :value.sync="eingaben_full[m]"
                reverse
                :ref="`full-${m}`"
                re="ref"
                :tabindex="m + numrows"
              >
              </iir-currency-input
            ></v-row>
            <v-row>
              Summe netto:
              <iir-currency-input
                dense
                disabled
                :precision="2"
                re="ref_full"
                ref="ref_full"
                reverse
              >
              </iir-currency-input
            ></v-row>
            <v-row>
              <v-slider
                v-model="margin_full"
                step="1"
                ticks
                max="60"
                :label="'Spanne: ' + margin_full + '%'"
                tabindex="-1"
              ></v-slider>
            </v-row>
            <v-row>
              VK brutto ({{ vat_rates.full }}%):
              <iir-currency-input
                dense
                disabled
                :precision="2"
                re="ref_vk_full"
                ref="ref_vk_full"
                reverse
              >
              </iir-currency-input
            ></v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-on:click="clearForm()"
          >Löschen<v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
  <!-- </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue";
import { parse, setValue } from "vue-currency-input";
export default {
  name: "InvoiceValuation",
  components: {
    iirCurrencyInput,
  },
  props: {
    source: String,
  },
  data: () => ({
    vat_rates: { reduced: 7, full: 19 },
    eingaben_reduced: { 0: 0 },
    eingaben_full: { 0: 0 },
    margin_reduced: 25,
    margin_full: 25,
    numrows: 1,
  }),
  computed: {
    full_sum_c: function () {
      return this.summarizeObject(this.eingaben_full);
    },
    reduced_sum_c: function () {
      return this.summarizeObject(this.eingaben_reduced);
    },
  },
  methods: {
    clearForm() {
      this.eingaben_reduced = { 0: 0 };
      this.eingaben_full = { 0: 0 };
      //console.log(this.$refs);
      //setValue(this.$refs["reduced1"][0].$refs["ref"], "");
      for (let i = 1; i <= this.numrows; i++) {
        setValue(this.$refs["reduced-" + i][0].$refs["ref"], null);
        setValue(this.$refs["full-" + i][0].$refs["ref"], null);
        //console.log(i);
      }
    },
    summarizeObject(val) {
      return Object.values(val).reduce((a, b) => a + this.parseNumber(b));
    },
    parseNumber(val) {
      return parse(val, {
        suffix: "",
        precision: "",
        locale: "DE",
        currency: "EUR",
      });
    },
    calculateGross(net, vat, margin) {
      return (net / (1 - margin / 100)) * (1 + vat / 100);
    },
    setSums() {
      setValue(this.$refs.ref_full.$refs.ref_full, this.full_sum_c);
      setValue(
        this.$refs.ref_vk_full.$refs.ref_vk_full,
        this.calculateGross(
          this.full_sum_c,
          this.vat_rates.full,
          this.margin_full
        )
      );
      setValue(this.$refs.ref_reduced.$refs.ref_reduced, this.reduced_sum_c);
      setValue(
        this.$refs.ref_vk_reduced.$refs.ref_vk_reduced,
        this.calculateGross(
          this.reduced_sum_c,
          this.vat_rates.reduced,
          this.margin_reduced
        )
      );
    },
  },
  watch: {
    full_sum_c: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
    reduced_sum_c: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
    margin_full: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
    margin_reduced: {
      handler: function () {
        this.setSums();
      },
      deep: true,
    },
  },
};
</script>

<style></style>