<template>
  <v-data-table
    :headers="users_table"
    :items="company_authorization"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [20, 50, 100, -1],
      'items-per-page-text': 'Einträge pro Seite',
      'items-per-page-all-text': 'Alle',
      'page-text': '{0}-{1} von {2}',
      'show-current-page': true,
    }"
    class="elevation-1"
    :sort-by="['last_name']"
    :sort-asc="['true']"
    item-key="user_id"
    :single-expand="true"
    :expanded.sync="itemExpanded"
    @click:row="handleClick"
    dense
    v-if="company_authorization"
    :item-class="getRowStyle"
  >
    <!-- <template v-slot:item.active_relation="{ item }">
          <v-simple-checkbox
            v-model="item.active_relation"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.departments="{ item }">
          <v-chip
            small
            v-for="dep in item.departments"
            :key="dep"
            color="secondary"
          >
            {{ dep }}
          </v-chip>
        </template>
        <template v-slot:item.categories="{ item }">
          <v-chip
            small
            v-for="cat in item.categories"
            :key="cat"
            color="secondary"
          >
            {{ cat }}
          </v-chip>
        </template>
        <template v-slot:item.electronic_invoicing="{ item }">
          <v-simple-checkbox
            v-model="item.electronic_invoicing"
            disabled
            class="text-center"
          ></v-simple-checkbox>
        </template> -->
    <template v-slot:item.locations="{ item }">
      <v-chip
        v-for="location in item.locations"
        :key="location.location_id"
        :value="location.location_id"
        small
        :class="getRowStyle(item)"
      >
        {{ location.location_id }}
      </v-chip>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        {{ item.locations }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  computed: {
    sync_value: {
      get() {
        return this.company
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
    users_table() {
      return [
        {
          text: "Nachname",
          value: "last_name",
          sortable: true,
          groupable: true,
        },
        {
          text: "Vorname",
          value: "first_name",
          groupable: false,
          sortable: false,
        },
        {
          text: "Standorte",
          value: "locations",
          align: "start",
          groupable: false,
        },
      ]
    },
  },
  data: () => ({
    company_authorization: null,
    itemExpanded: [],
  }),
  props: {
    company: {},
  },
  methods: {
    apiGetCompanyAuth() {
      this.loading = true
      this.$api
        .get("admin/company/" + this.company.public_id + "/users")
        .then((response) => (this.company_authorization = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false))
    },
    handleClick(item) {
      this.itemExpanded = item === this.itemExpanded[0] ? [] : [item]
    },
    getRowStyle(item) {
      const { is_blocked } = item

      if (is_blocked) {
        return "text-decoration-line-through font-weight-thin"
      } else {
        return null
      }
    },
  },
  mounted() {
    this.apiGetCompanyAuth()
  },
}
</script>

