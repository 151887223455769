<template>
  <v-card class="mx-auto">
    <v-card-title> {{ article.marke }} - {{ article.name }} </v-card-title>
    <v-card-subtitle>{{ article.menge }} - {{ article.nan }}</v-card-subtitle>
    <v-card-text class="pa-0 text-center justify-center">
      <v-row align="center" justify="center">
        <v-col align="center">
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            scrollable
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-card
                max-width="300"
                max-height="330"
                color="#FFFFFF"
                :class="'ma-0 pa-0 justify-center'"
                v-bind="attrs"
                v-on="on"
                v-cloak
                @drop.prevent="addDropFile"
                @dragover.prevent
              >
                <v-img
                  :src="img_url(article.nan, 400) + img_query"
                  contain
                  max-height="300"
                >
                  <template v-slot:placeholder>
                    <v-row class="ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <vue-barcode
                  :value="zeroPad(article.nan, 7)"
                  :height="25"
                  :width="2.5"
                  font-size="15"
                  font-options="bold"
                  :margin-right="0"
                  :margin="2"
                  :display-value="false"
                  :flat="true"
                ></vue-barcode>
              </v-card>
            </template>
            <v-card
              color="#FFFFFF"
              :class="'ma-0 pa-0 justify-center'"
              max-width="800"
              max-height="800"
              @click="dialog = false"
            >
              <v-img
                :src="img_url(article.nan, 800) + img_query"
                contain
                max-height="800"
              >
                <template v-slot:placeholder>
                  <v-row class="ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-btn
                class="mt-10 d-print-none"
                fab
                dark
                small
                top
                right
                absolute
                color="primary"
                @click="dialog = false"
              >
                <v-icon dark small> {{ $i.mdiClose }} </v-icon>
              </v-btn>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-list dense class="elevation-1">
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in wgr_table"
                :key="i"
                class="elevation-1"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content
                  @click="show_revenue = !show_revenue"
                  color="secondary"
                >
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="show_revenue = !show_revenue" color="secondary">
        {{ show_revenue ? "verkleinern" : "Umsatz" }}
        <v-icon right>{{ $i.mdiCreditcardClockOutline }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="show_revenue"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-card-text class="pa-0">
          <v-data-table
            :headers="revenue_table"
            :items="calc_revenue_table"
            :sort-by="['yearweek']"
            :sort-desc="[true]"
            mobile-breakpoint="0"
            hide-default-footer
            class="elevation-1"
            :items-per-page="-1"
            dense
            :height="
              calc_revenue_table && calc_revenue_table > revenue_rows
                ? 48 * revenue_rows - 1
                : undefined
            "
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import VueBarcode from "@/components/iirBarcode.js"
import { weekRange, getWeekNumber } from "@/assets/isoweek.js"

export default {
  name: "iirArticleInfo",
  components: {
    VueBarcode,
  },
  props: {
    nan: { default: null },
  },
  data: () => ({
    img: "https://img.rewe-bg.de/7223891/27491349_digital-image.png",
    article: [],
    revenue_rows: 5,
    show_revenue: false,
    dialog: false,
    image_upload: null,
    img_query: "",
  }),
  computed: {
    revenue_table() {
      return [
        { text: "KW", value: "yearweek", sortable: true },
        { text: "Umsatz", value: "umsatz", sortable: false },
        { text: "Menge", value: "menge", sortable: false },
      ]
    },
    is_admin() {
      return this.$store.state.user_obj.is_siteadmin
    },
    wgr_table() {
      return [
        { icon: this.$i.mdiCurrencyEur, value: this.calc_revenue + " / W" },
        { icon: this.$i.mdiDiameterVariant, value: this.calc_sales + " / W" },
        { icon: this.$i.mdiNumeric1Box, value: this.article.fokussortiment },
        { icon: this.$i.mdiNumeric2Box, value: this.article.fokusgruppe },
        { icon: this.$i.mdiNumeric3Box, value: this.article.wgr_4 },
      ]
    },
    current_week() {
      const zeroPad = (num, places) => String(num).padStart(places, "0")
      let wk = getWeekNumber(new Date())
      return wk.year + "-" + zeroPad(wk.week, 2)
    },
    calc_revenue_table() {
      //const zeroPad = (num, places) => String(num).padStart(places, "0");
      if (this.article && this.article.revenue) {
        let rev = this.article.revenue
        //console.log(this.current_week);
        if (!JSON.stringify(rev).includes(this.current_week)) {
          rev.push({
            yearweek: this.current_week,
            menge: Number(0).toFixed(4),
            umsatz: Number(0).toFixed(2),
          })
        }
        rev.sort((a, b) =>
          a.yearweek > b.yearweek ? 1 : b.yearweek > a.yearweek ? -1 : 0
        )
        const rev2 = weekRange(
          rev[0].yearweek,
          rev[rev.length - 1].yearweek
        ).map((value) => ({
          yearweek: value,
          menge: Number(0),
          umsatz: Number(0),
        }))
        rev = rev.concat(rev2)
        const mengen = Array.from(
          rev.reduce(
            (m, { yearweek, menge }) =>
              m.set(
                yearweek,
                Number(Number(m.get(yearweek) || 0) + Number(menge)).toFixed(2)
              ),
            new Map()
          ),
          ([yearweek, menge]) => ({ yearweek, menge })
        )
        const umsaetze = Array.from(
          rev.reduce(
            (m, { yearweek, umsatz }) =>
              m.set(
                yearweek,
                Number(Number(m.get(yearweek) || 0) + Number(umsatz)).toFixed(2)
              ),
            new Map()
          ),
          ([yearweek, umsatz]) => ({ yearweek, umsatz })
        )
        let res = umsaetze.map((item, i) => Object.assign({}, item, mengen[i]))
        //console.log(arr3);
        res.sort((a, b) =>
          a.yearweek < b.yearweek ? 1 : b.yearweek < a.yearweek ? -1 : 0
        )
        return res
      } else {
        return []
      }
    },
    calc_revenue() {
      return this.article && this.article.revenue
        ? (
            this.calc_revenue_table
              .slice(1, 8)
              .map((item) => parseFloat(item.umsatz))
              .reduce((prev, curr) => prev + curr, 0) / 8
          ).toFixed(2)
        : 0
    },
    calc_sales() {
      return this.article && this.article.revenue
        ? (
            this.calc_revenue_table
              .slice(1, 8)
              .map((item) => parseFloat(item.menge))
              .reduce((prev, curr) => prev + curr, 0) / 8
          ).toFixed(2)
        : 0
    },
  },
  methods: {
    addDropFile(e) {
      this.image_upload = e.dataTransfer.files[0]
      //console.log(this.image_upload);
      this.apiAddImage()
    },
    apiAddImage() {
      //this.$store.commit('startLoading')
      //@articles.route("/<location_id>/supplier/<supplier_id>/", methods=["POST"])
      let fd = new FormData()
      fd.append("img", this.image_upload)

      this.$api
        .post(
          "articles/" +
            this.$route.params.location_id +
            "/upload/" +
            this.article.nan,
          fd
        )
        .then()
        .catch()
        .then(
          () =>
            (this.img_query =
              "?q=" + (Math.random() + 1).toString(36).substring(7))
        )
    },
    img_url(local_nan, size = 400) {
      const zeroPad = (num, places) => String(num).padStart(places, "0")
      let nan = zeroPad(local_nan, 7)
      return (
        "https://img.rewe-bg.de/eweb/" +
        nan[0] +
        "/" +
        nan[0] +
        nan[1] +
        "/" +
        nan +
        "/" +
        nan +
        "-" +
        size +
        ".jpg"
      )
      //return "https://img.rewe-bg.de/eweb/${nan}-400.jpg"
    },
    apiGetArticle(location_id, nan) {
      //this.$store.commit('startLoading')
      //console.log(filteredData);
      this.article = {}
      this.$api
        .get("/articles/" + location_id + "/revenue/" + nan)
        //.then((response) => (filteredData = response.data))
        .then((response) => {
          this.article = response.data
        })
        .catch()
        .finally()
    },
    getRevenue(nan) {
      let rev = this.revenue.filter((x) => x.nan == nan)
      if (rev.length) {
        return rev[0].revenue
      } else {
        return []
      }
    },
    zeroPad(num, places) {
      return String(num).padStart(places, "0")
    },
  },
  watch: {
    nan: function () {
      this.apiGetArticle(this.$route.params.location_id, this.nan)
    },
  },
  mounted: function () {
    this.apiGetArticle(this.$route.params.location_id, this.nan)
  },
}
</script>
