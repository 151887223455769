<template>
  <v-card class="mx-auto elevation-1" flat>
    <v-toolbar color="secondary" dark flat dense>
      <!-- <v-progress-circular
        indeterminate
        color="primary"
        v-if="!ocr_finished"
      ></v-progress-circular>
      <v-autocomplete
        v-model="invoice.meta.name"
        :items="suppliers"
        :prepend-icon="$i.mdiTruckFast"
        item-text="name"
        item-value="name"
        label="Name"
        single-line
        hide-details
        clearable
        class="shrink"
        v-if="ocr_finished"
        @change="set_margin"
      >
        <template v-slot:no-data>
          <v-divider class="mb-2"></v-divider>
          <v-list-item>
            <v-list-item-avatar color="grey lighten-6">
              <v-icon> {{ $i.mdiClose }} </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> Kein Lieferant gefunden </v-list-item-title>
              <v-list-item-subtitle>
                Es wurde kein passender Lieferant gefunden
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item="{ item }">
          <template>
            <v-list-item-content>{{ item.name }}</v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <iir-date-input
        :value.sync="invoice.meta.receipt_date"
        :prepend-icon="$i.mdiCalendar"
        hide-details
        v-show="ocr_finished"
        single-line
        class="shrink"
        label="Belegdatum"
      >
      </iir-date-input>
      <v-text-field
        label="Belegnummer"
        v-model="invoice.meta.receipt_number"
        :prepend-icon="$i.mdiCounter"
        hide-details
        single-line
        v-if="ocr_finished"
        class="shrink"
      >
      </v-text-field> -->
      {{ config.original_filename }}
    </v-toolbar>
    <v-form @submit.prevent="clearForm" ref="marginFOrm">
      <v-card-text>
        <v-row class="fill-height">
          <v-col sm="12" md="4" lg="4">
            <v-row>
              <v-card-actions>
                <v-spacer />
                <v-btn icon @click="numrows++" color="success">
                  <v-icon dark> {{ $i.mdiPlus }} </v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="numrows > 1 ? numrows-- : null"
                  color="error"
                >
                  <v-icon dark> {{ $i.mdiMinus }} </v-icon>
                </v-btn>
                <v-spacer />
                <v-checkbox
                  v-model="net_as_gross"
                  label="Netto als Brutto"
                  @click="setSums"
                ></v-checkbox>
              </v-card-actions>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-row class="text-center">
                  <v-col
                    ><p class="text-center">{{ vat_rates.reduced }}%</p></v-col
                  >
                </v-row>
                <v-row v-for="n in numrows" v-bind:key="n" class="mx-1">
                  <iir-currency-input
                    dense
                    :precision="2"
                    :value.sync="eingaben_reduced[n]"
                    reverse
                    :ref="'reduced-' + n"
                    re="ref"
                    :tabindex="n"
                    :disabled="invoice.finalized"
                  >
                  </iir-currency-input
                ></v-row>
                <v-row class="mx-1">
                  Summe netto:
                  <iir-currency-input
                    dense
                    disabled
                    :precision="2"
                    re="ref_reduced"
                    ref="ref_reduced"
                    reverse
                  >
                  </iir-currency-input
                ></v-row>
                <v-row class="mx-1">
                  <v-slider
                    v-model="margin_reduced"
                    step="1"
                    ticks
                    max="60"
                    :label="'Spanne: ' + margin_reduced + '%'"
                    tabindex="-1"
                    :disabled="invoice.finalized"
                  ></v-slider>
                </v-row>
                <v-row class="mx-1">
                  VK brutto ({{ vat_rates.reduced }}%):
                  <iir-currency-input
                    dense
                    disabled
                    :precision="2"
                    re="ref_vk_reduced"
                    ref="ref_vk_reduced"
                    reverse
                  >
                  </iir-currency-input
                ></v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col>
                    <p class="text-center">{{ vat_rates.full }}%</p>
                  </v-col>
                </v-row>
                <v-row v-for="m in numrows" v-bind:key="m" class="mx-1">
                  <iir-currency-input
                    dense
                    :precision="2"
                    :value.sync="eingaben_full[m]"
                    reverse
                    :ref="`full-${m}`"
                    re="ref"
                    :tabindex="m + numrows"
                    :disabled="invoice.finalized"
                  >
                  </iir-currency-input
                ></v-row>
                <v-row class="mx-1">
                  Summe netto:
                  <iir-currency-input
                    dense
                    disabled
                    :precision="2"
                    re="ref_full"
                    ref="ref_full"
                    reverse
                  >
                  </iir-currency-input
                ></v-row>
                <v-row class="mx-1">
                  <v-slider
                    v-model="margin_full"
                    step="1"
                    ticks
                    max="60"
                    :label="'Spanne: ' + margin_full + '%'"
                    tabindex="-1"
                    :disabled="invoice.finalized"
                  ></v-slider>
                </v-row>
                <v-row class="mx-1">
                  VK brutto ({{ vat_rates.full }}%):
                  <iir-currency-input
                    dense
                    disabled
                    :precision="2"
                    re="ref_vk_full"
                    ref="ref_vk_full"
                    reverse
                  >
                  </iir-currency-input
                ></v-row>
              </v-col>
            </v-row>
            <v-row class="mx-1">
              <v-chip-group
                v-model="market_selected"
                active-class="secondary--text"
                column
              >
                <v-chip
                  v-for="market in markets"
                  :key="market.id"
                  :value="market.id"
                >
                  <v-avatar color="primary" left>{{ market.id }}</v-avatar>
                  {{ market.name }}
                </v-chip>
              </v-chip-group>
            </v-row>
            <v-row class="mx-1">
              <v-chip-group
                v-model="department_selected"
                active-class="secondary--text"
                column
              >
                <v-chip
                  v-for="department in departments"
                  :key="department.id"
                  :value="department.id"
                >
                  <v-avatar color="primary" left>{{ department.id }}</v-avatar>
                  {{ department.name }}
                </v-chip>
              </v-chip-group>
              <v-chip @click="dialog_add_department = !dialog_add_department">
                <v-avatar color="success" left>+</v-avatar>
                bearbeiten
              </v-chip>
              <v-dialog v-model="dialog_add_department" max-width="500px">
                <v-card>
                  <v-form @submit.prevent="dialog_add_department = false">
                    <v-card-title>
                      <span class="headline">Abteilung hinzufügen</span>
                    </v-card-title>
                    <v-card-text>
                      <!-- <v-container>
                        <v-layout wrap>
                          <v-row>
                            <v-col cols="12" class="d-flex"> -->
                      <v-autocomplete
                        v-model="departments_shown"
                        :items="departments_all"
                        :prepend-icon="$i.mdiNumeric1Box"
                        item-text="name"
                        item-value="id"
                        label="Abteilung"
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item>
                            <v-list-item-avatar color="grey lighten-6">
                              <v-icon> {{ $i.mdiClose }} </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                Nicht gefunden
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Es wurde keine passende Abteilung gefunden
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <template v-slot:item="{ item }">
                          <v-list-item-avatar color="grey lighten-6" dense>
                            {{ item.id }}
                          </v-list-item-avatar>
                          <v-list-item-content dense>{{
                            item.name
                          }}</v-list-item-content>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-chip small>
                            <span>{{ item.id }}</span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                      <!-- </v-col>
                          </v-row>
                        </v-layout>
                      </v-container> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        @click="dialog_add_department = false"
                      >
                        schließen
                        <v-icon right>{{ $i.mdiCloseCircleOutline }}</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>
            <v-row class="mx-1">
              <v-btn
                color="primary"
                v-on:click="addSticker()"
                :disabled="invoice.finalized"
                >Bewertung hinzufügen<v-icon right>{{
                  $i.mdiPlus
                }}</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                v-on:click="clearForm()"
                :disabled="invoice.finalized"
                >Leeren<v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
              >
            </v-row>
            <v-row class="mx-1 d-flex">
              <v-card class="elevation-0" width="100%">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    v-on:click="generatePdf()"
                    v-show="!invoice.finalized"
                    >Download<v-icon right>{{
                      $i.mdiContentSave
                    }}</v-icon></v-btn
                  >
                  <v-btn
                    color="success"
                    v-on:click="reopenPdf()"
                    v-show="invoice.finalized"
                    >Bearbeiten<v-icon right>{{ $i.mdiPencil }}</v-icon></v-btn
                  ><v-spacer></v-spacer>
                </v-card-actions>
                <v-list flat v-if="stickers.length" two-line>
                  <v-subheader>Bewertungen</v-subheader>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in stickers"
                      :key="i + item.market + item.department"
                    >
                      <v-list-item-avatar>
                        <v-avatar color="primary" left>
                          {{ item.department }}
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ markets.find((a) => a.id == item.market).name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip>
                            <v-avatar color="primary" left>
                              {{ vat_rates.reduced }}%
                            </v-avatar>
                            {{ item.vk_reduced }}
                          </v-chip>
                          <v-chip>
                            <v-avatar color="primary" left>
                              {{ vat_rates.full }}%
                            </v-avatar>
                            {{ item.vk_full }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon :disabled="invoice.finalized">
                          <v-icon @click="remove_sticker(item)">{{
                            $i.mdiCloseCircle
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-row>
          </v-col>
          <v-col sm="12" md="8" lg="8" min-width="820" class="fill-height">
            <v-flex text-xs-center>
              <v-card width="800" v-if="invoice" class="fill-height">
                <v-row>
                  <v-col style="overflow-y: visible !important">
                    <v-tabs v-model="active_page" icons-and-text>
                      <v-tab v-for="page in invoice.pages" :key="page.file">
                        <v-card tile>
                          <v-btn
                            fab
                            v-on:click.stop="removePage(page)"
                            x-small
                            absolute
                            left
                            top
                            class="mt-4"
                            style="opacity: 0.6"
                            elevation="2"
                            ><v-icon>{{ $i.mdiDelete }}</v-icon></v-btn
                          >
                          <v-img
                            :src="`${$api_url}/files/invoices/${invoice.id}/${page.file}?${nonce}`"
                            style="width: 50px"
                            contain
                            :gradient="
                              page.removed
                                ? 'to top right, rgba(255,0,0,.33), rgba(255,0,0,.7)'
                                : ''
                            "
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                            <v-card-title
                              class="align-end fill-height"
                              primary-title
                            >
                            </v-card-title>
                          </v-img>
                        </v-card>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="fill-height" style="overflow-y: auto">
                    <v-carousel
                      v-model="active_page"
                      hide-delimiter-background
                      hide-delimiters
                      show-arrows-on-hover
                      :height="invoice_tab_height"
                    >
                      <div
                        v-draggable
                        style="z-index: 1 !important; background: #bbb"
                        v-for="sticker in stickers_active"
                        :key="sticker.market + sticker.department"
                        :value="sticker.department"
                        :ref="`div-${sticker.market}-${sticker.department}`"
                        @click="sticker_move(sticker)"
                        :style="`left:${sticker.left}px;top:${sticker.top}px`"
                        transition="scale-transition"
                        origin="center center"
                      >
                        <svg-bewertung
                          :config="sticker"
                          :ref="`sticker-${sticker.market}-${sticker.department}`"
                        >
                        </svg-bewertung>
                      </div>

                      <v-carousel-item
                        v-for="page in invoice.pages"
                        :key="page.file"
                      >
                        <vue-photo-zoom-pro
                          :high-url="`${$api_url}/files/invoices/${invoice.id}/${page.file}?${nonce}`"
                          type="circle"
                        >
                          <v-img
                            :src="`${$api_url}/files/invoices/${invoice.id}/${page.file}?${nonce}`"
                            style="width: 800px"
                            ref="img_box"
                            :gradient="
                              page.removed
                                ? 'to top right, rgba(255,0,0,.33), rgba(255,0,0,.7)'
                                : ''
                            "
                          >
                            <span
                              style="
                                background-color: blue;
                                color: white;
                                font-weight: bold;
                              "
                            >
                              Info
                            </span>
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </vue-photo-zoom-pro>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-card>
            </v-flex>
          </v-col>
        </v-row>

        <!-- <v-row v-if="receipts && 'delivery_notes' in receipts && supplier.public_id">
          <v-col>
            <iir-file-table :items="receipts" subdir="delivery_notes" :supplier="supplier.public_id"></iir-file-table>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue"
import iirDateInput from "@/components/iirDateInput.vue"
import svgBewertung from "@/components/invoicevaluation/svgBewertung.vue"
import { parse, setValue } from "vue-currency-input"
import VuePhotoZoomPro from "vue-photo-zoom-pro"
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css"
import departments_ids from "@/assets/departments_ids.json"
import iirFileTable from "@/components/suppliers/iirFileTable.vue"

export default {
  name: "InvoiceValuationTab",
  components: {
    iirCurrencyInput,
    iirDateInput,
    VuePhotoZoomPro,
    svgBewertung,
    iirFileTable,
  },
  props: {
    config: { default: null },
  },
  data: () => ({
    dialog: false,
    vat_rates: { reduced: 7, full: 19 },
    net_as_gross: false,
    eingaben_reduced: { 0: 0 },
    eingaben_full: { 0: 0 },
    margin_reduced: 25,
    margin_full: 25,
    numrows: 1,
    download_file: null,
    active_page: null,
    dialog_add_department: false,
    departments_all: departments_ids,
    departments_shown: [10, 11, 30],
    markets: [
      { id: 1, name: "Hauptmarkt" },
      { id: 2, name: "Getränkemarkt" },
    ],
    department_selected: 10,
    market_selected: 1,
    stickers: [],
    supplier: null,
    invoice: {},
    receipts: {},
    nonce: 0,
  }),
  computed: {
    invoice_tab_height() {
      if (this.invoice && this.invoice.pages && this.active_page) {
        return this.invoice.pages[this.active_page].page_portrait ? 1131 : 650
      } else {
        return 1131
      }
    },
    departments() {
      let d = this.departments_all.filter((a) =>
        this.departments_shown.includes(a.id)
      )
      return d
    },
    full_sum_c: function () {
      return this.summarizeObject(this.eingaben_full)
    },
    reduced_sum_c: function () {
      return this.summarizeObject(this.eingaben_reduced)
    },
    stickers_active: function () {
      return this.stickers.filter((a) => a.page == this.active_page)
    },
  },
  methods: {
    removePage(file) {
      this.invoice.pages.find((x) => x.page == file.page)["removed"] = true
      this.active_page = file.page
    },
    set_margin() {
      this.margin_reduced = 25
      this.margin_full = 25
    },
    sticker_move(event) {
      const targetIndex = this.stickers.findIndex(
        (a) => a.department == event.department && a.market == event.market
      )

      this.stickers[targetIndex].left =
        this.$refs[`div-${event.market}-${event.department}`][0].offsetLeft
      this.stickers[targetIndex].top =
        this.$refs[`div-${event.market}-${event.department}`][0].offsetTop
    },
    generatePdf() {
      //   console.log(this.stickers);
      let q = []
      q = this.stickers.map((x) => ({
        ...x,
        svg: this.$refs[`sticker-${x.market}-${x.department}`][0].$el.outerHTML,
      }))
      // /invoices/<uuid>/download/
      this.$api
        .post(
          "files/invoices/" + this.invoice.id + "/generate",
          {
            stickers: q,
            pages: this.invoice.pages,
            // pages: this.invoice.pages.filter(
            //   (x) => !("removed" in x) || x.removed == false
            // ),
          },
          { withCredentials: true }
        )
        .then((response) => this.$emit("finalized", response.data))
        .catch()
      //.then(() => this.downloadPdf());
    },
    reopenPdf() {
      this.$emit("reopened", this.invoice)
    },
    downloadPdf() {
      this.$api
        .get(
          "files/invoices/" +
            this.download_file.id +
            "/" +
            this.download_file.filename,
          {
            responseType: "blob",
            params: {},
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data]) //, { type: "application/pdf" })
          )
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", this.download_file.filename)
          //document.body.appendChild(link);
          link.click()
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url)
          }, 100)
        })
        .catch()
        .then()
    },
    height_cal() {
      console.log(this.$refs.img_box)
      // console.log(
      //   this.$refs.img_box[this.active_page].$el.clientHeight,
      //   this.$refs.img_box[this.active_page].$el.clientWidth
      // );
    },
    addSticker() {
      // console.log(this.$refs.ref_vk_reduced.$refs.ref_vk_reduced);
      // this.height_cal();
      if (this.full_sum_c == 0 && this.reduced_sum_c == 0) {
        this.$root.toast.alert("VK-Wert kann nicht 0 sein.")
        return false
      }
      if (
        this.stickers.some(
          (sticker) =>
            sticker.department === this.department_selected &&
            sticker.market === this.market_selected
        )
      ) {
        alert("Abteilung bereits bewertet.")
      } else {
        // console.log();
        // let h =
        //   this.$refs.img_box[this.$refs.img_box.length - 1].$el.clientHeight;
        // let w =
        //   this.$refs.img_box[this.$refs.img_box.length - 1].$el.clientWidth;
        this.stickers.push({
          department: this.department_selected,
          market: this.market_selected,
          vk_reduced: this.calculateGross(
            this.reduced_sum_c,
            this.vat_rates.reduced,
            this.margin_reduced
          ),
          vk_full: this.calculateGross(
            this.full_sum_c,
            this.vat_rates.full,
            this.margin_full
          ),
          page: this.active_page,
          // page_portrait: h > w ? true : false,
          page_portrait: this.invoice.pages.filter(
            (a) => a.page == this.active_page
          )[0].page_portrait,
          left: 250,
          top: 400,
        })
        this.clearForm()
      }
    },
    remove_sticker(sticker) {
      this.stickers = this.stickers.filter(
        (a) =>
          !(a.department == sticker.department && a.market == sticker.market)
      )
    },
    clearForm() {
      this.eingaben_reduced = { 0: 0 }
      this.eingaben_full = { 0: 0 }
      //console.log(this.$refs);
      //setValue(this.$refs["reduced1"][0].$refs["ref"], "");
      for (let i = 1; i <= this.numrows; i++) {
        setValue(this.$refs["reduced-" + i][0].$refs["ref"], null)
        setValue(this.$refs["full-" + i][0].$refs["ref"], null)
        //console.log(i);
      }
      this.department_selected = 10
      this.market_selected = 1
    },
    summarizeObject(val) {
      return Object.values(val).reduce((a, b) => a + this.parseNumber(b))
    },
    parseNumber(val) {
      return parse(val, {
        suffix: "",
        precision: "",
        locale: "DE",
        currency: "EUR",
      })
    },
    calculateGross(net, vat, margin) {
      if (this.net_as_gross) return net.toFixed(2)
      else return ((net / (1 - margin / 100)) * (1 + vat / 100)).toFixed(2)
    },
    setSums() {
      setValue(this.$refs.ref_full.$refs.ref_full, this.full_sum_c)
      setValue(
        this.$refs.ref_vk_full.$refs.ref_vk_full,
        this.calculateGross(
          this.full_sum_c,
          this.vat_rates.full,
          this.margin_full
        )
      )
      setValue(this.$refs.ref_reduced.$refs.ref_reduced, this.reduced_sum_c)
      setValue(
        this.$refs.ref_vk_reduced.$refs.ref_vk_reduced,
        this.calculateGross(
          this.reduced_sum_c,
          this.vat_rates.reduced,
          this.margin_reduced
        )
      )
    },
    clear() {
      this.invoice = {}
    },
  },
  watch: {
    full_sum_c: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
    reduced_sum_c: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
    margin_full: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
    margin_reduced: {
      handler: function () {
        this.setSums()
      },
      deep: true,
    },
  },
  mounted() {
    this.invoice = this.config
    if (this.invoice) this.active_page = this.invoice.page_count - 1
    // this.height_cal();
  },
  created() {
    this.clear()
    this.nonce = parseInt(new Date().getTime() / 10000)
  },
}
</script>

<style scoped>
/*.v-carousel {
  cursor: url("~@/assets/bewertung.png") 0 0, crosshair;
}*/
</style>
