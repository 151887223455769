<template>
  <div>
    <v-layout style="white-space: nowrap">
      <v-tooltip
        top
        v-if="item.supplier.public_id == '00000000-0000-0000-0000-000000000000'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            justify="center"
            class="d-print-none"
            icon
            v-bind="attrs"
            v-on="on"
            v-show="item.supplier && item.supplier.public_id"
            ><v-icon> {{ $i.mdiPlusBoxMultiple }} </v-icon></v-btn
          >
        </template>
        <span>mehrere Lieferanten erkannt</span>
      </v-tooltip>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            justify="center"
            class="d-print-none"
            icon
            v-bind="attrs"
            v-on="on"
            v-show="item.supplier && item.supplier.public_id"
            @click.stop="navigateToSupplier(item)"
            ><v-icon> {{ $i.mdiLinkVariant }} </v-icon></v-btn
          >
        </template>
        <span>zum Lieferanten</span>
      </v-tooltip>
      {{ item.supplier.name || item.recognized ? item.supplier.name : "" }}
      <v-tooltip top v-if="item.duplicate">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="white" class="ml-1">{{
            $i.mdiAlertCircle
          }}</v-icon></template
        >
        <span>Beleg ist Duplikat</span>
      </v-tooltip>
    </v-layout>
    <v-progress-circular
      indeterminate
      color="primary"
      size="20"
      v-if="!(item.supplier.name || item.recognized)"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTablePreviewIcon",
  components: {},
  props: {
    item: {},
  },
  data: () => ({}),
  computed: {},
  methods: {
    navigateToSupplier(item) {
      let params = {
        location_id: this.$route.params.location_id,
        id: item.supplier.public_id,
      }
      this.$router.push({
        name: "ShowSupplier",
        params: params,
      })
    },
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>