function germanDate(d, year="numeric") {
      if (typeof d == "string") {
        return new Date(d.replace(" ", "T")).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: year,
        })
      } else {
        return d.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: year,
        })
      }
}
function addDaysToDate(date, days) {
      var result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    }
function dateDiff(d1, d2) {
      let days = Math.ceil((d1 - d2) / (1000 * 60 * 60 * 24)).toFixed(0)
      return days
}
function days_until_date(date1, date2 = null) {
  if (date2 === null) {
    date2 = new Date()
  } else {
    date2 = new Date(date2)
  }
    return Math.ceil((new Date(date1) - date2) / (24 * 60 * 60 * 1000));
}
function payment_days(receipt_date, discount_term) {
      return dateDiff(
        addDaysToDate(receipt_date, discount_term),
        new Date()
      )
}
function parseDec(v, d = 0) {
      if (!v) {
        v = 0
      }
  
      return parseFloat(v).toLocaleString("de-DE", {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      })
}
function parseLocalizedFloat(localizedFloatString) {
  if (localizedFloatString === null || localizedFloatString === undefined) {
    return NaN; // Or any other appropriate value
  }
  if(typeof localizedFloatString === "number") return localizedFloatString
  // console.log(localizedFloatString)
  const hasComma = localizedFloatString.includes(',');
  const hasPeriod = localizedFloatString.includes('.');
  localizedFloatString = String(localizedFloatString).replace(" ", "")
  
  if (!hasComma && !hasPeriod) {
    // Try to convert the string to a number
    let num = parseInt(localizedFloatString, 10);
    // Check if the conversion is successful and if the result is an integer
    if (!isNaN(num) && Number.isInteger(num))
    {
      return parseFloat(localizedFloatString)
      }
  }
  if (hasComma && !hasPeriod) {
    // Comma found, but no period - it's a German-style float.
    return parseFloat(localizedFloatString.replace(/\./g, '').replace(',', '.'));
  } else if (!hasComma && hasPeriod) {
    // Period found, but no comma - it's an English-style float.
    console.log("ENGLISCH STYLE")
    return parseFloat(localizedFloatString);
  } else if (hasComma && hasPeriod && localizedFloatString.indexOf('.') > localizedFloatString.indexOf(',')) {
    // Both period and comma found, and period appears after comma - it's an English-style float.
    return parseFloat(localizedFloatString);
  } else if (hasComma && hasPeriod && localizedFloatString.indexOf('.') < localizedFloatString.indexOf(',')) {
    // Both period and comma found, and period appears before comma
    return parseFloat(localizedFloatString.replace(/\./g, '').replace(',', '.'));
  } else {
    // Neither period nor comma found, or format is ambiguous.
    return NaN;
  }
}
    function parseGermanFloat(germanFloat) {
  if (germanFloat === null || germanFloat === undefined) {
    return NaN; // Or any other appropriate value
  }
  germanFloat = germanFloat.replace(' ', '');
  const standardFloat = germanFloat.replace('.','').replace(',', '.')
  const parsedFloat = parseFloat(standardFloat);

  return parsedFloat;
}
function utcToLocal(utcDateTimeString) {
      // console.log("ORIGIN", utcDateTimeString)
      const utcDate = new Date(Date.parse(utcDateTimeString + 'Z'))
      // console.log("UTC", utcDate)
      // const localDate = new Date(
      //   utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  // )
  const localDate = utcDate
      // console.log("LOCAL", localDate)

      // Format the local date as per your desired format (e.g., YYYY-MM-DD HH:mm:ss)
      const localDateTimeFormat = localDate.toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })

      return localDateTimeFormat
    }

    function debounce(func, delay) {
    let timerId;
    return function (...args) {
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
    }
  function zeroPad(num, places) {
  return String(num).padStart(places, "0");
  }

export {germanDate, addDaysToDate, dateDiff, days_until_date, payment_days, parseDec, utcToLocal, debounce, zeroPad, parseGermanFloat, parseLocalizedFloat}