<template>
  <v-tabs vertical v-model="tab" background-color="secondary">
    <v-tab
      v-for="company in $store.state.user_obj.companies"
      :key="company.public_id"
    >
      <v-icon left> {{ $i.mdiOfficeBuildingMarker }} </v-icon>
      {{ company.name_informal }}
    </v-tab>
    <v-tab key="register_company" to="register_company">
      <v-icon left> {{ $i.mdiPlus }} </v-icon> Unternehmen registrieren
    </v-tab>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="company in $store.state.user_obj.companies"
        :key="company.public_id"
      >
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col>
              <v-card class="mx-auto elevation-1" flat>
                <v-toolbar color="secondary" dark flat dense>
                  <v-toolbar-title>{{ company.name_informal }}</v-toolbar-title>
                </v-toolbar>

                <admin-tab-company-data :company="company">
                </admin-tab-company-data>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col>
              <v-card class="mx-auto elevation-1" flat>
                <v-toolbar color="secondary" dark flat dense>
                  <v-toolbar-title>Standorte</v-toolbar-title>
                </v-toolbar>

                <admin-tab-company-locations :company="company">
                </admin-tab-company-locations>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item key="register_company">
        <v-card flat>
          <v-card-text>REGISTRIEREN</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import AdminTabCompanyLocations from "@/components/admin/AdminTabCompanyLocations.vue"
import AdminTabCompanyData from "@/components/admin/AdminTabCompanyData.vue"

export default {
  components: {
    AdminTabCompanyLocations,
    AdminTabCompanyData,
  },
  data: () => ({
    tab: null,
    formData: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
    },
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    },
    show_password: false,
  }),
  props: {
    value: {},
  },
  methods: {
    apiAddSupplier() {
      this.loading = true
      this.$api
        .post("suppliers/" + this.$route.params.location_id, this.supplier)
        .then((response) => (this.supplier = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false))
    },
    hide: function (arg) {
      if (this.supplier.name == "" && arg == "save") {
        alert("Keine Eingabe gemacht")
      } else if (arg == "save") {
        this.apiAddSupplier()
        this.$emit("closed", this.supplier.name, this.supplier.name)
        this.sync_value = false
        this.$router.push({
          name: "Suppliers",
          params: { location_id: this.$store.state.location_id },
        })
      } else {
        this.sync_value = false
      }
    },
  },
}
</script>

