<template>
  <v-card>
    <v-form @submit.prevent="hide">
      <v-card-title>
        <span class="headline">Notiz hinzufügen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout wrap>
            <v-row>
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <v-autocomplete
                  v-model="note.supplier.supplier_id"
                  :items="suppliers"
                  :prepend-icon="$i.mdiTruckFast"
                  item-text="name"
                  item-value="public_id"
                  label="Lieferant"
                  ref="suppliers"
                >
                  <template v-slot:no-data>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item>
                      <v-list-item-avatar color="grey lighten-6">
                        <v-icon> {{ $i.mdiClose }} </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Kein Lieferant gefunden
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Es wurde kein passender Lieferant gefunden
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }} ({{ item.supplier_number }})
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content
                        >{{ item.name }} ({{
                          item.supplier_number
                        }})</v-list-item-content
                      >
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <iir-date-input
                  label="Gültig ab"
                  :value.sync="note.valid_from"
                  prepend-icon=""
                  showWeek
                  :readonly="false"
                >
                </iir-date-input>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-textarea
                  label="Notiz"
                  counter="255"
                  rows="3"
                  :rules="[rules.length(255)]"
                  v-model="note.note"
                  :prepend-icon="null"
                  :suffix="null"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="hide('close')">
          schließen
          <v-icon right>{{ $i.mdiCloseCircleOutline }}</v-icon>
        </v-btn>

        <v-btn color="success" @click="hide('save')">
          speichern
          <v-icon right>{{ $i.mdiContentSave }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import iirDateInput from "@/components/iirDateInput.vue"
export default {
  name: "addNote",
  components: {
    iirDateInput,
  },
  computed: {
    suppliers() {
      return this.$store.state.suppliers || []
    },
    sync_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
  },
  props: {},
  methods: {
    apiAddNote() {
      this.note.user = this.$store.state.user
      //this.$store.commit('startLoading')
      this.$api
        .post("notes/" + this.$route.params.location_id, this.note)
        .then((response) => console.log(response.data))
        .catch()
        .then(() => (this.$emit("saved"), this.clear()))
    },
    hide: function (arg) {
      if (this.note.note == "" && arg == "save") {
        alert("Keine Eingabe gemacht")
      } else if (arg == "save") {
        this.apiAddNote()
        //this.$emit("saved");
        this.sync_value = false
      } else {
        this.sync_value = false
        this.clear()
        this.$emit("closed")
      }
    },
    clear() {
      this.note = {
        public_id: null,
        supplier: {
          supplier_name: "",
          supplier_id: "",
        },
        note: "",
        archived: false,
        user: null,
        valid_from: null,
      }
    },
  },
  data() {
    return {
      note: null,
      rules: {
        length: (len) => (v) =>
          (v || "").length <= len || `Zu lang, maximal ${len} Zeichen.`,
      },
    }
  },
  created() {
    this.clear()
    // if (this.$store.state.suppliers == null) {
    //   this.$store.dispatch("apiGetSuppliers", {
    //     location_id: this.$route.params.location_id,
    //     active: true,
    //   });
    // }
  },
}
</script>

