<template>
  <v-row class="ma-0">
    <v-col cols="12" md="12">
      <iir-search-input v-model="search" class="mx-4"></iir-search-input>
    </v-col>
    <v-col cols="12" md="12" class="ma-0 pa-0">
      <v-data-table
        :items="itemsComp"
        :headers="receipts_table"
        :sort-by="['receipt_date']"
        :sort-desc="['true']"
        :footer-props="{
          'items-per-page-text': 'Einträge pro Seite',
          'items-per-page-all-text': 'Alle',
          'page-text': '{0}-{1} von {2}',
          'show-current-page': true,
        }"
        item-key="full_path"
        dense
        :hide-default-footer="itemsComp.length <= 15"
        :search="search"
        @click:row="handleClick_n"
        style="cursor: pointer"
        :single-expand="true"
        :items-per-page="15"
        :expanded.sync="expanded"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item.size="{ item }">
          {{ item.size ? humanFileSize(item.size, true) : "" }}
        </template>
        <template v-slot:item.receipt_date="{ item }">
          {{
            item.receipt_date
              ? new Date(item.receipt_date).toLocaleDateString("de-DE", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : ""
          }}
        </template>
        <template v-slot:item.icon="{ item }">
          <v-icon label="TEST">
            {{
              getExpandedPath == item.full_path
                ? $i.mdiFolderOpen
                : getIcon(item.type)
            }}
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" v-if="childrenLoaded">
            <iir-file-table :items="tempItem" subdir="" v-if="childrenLoaded">
            </iir-file-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-container fill-height class="d-flex">
          <v-layout column fill-height>
            <v-flex grow class="white--text">
              <v-card
                :class="'ma-0 pa-0 justify-center'"
                @click="dialog = false"
                :height="$vuetify.breakpoint.smAndUp ? '90vh' : '100vh'"
              >
                <v-row
                  class="ma-0"
                  align="center"
                  justify="center"
                  v-if="pdfsrc === null"
                  fill-height
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
                <iframe
                  :src="pdfsrc"
                  style="width: 100%; height: 100%; flex-grow: 1; border: none"
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Oops! an error has occurred.
                </iframe>
                <div class="pdf-viewer" v-if="pdfsrc !== null">
                  <vue-pdf-embed
                    :source="pdfsrc"
                    v-if="$vuetify.breakpoint.xsOnly"
                  />
                </div>

                <v-btn
                  class="mt-10"
                  fab
                  dark
                  small
                  top
                  right
                  absolute
                  color="primary"
                  @click="dialog = false"
                >
                  <v-icon dark small> {{ $i.mdiClose }} </v-icon>
                </v-btn>
                <v-btn
                  v-if="$vuetify.breakpoint.xsOnly"
                  class="mt-10"
                  fab
                  dark
                  small
                  top
                  left
                  absolute
                  color="success"
                  @click="downloadPdf()"
                >
                  <v-icon dark small> {{ $i.mdiContentSave }} </v-icon>
                </v-btn>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import filetypes from "@/assets/files.json"
import iirFileTable from "@/components/suppliers/iirFileTable.vue"
import iirSearchInput from "@/components/iirSearchInput.vue"
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed"

export default {
  name: "iirFileTable",
  components: {
    iirFileTable,
    iirSearchInput,
    VuePdfEmbed,
  },
  props: {
    items: null,
    itemText: { type: String, default: "" },
    activatable: { type: Boolean, default: true },
    itemKey: { type: String, default: "" },
    openOnClick: { type: Boolean, default: true },
    subdir: null,
    supplier: { type: String, default: "" },
  },
  computed: {
    getExpandedPath() {
      if (this.expanded.length) {
        return this.expanded[0].full_path
      } else {
        return ""
      }
    },
    itemsComp() {
      if (this.subdir != "") {
        return this.filesFolder(this.items, this.subdir)
      } else {
        return this.filesFolder(this.items, "files")
      }
    },
    receipts_table() {
      let r = [
        {
          text: "",
          value: "icon",
          align: "start",
          sortable: false,
        },
      ]

      if (this.subdir != "files" && this.subdir != "") {
        r.push({
          text: "Belegdatum",
          value: "receipt_date",
          sortable: true,
        })
        r.push({
          text: "Belegnummer",
          value: "receipt_number",
          sortable: true,
        })
        r.push({
          text: "Belegtyp",
          value: "receipt_type",
          sortable: true,
        })
        r.push({
          text: "Lieferant",
          value: "receipt_supplier",
          sortable: true,
        })
      }
      if ("id" in this.$route.params) {
        r.push({
          text: "Dateiname",
          value: "name",
          align: "start",
          sortable: true,
        })
      }
      r.push({
        text: "Dateigröße",
        value: "size",
        sortable: true,
      })
      return r
    },
    supplier_id() {
      let supplier_id = this.supplier
      if ("id" in this.$route.params) supplier_id = this.$route.params.id
      return supplier_id
    },
  },
  data() {
    return {
      open: [],
      active: [],
      files: filetypes,
      expanded: [],
      tempItem: null,
      search: "",
      childrenLoaded: false,
      dialog: false,
      pdfsrc: null,
      pdfname: null,
    }
  },
  methods: {
    handleClick(row) {
      this.pdfsrc = null
      this.apiGetFile(row)
      if (!("id" in this.$route.params)) {
        this.dialog = true
      }
    },
    handleClick_n(row) {
      this.pdfsrc = null
      this.apiGetFile(row, true)
      this.dialog = true
    },
    getIcon(filetype) {
      //   console.log(this.files);
      const f = this.files.filter((item) => item.type == filetype)[0]
      if (!f) {
        return this.files.filter((item) => item.type == "default")[0].icon
      }
      return f.icon
    },
    getChildren(item) {
      //console.log(item);
      this.childrenLoaded = false

      this.$api
        .get(
          "files/" + this.$route.params.location_id + "/" + this.supplier_id, //this.$route.params.id,
          {
            params: { path: item.path },
          }
        )
        .then((response) => {
          this.tempItem = response.data
        })
        .catch()
        .then((this.childrenLoaded = true))
    },
    apiGetFile(obj, show_inline = false) {
      if (!obj.type.includes("directory")) {
        this.$api
          .get(
            "files/" + this.$route.params.location_id + "/" + this.supplier_id,
            {
              responseType: "blob",
              params: { file: obj.full_path },
            }
          )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            if (
              (!("id" in this.$route.params) || show_inline) &&
              obj.type.includes("pdf")
            ) {
              this.pdfsrc = url
              this.pdfname = obj.name
            } else {
              this.dialog = false
              const link = document.createElement("a")
              link.href = url
              // const contentDisposition =
              //   response.headers["content-disposition"];
              link.setAttribute("download", obj.name)
              document.body.appendChild(link)
              link.click()
            }
          })
          .catch()
          .then()
      } else {
        // console.log(obj);

        if (JSON.stringify(this.expanded) === JSON.stringify([obj])) {
          this.expanded = []
        } else {
          this.getChildren(obj)
          this.expanded = [obj]
        }
      }
    },
    downloadPdf() {
      const link = document.createElement("a")
      link.href = this.pdfsrc
      // const contentDisposition =
      //   response.headers["content-disposition"];
      link.setAttribute("download", this.pdfname)
      document.body.appendChild(link)
      link.click()
    },
    filesFolder(arr, subdir) {
      //   console.log(arr);
      //   console.log(subdir);
      if (arr === null) return []
      const filteredKeys = [subdir]
      const fil = filteredKeys.reduce(
        (obj, key) => ({ ...obj, [key]: arr[key] }),
        {}
      )
      const f = fil[subdir]
      f.forEach((item) => {
        if (item.type == "httpd/unix-directory") {
          item.children = []
        }
      })
      return f
    },
    log(s) {
      console.log(s)
      //alert(s);
    },
    humanFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + " B"
      }

      const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      )

      return bytes.toFixed(dp) + " " + units[u]
    },
  },
  watch: {},
}
</script>

<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.pdf-viewer {
  padding: 24px 16px;
}
</style>