var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',[_c('v-simple-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.downloaded),expression:"item.downloaded"}],attrs:{"ripple":false,"dense":"","disabled":""},model:{value:(_vm.item.downloaded),callback:function ($$v) {_vm.$set(_vm.item, "downloaded", $$v)},expression:"item.downloaded"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
            !_vm.item.downloaded &&
            _vm.item.recognized &&
            (_vm.item.finalized || _vm.item.receipt_type != 'Rechnung')
          ),expression:"\n            !item.downloaded &&\n            item.recognized &&\n            (item.finalized || item.receipt_type != 'Rechnung')\n          "}],attrs:{"small":"","color":"secondary","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(_vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$i.mdiDownload)+" ")])],1)]}}])},[_c('span',[_vm._v("Beleg herunterladen")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
            !_vm.item.downloaded &&
            _vm.item.finalized &&
            _vm.item.receipt_type == 'Rechnung'
          ),expression:"\n            !item.downloaded &&\n            item.finalized &&\n            item.receipt_type == 'Rechnung'\n          "}],attrs:{"small":"","color":"secondary","rounded":"","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.mailReceipt(_vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$i.mdiEmailFastOutline)+" ")])],1)]}}])},[(!_vm.disabled)?_c('span',[_vm._v("Beleg versenden")]):_vm._e(),(_vm.disabled)?_c('span',[_vm._v("Beleg in Sendewarteschlange")]):_vm._e()]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.finalized && _vm.item.receipt_type == 'Rechnung'),expression:"!item.finalized && item.receipt_type == 'Rechnung'"}],attrs:{"small":""}},[_vm._v("nicht bewertet")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }