<template>
  <div>
    <v-layout>
      <v-simple-checkbox
        v-model="item.downloaded"
        :ripple="false"
        dense
        disabled
        v-show="item.downloaded"
      >
      </v-simple-checkbox>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-show="
              !item.downloaded &&
              item.recognized &&
              (item.finalized || item.receipt_type != 'Rechnung')
            "
            color="secondary"
            v-bind="attrs"
            v-on="on"
            rounded
            @click.stop="downloadFile(item)"
            ><v-icon> {{ $i.mdiDownload }} </v-icon></v-btn
          >
        </template>
        <span>Beleg herunterladen</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-show="
              !item.downloaded &&
              item.finalized &&
              item.receipt_type == 'Rechnung'
            "
            color="secondary"
            v-bind="attrs"
            v-on="on"
            rounded
            :disabled="disabled"
            @click.stop="mailReceipt(item)"
            ><v-icon> {{ $i.mdiEmailFastOutline }} </v-icon></v-btn
          >
        </template>
        <span v-if="!disabled">Beleg versenden</span>
        <span v-if="disabled">Beleg in Sendewarteschlange</span>
      </v-tooltip>
      <v-chip small v-show="!item.finalized && item.receipt_type == 'Rechnung'"
        >nicht bewertet</v-chip
      >
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTableDownloaded",
  components: {},
  props: {
    item: {},
  },
  data: () => ({
    disabled: false,
  }),
  computed: {},
  methods: {
    mailReceipt(item) {
      this.disabled = true

      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            item.public_id +
            "/mail"
        )
        .then(() => {
          this.apiPutReceipt(item["public_id"], { ["downloaded"]: true })
        })
        .catch()
      setTimeout(() => {
        this.disabled = false
      }, 20000)
    },
    downloadFile(item) {
      let url =
        "receipts/" +
        this.$store.state.location_id +
        "/" +
        item.public_id +
        "/" +
        item.public_id
      url = url + (item.receipt_type == "Rechnung" ? "_finalized.pdf" : ".pdf")
      this.$api
        .get(url, {
          responseType: "blob",
          // params: { meta: meta },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          )
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            response.headers["x-filename"] //.split("filename=")[1]
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
          // this.apiPutReceipt(item["public_id"], { ["downloaded"]: true })
          item.downloaded = true
        })
        .catch()
        .then()
    },
    apiPutReceipt(receipt_id, obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then()
        .catch()
    },
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>