import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import VueCurrencyInput from 'vue-currency-input'
import DatetimePicker from 'vuetify-datetime-picker'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

// import "@fontsource/roboto";

// import * as mIcons from "@mdi/js";
import * as mIcons from "./icons"

import * as general_functions from "./general_functions"

import API from './api';

import download from "downloadjs"

Vue.config.productionTip = false

Vue.use(DatetimePicker)
Vue.use(VueTelInputVuetify, {
  vuetify,
});


Vue.prototype.$api = API
Vue.prototype.$api_url = process.env.VUE_APP_API_URL
Vue.prototype.$i = mIcons
Vue.prototype.$gf = general_functions
Vue.prototype.$download = download

Vue.directive('draggable', {
  bind: function (el) {
    el.style.position = 'absolute';
    var startX, startY, initialMouseX, initialMouseY;

    function mousemove(e) {
      var dx = e.clientX - initialMouseX;
      var dy = e.clientY - initialMouseY;
      el.style.top = startY + dy + 'px';
      el.style.left = startX + dx + 'px';
      return false;
    }

    function mouseup() {
      document.removeEventListener('mousemove', mousemove);
      document.removeEventListener('mouseup', mouseup);
    }

    el.addEventListener('mousedown', function (e) {
      startX = el.offsetLeft;
      startY = el.offsetTop;
      initialMouseX = e.clientX;
      initialMouseY = e.clientY;
      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mouseup', mouseup);
      return false;
    });
  }
})


new Vue({
  vuetify,
  store,
  router,
  VueCurrencyInput,
  render: h => h(App),
  beforeCreate() {
    // this.$store.commit('initialiseStore');
  }
}).$mount('#app')