<template>
  <v-container fill-height class="d-flex">
    <v-layout column fill-height>
      <v-flex grow>
        <v-row>
          <v-col cols="6">
            <v-card>
              <receipt-file-table
                :items="delivery_notes"
                subdir="delivery_notes"
                :supplier="supplier_id"
                :receipt_id="receipt_id"
                v-if="
                  delivery_notes &&
                  'delivery_notes' in delivery_notes &&
                  supplier_id
                "
              ></receipt-file-table>
              <v-container fill-height v-else>
                <v-row justify="center" align="center">
                  <v-col cols="12" sm="4">
                    <v-card-title
                      primary-title
                      class="justify-center"
                      align-center
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-card-title>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-btn
                class="mt-10"
                fab
                dark
                small
                top
                right
                absolute
                color="primary"
                @click="closed()"
              >
                <v-icon dark small> {{ $i.mdiClose }} </v-icon>
              </v-btn> -->
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              :class="'ma-0 pa-0 justify-center'"
              @click="closed()"
              height="90vh"
            >
              <v-card-title>Rechnung</v-card-title>
              <iframe
                :src="receiptsrc"
                style="width: 100%; height: 100%; flex-grow: 1; border: none"
                :v-if="receiptsrc != 'about:blank'"
              >
                Oops! an error has occurred.
              </iframe>
              <v-btn
                class="mt-10 mr-10"
                fab
                dark
                small
                top
                right
                absolute
                color="success"
                @click="checked()"
              >
                <v-icon dark> {{ $i.mdiCheckCircle }} </v-icon>
              </v-btn>
              <v-btn
                class="mt-10"
                fab
                dark
                small
                top
                right
                absolute
                color="primary"
                @click="closed()"
              >
                <v-icon dark> {{ $i.mdiClose }} </v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ReceiptFileTable from "@/components/receipts/ReceiptFileTable.vue"

export default {
  name: "ReceiptDeliveryNotes",
  components: {
    ReceiptFileTable,
  },
  props: {
    supplier_id: { default: null },
    receipt_id: { default: null },
  },
  data: () => ({
    delivery_notes: {},
    receiptsrc: null,
  }),
  computed: {},
  methods: {
    closed() {
      // this.delivery_notes = null;
      this.$emit("closed")
    },
    checked() {
      this.apiPutReceipt({ checked: true })
      this.closed()
    },
    apiGetReceipts(supplier_id) {
      this.$api
        .get(
          "/receipts/" +
            this.$store.state.location_id +
            "/delivery_notes/" +
            supplier_id
        )
        .then((response) => {
          this.delivery_notes = response.data
        })
        .catch()
    },
    apiPutReceipt(obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + this.receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then(() =>
          // this.$store.dispatch("apiGetReceipts", this.$store.state.location_id)
          this.$store.dispatch(
            "apiGetReceiptsFQ",
            this.$store.state.location_id
          )
        )
        .catch()
    },
    clear() {
      // this.invoice = {
      //   meta: {
      //     name: null,
      //     receipt_date: null, //new Date().toISOString().slice(0, 10),
      //     receipt_number: null,
      //     receipt_type: null,
      //   },
      // };
    },
    apiGetReceipt() {
      this.receiptsrc = "about:blank"
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            this.receipt_id +
            "/" +
            this.receipt_id +
            ".pdf",
          {
            responseType: "blob",
            // params: { meta: meta },
          }
        )
        .then((response) => {
          this.receiptsrc = "about:blank"
          const url = window.URL.createObjectURL(new Blob([response.data]))
          this.receiptsrc = url
        })
        .catch()
        .then()
    },
  },
  watch: {
    supplier_id() {
      this.delivery_notes = null
      this.receiptsrc = "about:blank"
      this.apiGetReceipts(this.supplier_id)
      this.apiGetReceipt()
    },
    receipt_id() {
      this.delivery_notes = null
      this.receiptsrc = "about:blank"
      this.apiGetReceipts(this.supplier_id)
      this.apiGetReceipt()
    },
  },
  mounted() {
    this.receiptsrc = "about:blank"
    this.apiGetReceipts(this.supplier_id)
    this.apiGetReceipt()
  },
  created() {
    this.receiptsrc = "about:blank"
    this.apiGetReceipts(this.supplier_id)
    this.apiGetReceipt()
  },
}
</script>