<template>
  <div class="tasks">Tasks</div>
</template>

<script>
export default {
  name: "TasksView",
  components: {},
};
</script>
