<template>
  <v-combobox
    v-model="sync_value"
    :items="items"
    chips
    :clearable="clearable"
    :multiple="multiple"
    :filled="filled"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :label="label"
    :prefix="prefix"
    :suffix="suffix"
    :readonly="readonly"
    :disabled="disabled"
    :dense="dense"
    :single-line="singleLine"
    :hide-details="hideDetails"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        :close="!disabled"
        @click="select"
        @click:close="remove(item)"
        v-if="multiple"
        :small="dense"
        color="secondary"
      >
        <strong v-if="nested">{{ item[nested] }}</strong>
        <strong v-else>{{ item }}</strong>
      </v-chip>
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        v-if="!multiple"
        :small="dense"
        color="secondary"
        :close="!disabled"
      >
        <strong v-if="nested">{{ item[nested] }}</strong>
        <strong v-else>{{ item }}</strong>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    label: String,
    value: {},
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    prependIcon: { type: String, default: "" },
    appendIcon: { type: String, default: "" },
    suffix: { type: String, default: "" },
    textSuffix: { type: String, default: "" },
    prefix: { type: String, default: "" },
    showtextfield: { type: Boolean, default: true },
    type: { type: String, default: "text" },
    tooltip: { type: String, default: "" },
    clearable: {},
    filled: { type: Boolean, default: false },
    items: {},
    multiple: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    nested: { type: String, default: "" },
  },
  computed: {
    sync_value: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.nested != "") {
          var arr = [];
          /* eslint-disable no-unused-vars */
          for (const [key, value] of Object.entries(val)) {
            //console.log(`${key}: ${typeof value}`);
            if (typeof value === "string") {
              arr.push({ name: value });
            } else {
              arr.push(value);
            }
          }
          /* eslint-enable no-unused-vars */
          val = arr;
        }
        this.$emit("update:value", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    remove(item) {
      this.sync_value.splice(this.sync_value.indexOf(item), 1);
      this.sync_value = [...this.sync_value];
    },
  },
  watch: {},
};
</script>
