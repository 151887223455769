<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiAccountKey }}</v-icon>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="apiLogin" ref="bedienerForm">
            <v-card-text>
              <v-text-field
                label="Bedienernummer"
                name="Bedienernummer"
                ref="bedienerTextField"
                :prepend-icon="$i.mdiAccount"
                type="number"
                v-model="bedienernummer"
                v-if="false"
              ></v-text-field>
              <v-text-field
                label="Marktnummer (8-stellig)"
                name="Marktnummer"
                ref="marktTextField"
                :prepend-icon="$i.mdiWarehouse"
                type="number"
                v-model="marktnummer"
                v-if="false"
              ></v-text-field>
              <v-text-field
                label="Token"
                name="Token"
                ref="tokenTextField"
                :prepend-icon="$i.mdiShieldKey"
                :type="show_password ? 'text' : 'password'"
                :append-icon="show_password ? $i.mdiEye : $i.mdiEyeOff"
                @click:append="show1 = !show1"
                v-model="token"
                v-if="false"
              ></v-text-field>
              <v-text-field
                label="E-Mail"
                name="Email"
                ref="emailTextField"
                :prepend-icon="$i.mdiEmail"
                v-model="email"
              ></v-text-field>
              <v-text-field
                label="Passwort"
                name="Passwort"
                ref="passwordTextField"
                :prepend-icon="$i.mdiShieldKey"
                :type="show_password ? 'text' : 'password'"
                :append-icon="show_password ? $i.mdiEye : $i.mdiEyeOff"
                @click:append="show_password = !show_password"
                v-model="password"
              ></v-text-field>
              <v-btn block color="primary" type="submit">Anmelden</v-btn>
              <v-btn block text class="mt-2" @click.stop="dialog = !dialog"
                >Passwort vergessen</v-btn
              >
              <v-dialog v-model="dialog" max-width="400px">
                <reset-password @closed="dialog = false" />
              </v-dialog>
            </v-card-text>
          </v-form>
          <v-snackbar v-model="snackbar">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="" text v-bind="attrs" @click="snackbar = false">
                schließen
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
        <v-card class="mt-2" flat outlined color="transparent">
          <v-card-actions>
            <v-spacer></v-spacer
            ><v-btn color="accent" to="/register">Registrieren</v-btn
            ><v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import resetPassword from "@/components/ResetPassword.vue";
export default {
  name: "LoginView",
  components: {
    resetPassword,
  },
  props: {
    source: String,
  },
  data: () => ({
    marktnummer: "",
    bedienernummer: "",
    company: "",
    email: "",
    password: "",
    token: "",
    loaded: false,
    snackbarText: "Login fehlgeschlagen",
    snackbar: false,
    timer_check_login: "",
    show_password: false,
    dialog: false,
  }),
  methods: {
    setUser() {
      this.$store.dispatch("setLocation", this.marktnummer);
      this.$store.commit("setUser", this.bedienernummer);
      this.$store.commit("setCompany", this.company);
      if (this.$route.name != "Home") {
        this.$router.push({
          //name: "Suppliers",
          params: { location_id: this.marktnummer },
          name: "Home",
        });
      }
    },
    apiLogin() {
      if (this.email && this.password) {
        this.loading = true;
        let statusCode = 0;
        this.$api
          .post(
            "login",
            {
              // user: this.bedienernummer,
              // location: this.marktnummer,
              // token: this.token,
              email: this.email,
              password: this.password,
            },
            { withCredentials: true }
          )
          .then(
            (response) => (
              (statusCode = response.data.status),
              (this.company = response.data.company),
              this.setUser(),
              this.$store.dispatch("apiGetUser", this.$store.state.location_id)
            )
          )
          .catch(
            (error) => (
              (statusCode = error.response.data.status), (this.snackbar = true)
            )
          )
          .finally(() => (this.loaded = true));
        return statusCode;
      }
    },
    check_login() {
      if (this.$store.state.logged_in && this.$route.name != "Home") {
        this.$router.push({
          name: "Home",
        });
        clearInterval(this.timer_check_login);
      }
    },
  },
  mounted() {
    //this.timer_check_login = setInterval(this.check_login, 100);
  },
};
</script>

<style></style>
