<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="mx-auto elevation-6">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiSackPercent }}</v-icon>
            <v-toolbar-title>Spannenrechner</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="clearForm" ref="marginForm">
            <v-card-text>
              <v-row>
                <v-col>
                  <iir-currency-input
                    label="EK (netto)"
                    dense
                    :precision="2"
                    :value.sync="ek"
                    reverse
                    ref="ek"
                    re="ref"
                  >
                  </iir-currency-input>
                </v-col>
                <v-col>
                  <iir-currency-input
                    label="VK (brutto)"
                    dense
                    :precision="2"
                    reverse
                    :value.sync="vk"
                    ref="vk"
                    re="ref"
                  >
                  </iir-currency-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">USt.</th>
                          <th class="text-center">{{ vat_rates.reduced }}%</th>
                          <th class="text-center">{{ vat_rates.full }}%</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Spanne</td>
                          <td class="text-center">
                            {{ results.margin.reduced }}%
                          </td>
                          <td class="text-center">
                            {{ results.margin.full }}%
                          </td>
                        </tr>
                        <tr>
                          <td>Rohertrag</td>
                          <td class="text-center">
                            {{ results.income.reduced }}€
                          </td>
                          <td class="text-center">
                            {{ results.income.full }}€
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" v-on:click="clearForm()"
                >Löschen <v-icon right>{{ $i.mdiCached }}</v-icon></v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import iirCurrencyInput from "@/components/iirCurrencyInput.vue"
import { parse, setValue } from "vue-currency-input"
export default {
  name: "MarginView",
  components: {
    iirCurrencyInput,
  },
  props: {
    source: String,
  },
  data: () => ({
    vat_rates: { reduced: 7, full: 19 },
    ek: null,
    vk: null,
  }),
  computed: {
    results: function () {
      let ret = {
        margin: { full: 0, reduced: 0 },
        income: { full: 0, reduced: 0 },
      }
      if (this.ek && this.vk) {
        ret.income = {
          full:
            (
              (this.parseNumber(this.vk) / (100 + this.vat_rates.full)) * 100 -
              this.parseNumber(this.ek)
            ).toFixed(2) || 0,
          reduced:
            (
              (this.parseNumber(this.vk) / (100 + this.vat_rates.reduced)) *
                100 -
              this.parseNumber(this.ek)
            ).toFixed(2) || 0,
        }
        ret.margin.full =
          (
            ret.income.full /
            (this.parseNumber(this.vk) / (100 + this.vat_rates.full))
          ).toFixed(2) || 0
        ret.margin.reduced =
          (
            ret.income.reduced /
            (this.parseNumber(this.vk) / (100 + this.vat_rates.reduced))
          ).toFixed(2) || 0
      }
      return ret
    },
  },
  methods: {
    clearForm() {
      this.ek = null
      this.vk = null
      setValue(this.$refs["ek"].$refs["ref"], null)
      setValue(this.$refs["vk"].$refs["ref"], null)
    },
    parseNumber(val) {
      return parse(val, {
        suffix: "",
        precision: "",
        locale: "DE",
        currency: "EUR",
      })
    },
  },
}
</script>

<style></style>
