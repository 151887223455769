<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiAccountPlus }}</v-icon>
            <v-toolbar-title>Gesellschaft registrieren</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="apiRegisterCompany" ref="registerForm">
            <v-card-text>
              <v-text-field
                label="Firma"
                name="name"
                ref="nameTextField"
                :prepend-icon="$i.mdiEmail"
                v-model="formData.name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Geschäftsbezeichnung"
                name="name_informal"
                ref="informalNameTextField"
                :prepend-icon="$i.mdiAccount"
                v-model="formData.name_informal"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Anschrift"
                name="address"
                ref="addressTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.address"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Postleitzahl"
                name="postcode"
                ref="postcodeTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.postcode"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Stadt"
                name="city"
                ref="cityNameTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.city"
                :rules="[rules.required]"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit">Registrieren</v-btn>
            </v-card-actions>
          </v-form>
          <v-snackbar v-model="snackbar">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="" text v-bind="attrs" @click="snackbar = false">
                schließen<v-icon right>{{ $i.mdiSend }}</v-icon></v-btn
              >
            </template>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegisterCompany",
  props: {
    source: String,
  },
  data: () => ({
    registered: false,
    snackbarText: "Login fehlgeschlagen",
    snackbar: false,
    formData: {
      name: "",
      name_informal: "",
      address: "",
      postcode: "",
      city: "",
      utp_cluster: null,
    },
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    },
    show_password: false,
  }),
  methods: {
    setUser() {
      this.$store.dispatch("apiGetUser")
      this.$router.push({
        name: "Home",
      })
    },
    apiRegisterCompany() {
      this.loading = true
      let statusCode = 0
      this.$api
        .post("register_company", this.formData)
        .then((response) => (statusCode = response.data.status))
        .catch(
          (error) => (
            (statusCode = error.response.data.status),
            (this.snackbar = true),
            error.response.data.error == "duplicate"
              ? this.$root.toast.error("Gesellschaft registriert")
              : null
          )
        )
        .finally(() => {
          ;(this.registered = true), this.setUser()
        })
      return statusCode
    },
  },
}
</script>

<style></style>
