<template>
  <div>
    <v-layout>
      <v-simple-checkbox
        v-model="item.archived"
        :ripple="false"
        dense
        @input="checked_box(item, 'archived')"
      ></v-simple-checkbox>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            color="secondary"
            rounded
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            v-show="
              ((item.finalized && item.receipt_type == 'Rechnung') ||
                item.receipt_type == 'Lieferschein' ||
                item.receipt_type == 'Zahlungsavis' ||
                item.receipt_type == 'WKPS' ||
                item.receipt_type == 'THM') &&
              !item.archived
            "
            @click.stop="apiMoveToNextcloud(item)"
            ><v-icon> {{ $i.mdiCloudUpload }} </v-icon></v-btn
          >
        </template>
        <span>Ins Archiv verschieben</span>
      </v-tooltip>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ReceiptDataTableArchived",
  components: {},
  props: {
    item: {},
  },
  data: () => ({
    disabled: false,
  }),
  computed: {},
  methods: {
    checked_box(item, box) {
      this.apiPutReceipt(item["public_id"], { [box]: item[box] })
    },
    apiMoveToNextcloud(item) {
      this.disabled = true
      this.$api
        .get(
          "receipts/" +
            this.$store.state.location_id +
            "/" +
            item.public_id +
            "/archive_file"
        )
        .then(() => {
          this.apiPutReceipt(item["public_id"], { ["archived"]: true })
          item.archived = true
          this.disabled = false
        })
        .catch()
    },
    apiPutReceipt(receipt_id, obj) {
      this.$api
        .put(
          "receipts/" + this.$route.params.location_id + "/" + receipt_id,
          obj,
          {
            withCredentials: true,
          }
        )
        .then()
        .catch()
    },
  },
  watch: {},
  mounted() {},
  created() {},
}
</script>

<style scoped>
</style>