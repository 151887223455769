<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12" v-show="!registered">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiAccountPlus }}</v-icon>
            <v-toolbar-title>Registrieren</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="apiRegister" ref="registerForm">
            <v-card-text>
              <v-text-field
                label="E-Mail"
                name="email"
                ref="emailTextField"
                :prepend-icon="$i.mdiEmail"
                v-model="formData.email"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Vorname"
                name="first_name"
                ref="firstNameTextField"
                :prepend-icon="$i.mdiAccount"
                v-model="formData.first_name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Nachname"
                name="last_name"
                ref="lasttNameTextField"
                :prepend-icon="$i.mdiAccountOutline"
                v-model="formData.last_name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Passwort"
                name="password"
                ref="passwordTextField"
                :prepend-icon="$i.mdiShieldKey"
                :type="show_password ? 'text' : 'password'"
                :append-icon="show_password ? $i.mdiEye : $i.mdiEyeOff"
                @click:append="show_password = !show_password"
                v-model="formData.password"
                hint="Mindestens 8 Zeichen"
                :rules="[rules.required, rules.min]"
                counter
              ></v-text-field>
              <v-checkbox
                v-model="formData.privacy_agreed"
                :prepend-icon="$i.mdiShieldLock"
              >
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="/privacy"
                          @click.stop
                          v-on="on"
                        >
                          Datenschutzbedingungen
                        </a>
                      </template>
                      Wird in neuem Tab geöffnet
                    </v-tooltip>
                    akzeptiert
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="primary"
                type="submit"
                :disabled="!formData.privacy_agreed"
                >Registrieren</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-snackbar v-model="snackbar">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="" text v-bind="attrs" @click="snackbar = false">
                schließen<v-icon right>{{ $i.mdiSend }}</v-icon></v-btn
              >
            </template>
          </v-snackbar>
        </v-card>
        <v-card class="elevation-12" v-show="registered">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiAccountPlus }}</v-icon>
            <v-toolbar-title>Registrieren</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            Registrierung erfolgreich.<br />
            Bitte
            <router-link to="/login" v-show="!logged_in">einloggen</router-link>
            <router-link to="/" v-show="logged_in && companies_count"
              >Markt auswählen</router-link
            >
            <router-link
              to="/register_company"
              v-show="logged_in && !companies_count"
              >Gesellschaft registrieren, wenn Sie der Inhaber
              sind.</router-link
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegisterPage",
  props: {
    source: String,
  },
  data: () => ({
    registered: false,
    snackbarText: "Login fehlgeschlagen",
    snackbar: false,
    formData: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      privacy_agreed: false,
    },
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      min: (v) => v.length >= 8 || "Mindestens 8 Zeichen",
    },
    show_password: false,
  }),
  computed: {
    logged_in() {
      return this.$store.state.logged_in
    },
    companies_count() {
      if (this.$store.state.user_obj && this.$store.state.user_obj.companies)
        return this.$store.state.user_obj.companies.length
      else return 0
    },
  },
  methods: {
    setUser() {
      //   this.$store.commit("setLocation", this.marktnummer);
      //   this.$store.commit("setUser", this.bedienernummer);
      //   this.$store.commit("setCompany", this.company);
      //   this.$router.push({
      //     //name: "Suppliers",
      //     params: { location_id: this.marktnummer },
      //     name: "Home",
      //   });

      this.$router.push({
        name: "Home",
      })
    },
    apiRegister() {
      this.loading = true
      let statusCode = 0
      this.$api
        .post("register", this.formData)
        .then(
          (response) => (
            (statusCode = response.data.status),
            statusCode == 200 ? this.setUser() : null,
            (this.registered = true),
            this.$store.dispatch("apiGetUser")
          )
        )
        .catch(
          (error) => (
            (statusCode = error.response.data.status),
            (this.snackbar = true),
            error.response.data.error == "duplicate"
              ? this.$root.toast.error("Email bereits registriert")
              : null,
            error.response.data.error == "email required"
              ? this.$root.toast.error("Email nicht angegeben")
              : null
          )
        )
      // .finally(() => (this.registered = true))
      return statusCode
    },
  },
}
</script>

<style></style>
