<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="mx-auto elevation-6">
          <v-toolbar color="primary" dark flat dense>
            <v-icon class="mr-1">{{ $i.mdiCog }}</v-icon>
            <v-toolbar-title>Administration</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-tabs v-model="tab" dark>
              <v-tab href="#account">
                <v-icon>{{ $i.mdiAccount }}</v-icon>
                Account
              </v-tab>

              <v-tab href="#assignment" v-if="is_any_admin">
                <v-icon>{{ $i.mdiLinkVariant }}</v-icon>
                Zuordnung
              </v-tab>

              <v-tab href="#company" v-if="is_any_admin">
                <v-icon>{{ $i.mdiDomain }}</v-icon>
                Unternehmen
              </v-tab>
              <v-tab href="#access_log" v-if="is_siteadmin">
                <v-icon>{{ $i.mdiDomain }}</v-icon>
                Log
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="account" :transition="false">
                <admin-tab-account
                  v-if="$store.state.user_obj"
                ></admin-tab-account>
              </v-tab-item>

              <v-tab-item
                value="assignment"
                :transition="false"
                v-if="is_any_admin"
              >
                <v-card flat>
                  <v-card-text>
                    <admin-tab-authorization></admin-tab-authorization>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item
                value="company"
                :transition="false"
                v-if="is_any_admin"
              >
                <admin-tab-company></admin-tab-company>
              </v-tab-item>
              <v-tab-item
                value="access_log"
                :transition="false"
                v-if="is_siteadmin"
              >
                <admin-tab-access-log></admin-tab-access-log>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdminTabAccount from "@/components/admin/AdminTabAccount.vue"
import AdminTabAuthorization from "@/components/admin/AdminTabAuthorization.vue"
import AdminTabCompany from "@/components/admin/AdminTabCompany.vue"
import AdminTabAccessLog from "@/components/admin/AdminTabAccessLog.vue"

export default {
  name: "AdminView",
  components: {
    AdminTabAccount,
    AdminTabCompany,
    AdminTabAuthorization,
    AdminTabAccessLog,
  },
  data: () => ({
    tab: null,
  }),
  created() {},
  activated() {},
  methods: {},
  computed: {
    is_any_admin() {
      if (this.$store.state.user_obj) {
        let c = this.$store.state.user_obj.companies || []
        return c.some((x) => x.is_admin == true)
      } else {
        return false
      }
    },
    is_siteadmin() {
      return this.$store.state.user_obj?.is_siteadmin ?? false
    },
  },
}
</script>
