<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg
    viewBox="0 0 507 203"
    :width="w"
    :height="h"
    style="background: rgb(225, 229, 220)"
  >
    <rect
      x="0"
      y="0"
      width="507"
      height="203"
      style="stroke: rgb(0, 0, 0); fill: rgb(225, 229, 220); stroke-opacity: 0"
    />
    <text
      id="hm"
      style="font-family: Arial, sans-serif; font-size: 40px; white-space: pre"
      x="52.17"
      y="54.718"
    >
      HM
    </text>
    <text
      id="gm"
      style="font-family: Arial, sans-serif; font-size: 40px; white-space: pre"
      x="49.87"
      y="99.177"
    >
      GM
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 22px; white-space: pre"
      x="28.153"
      y="140.135"
    >
      Ware VK:
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 22px; white-space: pre"
      x="27.305"
      y="170.76"
    >
      Ware VK:
    </text>
    <text
      id="red"
      style="font-family: Arial, sans-serif; font-size: 22px; white-space: pre"
      x="174.039"
      y="140.691"
    >
      7%
    </text>
    <text
      id="full"
      style="font-family: Arial, sans-serif; font-size: 22px; white-space: pre"
      x="158.449"
      y="171.193"
    >
      19%
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 21px; white-space: pre"
      x="155.665"
      y="53.643"
    >
      10 Kolo
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 21px; white-space: pre"
      x="260.373"
      y="54.323"
    >
      11 O+G
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 21px; white-space: pre"
      x="366.725"
      y="53.766"
    >
      30 Service
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 21px; white-space: pre"
      x="155.691"
      y="97.012"
    >
      12 Getränke
    </text>
    <text
      id="dep_others"
      style="font-family: Arial, sans-serif; font-size: 21px; white-space: pre"
      x="303.396"
      y="96.919"
    >
      {{ other_department }}
    </text>
    <rect
      x="26.499"
      y="32.123"
      width="21.206"
      height="21.764"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <rect
      x="134.205"
      y="80.902"
      width="15.625"
      height="16.037"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <rect
      x="133.089"
      y="37.931"
      width="15.625"
      height="16.037"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <rect
      x="241.352"
      y="37.373"
      width="15.625"
      height="16.037"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <rect
      x="347.383"
      y="36.257"
      width="15.625"
      height="16.037"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <rect
      x="283.206"
      y="79.786"
      width="15.625"
      height="16.037"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <text
      style="font-family: Arial, sans-serif; font-size: 22px; white-space: pre"
      x="230"
      y="141.378"
      content-value="param(test)"
    >
      MWST {{ val_red }}
    </text>
    <text
      style="font-family: Arial, sans-serif; font-size: 22px; white-space: pre"
      x="230"
      y="169.095"
    >
      MWST {{ val_full }}
    </text>
    <line
      x1="333.666"
      y1="145.498"
      x2="477.711"
      y2="145.704"
      style="fill: rgb(51, 51, 51)"
    />
    <!-- <rect x="332.841" y="143.247" width="144.87" height="1.857" style="" />
    <rect x="332.666" y="170.921" width="144.87" height="1.857" style="" /> -->
    <rect
      x="26.577"
      y="74.351"
      width="21.206"
      height="21.764"
      style="
        paint-order: stroke;
        stroke: rgb(0, 0, 0);
        stroke-width: 4px;
        fill: rgb(225, 229, 220);
      "
    ></rect>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 29px; white-space: pre;'"
      :visibility="market(1) ? 'visible' : 'hidden'"
      v-if="market(1)"
      x="27.558"
      y="53.396"
    >
      X
    </text>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 29px; white-space: pre;'"
      :visibility="market(2) ? 'visible' : 'hidden'"
      v-if="market(2)"
      x="26.986"
      y="95.816"
    >
      X
    </text>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 21px; white-space: pre;'"
      :visibility="department(10) ? 'visible' : 'hidden'"
      v-if="department(10)"
      x="133.934"
      y="53.505"
    >
      X
    </text>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 21px; white-space: pre;'"
      x="134.838"
      y="96.683"
      :visibility="department(12) ? 'visible' : 'hidden'"
      v-if="department(12)"
    >
      X
    </text>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 21px; white-space: pre;'"
      x="241.967"
      y="52.939"
      :visibility="department(11) ? 'visible' : 'hidden'"
      v-if="department(11)"
    >
      X
    </text>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 21px; white-space: pre;'"
      x="284.068"
      y="95.495"
      :visibility="department(40) ? 'visible' : 'hidden'"
      v-if="department(40)"
    >
      X
    </text>
    <text
      :style="'font-family: Arial, sans-serif; font-size: 21px; white-space: pre;'"
      x="348.268"
      y="51.864"
      :visibility="department(30) ? 'visible' : 'hidden'"
      v-if="department(30)"
    >
      X
    </text>
  </svg>
</template>

<script>
import departments_ids from "@/assets/departments_ids.json"

export default {
  name: "svgBewertung",

  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    departments: departments_ids,
  }),
  methods: {
    market(a) {
      return this.config.market == a
    },
    department(a) {
      if (this.is_main_department(a)) {
        return this.config.department == a
      } else {
        return !this.is_main_department(this.config.department)
      }
    },
    is_main_department(a) {
      return [10, 11, 12, 30].includes(a)
    },
  },
  computed: {
    other_department() {
      return this.is_main_department(this.config.department)
        ? "Sonstige"
        : "" +
            this.config.department +
            this.departments.filter((a) => a.id == this.config.department)[0]
              .name
    },
    val_full() {
      let val = this.config.vk_full.split(" ")
      let ret = parseFloat(val[0]).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      if (val.length > 1) {
        ret +=
          " (" +
          parseFloat(val[1]).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          ")"
      }
      return ret
    },
    val_red() {
      let val = this.config.vk_reduced.split(" ")
      let ret = parseFloat(val[0]).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      if (val.length > 1) {
        ret +=
          " (" +
          parseFloat(val[1]).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          ")"
      }
      return ret
      // return parseFloat(this.config.vk_reduced).toLocaleString("de-DE", {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // })
    },
    // width="330"
    // height="134"
    w() {
      return this.config.page_portrait ? 330 : 233
    },
    h() {
      return this.config.page_portrait ? 134 : 95
    },
  },
}
</script>

<style scoped>
svg {
  background-color: rgb(225, 229, 220);
}
svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>