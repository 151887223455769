<template>
  <div class="contacts">
    <v-card class="mx-auto elevation-6" v-if="initialized">
      <v-toolbar color="primary" dark flat dense>
        <v-icon class="mr-1">{{ $i.mdiAccountMultiple }}</v-icon>
        <v-toolbar-title>Kontakte</v-toolbar-title>
        <title-toolbar-print />
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <iir-search-input v-model="search_filter"></iir-search-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="contacts_table"
        :items="contacts"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, -1],
          'items-per-page-text': 'Einträge pro Seite',
          'items-per-page-all-text': 'Alle',
          'page-text': '{0}-{1} von {2}',
          'show-current-page': true,
        }"
        class="elevation-1"
        :search="search_filter"
        :custom-filter="customSearch"
        :sort-by="['company', 'name']"
        :sort-asc="['true']"
        item-key="public_id"
        @click:row="handleClick"
        dense
        mobile-breakpoint="0"
      >
        <template v-slot:item.departments="{ item }">
          <v-chip
            small
            v-for="dep in item.departments"
            :key="dep"
            color="secondary"
          >
            {{ dep }}
          </v-chip>
        </template>
        <template v-slot:item.suppliers="{ item }">
          <v-chip
            small
            v-for="supplier in item.suppliers"
            :key="supplier.supplier_id"
            color="secondary"
          >
            {{ supplier.name }}
          </v-chip>
        </template>
        <template v-slot:item.full_name="{ item }">
          {{ item.first_name }} {{ item.name }}
        </template>
        <template v-slot:item.company="{ item }">
          {{ item.company.replace(/(.{30})..+/, "$1 …") }}
        </template>
        <template v-slot:item.type_company="{ item }">
          {{ item.type }} {{ item.company ? "(" + item.company + ")" : "" }}
        </template>
        <template v-slot:item.tel="{ item }">
          <span style="white-space: nowrap">{{ item.tel }}</span>
        </template>
        <template v-slot:item.fax="{ item }">
          <span style="white-space: nowrap">{{ item.fax }}</span>
        </template>
        <template v-slot:item.landline="{ item }">
          <span style="white-space: nowrap">{{ item.landline }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 d-print-none"
      fab
      dark
      color="secondary"
      fixed
      right
      bottom
      to="add"
      append
    >
      <v-icon dark> {{ $i.mdiPlus }} </v-icon>
    </v-btn>
  </div>
</template>

<script>
import IirSearchInput from "@/components/iirSearchInput.vue"
import TitleToolbarPrint from "@/components/TitleToolbarPrint.vue"

export default {
  name: "ContactsView",
  components: {
    IirSearchInput,
    TitleToolbarPrint,
  },
  data: () => ({
    initialized: false,
  }),
  computed: {
    search_filter: {
      get() {
        return this.$store.state.contacts_search_filter
      },
      set(value) {
        this.$store.commit("CONTACTS_FILTER_SEARCH", value)
      },
    },
    contacts_table() {
      let t = [
        { text: "Name", value: "name", sortable: true, hide: "smAndUp" },
        {
          text: "Vorname",
          value: "first_name",
          sortable: true,
          hide: "smAndUp",
        },
        { text: "Name", value: "full_name", sortable: true, hide: "xsOnly" },
        {
          text: "Position",
          value: "type_company",
          sortable: true,
        },
        { text: "Firma", value: "company", sortable: true, hide: "smAndUp" },
        {
          text: "Position",
          value: "type",
          align: "start",
          sortable: true,
          hide: "smAndUp",
        },
        { text: "Telefon", value: "tel", sortable: true, hide: "smAndDown" },
        {
          text: "Festnetz",
          value: "landline",
          sortable: true,
          hide: "smAndDown",
        },
        { text: "Email", value: "email", sortable: true, hide: "smAndDown" },
        { text: "Fax", value: "fax", sortable: true, hide: "smAndDown" },
        {
          text: "Lieferant/en",
          value: "suppliers",
          sortable: true,
        },
      ]
      return t.filter((h) => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    contacts() {
      let c = this.$store.state.contacts || []
      c = c.map(function (x) {
        x["suppliers_list"] = x.suppliers.map(function (el) {
          return el.name
        })
        return x
      })

      return c
    },
  },
  methods: {
    handleClick(row) {
      if (event.shiftKey || event.ctrlKey) {
        let route = this.$router.resolve({
          name: "ShowContact",
          params: { public_id: row.public_id },
        })
        window.open(route.href, "_blank")
        self.focus()
      } else {
        this.$router.push({
          name: "ShowContact",
          params: { public_id: row.public_id },
        })
      }
    },
    // customSearch(value, search, item) {
    //   const getObjectValues = (obj) =>
    //     obj && typeof obj === "object"
    //       ? Object.values(obj).map(getObjectValues).flat()
    //       : [obj];
    //   // console.log(getObjectValues(item));
    //   return getObjectValues(item).some(
    //     (v) => v && v.toString().toLowerCase().includes(search)
    //   );
    // },
    customSearch(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase())
      })
    },
  },
  watch: {},
  mounted: function () {},
  created() {
    this.$store.dispatch("apiGetContacts", this.$route.params.location_id)
    this.initialized = true
  },
}
</script>
